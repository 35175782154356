import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
      cacheTime: 3600000,
      useErrorBoundary: true,
      staleTime: 3600000,
    },
    mutations: {
      retry: 3,
    },
  },
});
