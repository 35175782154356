import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import {
  GridRow,
  ProgramTermAprHeader,
  ProgramTermMoneyFactorHeader,
  QuickQuoteFormValues,
} from 'components/pdp/payment-customization-block/quick-quote-modal/QuickQuoteService';

import {
  Fee,
  PaymentMethod,
  TaxCreditTo,
  TaxRate,
} from 'services/payment-grid/PaymentGridApiModels.generated';
import {
  mapAddOnsPaymentMethod,
  mapFeePaymentMethod,
  mapFirstLeasePaymentMethod,
  mapTaxCreditTo,
} from 'services/payment-grid/PaymentGridApiRequestService';
import { LocationWithZip } from 'services/ZipCodeApiService';

import { TtlResult } from './QuickQuoteApiResponseService';

export interface Buyer {
  email: string | undefined;
  fullName: string | undefined;
  location: LocationWithZip | undefined;
  phone: string | undefined;
}

export interface Row {
  downPayment: number;
  maxOutOfPocket: number;
}

export interface Rows {
  row1: Row;
  row2: Row | undefined;
  row3: Row | undefined;
  row4: Row | undefined;
}

export enum ResidualValueType {
  Percentage = 'Percentage',
  Amount = 'Amount',
}

export interface LeaseColumn {
  moneyFactor: number;
  residual: number;
  residualValueType: ResidualValueType;
  term: number;
}

export interface LeaseColumns {
  column1: LeaseColumn | undefined;
  column2: LeaseColumn | undefined;
  column3: LeaseColumn | undefined;
  column4: LeaseColumn | undefined;
}

export interface LeaseOptions {
  columns: LeaseColumns;
  firstPaymentMethod: PaymentMethod;
  mileage: number;
  rows: Rows;
}

export interface LoanColumn {
  apr: number;
  term: number;
}

export interface LoanColumns {
  column1: LoanColumn | undefined;
  column2: LoanColumn | undefined;
  column3: LoanColumn | undefined;
  column4: LoanColumn | undefined;
}

export interface LoanOptions {
  columns: LoanColumns;
  rows: Rows;
}

export interface PriceRangeOptions {
  dueAtSigningInterval: number;
  enabled: boolean;
  paymentInterval: number;
}

export interface TradeIn {
  allowance: number;
  balance: number;
  leaseTaxCreditTo: TaxCreditTo;
  loanTaxCreditTo: TaxCreditTo;
  make: string | undefined;
  mileage: number | undefined;
  model: string | undefined;
  value: number;
  vin: string | undefined;
  year: number | undefined;
}

export interface LeaseSalesTax {
  fee: number;
  isPaidInLease: boolean;
  monthlyFee: number;
  rate: TaxRate;
}

export interface LoanSalesTax {
  fee: number;
  rate: TaxRate;
}

export interface ProgramTtl<T> {
  fees: Fee[];
  salesTax: T;
}

export enum TtlPrecision {
  High = 'High',
  Specified = 'Specified',
}

export interface TtlOptions {
  precision: TtlPrecision;
  specifiedValue: TtlResult | undefined;
}

export interface QuickQuoteRequest {
  addonsPaymentMethod: PaymentMethod;
  buyer: Buyer;
  feePaymentMethod: PaymentMethod;
  lease: LeaseOptions | undefined;
  loan: LoanOptions | undefined;
  priceRange: PriceRangeOptions | undefined;
  tradeIn: TradeIn;
  ttl: TtlOptions;
}

export function getQuickQuoteApiUrl(carId: number): string {
  return `quick-quote/${carId}`;
}

export function getQuickQuotePdfApiUrl(carId: number): string {
  return `${getQuickQuoteApiUrl(carId)}/pdf`;
}

export interface CreateQuickQuoteRequestRequest {
  paymentInterval: number;
  dueAtSigningInterval: number;
  includeLease: boolean;
  includeLoan: boolean;
  paymentRangeEnabled: boolean;
  pdpFormValues: PdpFormValues;
  quickQuoteFormValues: QuickQuoteFormValues;
  ttl: TtlResult | undefined;
}

export function createQuickQuoteRequest({
  pdpFormValues,
  quickQuoteFormValues,
  ttl,
  ...rest
}: CreateQuickQuoteRequestRequest): QuickQuoteRequest {
  const lease = rest.includeLease
    ? mapLeaseOptions(pdpFormValues, quickQuoteFormValues)
    : undefined;
  const loan = rest.includeLoan ? mapLoanOptions(quickQuoteFormValues) : undefined;

  return {
    addonsPaymentMethod: mapAddOnsPaymentMethod(pdpFormValues),
    buyer: mapBuyer(pdpFormValues),
    feePaymentMethod: mapFeePaymentMethod(pdpFormValues),
    lease: lease?.columns.column1 ? lease : undefined,
    loan: loan?.columns.column1 ? loan : undefined,
    priceRange: mapPriceRangeOptions(rest),
    tradeIn: mapTradeIn(pdpFormValues),
    ttl: mapTtl(ttl, quickQuoteFormValues),
  };
}

function mapBuyer(pdpFormValues: PdpFormValues): Buyer {
  return {
    email: pdpFormValues.buyer.email,
    fullName: pdpFormValues.buyer.name,
    location: pdpFormValues.buyer.location?.zipCode ? pdpFormValues.buyer.location : undefined,
    phone: pdpFormValues.buyer.phone,
  };
}

function mapLeaseOptions(
  pdpFormValues: PdpFormValues,
  quickQuoteFormValues: QuickQuoteFormValues
): LeaseOptions {
  const { leaseProgram: { millage } = { millage: 0, residualAmount: 0 } } = pdpFormValues;

  const column1 = tryMapLeaseColumn(quickQuoteFormValues.leaseHeader1);
  const column2 =
    column1 === undefined ? undefined : tryMapLeaseColumn(quickQuoteFormValues.leaseHeader2);
  const column3 =
    column2 === undefined ? undefined : tryMapLeaseColumn(quickQuoteFormValues.leaseHeader3);
  const column4 =
    column3 === undefined ? undefined : tryMapLeaseColumn(quickQuoteFormValues.leaseHeader4);

  return {
    columns: {
      column1,
      column2,
      column3,
      column4,
    },
    firstPaymentMethod: mapFirstLeasePaymentMethod(pdpFormValues),
    mileage: millage,
    rows: {
      row1: mapRow(quickQuoteFormValues.leaseRow1),
      row2: tryMapRow(quickQuoteFormValues.leaseRow2),
      row3: tryMapRow(quickQuoteFormValues.leaseRow3),
      row4: tryMapRow(quickQuoteFormValues.leaseRow4),
    },
  };
}

function tryMapRow(gridRow: GridRow): Row | undefined {
  return gridRow.cashDown > 0 ? mapRow(gridRow) : undefined;
}

function mapRow(gridRow: GridRow): Row {
  return {
    downPayment: gridRow.cashDown,
    maxOutOfPocket: gridRow.cashDown,
  };
}

function tryMapLeaseColumn(header: ProgramTermMoneyFactorHeader): LeaseColumn | undefined {
  return header.term > 0 && header.moneyFactor > 0 ? mapLeaseColumn(header) : undefined;
}

function mapLeaseColumn(header: ProgramTermMoneyFactorHeader): LeaseColumn {
  return {
    moneyFactor: header.moneyFactor,
    residual: header.residualValue,
    residualValueType: header.residualValueType,
    term: header.term,
  };
}

function mapLoanOptions(quickQuoteFormValues: QuickQuoteFormValues): LoanOptions | undefined {
  const column1 = tryMapLoanColumn(quickQuoteFormValues.loanHeader1);
  const column2 =
    column1 === undefined ? undefined : tryMapLoanColumn(quickQuoteFormValues.loanHeader2);
  const column3 =
    column2 === undefined ? undefined : tryMapLoanColumn(quickQuoteFormValues.loanHeader3);
  const column4 =
    column3 === undefined ? undefined : tryMapLoanColumn(quickQuoteFormValues.loanHeader4);

  return {
    columns: {
      column1,
      column2,
      column3,
      column4,
    },
    rows: {
      row1: mapRow(quickQuoteFormValues.loanRow1),
      row2: tryMapRow(quickQuoteFormValues.loanRow2),
      row3: tryMapRow(quickQuoteFormValues.loanRow3),
      row4: tryMapRow(quickQuoteFormValues.loanRow4),
    },
  };
}

function tryMapLoanColumn(header: ProgramTermAprHeader): LoanColumn | undefined {
  return header.apr > 0 && header.term > 0 ? mapLoanColumn(header) : undefined;
}

function mapLoanColumn(header: ProgramTermAprHeader): LoanColumn {
  return {
    apr: header.apr / 100,
    term: header.term,
  };
}

function mapPriceRangeOptions(request: {
  paymentInterval: number;
  dueAtSigningInterval: number;
  paymentRangeEnabled: boolean;
}): PriceRangeOptions | undefined {
  return {
    enabled: request.paymentRangeEnabled,
    dueAtSigningInterval: request.dueAtSigningInterval,
    paymentInterval: request.paymentInterval,
  };
}

function mapTradeIn(pdpFormValues: PdpFormValues): TradeIn {
  return {
    allowance: pdpFormValues.tradeInValue,
    balance: pdpFormValues.tradeInOutstandingBal,
    leaseTaxCreditTo: mapTaxCreditTo(pdpFormValues.leaseTaxCreditTo),
    loanTaxCreditTo: mapTaxCreditTo(pdpFormValues.loanTaxCreditTo),
    make: pdpFormValues.tradeVehicle?.make,
    mileage: pdpFormValues.tradeVehicle?.mileage,
    model: pdpFormValues.tradeVehicle?.model,
    value: pdpFormValues.actualCashValue,
    vin: pdpFormValues.tradeVehicle?.vin,
    year: pdpFormValues.tradeVehicle?.year,
  };
}
function mapTtl(
  ttl: TtlResult | undefined,
  quickQuoteFormValues: QuickQuoteFormValues
): TtlOptions {
  return !!ttl && isTtlValid(ttl, quickQuoteFormValues)
    ? {
        specifiedValue: mapSpecifiedTtl(ttl),
        precision: TtlPrecision.Specified,
      }
    : {
        specifiedValue: undefined,
        precision: TtlPrecision.High,
      };
}

function isTtlValid(
  ttl: TtlResult | undefined,
  quickQuoteFormValues: QuickQuoteFormValues
): boolean {
  return (
    (!quickQuoteFormValues.showLease || !!ttl?.lease.salesTax.fee) &&
    (!quickQuoteFormValues.showLoan || !!ttl?.loan.salesTax.fee)
  );
}
function mapSpecifiedTtl(ttl: TtlResult): TtlResult | undefined {
  if (!ttl.lease.salesTax.rate.supplemental) {
    ttl.lease.salesTax.rate.supplemental = {};
  }

  if (!ttl.loan.salesTax.rate.supplemental) {
    ttl.loan.salesTax.rate.supplemental = {};
  }

  return ttl;
}
