import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { AxiosContext } from 'providers/AxiosProvider';

import {
  DealOffer,
  DealOfferAdmin,
  getAllDealOffersUrl,
  mapDealOfferToDealOfferAdmin,
} from 'services/DealOfferApiService';

export const useDealOffersQuery = (
  customerId: number | null
): UseQueryResult<DealOfferAdmin[], unknown> => {
  const axiosClient = useContext(AxiosContext);

  const dealOffersQuery = useQuery(['dealOffersQuery', customerId], async () => {
    const { data } = await axiosClient.get<DealOffer[]>(getAllDealOffersUrl(customerId));

    return mapDealOfferToDealOfferAdmin(data);
  });

  return dealOffersQuery;
};
