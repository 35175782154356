export const distinct = <TItem>(sourceItems: TItem[]): TItem[] => {
  return [...sourceItems.reduce((distinct, i) => distinct.add(i), new Set<TItem>()).values()];
};

export const distinctBy = <TItem, TKey>(
  sourceItems: TItem[],
  keySelector: (item: TItem) => TKey
): TKey[] => {
  return [
    ...sourceItems.reduce((distinct, i) => distinct.add(keySelector(i)), new Set<TKey>()).values(),
  ];
};

export const groupByToMap = <TItem, TKey extends keyof any>(
  sourceItems: TItem[],
  keySelector: (item: TItem) => TKey
): Map<TKey, TItem[]> =>
  sourceItems.reduce((groups, item) => {
    const k = keySelector(item);
    let existing = groups.get(k);
    if (!existing) {
      existing = [];
      groups.set(k, existing);
    }
    existing.push(item);

    return groups;
  }, new Map<TKey, TItem[]>());

export const naturalJoin = (items: any[]): string => {
  switch (items.length) {
    case 1:
      return items[0];
    case 2:
      return items.join(' and ');
    default:
      return `${items.slice(0, -1).join(', ')} and ${items.slice(-1)}`;
  }
};
