import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';

import { DealerGroupSummary, getDealerGroupsApiUrl } from 'services/DealerGroupApiService';

import { useConfiguration } from './useConfiguration';

export interface HookResult {
  dealerGroups: UseQueryResult<DealerGroupSummary[], unknown>;
}

export const useDealerGroupsQuery = (): HookResult => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);
  const staleTime = 3600000;
  const { dealerApiUrl: dealerGroupApiUrl } = useConfiguration();

  const dealerGroupsQuery = useQuery(
    'dealerGroupsQuery',
    async () => {
      const { data } = await axiosClient.get<DealerGroupSummary[]>(getDealerGroupsApiUrl(), {
        baseURL: dealerGroupApiUrl,
      });

      return data;
    },
    {
      staleTime,
      onError: () => {
        enqueueSnackbar('Failed to load dealers', {
          variant: 'error',
        });
      },
    }
  );

  return {
    dealerGroups: dealerGroupsQuery,
  };
};
