import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { CustomAddonsAtom } from 'models/CustomAddonsModel';

import { CustomAddonFormValues } from './form-wrapper/useCustomAddOnFormWrapper';

interface HookResult {
  handleSaveCustomAddon: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isSubmitting: boolean;
}

export const useCustomAddOnForm = (): HookResult => {
  const [customAddonsValue, setCustomAddonsValue] = useRecoilState(CustomAddonsAtom);
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useFormContext<CustomAddonFormValues>();

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.stopPropagation();
      handleSubmit((values) => {
        setCustomAddonsValue([...customAddonsValue, { ...values, includeByDefault: false }]);
        reset();
      })(event);
    },
    [customAddonsValue]
  );

  return {
    handleSaveCustomAddon: handleOnSubmit,
    isSubmitting,
  };
};
