import React from 'react';

import { MultiSelectFormField } from 'components/molecules/form-fields';
import { formatTermResultString } from 'components/pdp/payment-customization-block/filter-fields/termFormFieldService';

import { useLoanTermFormField } from './useLoanTermFormField';

interface Props {
  loanTerms: number[];
}

// NOTE React won't rerender the Fields, rerendering is based on React key attribute: https://react-hook-form.com/faqs#Whyisdefaultvaluenotchangingcorrectlywithternaryoperator
export const LoanTerm: React.FC<Props> = ({ loanTerms }) => {
  const { options, current } = useLoanTermFormField({ loanTerms });

  return (
    <MultiSelectFormField
      key="calculationOptions.loanTerms"
      name="calculationOptions.loanTerms"
      label="LENGTH OF LOAN"
      dataCyPrefix="searchPage_customerSection_loan_term"
      options={options}
      value={current}
      fullWidth
      resultStringFormatter={formatTermResultString}
    />
  );
};
