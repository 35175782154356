import React from 'react';

import { Switch } from 'components/molecules/switch';

import { usePriceRangeSwitch } from './usePriceRangeSwitch';

export const PriceRangeSwitch: React.FC = () => {
  const { priceRangeValue, handlePriceRangeChange, showPriceRangeToggle } = usePriceRangeSwitch();

  return (
    <>
      {showPriceRangeToggle && (
        <Switch
          label="Show payment ranges"
          checked={priceRangeValue}
          onChange={handlePriceRangeChange}
          labelDataCy="universalAppBar_paymentRangesText"
          switchDataCy="universalAppBar_priceRange_switch"
        />
      )}
    </>
  );
};
