import { atom } from 'recoil';

export const dealOfferCodeAtom = atom<string | undefined>({
  key: 'dealOfferCode',
  default: undefined,
});

export const isSavingDealOfferAtom = atom<boolean>({
  key: 'isSavingSaveDealOffer',
  default: false,
});

export const shouldSaveDealOfferAtom = atom<boolean>({
  key: 'shouldSaveDealOffer',
  default: false,
});

export const dealOfferHasChangesAtom = atom<boolean>({
  key: 'dealOfferHasChanges',
  default: true,
});

export const dealOfferHasUnsavedCreditScoreAtom = atom<boolean>({
  key: 'dealOfferHasUnsavedCreditScore',
  default: false,
});
