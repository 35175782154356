import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface HookResult {
  navigateToCustomerDealOffers: (id?: number) => void;
}

export const useDealOffersListPageNavigation = (): HookResult => {
  const navigate = useNavigate();

  const navigateToCustomerDealOffers = useCallback((id?: number): void => {
    const baseUrl = '/administration/deal-offers';
    const url = id ? `${baseUrl}?customer=${id}` : baseUrl;

    navigate(url);
  }, []);

  return {
    navigateToCustomerDealOffers,
  };
};
