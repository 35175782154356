import { useCallback, useContext, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { getDefaultFormValues } from 'components/search/form-wrapper/SearchFormWrapperService';
import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { Customer } from 'services/CustomerService';
import {
  CurrentDealership,
  OrderByDefaultDirection,
  OrderDirection,
} from 'services/SearchApiService';

import {
  getDirectionText,
  getExcludedText,
  getOptions,
  getSortTitleText,
  getTitleText,
  getTransform,
  shouldUpdateOrderDirection,
} from './SearchFormHeaderService';

interface HookOptions {
  currentDealership: CurrentDealership;
  searchResultsCount: number;
  excludedCount: number;
}

interface HookResult {
  titleText: string;
  sortTitleText: string;
  excludedText?: string;
  directionText?: string;
  options: { value: number; label: string }[];
  handleOrderDirectionChange(): void;
  handleClearFilters(customer?: Customer): void;
  transform?: string;
}

export const useSearchFormHeader = ({
  currentDealership,
  searchResultsCount,
  excludedCount,
}: HookOptions): HookResult => {
  const { isDeskingManager, isGeneralManager } = useContext(PermissionsContext);
  const allowProfitSorting = isDeskingManager || isGeneralManager;
  const { control, setValue, reset } = useFormContext<SearchFormValues>();
  const values = useWatch({ control }) as SearchFormValues;
  const { orderBy } = values;
  const { orderDirection } = values;
  const options = getOptions(allowProfitSorting);
  const { current: initialOrderBy } = useRef(orderBy);

  const handleOrderDirectionChange = useCallback(() => {
    setValue(
      'orderDirection',
      orderDirection === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc
    );
  }, [orderDirection]);

  const handleClearFilters = useCallback(
    (customer?: Customer) => {
      const defaults = getDefaultFormValues({
        currentDealership,
        searchFormValues: undefined,
      });

      const newDefaults = {
        ...defaults,
        year: { from: defaults.year?.from ?? null, to: defaults.year?.to ?? null },
        mileage: { from: defaults.mileage?.from ?? null, to: defaults.mileage?.to ?? null },
        monthlyPayment: {
          from: defaults.monthlyPayment?.from ?? null,
          to: defaults.monthlyPayment?.to ?? null,
        },
        price: { from: defaults.price?.from ?? null, to: defaults.price?.to ?? null },
        calculationOptions: {
          ...defaults.calculationOptions,
          downPayment: currentDealership.downPayment,
          maxOutOfPocket: currentDealership.downPayment,
          location:
            customer?.location ?? values.customer.location ?? values.calculationOptions?.location,
        },
        customer: customer ?? values.customer,
      };

      reset(newDefaults);
    },
    [
      currentDealership,
      values.customer,
      values.customer?.location,
      values.calculationOptions?.location,
    ]
  );

  useEffect(() => {
    if (
      orderBy !== undefined &&
      shouldUpdateOrderDirection(initialOrderBy, orderBy, orderDirection)
    ) {
      setValue('orderDirection', OrderByDefaultDirection[orderBy]);
    }
  }, [orderBy]);

  return {
    titleText: getTitleText(searchResultsCount, orderBy),
    excludedText: getExcludedText(excludedCount),
    options,
    directionText: getDirectionText(orderDirection, orderBy),
    sortTitleText: getSortTitleText(orderBy),
    handleOrderDirectionChange,
    handleClearFilters,
    transform: getTransform(orderDirection),
  };
};
