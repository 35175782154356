import React from 'react';

import { AccessTime, Edit, Error, PersonSearch, VerifiedUser, Warning } from '@mui/icons-material';
import {
  Avatar,
  Button,
  CardContent,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { Card } from 'components/atoms/card';
import { TextFormField, ZipFormField } from 'components/molecules/form-fields';

import { CreditStatus, CustomerMode } from 'services/CustomerService';

import { theme } from 'utils/muiThemeUtils';

import { DealOfferStatus as DealCreditWarning } from './CustomerService';
import { useCustomerBlock } from './useCustomerBlock';

interface Props {
  isDisabled: boolean;
  isDealOffer: boolean;
}

export const CustomerBlock: React.FC<Props> = ({ isDisabled, isDealOffer }) => {
  const {
    customer,
    customerInitials,
    customerMode,
    dealOfferStatus,
    handleNavigateToCustomerDealOffers,
    handleFindCustomerByEmail,
    handleFindCustomerByPhone,
    handleEditCustomer,
    handleNewCustomer,
  } = useCustomerBlock({ isDealOffer });

  const StyledButton = styled(Button)({
    maxHeight: '2.5rem',
  });

  return (
    <Card elevation={13} data-cy="customerBlock" sx={{ minHeight: '450px' }}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="subtitle3">Current Customer</Typography>
          {customerMode === CustomerMode.Edit && (
            <Stack spacing={2}>
              <Stack spacing={2} direction="row">
                <TextFormField
                  size="small"
                  label="E-MAIL"
                  data-cy="pdpPage_customerForm_customerEmailInput"
                  placeholder="Enter e-mail"
                  focused
                  name="buyer.email"
                  disabled={isDisabled}
                  fullWidth
                />
                <StyledButton
                  disabled={isDisabled}
                  type="button"
                  variant="contained"
                  data-cy="pdpPage_customerForm_findCustomerButton"
                  onClick={handleFindCustomerByEmail}
                >
                  <PersonSearch></PersonSearch>
                </StyledButton>
              </Stack>
              <Stack spacing={2} direction="row">
                <TextFormField
                  size="small"
                  label="PHONE"
                  data-cy="pdpPage_customerForm_customerPhoneInput"
                  placeholder="Enter phone number"
                  focused
                  name="buyer.phone"
                  disabled={isDisabled}
                  fullWidth
                />
                <StyledButton
                  disabled={isDisabled}
                  type="button"
                  variant="contained"
                  data-cy="pdpPage_customerForm_findCustomerByPhoneButton"
                  onClick={handleFindCustomerByPhone}
                >
                  <PersonSearch></PersonSearch>
                </StyledButton>
              </Stack>
            </Stack>
          )}
          {customerMode === CustomerMode.ReadOnly && (
            <>
              <Grid container>
                <Grid item xs={10}>
                  <Stack spacing={2} direction="row">
                    <Avatar>{customerInitials}</Avatar>
                    <Stack>
                      {customer.email && (
                        <Typography data-cy="pdpPage_customerForm_customerEmail" variant="body1">
                          {customer.email}
                        </Typography>
                      )}
                      {customer.phone && (
                        <Typography data-cy="pdpPage_customerForm_customerPhone" variant="body1">
                          {customer.phone}
                        </Typography>
                      )}
                      <Typography
                        data-cy="pdpPage_customerForm_customerId"
                        variant="caption"
                        color={`${theme.palette.grey[500]}`}
                      >{`registration ID ${customer.id}`}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <StyledButton
                    disabled={isDisabled}
                    type="button"
                    variant="contained"
                    data-cy="pdpPage_customerForm_editCustomerButton"
                    onClick={handleEditCustomer}
                  >
                    <Edit></Edit>
                  </StyledButton>
                </Grid>
              </Grid>
              {dealOfferStatus === CreditStatus.Pending && (
                <Stack direction="row" spacing={1}>
                  <AccessTime color="warning"></AccessTime>
                  <Typography data-cy="pdpPage_customerForm_creditStatus">
                    credit pending
                  </Typography>
                </Stack>
              )}
              {dealOfferStatus === CreditStatus.Verified && (
                <Stack direction="row" spacing={1}>
                  <VerifiedUser color="success"></VerifiedUser>
                  <Typography data-cy="pdpPage_customerForm_creditStatus">
                    credit verified
                  </Typography>
                </Stack>
              )}
              {dealOfferStatus === DealCreditWarning.UnsavedCreditScore && (
                <Stack direction="row" spacing={1}>
                  <Warning color="warning"></Warning>
                  <Typography data-cy="pdpPage_customerForm_creditStatus">
                    credit score unsaved
                  </Typography>
                </Stack>
              )}
              {dealOfferStatus === DealCreditWarning.InvalidCreditScore && (
                <Stack direction="row" spacing={1}>
                  <Warning color="warning"></Warning>
                  <Typography data-cy="pdpPage_customerForm_creditStatus">
                    credit score invalid
                  </Typography>
                </Stack>
              )}
              {dealOfferStatus === DealCreditWarning.InvalidDeal && (
                <Stack direction="row" spacing={1}>
                  <Error color="error"></Error>
                  <Typography data-cy="pdpPage_customerForm_creditStatus">invalid deal</Typography>
                </Stack>
              )}
              <Divider />
            </>
          )}
          <TextFormField
            size="small"
            label="NAME"
            data-cy="pdpPage_customerForm_customerNameInput"
            placeholder="Enter name"
            focused
            name="buyer.name"
            disabled={isDisabled}
          />
          <ZipFormField
            dataCy="pdpPage_customerForm_customerZipInput"
            name="buyer.location.zipCode"
            parentName="buyer.location"
            isDisabled={isDisabled}
          />
          <Button
            type="button"
            variant="contained"
            data-cy="pdpPage_customerForm_newCustomerButton"
            onClick={handleNewCustomer}
          >
            NEW CUSTOMER
          </Button>
          <Button
            type="button"
            variant="contained"
            data-cy="pdpPage_customerForm_goToDealOffersButton"
            onClick={handleNavigateToCustomerDealOffers}
          >
            OPEN DEAL OFFERS
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
