import React from 'react';

import { Grid, Stack, TextField, Typography } from '@mui/material';

import { DealersTable } from 'components/administrations/dealers/table/DealersTable';
import { Layout } from 'components/molecules/layout';

import { useDealersListPage } from './useDealersListPage';

export const DealersListPage: React.FC = () => {
  const { columns, isFetching, tableData, canEditDealers, filterChangeHandler } =
    useDealersListPage();

  return (
    <Stack>
      <Layout>
        <Grid container spacing={2} paddingTop="48px" paddingX="16px">
          <Grid item xs={12}>
            <Typography data-cy="dealerListPage_pageTitle" variant="h6">
              Dealers
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              name="filterInput"
              onChange={filterChangeHandler}
              label="Filter dealers"
              data-cy="dealerListPage_filterInput"
              focused
              placeholder="Filter dealers by name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} paddingTop="40px">
            <DealersTable
              columns={columns}
              isLoading={isFetching}
              tableData={tableData}
              canEditDealers={canEditDealers}
            />
          </Grid>
        </Grid>
      </Layout>
    </Stack>
  );
};
