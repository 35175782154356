import React from 'react';
import CsvDownloadButton from 'react-json-to-csv';

import { Grid, Typography } from '@mui/material';

import { ReportTable } from 'components/molecules/report-table/ReportTable';

import { MinutesAgo, useReportDataQuery } from './useReportDataQuery';

export const UserReport: React.FC = () => {
  const {
    reportQuery: { data: reportData, isLoading },
  } = useReportDataQuery({
    minutesAgo: MinutesAgo.LastThirtyDays, // ToDo - Allow user to specify on the reports page.
  });

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Typography data-cy="usage_by_user_report_pageTitle" variant="h6">
            Usage By User Report (Last 30 days)
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="right">
          <CsvDownloadButton data={reportData} delimiter="," filename="userReport.csv">
            Download CSV
          </CsvDownloadButton>
        </Grid>
      </Grid>
      <Grid item xs={12} paddingTop="40px">
        <ReportTable
          tableData={reportData}
          groupedColumns={['dealerName']}
          sumColumns={['clickCount', 'searchCount']}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
};
