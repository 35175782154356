import React from 'react';
import { FormProvider } from 'react-hook-form';

import { CustomAddOnForm } from 'components/molecules/custom-add-on-form/CustomAddOnForm';

import { useCustomAddOnFormWrapper } from './useCustomAddOnFormWrapper';

export const CustomAddOnFormWrapper: React.FC = () => {
  const { methods } = useCustomAddOnFormWrapper();

  return (
    <FormProvider {...methods}>
      <CustomAddOnForm />
    </FormProvider>
  );
};
