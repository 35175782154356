import React from 'react';

import { ColorFilter, Filter } from 'services/SearchFiltersApiService';

export interface FilterItem<T> {
  label: React.ReactElement | string;
  value: T;
  isDisabled: boolean;
}

export interface ColorItem extends FilterItem<number> {}

export function getFilterItems<T>(filterData?: Filter<T>[]): FilterItem<T>[] {
  return (
    filterData?.map(({ code, count, name }) => ({
      value: code,
      label: `${name} (${count})`,
      isDisabled: count === 0,
    })) ?? []
  );
}

export function getColorItems(
  getLabel: (name: string, rgb: string, count: number) => React.ReactElement | string,
  filterData?: ColorFilter[]
): ColorItem[] {
  return (
    filterData?.map(({ code, count, name, rgb }) => ({
      value: code,
      label: getLabel(name, rgb, count),
      rgb,
      isDisabled: count === 0,
    })) ?? []
  );
}
