import { User, useAuth0 } from '@auth0/auth0-react';

export interface AuthUser {
  email?: string;
  userName?: string;
}

export const useAuthUser = (): AuthUser => {
  const { user } = useAuth0();

  return {
    email: user?.email,
    userName: getUserName(user),
  };
};

function getUserName(user: User | undefined): string | undefined {
  if (!user) return undefined;

  const { given_name: firstName = '', family_name: lastName = '', name = '' } = user;

  return firstName || lastName ? `${firstName} ${lastName}`.trim() : name;
}
