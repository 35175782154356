import React from 'react';

import { MultiSelectFormField } from 'components/molecules/form-fields';

import { formatTermResultString } from './termFormFieldService';
import { useLeaseTermFormField } from './useLeaseTermFormField';

interface Props {
  isDisabled: boolean;
}

// NOTE React won't rerender the Fields, rerendering is based on React key attribute: https://react-hook-form.com/faqs#Whyisdefaultvaluenotchangingcorrectlywithternaryoperator
export const LeaseTerm: React.FC<Props> = ({ isDisabled }) => {
  const { options, current } = useLeaseTermFormField();

  return (
    <MultiSelectFormField
      key="programFilters.leaseTerms"
      name="programFilters.leaseTerms"
      label="LEASE TERMS"
      dataCyPrefix="pdpPage_paymentCustomization_lease_term"
      options={options}
      value={current}
      fullWidth
      disabled={isDisabled}
      resultStringFormatter={formatTermResultString}
    />
  );
};
