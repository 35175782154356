import React from 'react';
import { Link } from 'react-router-dom';

import { AccountCircle } from '@mui/icons-material';
import { AppBar as MuiAppBar, IconButton, Stack, styled, Toolbar } from '@mui/material';

import { Menu } from 'components/molecules/menu/Menu';
import { SignOutDialog } from 'components/molecules/sign-out-dialog';

import { PriceRangeSwitch } from './price-range-switch';
import { useAppBar } from './useAppBar';
import { ViewProfitSwitch } from './view-profit-switch';

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  boxShadow: 'none',
}));

const StyledLink = styled(Link)({
  width: '112.87px',
  height: '32px',
});

export const AppBar: React.FC = () => {
  const {
    anchorEl,
    handleMenuClick,
    handleCloseMenu,
    dialogOpen,
    handleCancelDialog,
    handleOkDialog,
    handleLogoutClick,
    permissions,
  } = useAppBar();

  return (
    <StyledAppBar position="static" data-cy="universalAppBar">
      <Toolbar variant="dense">
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
            <StyledLink to="/">
              <img
                src="/icon/carmaticLogo2.svg"
                alt="Carmatic"
                data-cy="universalAppBar_carmaticIcon"
              />
            </StyledLink>
            <PriceRangeSwitch />
            <ViewProfitSwitch />
          </Stack>
          <IconButton
            onClick={handleMenuClick}
            data-cy="universalAppBar_menuIcon"
            edge="start"
            color="inherit"
          >
            <AccountCircle fontSize="large" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            onLogout={handleLogoutClick}
            permissions={permissions}
          />
          <SignOutDialog onCancel={handleCancelDialog} onOk={handleOkDialog} open={dialogOpen} />
        </Stack>
      </Toolbar>
    </StyledAppBar>
  );
};
