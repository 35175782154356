import { DefaultValue, selector } from 'recoil';

import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { Customer, createDefaultCustomer } from 'services/CustomerService';

import { searchValuesAtom } from './SearchModel';

export const currentCustomerAtom = selector<Customer>({
  key: 'CurrentCustomer',
  get: ({ get }) => {
    const searchFormValues = get(searchValuesAtom);
    const customer: Customer = searchFormValues?.customer ?? createDefaultCustomer();

    return customer;
  },
  set: ({ set }, newValue) => {
    set(searchValuesAtom, (existing) => {
      const searchFormValues = existing ?? ({} as SearchFormValues);

      const customer: Customer =
        newValue instanceof DefaultValue
          ? searchFormValues?.customer ?? createDefaultCustomer()
          : newValue;

      const updatedSearchFormValues: SearchFormValues = {
        ...searchFormValues,
        customer,
      };

      return updatedSearchFormValues;
    });
  },
});
