import { UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { useDealOfferQuery } from 'hooks/useDealOfferQuery';

import { responderAtom } from 'models/ResponderModel';

import { DealOffer } from 'services/DealOfferApiService';

interface HookResult {
  dealOfferQuery: UseQueryResult<DealOffer, unknown>;
  backNavigationLink: string;
}

export const useResponderPage = (): HookResult => {
  const { dealOfferId = '' } = useParams<{ dealOfferId: string }>();

  const dealOfferQuery = useDealOfferQuery({ dealOfferId });
  const { data } = dealOfferQuery;
  const setResponderValue = useSetRecoilState(responderAtom);

  if (data) {
    const {
      vehicle: { year, make, model, trim, location },
      paymentCustomization: {
        buyer: { email: customerEmail, name: customerName },
        calculationLeaseOutputData: { payment: leaseMonthlyPayment = 0 } = {},
        calculationLoanOutputData: { payment: loanMonthlyPayment = 0 } = {},
      },
    } = data;

    setResponderValue({
      dealOfferId,
      customerEmail,
      customerName,
      year,
      make,
      model,
      trim,
      location,
      leaseMonthlyPayment,
      loanMonthlyPayment,
    });
  }

  return {
    dealOfferQuery,
    backNavigationLink: dealOfferId !== '' ? `/pdp/dealOffer/${dealOfferId}` : '',
  };
};
