import { OrderBy, OrderDirection } from 'components/pdp/recommendation/RecommendationService';

import { CalculationLeaseOutput, CalculationLoanOutputData } from './CalculationService';
import {
  LeaseProfit,
  LoanProfit,
  StackedIncentives,
} from './payment-grid/PaymentGridApiModels.generated';
import { Vehicle } from './SearchApiService';
import { LeaseTtlFee, LoanTtlFee } from './TtlApiService';

export enum DealType {
  Any = 'Any',
  Lease = 'Lease',
  Loan = 'Loan',
}

export interface Fee {
  name: string;
  value: number;
  dealType: DealType;
}

export enum AddonType {
  Addition = 'Addition',
  ExtendedWarranty = 'ExtendedWarranty',
  InsurancePackage = 'InsurancePackage',
  ProtectionPackage = 'ProtectionPackage',
  TheftProtection = 'TheftProtection',
  Warranty = 'Warranty',
}

export interface Addon {
  name: string;
  price: number;
  wholesale: number;
  includeByDefault: boolean;
  type: AddonType;
  dealType: DealType;
}

export interface Dealer {
  creditScore: number;
  downPayment: number;
  nonTtlFees: Fee[];
  creditAppUrl: string;
  tradeInUrl: string;
  addonsV2: Addon[];
  customAddons: Addon[];
  payAllPackagesUpfront: boolean;
  dealerId: number;
}

export enum TaxCreditTo {
  TradeEquity = 1,
  CustomerAllowance = 2,
  NoCredit = 3,
  Payoff = 4,
}

interface RecommendationsFilter {
  higherProfit: boolean;
  sameMakeModel: boolean;
  sameYear: boolean;
  onlyNew: boolean;
}

export interface DealerDefaults {
  leaseTaxCreditTo: TaxCreditTo;
  loanTaxCreditTo: TaxCreditTo;
  payAllFeesUpFront: boolean;
  leaseTerms: number[];
  leaseMileages: number[];
  loanTerms: number[];
  recommendationsPaymentRange: number;
  leaseMarkup?: number; // ToDo: make non-nullable after api deployment
  loanMarkup?: number; // ToDo: make non-nullable after api deployment
  recommendationsOrderBy: OrderBy;
  recommendationsOrderDirection: OrderDirection;
  recommendationsFilter: RecommendationsFilter;
}

export interface MarkedUpValue {
  originalValue: number;
  markedUpValue: number;
  maxMarkup?: number;
}

export enum ValueType {
  Amount,
  Percentage,
}

export interface LeaseProgram {
  programId: number;
  programName: string;
  term: number;
  millage: number;
  residualAmount: number;
  residualRate?: number;
  creditScore: number;
  lenderId: string;
  lenderName: string;
  expiryDate: string;
  nonTtlFees: Fee[];
  id: string;
  moneyFactorValues: MarkedUpValue;
  maxAdvance: number;
  isMaxAdvanceExceeded?: boolean;
  calculation: CalculationLeaseOutput;
  incentives: StackedIncentives;
  profit: LeaseProfit;
}

export interface Ltv {
  minRate: number;
  maxRate: number;
  aprValues: MarkedUpValue;
}

export interface LoanProgram {
  programId: number;
  programName: string;
  term: number;
  creditScore: number;
  lenderId: string;
  ltv: Ltv[];
  isMaxLtvExceeded?: boolean;
  lenderName: string;
  expiryDate: string;
  nonTtlFees: Fee[];
  id: string;
  aprValues: MarkedUpValue;
  calculation: CalculationLoanOutputData;
  incentives: StackedIncentives;
  profit: LoanProfit;
}

export interface Lease {
  programs: LeaseProgram[];
  ttlFees?: LeaseTtlFee;
}

export interface Loan {
  programs: LoanProgram[];
  ttlFees?: LoanTtlFee;
}

export interface ProgramRule {
  description: string;
  type: string;
}

export interface RebateTerm {
  month: number;
  from: number;
  to: number;
  value: number;
}

export enum ProgramType {
  Lease = 'Lease',
  Loan = 'Loan',
}

export interface Rebate {
  cash: number;
  description: string;
  programId: number;
  programNumber: string;
  providerId: number;
  availableWithPrograms: number[];
  availableWithIncentives: number[];
  expiryDate: string;
  groupAffiliation: string;
  groupAffiliationDetail: string;
  programRules: ProgramRule[];
  rebateTerms: RebateTerm[];
  isGuaranteed: boolean;
  programTypes: ProgramType[];
  lenderNames: string[];
}
export interface LenderData {
  lease: Lease;
  loan: Loan;
  rebates: Rebate[];
}

export interface LoanFee {
  originationFee: number;
}

export function createLoanFee(loanProgram?: LoanProgram): LoanFee {
  if (!loanProgram) {
    return { originationFee: 0 };
  }

  return { originationFee: findFee('Origination Fee', loanProgram.nonTtlFees) };
}

export interface LeaseFee {
  acquisitionFee: number;
  originalAcquisitionFee: number;
  overMilageFee: number;
  dispositionFee: number;
}

export function createLeaseFee(
  leaseProgram?: LeaseProgram,
  leaseMarkedUpAcquisitionFee?: number
): LeaseFee {
  if (!leaseProgram) {
    return { acquisitionFee: 0, originalAcquisitionFee: 0, overMilageFee: 0, dispositionFee: 0 };
  }

  const { nonTtlFees } = leaseProgram;

  const originalAcquisitionFee = findFee('Acquisition Fee', nonTtlFees);

  return {
    acquisitionFee: leaseMarkedUpAcquisitionFee ?? originalAcquisitionFee,
    originalAcquisitionFee,
    dispositionFee: findFee('Disposition Fee', nonTtlFees),
    overMilageFee: findFee('Overmileage Fee', nonTtlFees),
  };
}

function findFee(name: string, nonTtlFees: Fee[]): number {
  return nonTtlFees.find((x) => x.name === name)?.value ?? 0;
}

export interface ProgramVariations {
  leaseMileages: number[];
  leaseTerms: number[];
  loanTerms: number[];
}

export interface PdpItem {
  dealer: Dealer;
  dealerDefaults: DealerDefaults | undefined;
  lenderData: LenderData;
  vehicle: Vehicle;
  programVariations?: ProgramVariations; // ToDo: make non-nullable after migration
}

export interface SimilarVehicles {
  count: number;
  excludedCount: number;
  items: Vehicle[];
}

export interface VehicleFeatures extends Vehicle {
  pictureUrls: string[];
  lowestPaymentLoan: number;
  lowestPaymentLease: number;
}

export function getIncentivesUrl(id: string): string {
  return `/pdp/${id}/incentives`;
}

export function getSimilarVehiclesByMakeModelUrl(id: string): string {
  return `/pdp/${id}/similarVehiclesByMakeModel`;
}

export function getSimilarVehiclesByBodyStyleUrl(id: string): string {
  return `/pdp/${id}/similarVehiclesByBodyStyle`;
}

export function getPdpApiUrl(id: string): string {
  return `/pdp/${id}`;
}

export function getVehicleFeaturesUrl(id: string): string {
  return `/pdp/${id}/vehicleFeatures`;
}

export function getResponderUrl(dealOfferId: string): string {
  return `/responder/${dealOfferId}`;
}
