import React from 'react';

import { Tooltip, Typography } from '@mui/material';

import { MRT_Cell as MrtCell } from 'material-react-table';

interface Props<T> {
  cell: MrtCell<Partial<T>>;
  maxLengthChars: number;
}
export const TableStringArrayCell: <TValue extends {}>({
  cell,
}: Props<TValue>) => React.ReactElement = <TValue extends {}>({
  cell,
  maxLengthChars: maxWidth,
}: Props<TValue>) => {
  const value = cell.getValue() as string[];
  const displayValue = value?.join(', ') ?? '';

  return (
    <Tooltip title={displayValue}>
      <Typography noWrap textOverflow="ellipsis" maxWidth={maxWidth}>
        {displayValue}
      </Typography>
    </Tooltip>
  );
};
