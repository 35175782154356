import React from 'react';

import { Chip, ChipPropsColorOverrides, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

interface Props {
  dataCy: string;
  color: OverridableStringUnion<
    'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning',
    ChipPropsColorOverrides
  >;
  label: string | number;
}

export const ColorChip: React.FC<Props> = ({ color, label, dataCy }) => (
  <Chip
    size="small"
    color={color}
    label={<Typography variant="overline">{label}</Typography>}
    data-cy={dataCy}
  />
);
