import { DealerDefaults, LeaseProgram, LoanProgram, MarkedUpValue } from './PdpApiService';

export enum ReserveType {
  Flat = 0,
  MaxAllowed = 1,
  PercentOfCapCostOrFinanced = 2,
  DollarAmount = 3,
  RateMarkup = 4,
  Auto = 5,
}

export function getMarkups(
  lease?: LeaseProgram,
  loan?: LoanProgram,
  dealerDefaults?: DealerDefaults
): { moneyFactorMarkup: number; aprMarkup: number } {
  if (dealerDefaults?.leaseMarkup !== undefined && dealerDefaults?.loanMarkup !== undefined) {
    return { moneyFactorMarkup: dealerDefaults.leaseMarkup, aprMarkup: dealerDefaults.loanMarkup };
  }

  const moneyFactorMarkup = getMoneyFactorMarkup(lease?.moneyFactorValues);
  const aprMarkup = getAprMarkup(loan?.aprValues);

  return { moneyFactorMarkup, aprMarkup };
}

export function getAprMarkup(aprValues?: MarkedUpValue): number {
  const { originalValue: apr, markedUpValue: markedUpApr } = aprValues ?? {
    originalValue: 0,
    markedUpValue: 0,
  };

  return +(markedUpApr - apr).toPrecision(8);
}

export function getAprMarkupByReserveType(
  reserveType: ReserveType,
  rateMarkup: number,
  aprValues?: MarkedUpValue
): number {
  const { maxMarkup } = aprValues ?? {};
  switch (+reserveType) {
    case ReserveType.Flat:
      return 0;
    case ReserveType.MaxAllowed:
      return maxMarkup ?? getAprMarkup(aprValues);
    default:
      return rateMarkup;
  }
}

export function getMoneyFactorMarkupByReserveType(
  reserveType: ReserveType,
  rateMarkup: number,
  moneyFactorValues?: MarkedUpValue
): number {
  const { maxMarkup } = moneyFactorValues ?? {};

  switch (+reserveType) {
    // ToDo - I think the markup value now needs to come from the profit.reserve.rateMarkup results of the pdpGrid request.
    case ReserveType.Flat:
      return 0;
    case ReserveType.MaxAllowed:
      return maxMarkup ?? getMoneyFactorMarkup(moneyFactorValues);
    default:
      return rateMarkup;
  }
}

export function getMoneyFactorMarkup(moneyFactorValues?: MarkedUpValue): number {
  const { originalValue: moneyFactor, markedUpValue: markedUpMoneyFactor } = moneyFactorValues ?? {
    originalValue: 0,
    markedUpValue: 0,
  };

  return +(markedUpMoneyFactor - moneyFactor).toPrecision(10);
}
