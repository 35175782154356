import React from 'react';
import { Link } from 'react-router-dom';

import { Divider, Menu as MuiMenu, MenuItem } from '@mui/material';

import { UserPermission } from 'utils/UserPermission';

export interface MenuProps {
  anchorEl: HTMLElement | null;
  onClose(): void;
  onLogout(): void;
  permissions: UserPermission[];
}

export const Menu: React.FC<MenuProps> = ({ anchorEl, onClose, onLogout, permissions }) => {
  return (
    <MuiMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem data-cy="universalAppBar_menu_homeLink" component={Link} to="/">
        Home
      </MenuItem>
      {permissions.includes(UserPermission.viewReports) && (
        <MenuItem data-cy="universalAppBar_menu_reportsLink" component={Link} to="/reports">
          Reporting
        </MenuItem>
      )}
      <Divider />
      <MenuItem
        data-cy="universalAppBar_menu_dealOffersLink"
        component={Link}
        to="/administration/deal-offers"
      >
        Administration of deal offers
      </MenuItem>
      {permissions.includes(UserPermission.adminUsers) && (
        <MenuItem
          data-cy="universalAppBar_menu_administrationOfUsersLink"
          component={Link}
          to="/administration/users"
        >
          Administration of users
        </MenuItem>
      )}
      {permissions.includes(UserPermission.viewDealers) && (
        <MenuItem
          data-cy="universalAppBar_menu_administrationOfDealersLink"
          component={Link}
          to="/administration/dealers"
        >
          Administration of dealers
        </MenuItem>
      )}
      <Divider />
      <MenuItem onClick={onLogout} data-cy="universalAppBar_menu_logoutLink">
        Sign out
      </MenuItem>
    </MuiMenu>
  );
};
