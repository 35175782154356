import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import {
  LeaseProgramGrid,
  LoanProgramGrid,
  ProgramGrid,
  GridType,
} from 'components/pdp/grid-view/table/GridTableService';

const { persistAtom } = recoilPersist({
  key: 'gridView',
  storage: localStorage,
});

export const gridViewAtom = atom<boolean>({
  key: 'gridView',
  default: false,
  effects: [persistAtom],
});

const { persistAtom: persistAtomLeases } = recoilPersist({
  key: 'leasesView',
  storage: localStorage,
});

export const leasesViewAtom = atom<boolean>({
  key: 'leasesView',
  default: true,
  effects: [persistAtomLeases],
});

const { persistAtom: persistAtomLoans } = recoilPersist({
  key: 'loansView',
  storage: localStorage,
});

export const loansViewAtom = atom<boolean>({
  key: 'loansView',
  default: true,
  effects: [persistAtomLoans],
});

export const isLoanGridEnabledSelector = selector<boolean>({
  key: 'isLoanGridEnabled',
  get: ({ get }) => {
    const gridEnabled = get(gridViewAtom);
    const gridLoanEnabled = get(loansViewAtom);

    return gridEnabled && gridLoanEnabled;
  },
});

export const isLeaseGridEnabledSelector = selector<boolean>({
  key: 'isLeaseGridEnabled',
  get: ({ get }) => {
    const gridEnabled = get(gridViewAtom);
    const gridLeaseEnabled = get(leasesViewAtom);

    return gridEnabled && gridLeaseEnabled;
  },
});

export const loanProgramsGridAtom = atom<LoanProgramGrid[]>({
  key: 'loanProgramsGrid',
  default: [],
});

export const leaseProgramsGridAtom = atom<LeaseProgramGrid[]>({
  key: 'leaseProgramsGrid',
  default: [],
});

const gridTypeSelector = selector<GridType>({
  key: 'gridType',
  get: ({ get }) => {
    const loansViewEnabled = get(loansViewAtom);
    const leasesViewEnabled = get(leasesViewAtom);

    if (loansViewEnabled && leasesViewEnabled) {
      return GridType.Both;
    }

    if (!loansViewEnabled && !leasesViewEnabled) {
      return GridType.None;
    }

    return loansViewEnabled ? GridType.Loan : GridType.Lease;
  },
});

export const gridTableDataSelector = selector<ProgramGrid[]>({
  key: 'gridTableData',
  get: ({ get }) => {
    const loanProgramsGrid = get(loanProgramsGridAtom);
    const leaseProgramsGrid = get(leaseProgramsGridAtom);
    const gridType = get(gridTypeSelector);

    if (gridType === GridType.Lease) {
      return leaseProgramsGrid;
    }

    if (gridType === GridType.Loan) {
      return loanProgramsGrid;
    }

    if (gridType === GridType.None) {
      return [];
    }

    return [...leaseProgramsGrid, ...loanProgramsGrid];
  },
});

const { persistAtom: persistAtomPayFirstLeasePaymentUpfront } = recoilPersist({
  key: 'payFirstLeasePaymentUpfront',
  storage: localStorage,
});

export const payFirstLeasePaymentUpfrontAtom = atom<boolean>({
  key: 'payFirstLeasePaymentUpfront',
  default: true,
  effects: [persistAtomPayFirstLeasePaymentUpfront],
});

const { persistAtom: persistAtomUseOnlyCaptiveLenders } = recoilPersist({
  key: 'useOnlyCaptiveLenders',
  storage: localStorage,
});

export const useOnlyCaptiveLendersAtom = atom<boolean>({
  key: 'useOnlyCaptiveLenders',
  default: false,
  effects: [persistAtomUseOnlyCaptiveLenders],
});
