import React from 'react';
import { FormProvider } from 'react-hook-form';

import { CustomIncentiveForm } from 'components/molecules/custom-incentive-form/CustomIncentiveForm';

import { useCustomIncentiveFormWrapper } from './useCustomIncentiveFormWrapper';

export const CustomIncentiveFormWrapper: React.FC = () => {
  const { methods } = useCustomIncentiveFormWrapper();

  return (
    <FormProvider {...methods}>
      <CustomIncentiveForm />
    </FormProvider>
  );
};
