import React from 'react';

import { Chip, Stack, Typography } from '@mui/material';

import { ProgramType } from 'services/PdpApiService';

interface Props {
  programTypes: ProgramType[];
}

export const IncentivesTypeTableCell: React.FC<Props> = ({ programTypes }) => {
  if (programTypes.length === 1) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0.5}>
        <Chip
          color={programTypes[0] === ProgramType.Lease ? 'success' : 'info'}
          size="small"
          label={
            <Stack direction="row">
              <Typography variant="overline">{programTypes}</Typography>
            </Stack>
          }
        />
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0.5}>
      <Chip
        color="success"
        size="small"
        label={
          <Stack direction="row">
            <Typography variant="overline">{ProgramType.Lease}</Typography>
          </Stack>
        }
      />
      <Chip
        color="info"
        size="small"
        label={
          <Stack direction="row">
            <Typography variant="overline">{ProgramType.Loan}</Typography>
          </Stack>
        }
      />
    </Stack>
  );
};
