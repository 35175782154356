import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { AxiosContext } from 'providers/AxiosProvider';

import { dealOfferCodeAtom, dealOfferHasChangesAtom } from 'models/DealOfferModel';

import {
  ValidatedDealOfferPdp,
  getDealOfferPdpUrl,
  initializeDealOffer,
} from 'services/DealOfferApiService';

export const useDealOfferPdpQuery = (): UseQueryResult<ValidatedDealOfferPdp, unknown> => {
  const { dealOfferCode } = useParams<{ dealOfferCode: string }>();
  const axiosClient = useContext(AxiosContext);
  const setDealCode = useSetRecoilState(dealOfferCodeAtom);
  const setDealOfferHasChanges = useSetRecoilState(dealOfferHasChangesAtom);

  const dealOfferPdpQuery = useQuery(
    ['dealOfferPdpQuery', dealOfferCode],
    async () => {
      const schemaVersion = '2.5.0';

      const { data } = await axiosClient.get<ValidatedDealOfferPdp>(
        getDealOfferPdpUrl(dealOfferCode ?? '', schemaVersion)
      );

      return initializeDealOffer(data);
    },
    {
      enabled: !!dealOfferCode,
      onSuccess: () => {
        setDealCode(dealOfferCode);
        setDealOfferHasChanges(false);
      },
    }
  );

  return dealOfferPdpQuery;
};
