import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

const { persistAtom } = recoilPersist({
  key: 'searchFormValues',
  storage: localStorage,
});

export const searchValuesAtom = atom<SearchFormValues | undefined>({
  key: 'searchFormValues',
  default: undefined,
  effects: [persistAtom],
});
