import React, { useContext } from 'react';

import { Grid, Stack, Typography, styled } from '@mui/material';

import { CustomIncentiveFormWrapper } from 'components/molecules/custom-incentive-form/form-wrapper/CustomIncentiveFormWrapper';
import { CheckboxFormField } from 'components/molecules/form-fields';
import { TriggeredModal } from 'components/molecules/modal';
import { IncentivesGridTable } from 'components/pdp/grid-view/incentives-table/IncentivesGridTable';

import { PdpContext } from 'providers/PdpContextProvider';

import { ExtendedRebate } from 'services/RebatesService';

import { useIncentivesModal } from './useIncentivesModal';

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

interface Props {
  allRebates: ExtendedRebate[];
  isIncentiveError: boolean;
  trigger: React.ReactElement;
}

export const IncentivesModal: React.FC<Props> = ({ allRebates, isIncentiveError, trigger }) => {
  const { customIncentivesCheckboxItems, groupedRebates } = useIncentivesModal(allRebates);
  const { shouldBeReadOnly } = useContext(PdpContext);

  return (
    <TriggeredModal
      title="Incentives"
      trigger={trigger}
      displayCloseButton={false}
      confirmButtonDisabled={isIncentiveError}
      maxWidth="lg"
      content={
        <Grid container spacing={2}>
          {groupedRebates.map(function (rebatesGroup, i) {
            return (
              <React.Fragment key={rebatesGroup.groupLabel}>
                {groupedRebates.length > 1 && (
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle3"
                      data-cy="incentivesModal_customIncentiveTitle"
                      paddingBottom={4}
                    >
                      Incentives for {rebatesGroup.groupLabel}
                    </Typography>
                  </Grid>
                )}
                <StyledGridItem item xs={12} justifyContent="center">
                  <IncentivesGridTable allRebates={rebatesGroup.rebates} />
                </StyledGridItem>
              </React.Fragment>
            );
          })}
          <>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <StyledTypography
                  variant="subtitle3"
                  data-cy="incentivesModal_customIncentivesTitle"
                >
                  Custom incentives
                </StyledTypography>
                <CheckboxFormField
                  name="customIncentiveNames"
                  items={customIncentivesCheckboxItems}
                  selectorPrefix="incentivesModal"
                  labelTypographyVariant="caption"
                  checkBoxSize="small"
                  disabled={shouldBeReadOnly}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <CustomIncentiveFormWrapper />
            </Grid>
          </>
        </Grid>
      }
    />
  );
};
