import { CustomIncentiveAnyLender, CustomIncentiveProgramType } from 'models/CustomIncentivesModel';

import { LenderData, Rebate } from 'services/PdpApiService';
import { ExtendedRebate } from 'services/RebatesService';

import { distinct, groupByToMap, naturalJoin } from 'utils/arrayUtils';

export interface RebatesGroup {
  groupLabel: string;
  rebates: ExtendedRebate[];
}

export function getCustomIncentiveLenderNameOptions(
  lenderData: LenderData
): { label: string; value: string | number }[] {
  const lenderNames = getLenderNames(lenderData);

  return [{ label: CustomIncentiveAnyLender, value: CustomIncentiveAnyLender }].concat(
    lenderNames.map((ln) => ({
      label: ln,
      value: ln,
    }))
  );
}

export function getCustomIncentiveProgramTypeOptions(): {
  label: string;
  value: string | number;
}[] {
  return [
    {
      label: 'Both',
      value: CustomIncentiveProgramType.Both,
    },
    {
      label: 'Lease',
      value: CustomIncentiveProgramType.Lease,
    },
    {
      label: 'Loan',
      value: CustomIncentiveProgramType.Loan,
    },
  ];
}

export function getGroupedRebates(
  lenderRebates: Rebate[],
  extendedRebates: ExtendedRebate[]
): RebatesGroup[] {
  const extendedRebatesMap = groupByToMap(extendedRebates, (r) => r.providerId);

  const groupedRebates = [...extendedRebatesMap.entries()].reduce((groups, [k, v]) => {
    groups.set(
      k,
      getRebateGroup(
        v,
        lenderRebates.filter((ar) => ar.providerId === k)
      )
    );

    return groups;
  }, new Map<number, RebatesGroup>());

  return [...groupedRebates.values()];
}

export function getProgramTypeString(programType: CustomIncentiveProgramType): string {
  switch (programType) {
    case CustomIncentiveProgramType.Lease:
      return 'Lease';
    case CustomIncentiveProgramType.Loan:
      return 'Loan';
    default:
      return 'Lease & Loan';
  }
}

const getRebateGroup = (rebates: ExtendedRebate[], allRebates: Rebate[]): RebatesGroup => {
  const distinctLenders = distinct(allRebates.flatMap((r) => r.lenderNames)).sort((a, b) =>
    a.localeCompare(b)
  );

  return {
    groupLabel: naturalJoin(distinctLenders),
    rebates,
  };
};

const getLenderNames = (lenderData: LenderData): string[] => {
  return distinct(
    (lenderData?.lease?.programs ?? [])
      .map((l) => l.lenderName)
      .concat((lenderData?.loan?.programs ?? []).map((l) => l.lenderName))
  );
};
