import { useCallback, useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useSaveCreditApplicationMutation } from 'hooks/useSaveCreditApplicationMutation';

import { currentCustomerAtom } from 'models/CurrentCustomerModel';
import { dealOfferCodeAtom, dealOfferHasChangesAtom } from 'models/DealOfferModel';
import { isStandardCalculationSelector } from 'models/ExactPaymentModel';

import { saveCreditApplication } from 'services/CustomerService';

interface HookOptions {
  isSaveDealOfferAllowed: boolean;
}

interface HookResult {
  showPushToCrmButton: boolean;
  pushToCrmButtonIsDisabled: boolean;
  emailTextCustomerButtonIsDisabled: boolean;
  printButtonIsDisabled: boolean;
  elendIntegrationEnabled: boolean;
  creditApplicationButtonIsDisabled: boolean;
  showQuickQuoteButton: boolean;
  quickQuoteButtonIsDisabled: boolean;
  handleCreditApplication: () => void;
}

export const usePaymentCustomizationBlockFooter = ({
  isSaveDealOfferAllowed,
}: HookOptions): HookResult => {
  const { pushToCrm, elendIntegration } = useFeatureFlags();
  const { isBdcRepresentative } = useContext(PermissionsContext);
  const dealOfferId = useRecoilValue(dealOfferCodeAtom);
  const dealOfferHasChanges = useRecoilValue(dealOfferHasChangesAtom);
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const isStandardCalculation = useRecoilValue(isStandardCalculationSelector);

  const dealOfferActionsAreDisabled = shouldDisableDealOfferActions({
    dealOfferId,
    dealOfferHasChanges,
    isSaveDealOfferAllowed,
  });

  const { mutateAsync: saveCreditApplicationMutation } = useSaveCreditApplicationMutation();

  const handleCreditApplication = useCallback(() => {
    (async (): Promise<void> => {
      if (dealOfferId) {
        await saveCreditApplication(
          dealOfferId,
          currentCustomer,
          saveCreditApplicationMutation,
          setCurrentCustomer
        );
      }
    })();
  }, [dealOfferId]);

  const quickQuoteButtonIsDisabled = !isStandardCalculation;

  return {
    showPushToCrmButton: pushToCrm,
    pushToCrmButtonIsDisabled: dealOfferActionsAreDisabled,
    emailTextCustomerButtonIsDisabled: dealOfferActionsAreDisabled,
    creditApplicationButtonIsDisabled: dealOfferActionsAreDisabled,
    printButtonIsDisabled: dealOfferActionsAreDisabled,
    elendIntegrationEnabled: elendIntegration,
    handleCreditApplication,
    showQuickQuoteButton: shouldShowQuickQuoteButton(isBdcRepresentative),
    quickQuoteButtonIsDisabled,
  };
};

interface ShouldDisableDealOfferActionsOptions {
  dealOfferId: string | undefined;
  dealOfferHasChanges: boolean;
  isSaveDealOfferAllowed: boolean;
}

const shouldDisableDealOfferActions = ({
  dealOfferId,
  dealOfferHasChanges,
  isSaveDealOfferAllowed,
}: ShouldDisableDealOfferActionsOptions): boolean => {
  return (
    !dealOfferId ||
    dealOfferId.trim().length <= 0 ||
    (dealOfferHasChanges && isSaveDealOfferAllowed)
  );
};
function shouldShowQuickQuoteButton(isBdcRepresentative: boolean): boolean {
  return !isBdcRepresentative;
}
