import React, { useContext } from 'react';

import { Button, Grid, InputAdornment } from '@mui/material';

import { NumberFormField, TextFormField } from 'components/molecules/form-fields';

import { PdpContext } from 'providers/PdpContextProvider';

import { useCustomAddOnForm } from './useCustomAddOnForm';

export const CustomAddOnForm: React.FC = () => {
  const { handleSaveCustomAddon, isSubmitting } = useCustomAddOnForm();
  const { shouldBeReadOnly } = useContext(PdpContext);

  return (
    <form onSubmit={handleSaveCustomAddon}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFormField
            name="name"
            label="Add-on name"
            focused
            placeholder="Enter add-on name"
            dataCy="pdpPage_addonsModal_name_input"
            fullWidth
            disabled={shouldBeReadOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberFormField
            name="price"
            label="Add-on retail price"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            dataCy="pdpPage_addonsModal_retail_input"
            fullWidth
            disabled={shouldBeReadOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberFormField
            name="wholesale"
            label="Wholesale cost"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            dataCy="pdpPage_addonsModal_wholesale_input"
            fullWidth
            disabled={shouldBeReadOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            data-cy="add-custom-addon-button"
            disabled={isSubmitting || shouldBeReadOnly}
          >
            Add custom add-on
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
