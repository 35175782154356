import { useParams } from 'react-router-dom';

import { useDealerGroupsQuery } from 'hooks/useDealerGroupsQuery';

import { DealerGroupSummary } from 'services/DealerGroupApiService';
import { AdministrationUser } from 'services/UserAdministrationApiService';

import { useUserDetailQuery } from './useUserDetailQuery';

export interface HookResult {
  userID: string | undefined;
  isLoading: boolean;
  userData?: AdministrationUser;
  dealerGroups: DealerGroupSummary[] | undefined;
}

export const useUserDetailPage = (): HookResult => {
  const { userID = '' } = useParams();

  const { getUserQuery } = useUserDetailQuery({ userID });

  const { dealerGroups: dealerGroupsQuery } = useDealerGroupsQuery();

  return {
    userID,
    isLoading: getUserQuery.isFetching || dealerGroupsQuery.isFetching,
    userData: getUserQuery.data,
    dealerGroups: dealerGroupsQuery.data?.sort((a, b) => a.name.localeCompare(b.name)),
  };
};
