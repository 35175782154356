import { useRecoilValue } from 'recoil';

import { gridViewAtom } from 'models/GridViewModel';

interface HookResult {
  shouldRender: boolean;
}

export const useGridTableWrapper = (): HookResult => {
  const gridViewEnabled = useRecoilValue(gridViewAtom);

  return {
    shouldRender: gridViewEnabled,
  };
};
