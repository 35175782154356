import React from 'react';

import { Stack } from '@mui/material';

import { DashboardTile } from 'components/pdp/dashboard-tile';
import { InfoTile } from 'components/pdp/info-tile';

import { ProgramSelectionMethod } from 'models/PaymentSelectionModel';

import { CalculationLeaseOutput } from 'services/CalculationService';

import { formatDate } from 'utils/formatterUtils';
import { isGreaterThanToday } from 'utils/validationUtils';

import { useLeaseMainBox } from './useLeaseMainBox';

interface Props {
  calculationOutput?: CalculationLeaseOutput;
  lenderName?: string;
  isLeaseProgramAvailable: boolean;
  leaseProgramName?: string;
  expirationDate?: string;
  programSelectionMethod: ProgramSelectionMethod;
}

export const LeaseMainBox: React.FC<Props> = (input) => {
  const { lenderName, isLeaseProgramAvailable, leaseProgramName, expirationDate } = input;

  const { payment, inPaymentTaxNote, dueAtSigning, dueAtSigningNote, programNote1, programNote2 } =
    useLeaseMainBox(input);

  return (
    <Stack direction="column" spacing={3}>
      {isLeaseProgramAvailable ? (
        <DashboardTile
          type="lease"
          price={payment}
          priceNote={`${lenderName}/${leaseProgramName}`}
          expirationNote={`Ends ${expirationDate ? formatDate(expirationDate) : ''}`}
          inPaymentTaxNote={inPaymentTaxNote}
          dueAtSigningPrice={dueAtSigning}
          dueAtSigningPriceNote={dueAtSigningNote}
          selectorPrefix="pdpPage_paymentCustomization_"
          isProgramExpired={isGreaterThanToday(expirationDate)}
          programNote1={programNote1}
          programNote2={programNote2}
        />
      ) : (
        <InfoTile
          title="This mileage/term combination isn’t available."
          text="Try a different mileage number or a different lease term."
          selectorPrefix="pdpPage_leaseCombinationUnavailableBox"
          color="error"
        />
      )}
    </Stack>
  );
};
