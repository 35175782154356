import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { useSyncPriceType } from 'components/molecules/exact-payments/useSyncPriceType';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { useInvalidCreditScoreModal } from 'components/pdp/invalid-credit-score-modal/useInvalidCreditScoreModal';

import { currentCustomerAtom } from 'models/CurrentCustomerModel';
import {
  isDownPaymentCalculatedTypeSelector,
  isMonthlyPaymentCalculatedTypeSelector,
} from 'models/ExactPaymentModel';

import {
  CreditTierOption,
  creditTierOptions,
  getCreditTierByCreditScore,
  getCreditTierById,
} from 'services/CreditTiersService';

interface HookOptions {
  carId: string;
  isDealOffer: boolean;
}

interface HookResult {
  payAllFeesUpfront: boolean;
  creditTierOptions: CreditTierOption[];
  isMonthlyPaymentCalculated: boolean;
  isDownPaymentCalculated: boolean;
  creditReadOnly: boolean;
  isCreditScoreInvalidModalOpen: boolean;
  customerCreditTier?: CreditTierOption;
  dealCreditTier?: CreditTierOption;
  confirmCreditScoreInvalidModal: () => void;
  onImplicitCloseCreditScoreInvalidModal: () => void;
}

export const useDealDefaults = ({ isDealOffer, carId }: HookOptions): HookResult => {
  const [payAllFeesUpfront, dealCreditScore] = useWatch<
    PdpFormValues,
    ['payAllFeesUpfront', 'creditScore']
  >({ name: ['payAllFeesUpfront', 'creditScore'] });

  useSyncPriceType();
  const isMonthlyPaymentCalculated = useRecoilValue(isMonthlyPaymentCalculatedTypeSelector);
  const isDownPaymentCalculated = useRecoilValue(isDownPaymentCalculatedTypeSelector);
  const { creditTierId: customerCreditTierId } = useRecoilValue(currentCustomerAtom);

  const customerCreditTier = customerCreditTierId
    ? getCreditTierById(customerCreditTierId)
    : undefined;

  const dealCreditTier = getCreditTierByCreditScore(dealCreditScore);

  const {
    open: openCreditScoreInvalidModal,
    confirm: confirmCreditScoreInvalidModal,
    isOpen: isCreditScoreInvalidModalOpen,
    onImplicitClose: onImplicitCloseCreditScoreInvalidModal,
  } = useInvalidCreditScoreModal({ carId, canUpdateCreditScore: !isDealOffer });

  useEffect(() => {
    if (customerCreditTierId) {
      const customerCreditTierOption = getCreditTierById(customerCreditTierId);
      const customerCreditScore = customerCreditTierOption?.value;

      if (customerCreditScore && dealCreditScore !== customerCreditScore) {
        openCreditScoreInvalidModal();
      }
    }
  }, [customerCreditTierId, dealCreditScore, openCreditScoreInvalidModal]);

  return {
    payAllFeesUpfront,
    creditTierOptions,
    isMonthlyPaymentCalculated,
    isDownPaymentCalculated,
    creditReadOnly: !!customerCreditTierId,
    isCreditScoreInvalidModalOpen,
    customerCreditTier,
    dealCreditTier,
    confirmCreditScoreInvalidModal,
    onImplicitCloseCreditScoreInvalidModal,
  };
};
