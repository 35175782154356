import React from 'react';

import { MultiSelectFormField } from 'components/molecules/form-fields';
import { formatLeaseMileages } from 'components/pdp/payment-customization-block/filter-fields/leaseMileageFormFieldService';

import { useLeaseMileageFormField } from './useLeaseMileageFormField';

interface Props {
  leaseMileages: number[];
}

// NOTE React won't rerender the Fields, rerendering is based on React key attribute: https://react-hook-form.com/faqs#Whyisdefaultvaluenotchangingcorrectlywithternaryoperator
export const LeaseMileage: React.FC<Props> = ({ leaseMileages }) => {
  const { options, current } = useLeaseMileageFormField({ leaseMileages });

  return (
    <MultiSelectFormField
      key="calculationOptions.leaseMileages"
      name="calculationOptions.leaseMileages"
      label="LEASE MILEAGES"
      dataCyPrefix="searchPage_customerSection_lease_mileage"
      options={options}
      value={current}
      fullWidth
      resultStringFormatter={formatLeaseMileages}
    />
  );
};
