import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { PdpContext } from 'providers/PdpContextProvider';

import { usePdpData } from 'hooks/usePdpData';

import { leasesViewAtom, loansViewAtom } from 'models/GridViewModel';
import {
  leaseProgramSelectionMethodAtom,
  ProgramSelectionMethod,
} from 'models/PaymentSelectionModel';

interface HookResult {
  formValues: PdpFormValues;
  hasLeasePrograms: boolean;
  hasFilteredLeasePrograms: boolean;
  hasLoanPrograms: boolean;
  hasFilteredLoanPrograms: boolean;
  isInitialLoad: boolean;
  loansViewEnabled: boolean;
  leasesViewEnabled: boolean;
  vehiclePrice: number;
  vehicleMsrp: number;
  leaseProgramSelectionMethod: ProgramSelectionMethod;
  isLeaseCapCostInRangeOfMaxAdvance: boolean;
  isLoanTotalFinancedInRangeOfMaxAdvance: boolean;
}

export const useInfoBlock = (): HookResult => {
  const {
    vehicle: { msrp: vehicleMsrp, price },
    lenderData: { lease, loan },
  } = usePdpData();

  const formValues = useWatch({}) as PdpFormValues;
  const { leaseProgram, loanProgram } = formValues;

  const filteredLeasePrograms = lease.programs;

  const filteredLoanPrograms = loan.programs;

  const hasLeasePrograms = formValues.leaseTerms.length > 0 && formValues.miles.length > 0;

  const hasLoanPrograms = formValues.loanTerms.length > 0;

  const isLeaseCapCostInRangeOfMaxAdvance = !(leaseProgram?.isMaxAdvanceExceeded ?? false);

  const isLoanTotalFinancedInRangeOfMaxAdvance = !(loanProgram?.isMaxLtvExceeded ?? false);

  const { isInitialLoadComplete } = useContext(PdpContext);

  return {
    formValues,
    hasLeasePrograms,
    hasFilteredLeasePrograms: !!filteredLeasePrograms.length,
    hasLoanPrograms,
    hasFilteredLoanPrograms: !!filteredLoanPrograms.length,
    isInitialLoad: !isInitialLoadComplete,
    loansViewEnabled: useRecoilValue(loansViewAtom),
    leasesViewEnabled: useRecoilValue(leasesViewAtom),
    vehiclePrice: price,
    vehicleMsrp,
    leaseProgramSelectionMethod: useRecoilValue(leaseProgramSelectionMethodAtom),
    isLeaseCapCostInRangeOfMaxAdvance,
    isLoanTotalFinancedInRangeOfMaxAdvance,
  };
};
