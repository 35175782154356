import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { PdpFormValues, PriceType } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import {
  isDownPaymentCalculatedTypeSelector,
  isMonthlyPaymentCalculatedTypeSelector,
} from 'models/ExactPaymentModel';

export const useSyncPriceType = (): void => {
  const isDownPaymentCalculatedType = useRecoilValue(isDownPaymentCalculatedTypeSelector);
  const isMonthlyPaymentCalculatedType = useRecoilValue(isMonthlyPaymentCalculatedTypeSelector);
  const { setValue } = useFormContext<PdpFormValues>();

  useEffect(() => {
    if (!isMonthlyPaymentCalculatedType && !isDownPaymentCalculatedType) {
      setValue('priceType', PriceType.DYNAMIC);

      return;
    }

    setValue('priceType', PriceType.FIXED);
  }, [isMonthlyPaymentCalculatedType, isDownPaymentCalculatedType]);
};
