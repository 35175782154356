import { ProgramType } from 'services/PdpApiService';
import { ComparisonDifference, ComparisonResults } from 'services/SearchApiService';

const higherTrimMessage = 'Higher tier trim';

export function shouldShowLease(
  showLeaseToggleState: boolean,
  comparison: ComparisonResults
): boolean {
  return (
    showLeaseToggleState && (comparison.leasePayment.isBetter || comparison.leaseProfit.isBetter)
  );
}

export function shouldShowLoan(
  showLoanToggleState: boolean,
  comparison: ComparisonResults
): boolean {
  return showLoanToggleState && (comparison.loanPayment.isBetter || comparison.loanProfit.isBetter);
}

export function createReasonListText(
  comparisonResult: ComparisonResults,
  programType: ProgramType,
  showProfit: boolean,
  allowViewProfitAmount: boolean,
  showRanges: boolean
): string[] {
  const result = [];
  if (comparisonResult.higherTrim) {
    result.push(higherTrimMessage);
  }

  if (comparisonResult.msrp.isBetter) {
    result.push(createMsrpMessage(comparisonResult.msrp));
  }

  const paymentComparison =
    programType === ProgramType.Lease
      ? comparisonResult.leasePayment
      : comparisonResult.loanPayment;

  if (paymentComparison?.delta !== 0) {
    result.push(createBetterPaymentMessage(paymentComparison, showRanges));
  }

  const profitComparison =
    programType === ProgramType.Lease ? comparisonResult.leaseProfit : comparisonResult.loanProfit;

  if (showProfit && profitComparison.delta !== 0) {
    result.push(createBetterProfitMessage(profitComparison, allowViewProfitAmount));
  }

  return result;
}

function createMsrpMessage(comparisonDifference: ComparisonDifference): string {
  return `Higher MSRP valuation: $${comparisonDifference.current} (vs. $${comparisonDifference.comparison})`;
}

function createBetterPaymentMessage(comparison: ComparisonDifference, showRanges: boolean): string {
  const isHigher = comparison?.delta > 0;

  if (showRanges) {
    return isHigher ? `Payment is only slightly higher` : `Payment is lower`;
  }

  return `Payment is${isHigher ? ' only' : ''} $${Math.abs(comparison.delta)} ${
    isHigher ? 'higher' : 'lower'
  }`;
}

function createBetterProfitMessage(
  comparison: ComparisonDifference,
  allowViewProfitAmount: boolean
): string {
  return allowViewProfitAmount
    ? `Expected profit is $${comparison.delta} higher`
    : `Expected profit is higher`;
}

export function createDeltaText(value: number): string {
  value = value ?? 0;
  const sign = value > 0 ? '+' : '-';

  return `${sign} $${Math.abs(value)}`;
}
