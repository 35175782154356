import React, { ReactNode } from 'react';
import { FieldArrayWithId, FormProvider } from 'react-hook-form';

import { Button, CircularProgress, Grid, List, ListItem, Stack, Typography } from '@mui/material';

import { UserDealerAssignmentForm } from 'components/administrations/users/form/user-dealer-assignment-modal/UserDealerAssignmentForm';
import { UserDealerAssignmentFormValues } from 'components/administrations/users/form/user-dealer-assignment-modal/UserDealerAssignmentFormService';

import { useUserBulkImportForm } from './useUserBulkImportForm';
import { UserBulkImportResult } from './useUserBulkImportMutation';

export const UserBulkImportForm: React.FC = () => {
  const {
    formMethods,
    handleOnSubmit,
    handleOnShowDealerAssignment,
    handleOnHideDealerAssignment,
    handleOnFileChange,
    handleOnSampleDownload,
    importSuccessfulResults,
    importFailedResults,
    fileName,
    submitButtonDisabled,
    isSubmitting,
    inputRef,
    isDealerAssignmentDisplayed,
    isLoadingDealers,
    userDealerFields,
  } = useUserBulkImportForm();

  return (
    <FormProvider {...formMethods}>
      <Stack spacing={2}>
        {!isDealerAssignmentDisplayed && (
          <Button variant="contained" component="label" onClick={handleOnSampleDownload}>
            Download sample csv
          </Button>
        )}
        <Grid container direction="column" maxHeight={500} overflow="scroll">
          {getFormBody(
            isLoadingDealers,
            isDealerAssignmentDisplayed,
            importSuccessfulResults,
            importFailedResults,
            userDealerFields
          )}
        </Grid>

        <Grid container direction="row">
          {isDealerAssignmentDisplayed ? (
            <>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  component="label"
                  onClick={handleOnHideDealerAssignment}
                >
                  Done
                </Button>
              </Grid>
              <Grid item xs={10}></Grid>
            </>
          ) : (
            <>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  component="label"
                  onClick={handleOnShowDealerAssignment}
                >
                  Set Dealers
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" component="label">
                  Select file
                  <input
                    hidden
                    accept="text/csv"
                    type="file"
                    onChange={handleOnFileChange}
                    ref={inputRef}
                  />
                </Button>
              </Grid>
              <Grid item display="flex" alignItems="center" xs={4}>
                <Typography variant="body1">{fileName}</Typography>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item>
                {isSubmitting ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitButtonDisabled}
                    name="files"
                    onClick={(e): Promise<void> => handleOnSubmit(e)}
                    data-cy="UserBulkImportForm_submitButton"
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </FormProvider>
  );
};

function getFormBody(
  isLoadingDealers: boolean,
  isDealerAssignmentDisplayed: boolean,
  importSuccessfulResults: UserBulkImportResult[],
  importFailedResults: UserBulkImportResult[],
  fields: FieldArrayWithId<UserDealerAssignmentFormValues, 'userDealers', 'id'>[]
): ReactNode {
  return isDealerAssignmentDisplayed ? (
    getDealerAssignmentComponent(isLoadingDealers, fields)
  ) : (
    <Grid item xs={12}>
      {!importSuccessfulResults?.length && !importFailedResults?.length ? (
        <Grid height={475}>
          <Typography>No results to display.</Typography>
        </Grid>
      ) : (
        <Stack overflow-y="scroll">
          <Typography variant="body1">Succeeded:</Typography>
          {getResults(importSuccessfulResults, 'None succeeded.')}
          <Typography variant="body1">Failed:</Typography>
          {getResults(importFailedResults, 'None failed.')}
        </Stack>
      )}
    </Grid>
  );
}

function getDealerAssignmentComponent(
  isLoadingDealers: boolean,
  fields: FieldArrayWithId<UserDealerAssignmentFormValues, 'userDealers', 'id'>[]
): React.ReactNode {
  return isLoadingDealers ? (
    <Grid item xs={12}>
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <UserDealerAssignmentForm fields={fields} />
    </Grid>
  );
}

function getResults(collection: UserBulkImportResult[], noneMessage: string): ReactNode {
  return collection.length ? (
    collection.map((result) => (
      <Stack key={result.email} paddingLeft={2}>
        <Typography paddingLeft={2} variant="body2">
          {result.email.length ? result.email : '<Empty email>'}
        </Typography>
        <List sx={{ pl: 7, listStyleType: 'disc' }}>
          {result.errors.map((error) => (
            <ListItem key={error} sx={{ display: 'list-item' }} disablePadding>
              <Typography variant="body3">{error}</Typography>
            </ListItem>
          ))}
        </List>
      </Stack>
    ))
  ) : (
    <Typography paddingLeft={2} variant="body2">
      {noneMessage}
    </Typography>
  );
}
