import React from 'react';

import { TableCellProps } from '@mui/material';

import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { TableDateCell, TableStringArrayCell } from 'components/molecules/table';

import { User } from 'services/UserAdministrationApiService';

function getMuiTableBodyCellProps(index: number, columnName: string): TableCellProps {
  return {
    // @ts-ignore
    'data-cy': `userListPage_table_row-${index}_${columnName}`,
  };
}

function getMuiTableHeadCellProps(columnName: string): TableCellProps {
  return {
    // @ts-ignore
    'data-cy': `userListPage_table_columnHeader_${columnName}`,
  };
}

export interface UserTableRow {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  lastLoginDate: string;
  dealerGroupId: string;
  dealerNames: string[];
  id: string;
  roles?: [];
  canDelete: boolean;
}

export const userTableColumns: ColumnDef<Partial<UserTableRow>>[] = [
  {
    accessorKey: 'firstName',
    header: 'First Name',
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
  },
  {
    accessorKey: 'lastName',
    header: 'Last Name',
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
  },
  {
    accessorKey: 'email',
    header: 'Email',
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
  },
  {
    accessorKey: 'dealerNames',
    header: 'Dealers',
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <TableStringArrayCell maxLengthChars={300} cell={cell} />,
  },
  {
    accessorKey: 'jobTitle',
    header: 'Job Title',
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
  },
  {
    accessorKey: 'lastLoginDate',
    header: 'Last login date',
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <TableDateCell cell={cell} />,
  },
];

export function filterTableData(users: UserTableRow[], filterText: string): UserTableRow[] {
  return users.filter(
    (user) =>
      `${user.firstName} ${user.lastName}`
        .toLocaleLowerCase()
        .includes(filterText.toLocaleLowerCase()) ||
      user.email.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
  );
}

export function mapTableData(
  users: User[],
  currentUserCanEditAllUserDealers: boolean,
  currentUserDealerPublicIds: string[]
): UserTableRow[] {
  return users.map((user) => {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      jobTitle: user.jobTitle,
      lastLoginDate: user.lastLoginDate,
      dealerGroupId: user.dealerGroupId,
      dealerNames: user.dealers.map((dealer) => dealer.name),
      id: user.id,
      roles: user.roles,
      canDelete:
        currentUserCanEditAllUserDealers || canDeleteUser(user, currentUserDealerPublicIds),
    };
  });
}

function canDeleteUser(user: User, currentUserDealerPublicIds: string[]): boolean {
  return !user.dealers.some(
    (dealer) => !currentUserDealerPublicIds.find((x) => x === dealer.publicId)
  );
}
