import { useContext } from 'react';
import { UseMutationResult, useMutation } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosError, AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { UserDealer, getUserBulkImportUrl } from 'services/UserAdministrationApiService';

import { handleOnError } from 'utils/queryUtils';

export interface UserBulkImportResult {
  email: string;
  errors: string[];
}

export interface UserBulkImportResponse {
  results: UserBulkImportResult[];
}

export const useUserBulkImportMutation = (): UseMutationResult<
  AxiosResponse<UserBulkImportResponse, unknown>,
  unknown,
  { file: File; dealers: UserDealer[] },
  unknown
> => {
  const axiosClient = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();

  const userBulkImportMutation = useMutation(
    async (params: { file: File; dealers: UserDealer[] }) => {
      const formData = new FormData();
      formData.append('file', params.file);
      formData.append('userDealers', JSON.stringify(params.dealers));

      return await axiosClient.post<UserBulkImportResponse>(getUserBulkImportUrl(), formData);
    },
    {
      onSuccess: async () => {
        enqueueSnackbar('Bulk import complete.', {
          variant: 'success',
        });
      },
      onError: (error: AxiosError) => {
        handleOnError(error, 'Failed to import users .csv!', null);
      },
    }
  );

  return userBulkImportMutation;
};
