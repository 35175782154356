import React from 'react';

import { Grid, InputAdornment, Stack, Typography } from '@mui/material';

import _ from 'lodash';

import { NumberFormField } from 'components/molecules/form-fields';
import { NumberHandlingMode } from 'components/molecules/form-fields/text-form-field/useTextFormField';

import {
  CarProgramFeeRule,
  FeeValueType,
  FlatProfit,
  RateMarkupProfit,
  Reserve,
  ReserveProfitMethod,
} from 'services/payment-grid/PaymentGridApiModels.generated';
import { ProgramType } from 'services/PdpApiService';
import { ReserveType } from 'services/ReserveService';

import { formatToCurrency, formatToPercentage } from 'utils/formatterUtils';

export interface ReserveFormValues {
  leaseReserveType: ReserveType;
  loanReserveType: ReserveType;
  aprMarkupPercent: number | undefined;
  moneyFactorMarkup: number | undefined;
  markedUpAcquisitionFee: number | undefined;
}

export const getProfitHeaderText = (
  reserve: Reserve,
  acquisitionFeeProfit: number | undefined,
  totalAmountFinancedOrNetCapCost: number,
  programType: ProgramType
): string =>
  `${getProfitHeaderTextBase(
    reserve,
    totalAmountFinancedOrNetCapCost,
    programType
  )}${getAcquisitionFeeProfitLabel(acquisitionFeeProfit)}`;

const getProfitHeaderTextBase = (
  reserve: Reserve,
  totalAmountFinancedOrNetCapCost: number,
  programType: ProgramType
): string => {
  if (reserve.appliedMethod === ReserveProfitMethod.Flat) {
    if (!isSuperFlat(reserve.flat)) {
      return `${formatToCurrency(reserve.total, 0)} from flat fee`;
    }

    return `${formatToCurrency(reserve.total, 0)} from ${getSuperFlatPercentValue(
      reserve,
      totalAmountFinancedOrNetCapCost
    )}% of ${getCapCostOrTotalAmtFinancedLabel(programType)}`;
  }

  return `${formatToCurrency(reserve.rateMarkup.total, 0)} from markup with split ${_.round(
    reserve.rateMarkup.splitRate * 100,
    1
  )}%`;
};

const getSuperFlatPercentValue = (
  reserve: Reserve,
  totalAmountFinancedOrNetCapCost: number
): number => {
  if (!reserve.flat.rules?.length) {
    return reserve.flat.value * 100;
  }

  const rule = reserve.flat.rules.find(
    (rule) =>
      (rule.amountFinancedMin === undefined ||
        totalAmountFinancedOrNetCapCost >= rule.amountFinancedMin) &&
      (rule.amountFinancedMax === undefined ||
        totalAmountFinancedOrNetCapCost <= rule.amountFinancedMax)
  );

  if (!rule) {
    return reserve.flat.value * 100;
  }

  return rule.value * 100;
};

export const getProgramHeader = (label: string, value: string): React.ReactNode => {
  return (
    <Grid container direction="row">
      <Grid item xs={2}>
        <Typography variant="body2">{label}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  );
};

export const createReserveTypeRadioOptions = (
  reserve: Reserve,
  maxMarkup: number | undefined,
  programType: ProgramType,
  reserveType: ReserveType,
  isDisabled: boolean
): {
  value: number;
  label: string;
  nestedElement?: React.ReactElement;
  isDisabled: boolean;
}[] => {
  return [
    createAutoRadioOption(isAutoSelected(reserveType), reserve, isDisabled),
    createFlatRadioOption(reserve.flat, programType, isDisabled),
    createMaxMarkupRadioOption(maxMarkup, reserve.rateMarkup, programType, isDisabled),
    createCustomRateMarkupRadioOption(programType, reserveType, reserve.rateMarkup, isDisabled),
  ];
};

export const createAutoRadioOption = (
  isAutoSelected: boolean,
  reserve: Reserve,
  isDisabled: boolean
): {
  value: number;
  label: string;
  nestedElement?: React.ReactElement;
  isDisabled: boolean;
} => {
  return {
    value: ReserveType.Auto,
    label: 'Auto',
    nestedElement: (
      <Stack paddingLeft={3}>
        <Typography variant="body3">
          {getAutoRadioOptionMessage(isAutoSelected, reserve)}
        </Typography>
      </Stack>
    ),
    isDisabled,
  };
};

export const createCustomRateMarkupRadioOption = (
  programType: ProgramType,
  reserveType: ReserveType,
  rateMarkup: RateMarkupProfit,
  isDisabled: boolean
): {
  value: number;
  label: string;
  nestedElement?: React.ReactElement;
  isDisabled: boolean;
} => {
  return {
    value: ReserveType.RateMarkup,
    label:
      programType === ProgramType.Lease ? 'Money factor markup - custom' : 'Rate markup - custom',
    nestedElement: (
      <Grid paddingLeft={3} marginTop={1}>
        <Stack direction="row" spacing={2}>
          <NumberFormField
            name={programType === ProgramType.Lease ? 'moneyFactorMarkup' : 'aprMarkupPercent'}
            label={programType === ProgramType.Lease ? 'Money factor markup' : 'Rate markup'}
            dataCy={`reserveModal_${ProgramType.Lease ? 'moneyFactor' : 'loanRatePercent'}_input`}
            InputProps={{
              startAdornment:
                programType === ProgramType.Lease ? (
                  <></>
                ) : (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              disabled: isDisabled || reserveType !== ReserveType.RateMarkup,
            }}
            focused
            numberHandlingMode={NumberHandlingMode.AsUndefined}
          />
          <Stack>
            <Typography
              fontWeight={700}
              variant="body4"
              data-cy="reserveModal_defaultReserveMarkup"
            >
              DEFAULT MARKUP
            </Typography>
            <Typography
              variant="body4"
              data-cy={`reserveModal_${
                ProgramType.Lease ? 'moneyFactor' : 'loanRatePercent'
              }_default`}
            >
              {getDefaultMarkupText(programType, rateMarkup)}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    ),
    isDisabled,
  };
};

const isAutoSelected = (reserveType: ReserveType): boolean => reserveType === ReserveType.Auto;

export const createMaxMarkupRadioOption = (
  maxMarkup: number | undefined,
  rateMarkup: RateMarkupProfit,
  programType: ProgramType,
  isDisabled: boolean
): {
  value: number;
  label: string;
  nestedElement?: React.ReactElement;
  isDisabled: boolean;
} => {
  return {
    value: ReserveType.MaxAllowed,
    label: 'Rate markup - max allowed',
    nestedElement: (
      <Stack paddingLeft={3}>
        <Typography variant="body3">
          {getMaxMarkupMessage(maxMarkup, programType, rateMarkup)}
        </Typography>
      </Stack>
    ),
    isDisabled,
  };
};

export const createFlatRadioOption = (
  flatProfit: FlatProfit,
  programType: ProgramType,
  isDisabled: boolean
): {
  value: number;
  label: string;
  nestedElement?: React.ReactElement;
  isDisabled: boolean;
} => {
  return {
    value: ReserveType.Flat,
    label: getFlatOrSuperFlatRadioButtonLabel(flatProfit, programType),
    nestedElement: getFlatOrSuperFlatRadioButtonContents(flatProfit, programType),
    isDisabled,
  };
};

const isSuperFlat = (flat: FlatProfit): boolean => flat.valueType === FeeValueType.Percentage;

const getFlatOrSuperFlatRadioButtonContents = (
  flatProfit: FlatProfit,
  programType: ProgramType
): React.ReactElement | undefined => {
  const hasRules = flatProfit.rules?.length;
  if (!hasRules) {
    return undefined;
  }

  return (
    <Stack paddingLeft={3}>
      {flatProfit.rules?.map((rule) => (
        <Typography key={`${rule.amountFinancedMin}-${rule.amountFinancedMax}`} variant="body3">
          {getFlatOrSuperFlatRadioButtonRuleText(flatProfit.valueType, rule, programType)}
        </Typography>
      ))}
    </Stack>
  );
};

const getFlatOrSuperFlatRadioButtonRuleText = (
  valueType: FeeValueType,
  rule: CarProgramFeeRule,
  programType: ProgramType
): string =>
  valueType === FeeValueType.Amount
    ? getFlatRadioButtonRuleText(rule, programType)
    : getSuperFlatRadioButtonRuleText(rule, programType);

const getFlatRadioButtonRuleText = (rule: CarProgramFeeRule, programType: ProgramType): string =>
  `${formatToCurrency(rule.value, 0)} flat fee ${getRuleRangeText(rule, programType)}`;

const getSuperFlatRadioButtonRuleText = (
  rule: CarProgramFeeRule,
  programType: ProgramType
): string =>
  `${rule.value * 100}% of ${getCapCostOrTotalAmtFinancedLabel(programType)} ${getRuleRangeText(
    rule,
    programType
  )}`;

const getRuleRangeText = (rule: CarProgramFeeRule, programType: ProgramType): string =>
  `(${programType === ProgramType.Lease ? 'net cap cost' : 'financed amount'} ${formatToCurrency(
    rule.amountFinancedMin ?? 0,
    0
  )}${getRuleMaxAmountValueText(rule)})`;

const getFlatOrSuperFlatRadioButtonLabel = (
  flatProfit: FlatProfit,
  programType: ProgramType
): string =>
  isSuperFlat(flatProfit) ? getSuperFlatLabel(flatProfit, programType) : getFlatLabel(flatProfit);

const getFlatLabel = (flat: FlatProfit): string => {
  const hasRules = flat.rules?.length;

  return hasRules ? 'Flat' : `Flat - ${formatToCurrency(flat.value, 0)}`;
};

const getSuperFlatLabel = (flat: FlatProfit, programType: ProgramType): string => {
  const hasRules = flat.rules?.length;

  return hasRules
    ? 'Superflat'
    : `Superflat - ${flat.value * 100}% of ${getCapCostOrTotalAmtFinancedLabel(programType)}`;
};

const getCapCostOrTotalAmtFinancedLabel = (programType: ProgramType): string => {
  return programType === ProgramType.Lease ? 'capitalized cost' : 'total amount financed';
};
const getRuleMaxAmountValueText = (rule: CarProgramFeeRule): string => {
  return rule.amountFinancedMax ? `-${formatToCurrency(rule.amountFinancedMax ?? 0, 0)}` : '+';
};
const getAcquisitionFeeProfitLabel = (acquisitionFeeProfit: number | undefined): string => {
  return acquisitionFeeProfit
    ? `, ${formatToCurrency(acquisitionFeeProfit ?? 0, 0)} from acquisition fee`
    : '';
};

export const getReserveButtonText = (
  leaseReserveType?: ReserveType,
  loanReserveType?: ReserveType,
  leaseReserve?: Reserve,
  loanReserve?: Reserve
): string =>
  `${getProgramReserveButtonText(ProgramType.Lease, leaseReserveType, leaseReserve)}${
    leaseReserveType !== undefined &&
    loanReserveType !== undefined &&
    leaseReserve !== undefined &&
    loanReserve !== undefined
      ? '/'
      : ''
  }${getProgramReserveButtonText(ProgramType.Loan, loanReserveType, loanReserve)}`;

const getProgramReserveButtonText = (
  programType: ProgramType,
  reserveType?: ReserveType,
  reserve?: Reserve
): string => {
  if (reserveType === undefined || reserve === undefined) {
    return '';
  }
  switch (reserveType) {
    case ReserveType.Auto: {
      if (reserve.appliedMethod === ReserveProfitMethod.Flat) {
        return getReserveFlatButtonText(reserve);
      }

      return getRateMarkupText(programType, reserve.rateMarkup.markupApplied);
    }
    case ReserveType.Flat:
      return getReserveFlatButtonText(reserve);
    case ReserveType.MaxAllowed:
      return 'MAX ALLOWED';
    default:
      return getRateMarkupText(programType, reserve.rateMarkup.markupApplied);
  }
};

const getReserveFlatButtonText = (reserve: Reserve): string => {
  if (isSuperFlat(reserve.flat)) {
    return `SUPERFLAT: ${reserve.flat.value * 100}%`;
  }

  return `FLAT: ${formatToCurrency(reserve.flat.value, 0)}`;
};

const getRateMarkupText = (programType: ProgramType, markup: number): string =>
  programType === ProgramType.Lease ? markup.toPrecision(5) : `${_.round(markup * 100, 1)}%`;

const getAutoRadioOptionMessage = (isAutoSelected: boolean, reserve: Reserve): string =>
  isAutoSelected ? getAutoRadioOptionSelectedMessage(reserve) : '';

const getAutoRadioOptionSelectedMessage = (reserve: Reserve): string =>
  `${
    reserve.appliedMethod === ReserveProfitMethod.Flat
      ? getFlatOrSuperFlatText(reserve.flat)
      : 'Rate markup'
  } selected`;

const getFlatOrSuperFlatText = (flat: FlatProfit): string =>
  flat.valueType === FeeValueType.Amount ? 'Flat' : 'Superflat';

const getMaxMarkupMessage = (
  maxMarkup: number | undefined,
  programType: ProgramType,
  rateMarkup: RateMarkupProfit
): string => {
  return maxMarkup !== undefined
    ? `${getLeaseMaxMarkupMessage(programType, maxMarkup)} (${_.round(
        rateMarkup.splitRate * 100,
        1
      )}% split)`
    : 'No maximum markup defined';
};
const getLeaseMaxMarkupMessage = (programType: ProgramType, maxMarkup: number): string => {
  return programType === ProgramType.Lease
    ? `${maxMarkup} Money factor markup`
    : `${maxMarkup * 100}% APR markup`;
};
function getDefaultMarkupText(
  programType: ProgramType,
  rateMarkup: RateMarkupProfit
): React.ReactNode {
  return rateMarkup.defaultMarkup !== undefined
    ? `${
        programType === ProgramType.Lease
          ? rateMarkup.defaultMarkup
          : `${formatToPercentage(rateMarkup.defaultMarkup, 2)}`
      }`
    : 'NO DEFAULT SPECIFIED';
}
