import React from 'react';

import { Button, ButtonProps, styled } from '@mui/material';

import { TriggeredModal } from 'components/molecules/modal';

import { UserBulkImportForm } from './UserBulkImportForm';

const StyledButton = styled(Button)<ButtonProps>(() => ({
  fontWeight: 'bold',
}));

export const UserBulkImportModal: React.FC = () => {
  return (
    <TriggeredModal
      title="Bulk User Import"
      content={<UserBulkImportForm />}
      showConfirmButton={false}
      trigger={
        <StyledButton variant="contained" data-cy="UserBulkImportModal_triggerButton" size="large">
          User Bulk Import
        </StyledButton>
      }
    />
  );
};
