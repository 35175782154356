import React from 'react';

import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

import {
  DefaultCustomSnackBar,
  ErrorCustomSnackBar,
  InfoCustomSnackBar,
  SuccessCustomSnackBar,
  WarningCustomSnackBar,
} from 'components/atoms/snackbar';

import { useConfiguration } from 'hooks/useConfiguration';

interface Props {
  children: React.ReactNode;
}

export const SnackBarProvider: React.FC<Props> = ({ children }) => {
  const {
    snackBarOptions: { autoHideDurationMs, maxSnackAcount },
  } = useConfiguration();

  return (
    <NotistackSnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={autoHideDurationMs}
      maxSnack={maxSnackAcount}
      Components={{
        info: InfoCustomSnackBar,
        error: ErrorCustomSnackBar,
        success: SuccessCustomSnackBar,
        warning: WarningCustomSnackBar,
        default: DefaultCustomSnackBar,
      }}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
