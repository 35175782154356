import React from 'react';

import { Divider, Grid, Paper, Stack, styled, Typography, TypographyProps } from '@mui/material';

import { FinancingType } from 'utils/commonTypes';

const PriceTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.grey[600],
  textTransform: 'uppercase',
}));

const PriceSubtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.grey[400],
  textTransform: 'uppercase',
}));

const ProgramNote = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.success.main,
  fontWeight: 700,
}));

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isProgramExpired',
})<{
  isProgramExpired?: boolean;
}>(({ isProgramExpired, theme }) => ({
  border: isProgramExpired
    ? `{theme.spacing(0.125)} solid ${theme.palette.brandOrange.main}`
    : `${theme.spacing(0.125)} solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(2),
}));

const ProgramExpiredMessage = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.brandOrange.main,
  fontWeight: 700,
}));

interface Props {
  type: FinancingType;
  price: string;
  priceNote: string;
  dueAtSigningPrice: string;
  dueAtSigningPriceNote: string;
  expirationNote: string;
  inPaymentTaxNote?: string | null;
  selectorPrefix: string;
  isProgramExpired?: boolean;
  programNote1?: string | null;
  programNote2?: string | null;
}

export const DashboardTile: React.FC<Props> = ({
  type,
  price,
  priceNote,
  expirationNote,
  inPaymentTaxNote,
  dueAtSigningPrice,
  dueAtSigningPriceNote,
  selectorPrefix,
  isProgramExpired,
  programNote1,
  programNote2,
}) => (
  <StyledPaper
    elevation={0}
    data-cy={`${selectorPrefix}${type}`}
    isProgramExpired={isProgramExpired}
  >
    <Stack direction="column" spacing={2} divider={<Divider />}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5}>
          <Stack>
            <PriceTitle
              color="primary"
              variant="overline"
              data-cy={`${selectorPrefix}${type}_priceTitle`}
            >
              {type}
            </PriceTitle>
            <Typography variant="h5" data-cy={`${selectorPrefix}${type}_price`}>
              {price}
            </Typography>
            <Typography variant="body4" data-cy={`${selectorPrefix}${type}_inPaymentTaxNote`}>
              {inPaymentTaxNote}
            </Typography>
            <PriceSubtitle
              color="primary"
              variant="overline"
              data-cy={`${selectorPrefix}${type}_subtilte`}
            >
              per month
            </PriceSubtitle>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Stack>
            {programNote1 && (
              <ProgramNote variant="body4" data-cy={`${selectorPrefix}${type}_programNote1`}>
                {programNote1}
              </ProgramNote>
            )}
            {programNote2 && (
              <Typography variant="body4" data-cy={`${selectorPrefix}${type}expirationNote`}>
                {programNote2}
              </Typography>
            )}
            <Typography variant="body4" data-cy={`${selectorPrefix}${type}_priceNote`}>
              {priceNote}
            </Typography>
            <Typography variant="body4" data-cy={`${selectorPrefix}${type}expirationNote`}>
              {expirationNote}
            </Typography>
            {isProgramExpired && (
              <ProgramExpiredMessage
                variant="body4"
                data-cy={`${selectorPrefix}${type}expirationNote`}
              >
                This program has expired.
              </ProgramExpiredMessage>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={5}>
          <PriceTitle
            color="primary"
            variant="overline"
            data-cy={`${selectorPrefix}${type}_dueAtSigninTitle`}
          >
            DUE AT SIGNING
          </PriceTitle>
          <Typography variant="h5" data-cy={`${selectorPrefix}${type}_dueAtSignin`}>
            {dueAtSigningPrice}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Typography variant="body4" data-cy={`${selectorPrefix}${type}_dueAtSigningPriceNote`}>
            {dueAtSigningPriceNote}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  </StyledPaper>
);
