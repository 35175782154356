import React from 'react';

import { Typography } from '@mui/material';

import { ExtendedRebate } from 'services/RebatesService';

import { getAmountIncentiveValue } from './AmountIncentiveTableCellService';

interface Props {
  rebate: ExtendedRebate;
  programTerms: number[];
}
export const AmountIncentiveTableCell: React.FC<Props> = ({ rebate, programTerms }) => (
  <Typography variant="body3" fontWeight={700}>
    {getAmountIncentiveValue(rebate, programTerms)}
  </Typography>
);
