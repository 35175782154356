import React from 'react';

import { Grid, InputAdornment, Stack, Typography } from '@mui/material';

import {
  DownPaymentSelect,
  MonthlyPaymentSelect,
  DownPaymentInput,
  MaxOutOfPocketInput,
  MonthlyPaymentInput,
} from 'components/molecules/exact-payments';
import { NumberFormField, SelectFormField } from 'components/molecules/form-fields';
import { NumberHandlingMode } from 'components/molecules/form-fields/text-form-field/useTextFormField';
import { InvalidCreditScoreModal } from 'components/pdp/invalid-credit-score-modal';
import { TradeOptionsModal } from 'components/pdp/payment-information-box/trade-options-modal';

import { useDealDefaults } from './useDealDefaults';

interface Props {
  carId: string;
  isDisabled: boolean;
  isDealOffer: boolean;
}

export const DealDefaults: React.FC<Props> = ({ carId, isDisabled, isDealOffer }) => {
  const {
    payAllFeesUpfront,
    creditTierOptions,
    isMonthlyPaymentCalculated,
    isDownPaymentCalculated,
    creditReadOnly,
    isCreditScoreInvalidModalOpen,
    customerCreditTier,
    dealCreditTier,
    confirmCreditScoreInvalidModal,
    onImplicitCloseCreditScoreInvalidModal,
  } = useDealDefaults({ carId, isDealOffer });

  return (
    <>
      <Grid item xs={12}>
        <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle3" data-cy="paymentInformationBox_title">
            Deal defaults
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Stack direction="row">
              {payAllFeesUpfront ? (
                <DownPaymentInput
                  isDisabled={isDisabled}
                  isPaymentCalculated={isDownPaymentCalculated}
                />
              ) : (
                <MaxOutOfPocketInput
                  isDisabled={isDisabled}
                  isPaymentCalculated={isDownPaymentCalculated}
                />
              )}
              <DownPaymentSelect isDisabled={isDisabled} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Stack direction="row">
              <MonthlyPaymentInput
                isDisabled={isDisabled}
                isMonthlyPaymentCalculated={isMonthlyPaymentCalculated}
              />

              <MonthlyPaymentSelect isDisabled={isDisabled} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={1.5}>
            <SelectFormField
              name="creditScore"
              label={
                <Stack direction="row" spacing={1}>
                  <div>CREDIT SCORE</div>
                </Stack>
              }
              options={creditTierOptions}
              dataCyPrefix="pdpPage_paymentCustomization"
              focused
              fullWidth
              disabled={isDisabled || creditReadOnly}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1.5}>
            <NumberFormField
              name="tradeInValue"
              label={
                <Stack direction="row" spacing={1}>
                  <div>TRADE ALLOWANCE</div>
                </Stack>
              }
              focused
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              dataCy="pdpPage_paymentCustomization_tradeInValue_input"
              disabled={isDisabled}
              numberHandlingMode={NumberHandlingMode.AsZero}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1.5}>
            <NumberFormField
              name="tradeInOutstandingBal"
              label="Trade Payoff"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              focused
              fullWidth
              dataCy="pdpPage_paymentCustomization_tradeInOutstandingBal_input"
              disabled={isDisabled}
              numberHandlingMode={NumberHandlingMode.AsZero}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1.5}>
            <TradeOptionsModal selectorPrefix="PdpPage" isDisabled={isDisabled} />
          </Grid>
        </Grid>
      </Grid>
      <InvalidCreditScoreModal
        customerCreditTierName={customerCreditTier?.label ?? ''}
        dealCreditTierName={dealCreditTier?.label ?? ''}
        isReadOnlyDeal={isDealOffer}
        isOpen={isCreditScoreInvalidModalOpen}
        confirm={confirmCreditScoreInvalidModal}
        onImplicitClose={onImplicitCloseCreditScoreInvalidModal}
      />
    </>
  );
};
