import React from 'react';

import { useConfiguration } from 'hooks/useConfiguration';

const LazyLdProvider = React.lazy(() =>
  import('launchdarkly-react-client-sdk').then(async ({ asyncWithLDProvider }) => {
    const {
      launchDarklyOptions: { clientSideID, flags },
    } = useConfiguration();

    return {
      default: await asyncWithLDProvider({
        clientSideID,
        options: { streaming: false },
        // NOTE It is recommend to use the same user key for every initialization and then replacing that with the actual user key.
        // This way LaunchDarkly counts the initialization user key only once against your MAU, instead of every time you initialize.
        // More info: https://docs.launchdarkly.com/sdk/features/user-config#javascript
        context: { key: 'ff6f4c76-84d9-4994-95b4-3a81bb44e014', kind: 'user' },
        flags,
      }),
    };
  })
);

interface Props {
  children: React.ReactNode;
}

export const LdProvider: React.FC<Props> = ({ children }) => (
  <LazyLdProvider>{children}</LazyLdProvider>
);
