import { EnqueueSnackbar } from 'notistack';

import { AxiosInstance } from 'axios';

import { Pdp } from 'providers/PdpContextProvider';

import { Rebate, getIncentivesUrl } from './PdpApiService';

export interface UpdateIncentivesRequest {
  axiosClient: AxiosInstance;
  carId: string;
  enqueueSnackbar: EnqueueSnackbar;
  msrp: number;
  pdpContext: Pdp;
  zipCode: string | undefined;
}

export async function updateIncentives({
  axiosClient,
  pdpContext,
  enqueueSnackbar,
  ...request
}: UpdateIncentivesRequest): Promise<void> {
  await axiosClient
    .get<Rebate[]>(getIncentivesUrl(request.carId), {
      params: { msrp: request.msrp, zipCode: request.zipCode },
    })
    .then((result) => {
      pdpContext.pdpData.lenderData.rebates = result.data;
      pdpContext.setPdpData(pdpContext.pdpData);
    })
    .catch(() => enqueueSnackbar('Unable to update incentives', { variant: 'error' }));
}
