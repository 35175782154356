import { useContext } from 'react';
import { UseMutationResult, useMutation } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosResponse } from 'axios';

import {
  PrintOptions,
  getDownloadConfigurablePdfUrl,
  getConfigurablePdfGridFileName,
} from 'components/pdp/form/DownloadPdfService';

import { AxiosContext } from 'providers/AxiosProvider';

import { handleDownloadFile } from 'services/FileDownloadService';

interface HookOptions {
  dealOfferId: string;
  vin: string;
}

export const useDownloadConfigurablePdfMutation = ({
  dealOfferId,
  vin,
}: HookOptions): UseMutationResult<
  AxiosResponse<BlobPart, any>,
  unknown,
  PrintOptions,
  unknown
> => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);

  const downloadPdfGridMutation = useMutation(
    (options: PrintOptions) =>
      axiosClient.post<BlobPart>(getDownloadConfigurablePdfUrl(dealOfferId), options, {
        headers: { Accept: 'application/pdf' },
        responseType: 'blob',
      }),
    {
      onError: () => {
        enqueueSnackbar('Failed to download grid pdf.', {
          variant: 'error',
        });
      },
      onSuccess: (response: AxiosResponse<BlobPart, any>) => {
        handleDownloadFile(
          response.data,
          getConfigurablePdfGridFileName({ description: dealOfferId, vin })
        );
      },
    }
  );

  return downloadPdfGridMutation;
};
