import React from 'react';

import { theme } from 'utils/muiThemeUtils';

import { CustomSnackbarProps, CustomSnackbar } from './Snackbar';

// eslint-disable-next-line react/display-name
export const WarningCustomSnackBar = React.forwardRef<HTMLDivElement, CustomSnackbarProps>(
  (props, ref) => <CustomSnackbar ref={ref} {...props} color={theme.palette.warning} />
);
