import React from 'react';
import { Link } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { List, ListItem, ListItemButton, ListItemText, styled } from '@mui/material';

import MaterialReactTable, { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { DealerTableRow } from 'pages/administration/dealers/list-page/DealersListPageService';

import { Popover } from 'components/atoms/popover';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'unset',
  width: '100%',
});

interface Props {
  tableData: DealerTableRow[];
  columns: ColumnDef<Partial<DealerTableRow>>[];
  canEditDealers: boolean;
  isLoading: boolean;
}

export const DealersTable: React.FC<Props> = ({
  columns,
  isLoading,
  canEditDealers,
  tableData,
}) => {
  return (
    <MaterialReactTable
      columns={columns}
      enableRowActions
      renderRowActions={({ row: { index, id } }): React.ReactNode =>
        canEditDealers && (
          <Popover
            data-cy={`dealerListPage_table_row-${index}_popover`}
            icon={<MoreVertIcon />}
            content={
              <List>
                <ListItem disablePadding>
                  <StyledLink to={`/administration/dealers/${id}`}>
                    <ListItemButton>
                      <ListItemText
                        data-cy={`dealerListPage_table_row-${index}_popover_editDealer`}
                      >
                        Edit Dealer
                      </ListItemText>
                    </ListItemButton>
                  </StyledLink>
                </ListItem>
              </List>
            }
          />
        )
      }
      data={tableData}
      enableColumnActions={false}
      enableTopToolbar
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableHiding={false}
      enableFilters={false}
      positionActionsColumn="last"
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
        },
      }}
      initialState={{
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'name', desc: false }],
      }}
      state={{
        isLoading,
      }}
      getRowId={({ publicId }): string => publicId?.toString() ?? (undefined as any as string)}
    />
  );
};
