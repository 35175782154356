import { useContext } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { AxiosError, AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { handleDownloadFile } from 'services/FileDownloadService';
import { getUserBulkImportSampleUrl } from 'services/UserAdministrationApiService';

import { handleOnError } from 'utils/queryUtils';

export const useUserBulkImportSampleFileDownloadQuery = (): UseQueryResult<
  AxiosResponse<any, unknown>,
  AxiosError<any, unknown>
> => {
  const axiosClient = useContext(AxiosContext);
  const userBulkImportSampleFileDownloadQuery = useQuery(
    'bulkUserImportSampleQuery',
    async () => {
      return await axiosClient.get<BlobPart>(getUserBulkImportSampleUrl(), {
        headers: { Accept: 'application/csv' },
        responseType: 'blob',
      });
    },
    {
      enabled: false,
      onSuccess: (response: AxiosResponse<BlobPart, any>) => {
        handleDownloadFile(response.data, 'sample.csv');
      },
      onError: (response: AxiosError<unknown, any>) => {
        handleOnError(response, 'Failed to download sample csv!', null);
      },
    }
  );

  return userBulkImportSampleFileDownloadQuery;
};
