import React from 'react';

import { Help } from '@mui/icons-material';
import { Stack, Typography, Grid, styled, Tooltip } from '@mui/material';

import { PriceListItem } from 'components/atoms/price-list-item';
import { Accordion, ExpandedType } from 'components/molecules/accordion';

import { SalesTaxDetailItem } from 'services/payment-grid/PaymentGridApiModels.generated';
import { TtlNote } from 'services/TtlApiService';

import { formatToCurrency, formatToPercentage } from 'utils/formatterUtils';

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

const TtlItemsWrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
  margin: `${theme.spacing(1)} 0`,
  background: `${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius,
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: '1rem',
}));

const TtlItemsListWrapper = styled('div')({
  flexGrow: 1,
});

interface DetailsCardProps {
  detailItems: {
    label: string;
    value: string | number;
    dataCy?: string;
    isHidden?: boolean;
    isSpace?: boolean;
  }[];
  calculationItems: {
    label: string;
    value: string | number;
    dataCy?: string;
    isHidden?: boolean;
    isSpace?: boolean;
  }[];
  signingTtlCalculationItems: {
    label: string;
    value: string | number;
    dataCy?: string;
    isHidden?: boolean;
    isSpace?: boolean;
  }[];
  dueAtSigning?: number;
  titleText: string;
  dataCyPrefix: string;
  expanded: boolean;
  type: ExpandedType;
  ttlNotes?: TtlNote[];
  salesTaxDetails: SalesTaxDetailItem[];
}

export const DetailsCard: React.FC<DetailsCardProps> = ({
  detailItems,
  calculationItems,
  signingTtlCalculationItems,
  dueAtSigning = 0,
  titleText,
  dataCyPrefix,
  expanded,
  type,
  ttlNotes = [] as TtlNote[],
  salesTaxDetails,
}) => (
  <Accordion titleText={titleText} type={type} expanded={expanded}>
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        {detailItems.map(({ dataCy, label, value, isHidden }, key) => (
          <PriceListItem
            key={key}
            label={label}
            value={value}
            selectorPrefix={dataCy}
            isHidden={isHidden}
            isSpace={isHidden}
          />
        ))}
      </Grid>
      <Grid item xs={12} lg={6}>
        {calculationItems.map(({ dataCy, label, value, isHidden, isSpace }, key) => (
          <PriceListItem
            key={key}
            label={label}
            value={value}
            selectorPrefix={dataCy}
            isHidden={isHidden}
            isSpace={isSpace}
          />
        ))}

        {signingTtlCalculationItems?.length > 0 && (
          <TtlItemsWrapper>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <TtlItemsListWrapper>
                {signingTtlCalculationItems.map(
                  ({ dataCy, label, value, isHidden, isSpace }, key) => (
                    <PriceListItem
                      key={key}
                      label={label}
                      value={value}
                      selectorPrefix={dataCy}
                      isHidden={isHidden}
                      isSpace={isSpace}
                    />
                  )
                )}
              </TtlItemsListWrapper>
              {(salesTaxDetails.length > 0 || ttlNotes.length > 0) && (
                <StyledTooltip
                  title={
                    <Stack direction="column" spacing={1}>
                      <Stack>
                        {salesTaxDetails.map((detail, i) => (
                          <Stack
                            direction="row"
                            spacing={3}
                            key={`${detail.description}-${i}`}
                            justifyContent="space-between"
                            data-cy={`${dataCyPrefix}-notesTooltip-content`}
                          >
                            <Typography variant="body4">{getTtlDetailItemLabel(detail)}</Typography>
                            <Typography variant="body4">{formatToCurrency(detail.fee)}</Typography>
                          </Stack>
                        ))}
                      </Stack>
                      {ttlNotes.map(({ text, sourceType }, i) => (
                        <Typography
                          variant="body4"
                          key={`${text}-${sourceType}-${i}`}
                          data-cy={`${dataCyPrefix}-notesTooltip-content-${sourceType}-${i}`}
                        >
                          {text} - {sourceType}
                        </Typography>
                      ))}
                    </Stack>
                  }
                  placement="top"
                  arrow
                  data-cy={`${dataCyPrefix}-notesTooltip`}
                >
                  <Help />
                </StyledTooltip>
              )}
            </Stack>
          </TtlItemsWrapper>
        )}
        <StyledStack direction="row" justifyContent="space-between">
          <Typography
            variant="body4"
            fontWeight={700}
            data-cy={`${dataCyPrefix}dueAtSigning_label`}
          >
            Total due at signing
          </Typography>
          <Typography variant="body4" data-cy={`${dataCyPrefix}dueAtSigning_value`}>
            {formatToCurrency(dueAtSigning)}
          </Typography>
        </StyledStack>
      </Grid>
    </Grid>
  </Accordion>
);

function getTtlDetailItemLabel(detail: SalesTaxDetailItem): string {
  return detail.rate > 0
    ? `${detail.description} (${formatToPercentage(detail.rate, 3)})`
    : detail.description;
}
