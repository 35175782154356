import React from 'react';

import { Stack, Typography } from '@mui/material';

import { ProfitData } from 'components/pdp/grid-view/table/GridTableService';

import {
  FeeValueType,
  ReserveProfitMethod,
} from 'services/payment-grid/PaymentGridApiModels.generated';

import { formatToCurrency, formatToPercentage } from 'utils/formatterUtils';

interface Props {
  dataCyPrefix: string;
  profitData: ProfitData;
}

export const ReserveProfitInfo: React.FC<Props> = ({ profitData, dataCyPrefix }) => {
  return <>{createReserveProfitInfo(profitData, dataCyPrefix)}</>;
};

const createReserveProfitInfo = (profitData: ProfitData, dataCyPrefix: string): React.ReactNode =>
  profitData.appliedReserveMethod === ReserveProfitMethod.RateMarkup
    ? getRateMarkupProfitInfo(profitData, dataCyPrefix)
    : getFlatProfitInfo(profitData, dataCyPrefix);

const getFlatProfitInfo = (profitData: ProfitData, dataCyPrefix: string): React.ReactNode => {
  return profitData.flatValueType === FeeValueType.Amount ? (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <Typography data-cy={`${dataCyPrefix}_flat_total`} variant="body4">
        Flat
      </Typography>
      <Typography data-cy={`${dataCyPrefix}_flat_value`} variant="body4">
        {formatToCurrency(profitData.reserveTotal)}
      </Typography>
    </Stack>
  ) : (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <Typography data-cy={`${dataCyPrefix}_superFlat_title`} variant="body4">
        {`Superflat (${formatToPercentage(profitData.flatValue, 2)} of cap cost/amount financed)`}
      </Typography>
      <Typography data-cy={`${dataCyPrefix}_superFlat_value`} variant="body4">
        {formatToCurrency(profitData.reserveTotal)}
      </Typography>
    </Stack>
  );
};

const getRateMarkupProfitInfo = (profitData: ProfitData, dataCyPrefix: string): React.ReactNode => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <Typography data-cy={`${dataCyPrefix}_leaseProfit_reserveSplitTitle`} variant="body4">
        {`Reserve (split ${formatToPercentage(profitData.splitProfit, 2)})`}
      </Typography>
      <Typography data-cy={`${dataCyPrefix}_leaseProfit_reserveSplitValue`} variant="body4">
        {formatToCurrency(profitData.reserveTotal)}
      </Typography>
    </Stack>
  );
};
