import { useContext } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { getDeleteUserUrl } from 'services/UserAdministrationApiService';

import { queryClient } from 'utils/queryClientUtils';

export const useDeleteUserMutation = (): UseMutationResult<
  AxiosResponse<string, unknown>,
  unknown,
  string,
  unknown
> => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);
  const navigate = useNavigate();

  const deleteUserMutation = useMutation(
    async (id: string) => await axiosClient.delete<string>(getDeleteUserUrl(id)),
    {
      onError: (error: any) => {
        enqueueSnackbar(
          error?.response?.data?.message ? error?.response?.data?.message : 'Error has occured.',
          {
            variant: 'error',
          }
        );
      },
      onSuccess: async () => {
        enqueueSnackbar('User was deleted.', {
          variant: 'success',
        });
        await queryClient.invalidateQueries('usersQuery');
        navigate(`/administration/users`);
      },
    }
  );

  return deleteUserMutation;
};
