import React, { createContext, useState } from 'react';

import { PdpItem } from 'services/PdpApiService';

interface Props {
  children: React.ReactNode;
  isInitialLoadComplete?: boolean;
  paymentCalculationDisabled?: boolean;
  programSelectionDisabled?: boolean;
  pdpData: PdpItem;
  shouldBeReadOnly: boolean;
}

export interface Pdp {
  isInitialLoadComplete: boolean;
  isPaymentCalculationDisabled: boolean;
  isProgramSelectionDisabled: boolean;
  shouldBeReadOnly: boolean;
  pdpData: PdpItem;
  initialLoadCompleted(): void;
  setPdpData: (pdpData: PdpItem) => void;
}

const defaultContext: Pdp = {
  isInitialLoadComplete: true,
  isPaymentCalculationDisabled: false,
  isProgramSelectionDisabled: false,
  shouldBeReadOnly: false,
  pdpData: {} as PdpItem,
  initialLoadCompleted: () => {},
  setPdpData: () => {},
};

export const PdpContext = createContext(defaultContext);

export const PdpContextProvider: React.FC<Props> = ({
  children,
  isInitialLoadComplete = true,
  paymentCalculationDisabled,
  pdpData,
  programSelectionDisabled,
  shouldBeReadOnly,
}) => {
  const setPdpData = (pdpData: PdpItem): void => {
    setContext((current: Pdp) => ({ ...current, pdpData }));
  };

  const initialLoadCompleted = (): void => {
    setContext((current: Pdp) => ({ ...current, isInitialLoadComplete: true }));
  };
  const initialContext: Pdp = {
    isInitialLoadComplete,
    isPaymentCalculationDisabled: paymentCalculationDisabled ?? false,
    isProgramSelectionDisabled: programSelectionDisabled ?? false,
    shouldBeReadOnly,
    pdpData,
    initialLoadCompleted,
    setPdpData,
  };

  const [context, setContext] = useState(initialContext);

  return <PdpContext.Provider value={context}>{children}</PdpContext.Provider>;
};
