import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Editor as TinyMCEEditor } from 'tinymce';

import { useConfiguration } from 'hooks/useConfiguration';

import { priceRangeAtom } from 'models/PriceRangeModel';

import { formatToCurrency, formatToCurrencyInterval } from 'utils/formatterUtils';

const LOAN_PAYMENT_ID = '#loan-payment-wrapper';
const LEASE_PAYMENT_ID = '#lease-payment-wrapper';

interface HookOptions {
  editor?: TinyMCEEditor;
  initialized?: boolean;
  leaseMonthlyPayment: number;
  loanMonthlyPayment: number;
}

export const useUpdateRanges = ({
  editor,
  leaseMonthlyPayment,
  loanMonthlyPayment,
  initialized,
}: HookOptions): void => {
  const priceRangeEnabled = useRecoilValue(priceRangeAtom);
  const {
    paymentsIntervalOptions: { defaultInterval },
  } = useConfiguration();

  useEffect(() => {
    if (initialized && editor) {
      const loanPaymentElements = editor.contentDocument.querySelectorAll(LOAN_PAYMENT_ID);
      const leasePaymentElements = editor.contentDocument.querySelectorAll(LEASE_PAYMENT_ID);

      loanPaymentElements.forEach((loanPayment) => {
        loanPayment.textContent = priceRangeEnabled
          ? formatToCurrencyInterval(loanMonthlyPayment, defaultInterval)
          : formatToCurrency(loanMonthlyPayment);
      });
      leasePaymentElements.forEach((leasePayment) => {
        leasePayment.textContent = priceRangeEnabled
          ? formatToCurrencyInterval(leaseMonthlyPayment, defaultInterval)
          : formatToCurrency(leaseMonthlyPayment);
      });

      editor.setContent(editor.getContent());
    }
  }, [priceRangeEnabled, initialized]);
};
