import { CustomIncentiveAnyLender, CustomIncentiveProgramType } from 'models/CustomIncentivesModel';

import { CustomIncentiveFormValues } from './useCustomIncentiveFormWrapper';

export function getDefaultFormValues(): CustomIncentiveFormValues {
  return {
    name: '',
    amount: 0,
    incentiveProgramType: CustomIncentiveProgramType.Both,
    incentiveLenderName: CustomIncentiveAnyLender,
  };
}
