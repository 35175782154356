import styled from '@emotion/styled';
import React, { FC } from 'react';

import { Modal, ModalProps } from './Modal';
import { useModal } from './useModal';

export interface TriggeredModalProps
  extends Omit<ModalProps, 'isOpen' | 'confirm' | 'onImplicitClose'> {
  openIsDisabled?: boolean;
  trigger?: React.ReactElement;
  onConfirm?: () => void;
}

const ModalTriggerWrapper = styled('div')(() => ({
  flexGrow: 1,
}));

export const TriggeredModal: FC<TriggeredModalProps> = ({
  onConfirm,
  confirmButtonDisabled = false,
  openIsDisabled = false,
  trigger,
  ...props
}) => {
  const { isOpen, open, confirm, onImplicitClose } = useModal({
    onConfirm,
    confirmButtonDisabled,
    openIsDisabled,
  });

  return (
    <>
      <ModalTriggerWrapper onClick={open}>{trigger}</ModalTriggerWrapper>
      <Modal
        isOpen={isOpen}
        confirm={confirm}
        onImplicitClose={onImplicitClose}
        confirmButtonDisabled={confirmButtonDisabled}
        {...props}
      />
    </>
  );
};
