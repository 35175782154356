import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { CustomAddonsAtom } from 'models/CustomAddonsModel';

import { Addon, DealType } from 'services/PdpApiService';

import {
  AddOnsFormValues,
  getTransformedCustomAddonCheckboxes,
  getTransformedNonZeroPriceCheckboxes,
  removeCustomAddons,
} from './AddOnsFormService';

interface HookOptions {
  addons: Addon[];
  customAddons: string[];
  addonsForm: UseFormReturn<AddOnsFormValues>;
}
interface CheckboxItem {
  value: string | number;
  label: string | React.ReactElement;
}

interface HookResult {
  leaseAddons: CheckboxItem[];
  loanAddons: CheckboxItem[];
  anyDealTypeAddons: CheckboxItem[];
  customCheckboxItems: CheckboxItem[];
}

export const useAddOnsForm = ({ addons, customAddons, addonsForm }: HookOptions): HookResult => {
  const [customAddonsValue, setCustomAddonsValue] = useRecoilState(CustomAddonsAtom);

  const handleClick = useCallback(
    (name: string) => {
      removeCustomAddons(addonsForm, customAddons, customAddonsValue, name, setCustomAddonsValue);
    },
    [customAddons, customAddonsValue]
  );

  const leaseAddons = getTransformedNonZeroPriceCheckboxes(addons, DealType.Lease);
  const loanAddons = getTransformedNonZeroPriceCheckboxes(addons, DealType.Loan);
  const anyDealTypeAddons = getTransformedNonZeroPriceCheckboxes(addons, DealType.Any);
  const customCheckboxItems = getTransformedCustomAddonCheckboxes({
    customAddonsValue,
    handleClick,
  });

  return {
    leaseAddons,
    loanAddons,
    anyDealTypeAddons,
    customCheckboxItems,
  };
};
