import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useModal } from 'components/molecules/modal/useModal';

import { useBuildNewDeal } from 'hooks/useBuildNewDeal';

import { currentCustomerAtom } from 'models/CurrentCustomerModel';
import { searchValuesAtom } from 'models/SearchModel';

import { buildNewDeal } from './InvalidCreditScoreService';

interface HookOptions {
  carId: string;
}

interface HookResult {
  isOpen: boolean;
  open: () => void;
  confirm: () => void;
  onImplicitClose: () => void;
}

export const useInvalidCreditScoreReadOnlyDealModal = ({ carId }: HookOptions): HookResult => {
  const setSearchFormValues = useSetRecoilState(searchValuesAtom);
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const { handleNavigate: navigateToNewDeal } = useBuildNewDeal({ openNewTab: false });

  const onConfirm = useCallback(() => {
    buildNewDeal({ customer: currentCustomer, setSearchFormValues, navigateToNewDeal, carId });
  }, [currentCustomer.creditTierId, navigateToNewDeal, carId]);

  const { open, confirm, isOpen, onImplicitClose } = useModal({ onConfirm });

  return { open, confirm, isOpen, onImplicitClose };
};
