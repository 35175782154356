import React from 'react';

import { CircularProgress, Grid } from '@mui/material';

import { PhoneEditorCard } from 'components/responder/phone-editor-card';
import { useResponderTemplate } from 'components/responder/useResponderTemplate';

import { TemplateType } from 'services/TemaplateService';

interface Props {
  leaseMonthlyPayment?: number;
  loanMonthlyPayment?: number;
}

export const PhoneEditorWrapper: React.FC<Props> = ({
  leaseMonthlyPayment = 0,
  loanMonthlyPayment = 0,
}) => {
  const {
    templateQuery: { data, isLoading },
  } = useResponderTemplate({ templateType: TemplateType.Phone });

  let element = null;

  if (isLoading) {
    element = (
      <Grid item xs={12} textAlign="center" justifyContent="center">
        <CircularProgress data-cy="responderPage_phoneTemplate_spinner" size={50} />
      </Grid>
    );
  } else if (data) {
    element = (
      <PhoneEditorCard
        buttonDataCy="responderPage_phoneTemplate_copyButton"
        editorBodyId="responderPage_phoneTemplate_editorBody"
        buttonText="Copy Text to Clipboard"
        defaultValue={data}
        isPlainText
        leaseMonthlyPayment={leaseMonthlyPayment}
        loanMonthlyPayment={loanMonthlyPayment}
        toolbarEnabled={false}
        elementPathEnabled={false}
      />
    );
  }

  return <>{element} </>;
};
