import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AssertsShape, ObjectShape, TypeOfShape } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';

import { isEmptyOrValidZip } from 'utils/validationUtils';

export function getPdpValidationResolver(): ReturnType<typeof yupResolver> {
  return yupResolver(getPdpValidationSchema());
}

export function getPdpValidationSchema(): Yup.ObjectSchema<
  ObjectShape,
  AnyObject,
  TypeOfShape<ObjectShape>,
  AssertsShape<ObjectShape>
> {
  return Yup.object().shape({
    creditScore: Yup.number().required('Please choose credit score'),
    downPayment: Yup.number()
      .min(0, 'Down payment rate must greater or equal to 0')
      .typeError('Down payment rate must be number')
      .required('Down payment rate is required'),
    maxOutOfPocket: Yup.number()
      .min(0, 'Max out of pocket must greater or equal to 0')
      .typeError('Max out of pocket must be number')
      .required('Max out of pocket is required'),
    tradeInValue: Yup.number()
      .min(0, 'Trade in value must be greater or equal to 0')
      .typeError('Trade in value must be number')
      .required('Trade in value is required'),
    tradeInOutstandingBal: Yup.number()
      .min(0, 'Owed on Trade-In value must be greater or equal to 0')
      .typeError('Owed on Trade-In value must be number')
      .required('Owed on Trade-In value is required'),
    bonusIncentive: Yup.number()
      .min(0, 'Bonus Incentive must be greater or equal to 0')
      .typeError('Bonus Incentive must be number'),
    msrp: Yup.number()
      .typeError('MSRP value must be number')
      .min(0, 'MSRP must be greater or equal to 0'),
    leaseMoneyFactorMarkup: Yup.number()
      .min(0, 'Money factor markup must be greater than 0')
      .typeError('Money factor markup must be number'),
    loanAprMarkupPercent: Yup.number()
      .min(0, 'APR markup must be greater than 0')
      .typeError('APR markup value must be number'),
    vehiclePrice: Yup.number().typeError('Vehicle price value must be number'),
    buyer: Yup.object().shape(
      {
        name: Yup.string().test('name', 'First and last name are required', (name) =>
          hasFirstAndLastName(name)
        ),
        location: Yup.object().shape({
          zipCode: Yup.string().test('zipLength', 'Enter a valid value.', (zipCode) =>
            isEmptyOrValidZip(zipCode)
          ),
        }),
        email: Yup.string()
          .email('Email must be in a valid format')
          .when('phone', {
            is: (phone: string | undefined) => !phone,
            then: Yup.string().required('Email or phone is required'),
          }),
        phone: Yup.string().when('email', {
          is: (email: string | undefined) => !email,
          then: Yup.string().required('Email or phone is required'),
        }),
      },
      // Avoid cyclic dependency
      // https://github.com/jquense/yup/issues/79#issuecomment-699605408
      [['email', 'phone']]
    ),
    exactPayment: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .min(0, 'Exact payment must greater or equal to 0.'),
  });
}

function hasFirstAndLastName(name: string | undefined): boolean {
  const parts = (name ?? '').trim().split(' ', 2);

  if (parts.length !== 2) {
    return false;
  }

  return !!parts[0].trim() && !!parts[1].trim();
}
