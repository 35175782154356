import React from 'react';

import { Layout } from 'components/molecules/layout';

import { ErrorCardContainer } from './ErrorCardContainer';

export const ErrorPage: React.FC = () => (
  <Layout>
    <ErrorCardContainer />
  </Layout>
);
