import React, { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { styled } from '@mui/material';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { getAppliedCustomIncentives } from 'components/pdp/payment-customization-block/BonusIncentiveCalculationService';

import { usePdpData } from 'hooks/usePdpData';

import { CustomIncentivesAtom } from 'models/CustomIncentivesModel';

import { ExtendedRebate, getRebatesByProgramType } from 'services/RebatesService';

import { getGroupedRebates, RebatesGroup, getProgramTypeString } from './IncentivesModalService';

interface CheckboxItem {
  value: string | number;
  label: string | React.ReactElement;
}

interface HookResult {
  customIncentivesCheckboxItems: CheckboxItem[];
  groupedRebates: RebatesGroup[];
}

export const StyledDeleteForeverOutlinedIcon = styled(DeleteForeverOutlinedIcon)(({ theme }) => ({
  fontSize: `${theme.spacing(2)}`,
  cursor: 'pointer',
  color: theme.palette.error.main,
  marginBottom: '5px',
}));

export const useIncentivesModal = (allRebates: ExtendedRebate[]): HookResult => {
  const [customIncentivesValue, setCustomIncentivesValue] = useRecoilState(CustomIncentivesAtom);
  const { control, setValue } = useFormContext<PdpFormValues>();
  const { customIncentiveNames, leaseProgram, loanProgram } = useWatch({
    control,
  }) as PdpFormValues;
  const {
    lenderData: { rebates: lenderRebates },
  } = usePdpData();

  const currentLeaseProgramId = leaseProgram?.programId;
  const currentLoanProgramId = loanProgram?.programId;
  const appliedLeaseRebates = leaseProgram?.incentives?.programIncentives ?? [];
  const appliedLoanRebates = loanProgram?.incentives?.programIncentives ?? [];

  useEffect(() => {
    const { leaseRebates, loanRebates, loanAndLeaseRebates } = getRebatesByProgramType({
      rebates: lenderRebates,
      leaseProgramId: currentLeaseProgramId,
      loanProgramId: currentLoanProgramId,
      appliedLeaseRebates,
      appliedLoanRebates,
    });

    setValue('leaseRebates', leaseRebates);
    setValue('loanRebates', loanRebates);
    setValue('loanAndLeaseRebates', loanAndLeaseRebates);
  }, [
    currentLeaseProgramId,
    currentLoanProgramId,
    appliedLeaseRebates.join(),
    appliedLoanRebates.join(),
    lenderRebates,
  ]);

  useEffect(() => {
    const appliedCustomIncentives = getAppliedCustomIncentives(
      customIncentiveNames ?? [],
      customIncentivesValue ?? []
    );
    setValue('appliedCustomIncentives', appliedCustomIncentives);
  }, [customIncentiveNames, customIncentivesValue]);

  const handleDelete = useCallback(
    (name: string) => {
      setValue('customIncentiveNames', customIncentiveNames?.filter((item) => item !== name) ?? []);
      setCustomIncentivesValue(customIncentivesValue.filter((item) => item.name !== name));
    },
    [customIncentiveNames, customIncentivesValue]
  );

  const customIncentivesCheckboxItems = customIncentivesValue.map((ci) => ({
    value: ci.name,
    label: `${ci.name} ($${ci.amount} - ${getProgramTypeString(ci.incentiveProgramType)} - ${
      ci.incentiveLenderName
    })`,
    postFix: <StyledDeleteForeverOutlinedIcon onClick={(): void => handleDelete(ci.name)} />,
  }));

  return {
    customIncentivesCheckboxItems,
    groupedRebates: getGroupedRebates(lenderRebates, allRebates),
  };
};
