import React from 'react';

import { Link, Button, CardContent, Stack, styled, Typography } from '@mui/material';

import { Card } from 'components/atoms/card';

import { PrintModal } from './print-modal/PrintModal';
import { QuickQuoteModal } from './quick-quote-modal/QuickQuoteModal';
import { usePaymentCustomizationBlockFooter } from './usePaymentCustomizationBlockFooter';

const StyledButton = styled(Button)({
  justifyContent: 'flex-start',
});

interface Props {
  isDisabled?: boolean;
  creditAppUrl: string | undefined;
  openResponder(): void;
  onPushToCrm(): Promise<void>;
  isExportPdfGridButtonDisabled: boolean;
  isSaveDealOfferAllowed: boolean;
}

export const PaymentCustomizationBlockFooter: React.FC<Props> = ({
  isDisabled = false,
  creditAppUrl,
  openResponder,
  onPushToCrm,
  isSaveDealOfferAllowed,
}) => {
  const {
    showPushToCrmButton,
    pushToCrmButtonIsDisabled,
    emailTextCustomerButtonIsDisabled,
    printButtonIsDisabled,
    creditApplicationButtonIsDisabled,
    elendIntegrationEnabled,
    showQuickQuoteButton,
    quickQuoteButtonIsDisabled,
    handleCreditApplication,
  } = usePaymentCustomizationBlockFooter({ isSaveDealOfferAllowed });

  return (
    <Card elevation={13}>
      <CardContent>
        <Stack direction="column" spacing={2} justifyContent="flex-start">
          <Typography variant="subtitle3" data-cy="pdpPage_nextSteps_text">
            Next steps
          </Typography>
          {isSaveDealOfferAllowed && (
            <StyledButton
              disabled={isDisabled}
              type="submit"
              variant="contained"
              data-cy="pdpPage_saveOfferButton"
            >
              Save Deal
            </StyledButton>
          )}
          {showPushToCrmButton && (
            <StyledButton
              disabled={isDisabled || pushToCrmButtonIsDisabled}
              onClick={onPushToCrm}
              type="submit"
              variant="contained"
              data-cy="pdpPage_pushToCrmButton"
            >
              Push to CRM
            </StyledButton>
          )}

          <PrintModal printButtonIsDisabled={printButtonIsDisabled} />
          {showQuickQuoteButton && (
            <QuickQuoteModal quickQuoteButtonIsDisabled={quickQuoteButtonIsDisabled} />
          )}
          <StyledButton
            disabled={isDisabled || emailTextCustomerButtonIsDisabled}
            onClick={openResponder}
            variant="contained"
            data-cy="pdpPage_openResponderButton"
          >
            Email/Text Customer
          </StyledButton>
          {creditAppUrl &&
            creditAppUrl !== '' &&
            (elendIntegrationEnabled ? (
              <StyledButton
                disabled={isDisabled || creditApplicationButtonIsDisabled}
                onClick={handleCreditApplication}
                variant="contained"
                data-cy="pdpPage_openCreditAppButton"
              >
                Credit Application
              </StyledButton>
            ) : (
              <StyledButton variant="contained" data-cy="pdpPage_openCreditAppButton">
                <Link
                  color="inherit"
                  underline="none"
                  href={creditAppUrl}
                  target="_blank"
                  rel="noopener"
                >
                  Credit Application
                </Link>
              </StyledButton>
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
