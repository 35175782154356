import { useFormContext } from 'react-hook-form';

import _ from 'lodash';

import { MultiSelectOptions } from 'components/molecules/form-fields/multi-select-form-field/MultiSelectFormField';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

interface HookResult {
  options: MultiSelectOptions[];
  current: number[];
}

export const useLeaseTermFormField = (): HookResult => {
  const {
    leaseTerms,
    programFilters: { leaseTerms: selectedLeaseTerms },
  } = useFormContext<PdpFormValues>().getValues();

  const options = leaseTerms.map((term) => {
    return {
      label: `${term}`,
      value: term,
      isChecked: selectedLeaseTerms.some((x) => term === x),
    };
  });

  const current = _.intersection(leaseTerms, selectedLeaseTerms);

  return { options, current };
};
