import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from 'routes';

import { DealOffersListPage } from 'pages/administration/deal-offers';
import { DealerDetailPage, DealersListPage } from 'pages/administration/dealers';
import { UserDetailPage, UsersListPage } from 'pages/administration/users';
import { DealOfferPdpPage } from 'pages/deal-offer-pdp-page';
import { NotFoundPage } from 'pages/not-found';
import { PdpPage } from 'pages/pdp-page';
import { ProfilePage } from 'pages/profile-page';
import { ReportsPage } from 'pages/reports-page';
import { ResponderPage } from 'pages/responder-page';
import { SearchPage } from 'pages/search-page';

import { UserPermission } from 'utils/UserPermission';

export const AppRouter: React.FC = () => (
  <Routes>
    <Route path="/pdp/:carId" element={<ProtectedRoute outlet={<PdpPage />} />} />
    <Route
      path="/pdp/dealOffer/:dealOfferCode"
      element={<ProtectedRoute outlet={<DealOfferPdpPage />} />}
    />
    <Route path="/profile" element={<ProtectedRoute outlet={<ProfilePage />} />} />
    <Route
      path="/reports"
      element={
        <ProtectedRoute requiredPermission={UserPermission.viewReports} outlet={<ReportsPage />} />
      }
    />
    <Route
      path="/administration/users"
      element={
        <ProtectedRoute requiredPermission={UserPermission.adminUsers} outlet={<UsersListPage />} />
      }
    />
    <Route
      path="/administration/users/create-user"
      element={
        <ProtectedRoute
          requiredPermission={UserPermission.adminUsers}
          outlet={<UserDetailPage />}
        />
      }
    />
    <Route
      path="/administration/users/:userID"
      element={
        <ProtectedRoute
          requiredPermission={UserPermission.adminUsers}
          outlet={<UserDetailPage />}
        />
      }
    />
    <Route
      path="/administration/dealers"
      element={
        <ProtectedRoute
          requiredPermission={UserPermission.viewDealers}
          outlet={<DealersListPage />}
        />
      }
    />
    <Route
      path="/administration/dealers/:dealerId"
      element={
        <ProtectedRoute
          requiredPermission={(UserPermission.viewDealers, UserPermission.editDealers)}
          outlet={<DealerDetailPage />}
        />
      }
    />
    <Route
      path="/administration/deal-offers"
      element={<ProtectedRoute outlet={<DealOffersListPage />} />}
    />
    <Route path="/responder/:dealOfferId" element={<ProtectedRoute outlet={<ResponderPage />} />} />
    <Route path="/" element={<ProtectedRoute outlet={<SearchPage />} />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
