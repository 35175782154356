import { enqueueSnackbar } from 'notistack';

import { AxiosError } from 'axios';

export function handleOnError(
  error: AxiosError,
  fallbackMessage: string,
  snackbarDurationMs?: number | null
): void {
  const data = error?.response?.data as any;
  const errors = data?.errors ?? [];

  const errorMessage = errors?.length
    ? errors.map((error: { errorMessage: any }) => error.errorMessage).join(' ')
    : fallbackMessage;

  enqueueSnackbar(errorMessage, {
    variant: 'error',
    autoHideDuration: snackbarDurationMs,
  });
}
