import { UseFormSetValue } from 'react-hook-form';
import { SetterOrUpdater } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { CreditTierOption, getCreditTierById } from 'services/CreditTiersService';
import { Customer } from 'services/CustomerService';

export interface BuildNewDealRequest {
  customer: Customer;
  setSearchFormValues: SetterOrUpdater<SearchFormValues | undefined>;
  navigateToNewDeal: (carId: string) => void;
  carId: string;
}

export function buildNewDeal({
  customer,
  setSearchFormValues,
  navigateToNewDeal,
  carId,
}: BuildNewDealRequest): void {
  const customerCreditTier = getCreditTier(customer);

  if (!customerCreditTier) {
    return;
  }

  setSearchFormValues((existing = {} as SearchFormValues) => {
    const updated: SearchFormValues = {
      ...existing,
      calculationOptions: {
        ...existing.calculationOptions,
        creditScore: customerCreditTier.value,
      },
    };

    return updated;
  });

  navigateToNewDeal(carId);
}

export interface UpdateDealCreditScoreRequest {
  customer: Customer;
  setValue: UseFormSetValue<PdpFormValues>;
  setDealOfferHasUnsavedCreditScore: SetterOrUpdater<boolean>;
}

export function updateDealCreditScore({
  customer,
  setValue,
  setDealOfferHasUnsavedCreditScore,
}: UpdateDealCreditScoreRequest): void {
  const customerCreditTier = getCreditTier(customer);

  if (!customerCreditTier) {
    return;
  }

  setValue('creditScore', customerCreditTier.value);
  setDealOfferHasUnsavedCreditScore(true);
}

function getCreditTier(customer: Customer): CreditTierOption | undefined {
  return customer.creditTierId ? getCreditTierById(customer.creditTierId) : undefined;
}
