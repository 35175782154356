import { TableCellProps } from '@mui/material';

import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { Dealer } from 'services/DealerApiService';

function getMuiTableBodyCellProps(index: number, columnName: string): TableCellProps {
  return {
    // @ts-ignore
    'data-cy': `dealerListPage_table_row-${index}_${columnName}`,
  };
}

function getMuiTableHeadCellProps(columnName: string): TableCellProps {
  return {
    // @ts-ignore
    'data-cy': `dealerListPage_table_columnHeader_${columnName}`,
  };
}

export interface DealerTableRow {
  publicId: string;
  name: string;
}

export const dealerTableColumns: ColumnDef<Partial<DealerTableRow>>[] = [
  {
    accessorKey: 'publicId',
    header: 'Id',
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
  },
  {
    accessorKey: 'name',
    header: 'Name',
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
  },
];

export function filterTableData(dealers: DealerTableRow[], filterText: string): DealerTableRow[] {
  return dealers.filter((dealer) =>
    `${dealer.name}`.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
  );
}

export function mapTableData(dealers: Dealer[]): DealerTableRow[] {
  return dealers.map((dealer) => {
    return {
      publicId: dealer.publicId,
      name: dealer.name,
    };
  });
}
