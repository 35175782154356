import React from 'react';

import { Info } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { MRT_Cell as Cell, MRT_Row as Row } from 'material-react-table';

import { ProgramGrid } from 'components/pdp/grid-view/table/GridTableService';

import { formatDate } from 'utils/formatterUtils';
import { isGreaterThanToday } from 'utils/validationUtils';

interface Props {
  cell: Cell<ProgramGrid>;
  row: Row<ProgramGrid>;
}
export const LenderNameTableCell: React.FC<Props> = ({
  cell,
  row: {
    original: { programName, expiryDate },
  },
}) => (
  <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0.5}>
    <Typography variant="body3">{cell.getValue() as string}</Typography>
    <Tooltip
      title={
        <Stack direction="column">
          <Typography variant="body4">{programName}</Typography>
          <Typography variant="body4">{`${
            isGreaterThanToday(expiryDate) ? 'Expired' : 'Ends'
          } ${formatDate(expiryDate)}`}</Typography>
        </Stack>
      }
    >
      <IconButton size="small">
        <Info fontSize="small" color={isGreaterThanToday(expiryDate) ? 'brandOrange' : 'inherit'} />
      </IconButton>
    </Tooltip>
  </Stack>
);
