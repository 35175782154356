import { ChangeEvent, ChangeEventHandler, FocusEventHandler, useCallback } from 'react';
import { useFormContext, get, FieldError, RefCallBack } from 'react-hook-form';

import { debounce } from 'lodash';

export enum NumberHandlingMode {
  Default,
  AsUndefined,
  AsZero,
}

interface HookOption {
  name: string;
  debounceTimeMs: number;
  debounceEnabled: boolean;
  valueAsNumber: boolean;
  numberHandlingMode?: NumberHandlingMode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

interface HookResult {
  handleChange(e: ChangeEvent<HTMLInputElement>): void;
  handleBlur: FocusEventHandler;
  isError: boolean;
  helperText?: string;
  ref: RefCallBack;
}

export const useTextFormField = ({
  name,
  debounceTimeMs,
  debounceEnabled,
  valueAsNumber,
  numberHandlingMode,
  onChange: onChangeCustom,
}: HookOption): HookResult => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { onChange, onBlur, ref } =
    valueAsNumber && numberHandlingMode !== NumberHandlingMode.Default
      ? register(name, { setValueAs: (val) => handleNumber(val, numberHandlingMode) })
      : register(name, { valueAsNumber });

  const error = get(errors, name) as FieldError;

  const callOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (onChangeCustom) {
        onChangeCustom(e);
      }

      onChange(e);
    },
    [onChange, onChangeCustom]
  );

  const handleDebounce = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      callOnChange(e);
    }, debounceTimeMs),
    []
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      debounceEnabled ? handleDebounce(e) : callOnChange(e);
    },
    [callOnChange, handleDebounce]
  );

  const handleBlur: FocusEventHandler = useCallback((e) => {
    onBlur(e);
  }, []);

  return {
    isError: !!error,
    handleChange,
    handleBlur,
    ref,
    helperText: error?.message,
  };
};
function handleNumber(
  val: any,
  numberHandlingMode: NumberHandlingMode | undefined
): number | null | undefined {
  const fallbackValue = numberHandlingMode === NumberHandlingMode.AsZero ? 0 : undefined;

  return val === '' || isNaN(parseFloat(val)) ? fallbackValue : parseFloat(val);
}
