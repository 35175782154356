import { useCallback } from 'react';
import { useResetRecoilState } from 'recoil';

import { useBuildNewDeal } from 'hooks/useBuildNewDeal';
import { useCreateVirtualCarQuery } from 'hooks/useCreateVirtualCarQuery';

import { dealOfferCodeAtom } from 'models/DealOfferModel';

interface HookOptions {
  carId: string;
}

interface HookResult {
  handleCreateVirtualCar(): Promise<string>;
}

export const useCreateVirtualCar = ({ carId }: HookOptions): HookResult => {
  const { mutateAsync: createVirtualCarAsync } = useCreateVirtualCarQuery();
  const resetDealCode = useResetRecoilState(dealOfferCodeAtom);

  const { handleNavigate } = useBuildNewDeal({
    persistStateFieldsBlacklist: ['leaseProgramId', 'loanProgramId'],
  });

  const handleCreateVirtualCar = useCallback(async () => {
    const result = await createVirtualCarAsync({
      sourceCarId: carId,
    });
    const newCarId = result.data.carId;

    resetDealCode();
    handleNavigate(newCarId);

    return newCarId;
  }, [carId, createVirtualCarAsync]);

  return { handleCreateVirtualCar };
};
