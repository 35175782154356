import React from 'react';

import { InputAdornment, TextField } from '@mui/material';

import { NumberFormField } from 'components/molecules/form-fields';
import { NumberHandlingMode } from 'components/molecules/form-fields/text-form-field/useTextFormField';

interface Props {
  isDisabled: boolean;
  isPaymentCalculated: boolean;
}

export const MaxOutOfPocketInput: React.FC<Props> = ({ isDisabled, isPaymentCalculated }) =>
  isPaymentCalculated ? (
    <TextField
      key={`maxOutOfPocket${isPaymentCalculated}`}
      label="DOWN/OUT OF POCKET"
      inputProps={{
        'data-cy': 'pdpPage_paymentCustomization_maxOutOfPocket_input',
      }}
      fullWidth
      disabled
      variant="outlined"
      size="small"
      value="SEE DETAILS"
    />
  ) : (
    <NumberFormField
      name="maxOutOfPocket"
      key={`maxOutOfPocket${isPaymentCalculated}`}
      label="DOWN/OUT OF POCKET"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      dataCy="pdpPage_paymentCustomization_maxOutOfPocket_input"
      focused
      fullWidth
      disabled={isDisabled}
      numberHandlingMode={NumberHandlingMode.AsZero}
    />
  );
