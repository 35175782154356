import React from 'react';

import { Typography } from '@mui/material';

import { MRT_Row as Row } from 'material-react-table';

import { ProgramGrid } from 'components/pdp/grid-view/table/GridTableService';

import { formatToCurrency, formatToPercentage } from 'utils/formatterUtils';

interface Props {
  row: Row<ProgramGrid>;
}
export const ResidualTableCell: React.FC<Props> = ({ row }) => {
  const { residualAmount, residualRate } = row.original;

  return (
    <Typography variant="body3">
      {residualRate === undefined
        ? getAmountDisplay(residualAmount)
        : getPercentDisplay(residualAmount, residualRate)}
    </Typography>
  );

  function getAmountDisplay(residualAmount: number): React.ReactNode {
    return residualAmount > 0 ? formatToCurrency(residualAmount) : 'N/A';
  }

  function getPercentDisplay(residualAmount: number, residualRate: number): React.ReactNode {
    return `${formatToCurrency(residualAmount)} (${formatToPercentage(residualRate, 0)})`;
  }
};
