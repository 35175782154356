import React from 'react';

import { Button, CardContent, Stack, TextField } from '@mui/material';

import { Editor } from '@tinymce/tinymce-react';

import { Card } from 'components/atoms/card';
import { TextFormField } from 'components/molecules/form-fields';

import { useEmailEditorCard } from './useEmailEditorCard';

const DEFAULT_EDITOR_HEIGHT = 700;

interface Props {
  defaultValue: string;
  toolbarEnabled: boolean;
  elementPathEnabled: boolean;
  isPlainText: boolean;
  branding?: boolean;
  leaseMonthlyPayment: number;
  loanMonthlyPayment: number;
}

export const EmailEditorCard: React.FC<Props> = ({
  defaultValue,
  toolbarEnabled,
  isPlainText,
  leaseMonthlyPayment,
  loanMonthlyPayment,
  elementPathEnabled,
  branding = false,
}) => {
  const { apiKey, toDisplay, fromDisplay, handleSendEmail, handleOnInit } = useEmailEditorCard({
    isPlainText,
    leaseMonthlyPayment,
    loanMonthlyPayment,
  });

  return (
    <Card>
      <CardContent>
        <Stack direction="column" justifyContent="start" spacing={3}>
          <TextField
            label="TO"
            value={toDisplay}
            size="small"
            disabled
            data-cy="responder_email_to_input"
          />
          <TextField
            label="FROM"
            value={fromDisplay}
            size="small"
            disabled
            data-cy="responder_email_from_input"
          />
          <TextFormField
            name="subject"
            label="Subject"
            focused
            placeholder="Enter subject"
            dataCy="responder_email_subject_input"
            fullWidth
          />
          <Editor
            initialValue={defaultValue}
            apiKey={apiKey}
            onInit={handleOnInit}
            init={{
              skin: 'material-outline',
              content_css: 'material-outline',
              icons: 'material',
              plugins: 'code image link lists',
              height: DEFAULT_EDITOR_HEIGHT,
              body_id: 'responderPage_emailTemplate_editorBody',
              menubar: toolbarEnabled,
              toolbar: toolbarEnabled,
              elementpath: elementPathEnabled,
              branding,
            }}
          />
          <Stack direction="row" justifyContent="center">
            <Button
              onClick={async (): Promise<void> => await handleSendEmail()}
              data-cy="responderPage_emailTemplate_sendButton"
              variant="contained"
            >
              Send Email
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
