import { atom, selector } from 'recoil';

export enum PaymentCalculationType {
  EXACT = 0,
  CALCULATED = 1,
}

export const downPaymentCalculationTypeAtom = atom<PaymentCalculationType>({
  key: 'downPaymentCalculationType',
  default: PaymentCalculationType.EXACT,
});

export const monthlyPaymentCalculationTypeAtom = atom<PaymentCalculationType>({
  key: 'monthlyPaymentCalculationType',
  default: PaymentCalculationType.CALCULATED,
});

export const isMonthlyPaymentCalculatedTypeSelector = selector<boolean>({
  key: 'isMonthlyPaymentCalculatedType',
  get: ({ get }) => get(monthlyPaymentCalculationTypeAtom) === PaymentCalculationType.CALCULATED,
});

export const isMonthlyPaymentExactTypeSelector = selector<boolean>({
  key: 'isMonthlyPaymentExactType',
  get: ({ get }) => get(monthlyPaymentCalculationTypeAtom) === PaymentCalculationType.EXACT,
});

export const isDownPaymentCalculatedTypeSelector = selector<boolean>({
  key: 'isDownPaymentCalculatedType',
  get: ({ get }) => get(downPaymentCalculationTypeAtom) === PaymentCalculationType.CALCULATED,
});

export const isDownPaymentExactTypeSelector = selector<boolean>({
  key: 'isDownPaymentExactType',
  get: ({ get }) => get(downPaymentCalculationTypeAtom) === PaymentCalculationType.EXACT,
});

export const isExactExactCalculationSelector = selector<boolean>({
  key: 'isExactExactCalculation',
  get: ({ get }) =>
    get(downPaymentCalculationTypeAtom) === PaymentCalculationType.EXACT &&
    get(monthlyPaymentCalculationTypeAtom) === PaymentCalculationType.EXACT,
});

export const isStandardCalculationSelector = selector<boolean>({
  key: 'isStandardCalculation',
  get: ({ get }) =>
    get(downPaymentCalculationTypeAtom) === PaymentCalculationType.EXACT &&
    get(monthlyPaymentCalculationTypeAtom) === PaymentCalculationType.CALCULATED,
});

export const isExactCalculationSelector = selector<boolean>({
  key: 'isExactCalculation',
  get: ({ get }) =>
    get(downPaymentCalculationTypeAtom) === PaymentCalculationType.CALCULATED &&
    get(monthlyPaymentCalculationTypeAtom) === PaymentCalculationType.EXACT,
});

export const exactMonthlyPaymentAtom = atom<number | undefined>({
  key: 'exactMonthlyPayment',
  default: undefined,
});
