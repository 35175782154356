import { useCallback } from 'react';

import { useDeleteUserMutation } from 'hooks/useDeleteUserMutation';

interface HookResult {
  handleDeleteUser(id: string): Promise<void>;
}

export const useDeleteUser = (): HookResult => {
  const { mutateAsync: deleteUserAsync } = useDeleteUserMutation();

  const handleDeleteUser = useCallback(async (id: string) => {
    await deleteUserAsync(id);
  }, []);

  return {
    handleDeleteUser,
  };
};
