import React from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import { Stack, styled } from '@mui/material';

const StyledCallMadeIcon = styled(CallMadeIcon)(({ theme }) => ({
  fontSize: `${theme.spacing(2)}`,
  color: theme.palette.grey[900],
}));

const Wrapper = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.blue300.main,
  flexGrow: 1,
  cursor: 'pointer',
}));

interface Props {
  inputContent: React.ReactNode;
  selectorPrefix?: string;
  dataCy: string;
}

export const InfoButton: React.FC<Props> = ({ inputContent, selectorPrefix, dataCy }) => (
  <Wrapper data-cy={dataCy}>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        {inputContent}
      </Stack>
      <StyledCallMadeIcon data-cy={`${selectorPrefix}_icon`} />
    </Stack>
  </Wrapper>
);
