import React from 'react';
import { FormProvider } from 'react-hook-form';

import {
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { NumberFormField, SwitchFormField } from 'components/molecules/form-fields';
import { NumberHandlingMode } from 'components/molecules/form-fields/text-form-field/useTextFormField';

import { ResidualValueType } from 'services/quick-quote/QuickQuoteApiRequestService';

import { formatPayment, getRowPayments } from './QuickQuoteService';
import { useQuickQuoteForm } from './useQuickQuoteForm';

const StyledButton = styled(Button)({
  justifyContent: 'flex-start',
});

const HeaderTypography = styled(Typography)(() => ({
  textAlign: 'right',
}));

const PaymentTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: 'bold',
}));

const HeaderLabel: React.FC<{ label: string }> = ({ label }: { label: string }) => (
  <Grid
    item
    xs={2}
    sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', fontSize: '.8rem' }}
  >
    <HeaderTypography variant="body2">{label}</HeaderTypography>
  </Grid>
);

const HeaderFormField: React.FC<{
  name: string;
  adornment?: string;
  isDisabled: boolean;
}> = ({
  name,
  adornment,
  isDisabled,
}: {
  name: string;
  adornment?: string;
  isDisabled: boolean;
}) => {
  const inputProps = adornment
    ? {
        startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
      }
    : {};

  return (
    <Grid item xs={2.5}>
      <NumberFormField
        sx={{
          '.MuiInputBase-input': { fontSize: '.8rem' },
        }}
        name={name}
        numberHandlingMode={NumberHandlingMode.AsZero}
        debounceEnabled={false}
        inputProps={{
          step: 'any',
        }}
        InputProps={{ ...inputProps }}
        focused
        disabled={isDisabled}
      />
    </Grid>
  );
};

export const QuickQuoteForm: React.FC = () => {
  const {
    quickQuoteForm,
    isBusy,
    handleOnPrint,
    isPriceRangeEnabled,
    defaultInterval,
    showLease,
    showLoan,
    residualValueType,
  } = useQuickQuoteForm();
  const numberOfColumns = 4;
  const numberOfRows = 4;

  const isPrintDisabled = isBusy || (!showLease && !showLoan);

  return (
    <FormProvider {...quickQuoteForm}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SwitchFormField
              name="showLease"
              dataCyPrefix="quickQuoteForm_showLease"
              label="Lease"
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <HeaderLabel label="Term" />
                  {Array.from({ length: numberOfColumns }, (_x, i) => {
                    const name = `leaseHeader${i + 1}.term`;

                    return (
                      <HeaderFormField key={name} name={name} isDisabled={isBusy || !showLease} />
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <HeaderLabel label="Money factor" />
                  {Array.from({ length: numberOfColumns }, (_x, i) => {
                    const name = `leaseHeader${i + 1}.moneyFactor`;

                    return (
                      <HeaderFormField key={name} name={name} isDisabled={isBusy || !showLease} />
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <HeaderLabel label="Residual" />
                  {Array.from({ length: numberOfColumns }, (_x, i) => {
                    const name = `leaseHeader${i + 1}.residualValue`;

                    return (
                      <HeaderFormField
                        key={name}
                        name={name}
                        adornment={residualValueType === ResidualValueType.Amount ? '$' : '%'}
                        isDisabled={isBusy || !showLease}
                      />
                    );
                  })}
                </Grid>
              </Grid>
              {Array.from({ length: numberOfRows }, (_x, i) => {
                const rowFieldName = `leaseRow${i + 1}`;
                const name = `${rowFieldName}.cashDown`;

                return (
                  <Grid item xs={12} key={rowFieldName}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <NumberFormField
                          sx={{
                            '.MuiInputBase-input': { fontSize: '.8rem' },
                          }}
                          name={name}
                          numberHandlingMode={NumberHandlingMode.AsUndefined}
                          focused
                          disabled={isBusy || !showLease}
                        />
                      </Grid>
                      {Array.from(getRowPayments(quickQuoteForm, rowFieldName), (payment, i) => {
                        return (
                          <Grid item xs={2.5} key={`${rowFieldName}_${i}`}>
                            {showLease && (
                              <PaymentTypography text-align="center">
                                {formatPayment(payment, isPriceRangeEnabled, defaultInterval)}
                              </PaymentTypography>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <SwitchFormField
              name="showLoan"
              dataCyPrefix="quickQuoteForm_showLoan"
              label="Finance"
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <HeaderLabel label="Term" />
                  {Array.from({ length: numberOfColumns }, (_x, i) => {
                    const name = `loanHeader${i + 1}.term`;

                    return (
                      <HeaderFormField key={name} name={name} isDisabled={isBusy || !showLoan} />
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <HeaderLabel label="APR" />
                  {Array.from({ length: numberOfColumns }, (_x, i) => {
                    const name = `loanHeader${i + 1}.apr`;

                    return (
                      <HeaderFormField key={name} name={name} isDisabled={isBusy || !showLoan} />
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} height="2.7rem"></Grid>
              {Array.from({ length: numberOfRows }, (_x, i) => {
                const rowFieldName = `loanRow${i + 1}`;
                const name = `${rowFieldName}.cashDown`;

                return (
                  <Grid item xs={12} key={rowFieldName}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <NumberFormField
                          sx={{
                            '.MuiInputBase-input': { fontSize: '.8rem' },
                          }}
                          numberHandlingMode={NumberHandlingMode.AsUndefined}
                          name={name}
                          debounceEnabled={false}
                          focused
                          disabled={isBusy || !showLoan}
                        />
                      </Grid>
                      {Array.from(getRowPayments(quickQuoteForm, rowFieldName), (payment, i) => {
                        return (
                          <Grid item xs={2.5} key={`${rowFieldName}_${i}`}>
                            {showLoan && (
                              <PaymentTypography text-align="center">
                                {formatPayment(payment, isPriceRangeEnabled, defaultInterval)}
                              </PaymentTypography>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <DialogActions>
        <Stack direction="row" spacing={1}>
          {isBusy && <CircularProgress data-cy="overlay-spinner" color="primary" />}
          <StyledButton
            type="submit"
            variant="contained"
            onClick={handleOnPrint}
            data-cy="QuickQuoteModal_printButton"
            disabled={isPrintDisabled}
          >
            Print
          </StyledButton>
        </Stack>
      </DialogActions>
    </FormProvider>
  );
};
