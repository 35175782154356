import { useCallback } from 'react';
import { UseQueryResult } from 'react-query';

import { PdpState } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { useBuildNewDeal } from 'hooks/useBuildNewDeal';
import { useVehicleDetailsQuery } from 'hooks/useVehicleDetailsQuery';

import { VehicleFeatures } from 'services/PdpApiService';

interface HookOptions {
  dialogOpen: boolean;
  carId: string;
  persistState: boolean;
  persistStateFieldsBlacklist?: (keyof PdpState)[];
}

interface HookResult {
  vehicleDetailsQuery: UseQueryResult<VehicleFeatures, unknown>;
  handleNavigate(): void;
}

export const useVehicleDetailsDialog = ({
  dialogOpen,
  carId,
  persistState,
  persistStateFieldsBlacklist,
}: HookOptions): HookResult => {
  const { handleNavigate: buildNewDeal } = useBuildNewDeal({
    persistState,
    persistStateFieldsBlacklist,
  });
  const vehicleDetailsQuery = useVehicleDetailsQuery({ carId, enabled: dialogOpen });

  const handleNavigate = useCallback(() => {
    buildNewDeal(carId);
  }, [carId, buildNewDeal]);

  return {
    vehicleDetailsQuery,
    handleNavigate,
  };
};
