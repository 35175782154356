import { useInvalidCreditScoreEditableDealModal } from './useInvalidCreditScoreEditableDealModal';
import { useInvalidCreditScoreReadOnlyDealModal } from './useInvalidCreditScoreReadOnlyDealModal';

interface HookOptions {
  canUpdateCreditScore: boolean;
  carId: string;
}

interface HookResult {
  isOpen: boolean;
  open: () => void;
  confirm: () => void;
  onImplicitClose: () => void;
}

export const useInvalidCreditScoreModal = ({
  canUpdateCreditScore,
  carId,
}: HookOptions): HookResult => {
  const readonlyDealModal = useInvalidCreditScoreReadOnlyDealModal({ carId });
  const editableDealModal = useInvalidCreditScoreEditableDealModal();

  const result = canUpdateCreditScore
    ? {
        open: editableDealModal.open,
        confirm: editableDealModal.confirm,
        isOpen: editableDealModal.isOpen,
        onImplicitClose: editableDealModal.onImplicitClose,
      }
    : {
        open: readonlyDealModal.open,
        confirm: readonlyDealModal.confirm,
        isOpen: readonlyDealModal.isOpen,
        onImplicitClose: readonlyDealModal.onImplicitClose,
      };

  return result;
};
