import React from 'react';

import { Switch } from 'components/molecules/switch';

import { useViewProfitSwitch } from './useViewProfitSwitch';

export const ViewProfitSwitch: React.FC = () => {
  const { viewProfitValue, handleViewProfitChange, showViewProfitToggle } = useViewProfitSwitch();

  return (
    <>
      {showViewProfitToggle && (
        <Switch
          label="Show profit"
          checked={viewProfitValue}
          onChange={handleViewProfitChange}
          labelDataCy="universalAppBar_viewProfitText"
          switchDataCy="universalAppBar_viewProfit_switch"
        />
      )}
    </>
  );
};
