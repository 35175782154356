import React, { FC } from 'react';

import { InvalidCreditScoreEditableDealModal } from './InvalidCreditScoreEditableDealModal';
import { InvalidCreditScoreReadOnlyDealModal } from './InvalidCreditScoreReadOnlyDealModal';

export interface InvalidCreditScoreModalProps {
  customerCreditTierName: string;
  dealCreditTierName: string;
  isOpen: boolean;
  isReadOnlyDeal: boolean;
  confirm: () => void;
  onImplicitClose: () => void;
}

export const InvalidCreditScoreModal: FC<InvalidCreditScoreModalProps> = ({
  customerCreditTierName,
  dealCreditTierName,
  isReadOnlyDeal,
  isOpen,
  confirm,
  onImplicitClose,
}) => {
  return isReadOnlyDeal ? (
    <InvalidCreditScoreReadOnlyDealModal
      customerCreditTierName={customerCreditTierName}
      dealCreditTierName={dealCreditTierName}
      isOpen={isOpen}
      confirm={confirm}
      onImplicitClose={onImplicitClose}
    />
  ) : (
    <InvalidCreditScoreEditableDealModal
      customerCreditTierName={customerCreditTierName}
      dealCreditTierName={dealCreditTierName}
      isOpen={isOpen}
      confirm={confirm}
      onImplicitClose={onImplicitClose}
    />
  );
};
