import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Stack,
  Typography,
  Divider,
  Button,
  IconButton,
  styled,
} from '@mui/material';

import { ColorAvatar } from 'components/atoms/color-avatar';
import { Scrollbar } from 'components/atoms/scrollbar';
import { VehicleFeatures } from 'components/molecules/vehicle-features';
import { VehicleFooter } from 'components/molecules/vehicle-footer';
import { VehicleHeader } from 'components/molecules/vehicle-header';
import { VehicleImages } from 'components/molecules/vehicle-images';
import { PdpState } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { InfoTile } from 'components/pdp/info-tile';

import { formatToCurrency } from 'utils/formatterUtils';

import { useVehicleDetailsDialog } from './useVehicleDetailsDialog';

const StyledDialog = styled(Dialog)({ '& .MuiDialog-paper': { width: '80%' } });

interface Props {
  open: boolean;
  onCancel(): void;
  carId: string;
  persistState: boolean;
  persistStateFieldsBlacklist?: (keyof PdpState)[];
}

export const VehicleDetailsDialog: React.FC<Props> = ({
  onCancel,
  open,
  carId,
  persistState,
  persistStateFieldsBlacklist,
}) => {
  const {
    vehicleDetailsQuery: { data, isError, isLoading },
    handleNavigate,
  } = useVehicleDetailsDialog({
    carId,
    dialogOpen: open,
    persistState,
    persistStateFieldsBlacklist,
  });

  let element = null;

  if (isError) {
    element = (
      <Grid item xs={12} textAlign="center">
        <InfoTile
          title="Failed to fetch vehicle details."
          selectorPrefix="vehicleDetailsDialog_recordNotFoundTile"
          color="error"
        />
      </Grid>
    );
  } else if (isLoading) {
    element = (
      <Grid item xs={12} textAlign="center">
        <CircularProgress size={50} />
      </Grid>
    );
  } else if (data) {
    const {
      year,
      make,
      model,
      trim,
      topFeatures,
      allFeatures,
      vin,
      price,
      stockNumber,
      daysInStock,
      location,
      exteriorColor,
      rgbExterior,
      rgbInterior,
      interiorColor,
      state,
      status,
      pictureUrls,
    } = data;
    element = (
      <Stack direction="column" divider={<Divider />} spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <VehicleHeader
            make={make}
            model={model}
            trim={trim}
            state={state}
            status={status}
            year={year}
            dataCyPrefix="vehicleDetailsDialog_header"
            justifyContent="space-between"
          />
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <div>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid xs={12} md={4} item>
              <VehicleImages dataCyPrefix="vehicleDetailsDialog" pictureUrls={pictureUrls} />
            </Grid>
            <Grid xs={12} md={8} item>
              <Stack direction="column" spacing={1.5} divider={<Divider />}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={0.5}
                >
                  <Stack direction="row" spacing={0.5}>
                    <ColorAvatar
                      color={rgbExterior}
                      data-cy="vehicleDetailsDialog_exteriorColorAvatar"
                    />
                    <ColorAvatar
                      color={rgbInterior}
                      data-cy="vehicleDetailsDialog_interiorColorAvatar"
                    />
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      variant="body4"
                      data-cy="vehicleDetailsDialog_colorDescription"
                    >{`${exteriorColor} exterior/ ${interiorColor} interior`}</Typography>
                  </Stack>
                </Stack>
                <VehicleFeatures
                  dataCyPrefix="vehicleDetailsDialog_topFeatures"
                  features={topFeatures}
                  withIcon
                  noWrap={false}
                />
                <Scrollbar maxheight="30vh">
                  <VehicleFeatures
                    dataCyPrefix="vehicleDetailsDialog_allFeatures"
                    features={allFeatures}
                    noWrap={false}
                  />
                </Scrollbar>
                <Typography data-cy="vehicleDetailsDialog_pricing_carPrice" variant="subtitle3">
                  {formatToCurrency(price)}
                </Typography>
                <VehicleFooter
                  stockNumber={stockNumber}
                  vin={vin}
                  location={location}
                  daysInStock={daysInStock}
                  status={status}
                  dataCyPrefix="vehicleDetailsDialog_footer"
                />
                <Stack alignItems="flex-end">
                  <Button
                    variant="contained"
                    data-cy="vehicleDetailsDialog_buildDealButton"
                    onClick={handleNavigate}
                  >
                    Build New Deal
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Stack>
    );
  }

  return (
    <StyledDialog maxWidth="lg" fullWidth open={open} onClose={onCancel}>
      <DialogContent data-cy="vehicleDetailsDialog">{element}</DialogContent>
    </StyledDialog>
  );
};
