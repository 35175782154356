import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PdpFormValues, PdpState } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { dealOfferCodeAtom } from 'models/DealOfferModel';
import {
  downPaymentCalculationTypeAtom,
  monthlyPaymentCalculationTypeAtom,
} from 'models/ExactPaymentModel';

import {
  createPdpState,
  createRedirectUrl,
  getBuildNewDealFields,
} from 'services/BuildNewDealService';

interface HookOptions {
  openNewTab?: boolean;
  persistState?: boolean;
  persistStateFieldsBlacklist?: (keyof PdpState)[];
}

interface HookResult {
  handleNavigate: (carId: string) => void;
}

export const useBuildNewDeal = ({
  openNewTab = true,
  persistState = true,
  persistStateFieldsBlacklist = [],
}: HookOptions): HookResult => {
  const { getValues } = useFormContext<PdpFormValues>();
  const monthlyPaymentCalculationType = useRecoilValue(monthlyPaymentCalculationTypeAtom);
  const downPaymentCalculationType = useRecoilValue(downPaymentCalculationTypeAtom);
  const fromDealOfferId = useRecoilValue(dealOfferCodeAtom);
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source');
  const handleNavigate = useCallback(
    (carId: string) => {
      const pdpState: PdpState = {
        ...createPdpState({
          getValues,
          fields: getBuildNewDealFields(persistStateFieldsBlacklist),
          monthlyPaymentCalculationType,
          downPaymentCalculationType,
        }),
      };

      const url = createRedirectUrl({
        carId,
        pdpState,
        persistState,
        fromDealOfferId,
        utmSource,
      });

      if (openNewTab) {
        window.open(url, '_blank', 'noopener');
      } else {
        window.location.href = url;
      }
    },
    [
      getValues,
      monthlyPaymentCalculationType,
      downPaymentCalculationType,
      fromDealOfferId,
      persistState,
    ]
  );

  return { handleNavigate };
};
