import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { ListItem, ListItemText, List, ListItemIcon, styled } from '@mui/material';

import { theme } from 'utils/muiThemeUtils';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  marginRight: theme.spacing(0.25),
}));

const StyledCheckIcon = styled(CheckIcon)({
  fontSize: '14px',
});
const StyledListItemText = styled(ListItemText)({
  margin: 0,
});

interface Props {
  features: string[];
  withIcon?: boolean;
  dataCyPrefix: string;
  noWrap?: boolean;
}

export const VehicleFeatures: React.FC<Props> = ({
  features,
  dataCyPrefix,
  withIcon = false,
  noWrap = true,
}) => (
  <List disablePadding>
    {features.map((feature, key) => (
      <ListItem key={key} disablePadding disableGutters>
        {withIcon && (
          <StyledListItemIcon>
            <StyledCheckIcon color="success" />
          </StyledListItemIcon>
        )}
        <StyledListItemText
          data-cy={`${dataCyPrefix}_feature_${key}`}
          primary={feature}
          primaryTypographyProps={{
            variant: 'body4',
            noWrap,
            color: theme.palette.text.secondary,
            fontSize: '10px',
          }}
        />
      </ListItem>
    ))}
  </List>
);
