import React from 'react';

import { Switch } from 'components/molecules/switch';

import { useGridSwitch } from './useGridSwitch';

export const GridSwitch: React.FC = () => {
  const { gridViewValue, handleGridViewChange, showGridToggle } = useGridSwitch();

  return (
    <>
      {showGridToggle && (
        <Switch
          label="Show payment grid"
          checked={gridViewValue}
          onChange={handleGridViewChange}
          labelDataCy="pdpPage_paymentCustomization_gridView_switch_text"
          switchDataCy="pdpPage_paymentCustomization_gridView_switch"
        />
      )}
    </>
  );
};
