import React from 'react';

import { ErrorBoundary } from 'components/ErrorBoundary';

import { useResetErrorState } from 'hooks/useResetErrorState';

interface HookResult {
  ref: React.RefObject<ErrorBoundary>;
}

interface HookOptions {
  enableNavigation?: boolean;
}

export const useGlobalReactErrorBoundary = ({ enableNavigation }: HookOptions): HookResult => {
  const ref = React.createRef<ErrorBoundary>();

  useResetErrorState({ ref, enableNavigation });

  return {
    ref,
  };
};
