import React from 'react';

import { InputAdornment, TextField } from '@mui/material';

import { NumberFormField } from 'components/molecules/form-fields';

import { useMonthlyPaymentInput } from './useMonthlyPaymentInput';

interface Props {
  isDisabled: boolean;
  isMonthlyPaymentCalculated: boolean;
}

export const MonthlyPaymentInput: React.FC<Props> = ({
  isDisabled,
  isMonthlyPaymentCalculated,
}) => {
  useMonthlyPaymentInput();

  return isMonthlyPaymentCalculated ? (
    <TextField
      key={`monthlyExactPayment${isMonthlyPaymentCalculated}`}
      label="MONTHLY PAYMENT"
      inputProps={{
        'data-cy': 'pdpPage_paymentCustomization_monthlyPayment_input',
      }}
      fullWidth
      disabled
      variant="outlined"
      size="small"
      value="SEE DETAILS"
    />
  ) : (
    <NumberFormField
      key={`monthlyExactPayment${isMonthlyPaymentCalculated}`}
      name="monthlyExactPayment"
      label="MONTHLY PAYMENT"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      dataCy="pdpPage_paymentCustomization_monthlyPayment_input"
      focused
      fullWidth
      disabled={isDisabled}
    />
  );
};
