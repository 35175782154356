import React from 'react';

import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';

import { useGlobalErrorAppInsightsErrorBoundary } from './useGlobalErrorAppInsightsErrorBoundary';

interface Props {
  children: React.ReactNode;
  errorComponent: React.ReactElement;
  enableNavigation?: boolean;
}

export const GlobalErrorAppInsightsErrorBoundary: React.FC<Props> = ({
  children,
  errorComponent,
  enableNavigation,
}) => {
  const { reactPlugin, ref } = useGlobalErrorAppInsightsErrorBoundary({ enableNavigation });

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={(): React.ReactElement => <>{errorComponent}</>}
        appInsights={reactPlugin}
        ref={ref}
      >
        <>{children}</>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};
