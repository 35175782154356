import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { AxiosError } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import {
  getQuickQuoteApiUrl,
  QuickQuoteRequest,
} from 'services/quick-quote/QuickQuoteApiRequestService';
import { QuickQuoteResponse } from 'services/quick-quote/QuickQuoteApiResponseService';

import { handleOnError } from 'utils/queryUtils';

interface HookOptions {
  carId: number;
  request: QuickQuoteRequest;
}

export const useQuickQuoteQuery = ({
  carId,
  request,
}: HookOptions): UseQueryResult<QuickQuoteResponse, unknown> => {
  const axiosClient = useContext(AxiosContext);

  return useQuery(
    ['quickQuoteQuery', carId, request],
    async () => {
      const { data } = await axiosClient.post<QuickQuoteResponse>(
        getQuickQuoteApiUrl(carId),
        request
      );

      return data;
    },
    {
      enabled: false,
      onError: (response: AxiosError<unknown, any>) => {
        handleOnError(response, 'Failed to calculate quick quote', null);
      },
    }
  );
};
