import React, { useCallback, useRef, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { Editor as TinyMCEEditor } from 'tinymce';

import { useSnackbar } from 'notistack';

import { useUpdateRanges } from 'components/responder/useUpdateRanges';

import { useConfiguration } from 'hooks/useConfiguration';

interface HookResult {
  apiKey: string;
  handleCopyToClipBoard(): void;
  handleOnInit(evt: unknown, editor: TinyMCEEditor): void;
}

interface HookOptions {
  isPlainText?: boolean;
  leaseMonthlyPayment: number;
  loanMonthlyPayment: number;
}

export const usePhoneEditorCard = ({
  isPlainText,
  leaseMonthlyPayment,
  loanMonthlyPayment,
}: HookOptions): HookResult => {
  const [, copyToClipboard] = useCopyToClipboard();
  const [initialized, setInitialized] = useState(false);
  const {
    tinyMceEditor: { apiKey },
  } = useConfiguration();
  const editorRef = useRef<TinyMCEEditor>();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyToClipBoard = useCallback(() => {
    const editorReference = editorRef.current;
    if (editorReference) {
      const copiedText = editorReference.getContent({ format: isPlainText ? 'text' : 'html' });
      copyToClipboard(copiedText);
      enqueueSnackbar('Email copied to clipboard', { variant: 'info' });
    }
  }, [editorRef.current, isPlainText]);

  const handleOnInit = useCallback((_: React.ReactEventHandler, editor: TinyMCEEditor) => {
    editorRef.current = editor;
    setInitialized(true);
  }, []);

  useUpdateRanges({
    editor: editorRef.current,
    initialized,
    leaseMonthlyPayment,
    loanMonthlyPayment,
  });

  return {
    apiKey,
    handleCopyToClipBoard,
    handleOnInit,
  };
};
