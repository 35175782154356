import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControlLabel, FormGroup, Switch, SwitchProps, Typography } from '@mui/material';

export type SwitchFormFieldProps = SwitchProps & {
  name: string;
  label: string;
  dataCyPrefix?: string;
};

export const SwitchFormField: React.FC<SwitchFormFieldProps> = (props: SwitchFormFieldProps) => {
  const { control } = useFormContext();
  const { dataCyPrefix, label, name, onChange: customOnChange, ...switchProps } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }): React.ReactElement => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                {...switchProps}
                name={name}
                checked={value}
                onChange={(e): void => {
                  onChange(e.target.checked);

                  if (customOnChange) {
                    customOnChange(e, e.target.checked);
                  }
                }}
                inputProps={{
                  // @ts-ignore Workaround to pass data-cy to Switch input
                  'data-cy': `${dataCyPrefix}_switch`,
                }}
              />
            }
            label={
              <Typography data-cy={`${dataCyPrefix}_${name}_SwitchLabel`} variant="caption">
                {label}
              </Typography>
            }
          />
        </FormGroup>
      )}
    />
  );
};
