import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { PrintOptionsFormValues } from 'components/pdp/payment-customization-block/print-modal/PrintModalService';

import { useConfiguration } from 'hooks/useConfiguration';
import { useDownloadConfigurablePdfMutation } from 'hooks/useDownloadConfigurablePdfMutation';

import { dealOfferCodeAtom, dealOfferHasChangesAtom } from 'models/DealOfferModel';
import { priceRangeAtom } from 'models/PriceRangeModel';

import { PdpItem } from 'services/PdpApiService';

import { mapPrintOptions } from './DownloadPdfService';

interface HookOptions {
  pdpData: PdpItem;
}

interface HookResult {
  handleDownloadConfigurablePdf(
    pdpValues: PdpFormValues,
    printOptionsFormValues: PrintOptionsFormValues
  ): Promise<void>;
}

export const useDownloadPdf = ({ pdpData }: HookOptions): HookResult => {
  const {
    vehicle: { vin },
  } = pdpData;
  const {
    paymentsIntervalOptions: { defaultInterval, dueAtSigningInterval },
  } = useConfiguration();
  const priceRangeEnabled = useRecoilValue(priceRangeAtom);
  const dealOfferHasChanges = useRecoilValue(dealOfferHasChangesAtom);

  const dealOfferCode = useRecoilValue(dealOfferCodeAtom) ?? '';

  const { mutateAsync: downloadConfigurablePdfAsync } = useDownloadConfigurablePdfMutation({
    dealOfferId: dealOfferCode,
    vin,
  });

  const handleDownloadConfigurablePdf = useCallback(
    async (_values: PdpFormValues, printOptionsFormValues: PrintOptionsFormValues) => {
      const printOptions = mapPrintOptions({
        printOptionsFormValues,
        isPriceRangeEnabled: priceRangeEnabled,
        defaultInterval,
        dueAtSigningInterval,
      });

      await downloadConfigurablePdfAsync(printOptions);
    },
    [dealOfferHasChanges]
  );

  return {
    handleDownloadConfigurablePdf,
  };
};
