import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import {
  getCurrentCalculationOptions,
  getSearchFormValues,
} from 'components/search/form-wrapper/SearchFormWrapperService';

import { searchValuesAtom } from 'models/SearchModel';

interface HookOptions {
  values: PdpFormValues;
}

export const useSyncFormValues = ({ values }: HookOptions): void => {
  const [currentSearchFormValues, setFormValues] = useRecoilState(searchValuesAtom);

  useEffect(() => {
    if (currentSearchFormValues) {
      const calculationOptions = getCurrentCalculationOptions(values, currentSearchFormValues);

      const searchFormValues = getSearchFormValues({
        ...currentSearchFormValues,
        calculationOptions,
        customer: {
          ...currentSearchFormValues.customer,
          ...values.buyer,
        },
      });

      setFormValues(searchFormValues);
    }
  }, [
    values.creditScore,
    values.downPayment,
    values.maxOutOfPocket,
    values.payAllFeesUpfront,
    values.tradeInValue,
    values.tradeInOutstandingBal,
    values.actualCashValue,
    values.buyer.id,
    values.buyer.location?.zipCode,
    values.buyer.name,
    values.buyer.phone,
    values.buyer.email,
  ]);
};
