import {
  CustomIncentive,
  CustomIncentiveAnyLender,
  CustomIncentiveProgramType,
} from 'models/CustomIncentivesModel';

import { DealerIncentive } from 'services/payment-grid/PaymentGridApiModels.generated';

export interface CustomIncentiveCalculationOptions {
  customIncentiveAmount: number;
  lenderName: string;
  customIncentiveLenderName: string;
}

export function calculateBonusIncentiveSumFromCustomIncentives(
  lenderName: string | undefined,
  customIncentives: CustomIncentive[],
  programType: CustomIncentiveProgramType
): number {
  const incentiveLenderName = lenderName ?? CustomIncentiveAnyLender;

  return (customIncentives ?? [])
    .filter((ci) =>
      incentiveApplies(
        incentiveLenderName,
        programType,
        ci.incentiveLenderName,
        ci.incentiveProgramType
      )
    )
    .map((ci) => ci.amount)
    .reduce((amt, current) => amt + current, 0);
}

export function getAppliedCustomIncentives(
  appliedCustomIncentiveNames: string[],
  allCustomIncentives: CustomIncentive[]
): CustomIncentive[] {
  return allCustomIncentives.filter(
    (aci) => !!appliedCustomIncentiveNames?.find((n) => n === aci.name)
  );
}

function incentiveApplies(
  lenderName: string,
  programType: CustomIncentiveProgramType,
  customIncentiveLenderName: string,
  customIncentiveProgramType: CustomIncentiveProgramType
): boolean {
  return (
    (lenderName === customIncentiveLenderName ||
      customIncentiveLenderName === CustomIncentiveAnyLender) &&
    incentiveProgramTypeApplies(programType, customIncentiveProgramType)
  );
}

function incentiveProgramTypeApplies(
  programType: CustomIncentiveProgramType,
  customIncentiveProgramType: CustomIncentiveProgramType
): boolean {
  return (
    customIncentiveProgramType === CustomIncentiveProgramType.Both ||
    customIncentiveProgramType === programType
  );
}

export function mapToDealerIncentives(
  allCustomIncentives: CustomIncentive[],
  programType: CustomIncentiveProgramType
): DealerIncentive[] {
  return allCustomIncentives
    .filter((x) => incentiveProgramTypeApplies(programType, x.incentiveProgramType))
    .map((x) => mapToDealerIncentive(x));
}

function mapToDealerIncentive(customIncentive: CustomIncentive): {
  name: string;
  lenders: string[];
  value: number;
} {
  return {
    name: customIncentive.name,
    lenders:
      customIncentive.incentiveLenderName === CustomIncentiveAnyLender
        ? []
        : [customIncentive.incentiveLenderName],
    value: customIncentive.amount,
  };
}
