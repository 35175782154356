import React, { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Help } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';

import { TypographyVariants } from 'utils/muiThemeUtils';

export type CheckboxFormFieldProps = {
  name: string;
  selectorPrefix?: string;
  items: {
    value: string | number;
    label: string | React.ReactElement;
    toolTipText?: string | React.ReactElement;
    isDisabled?: boolean;
    postFix?: React.ReactElement;
  }[];
  checkBoxSize?: 'small' | 'medium';
  labelTypographyVariant?: TypographyVariants;
  showTooltTip?: boolean;
  disabled?: boolean;
};

const StyledFormControlLabel = styled(FormControlLabel)({
  paddingBottom: '5px',
});

const StyledCheckbox = styled(Checkbox)({
  paddingTop: 0,
  paddingBottom: 0,
});

export const CheckboxFormField: React.FC<CheckboxFormFieldProps> = ({
  name,
  selectorPrefix,
  items,
  checkBoxSize = 'medium',
  labelTypographyVariant = 'body2',
  showTooltTip = false,
  disabled,
}) => {
  const { control } = useFormContext();
  const values = useWatch({ control });
  const currentControlValue = values[name];

  const getNewValues = useCallback(
    (newValue: string | number) => {
      const shouldRemoveValue = currentControlValue?.includes(newValue);

      if (shouldRemoveValue) {
        return currentControlValue?.filter((value: string) => value !== newValue);
      }

      return [...currentControlValue, newValue];
    },
    [currentControlValue]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, name }, fieldState: { error } }): React.ReactElement => (
        <FormControl error={!!error}>
          {items.map(
            ({ label, value: itemValue, isDisabled = false, toolTipText = '', postFix }, key) => (
              <Stack key={key} direction="row" alignItems="center">
                <StyledFormControlLabel
                  control={
                    <StyledCheckbox
                      name={name}
                      disabled={isDisabled || disabled}
                      onChange={(): void => onChange(getNewValues(itemValue))}
                      checked={value?.includes(itemValue)}
                      inputProps={{
                        // @ts-ignore Workaround to pass data-cy to Checkbox input
                        'data-cy': `${selectorPrefix}_${name}_${itemValue}_checkbox`,
                      }}
                      size={checkBoxSize}
                    />
                  }
                  label={
                    <Stack direction="row" alignItems="center">
                      <Typography
                        component="span"
                        variant={labelTypographyVariant}
                        data-cy={`${selectorPrefix}_${name}_${itemValue}_checkboxLabel`}
                      >
                        {label}
                      </Typography>
                      {showTooltTip && (
                        <Tooltip
                          data-cy={`${selectorPrefix}_${name}_${itemValue}_tooltip`}
                          title={toolTipText}
                        >
                          <IconButton size={checkBoxSize}>
                            <Help fontSize={checkBoxSize} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  }
                />
                {postFix}
              </Stack>
            )
          )}
          {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
