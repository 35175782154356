import { UseMutateAsyncFunction } from 'react-query';
import { SetterOrUpdater } from 'recoil';

import { AxiosResponse } from 'axios';

import { PullCreditRequest } from './CustomerApiService';
import { LocationWithZip } from './ZipCodeApiService';

export enum CustomerMode {
  ReadOnly,
  Edit,
}

export enum CreditStatus {
  Unverified = 'Unverified',
  Pending = 'Pending',
  Verified = 'Verified',
}

export interface Customer {
  name: string;
  phone: string | undefined;
  email: string | undefined;
  id: number;
  creditStatus: CreditStatus;
  creditTierId?: number;
  location?: LocationWithZip;
}

export function createDefaultCustomer(): Customer {
  return {
    id: 0,
    name: '',
    email: '',
    phone: '',
    creditStatus: CreditStatus.Unverified,
  };
}

export function getCustomerMode(id: number): CustomerMode {
  const isExistingCustomer = id > 0;

  const result = isExistingCustomer ? CustomerMode.ReadOnly : CustomerMode.Edit;

  return result;
}

export function getCustomerInitials(name?: string): string | undefined {
  if (!name) {
    return undefined;
  }

  const nameParts = name.trim().split(' ');
  const first = nameParts[0];
  const last = nameParts.length > 1 ? nameParts.pop() ?? '' : '';
  const initials = first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase();
  const result = initials === '' ? undefined : initials;

  return result;
}

export async function pullCredit(
  request: PullCreditRequest,
  pullCreditMutation: (request: PullCreditRequest) => Promise<Customer>,
  setCurrentCustomer: SetterOrUpdater<Customer>
): Promise<void> {
  const customer = await pullCreditMutation(request);

  if (customer.creditTierId) {
    setCurrentCustomer((existing) => {
      const updated: Customer = {
        ...existing,
        creditTierId: customer.creditTierId,
        creditStatus: customer.creditStatus,
      };

      return updated;
    });
  }
}

export async function saveCreditApplication(
  dealOfferId: string,
  customer: Customer,
  saveCreditApplicationMutation: UseMutateAsyncFunction<
    AxiosResponse<any, any>,
    unknown,
    string,
    unknown
  >,
  setCurrentCustomer: SetterOrUpdater<Customer>
): Promise<void> {
  await saveCreditApplicationMutation(dealOfferId);

  if (customer.creditStatus === CreditStatus.Unverified) {
    setCurrentCustomer((existing) => {
      const updated: Customer = {
        ...existing,
        creditStatus: CreditStatus.Pending,
      };

      return updated;
    });
  }
}
