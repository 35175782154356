import React from 'react';

import { Stack, Grid, Button, styled } from '@mui/material';

import { VehicleCard } from 'components/molecules/vehicle-card';
import { InfoTile } from 'components/pdp/info-tile';
import { CustomerBlock } from 'components/search/customer-block';
import { FilterBlocks } from 'components/search/filter-blocks';
import { SearchFormHeader } from 'components/search/form/form-header/SearchFormHeader';
import { SearchInput } from 'components/search/input';
import { SearchSkeleton } from 'components/search/search-skeleton';

import { CurrentDealership } from 'services/SearchApiService';

import { useSearchForm } from './useSearchForm';

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(6)} 0`,
}));

interface Props {
  currentDealership: CurrentDealership;
}

export const SearchForm: React.FC<Props> = ({ currentDealership }: Props) => {
  const {
    searchFiltersData,
    ref,
    isSearchLoading,
    handleFetchMore,
    loadMoreButtonDisabled,
    isSearchError,
    searchResults,
    hasNextPage,
    searchResultsCount,
    excludedCount,
    handleNavigateToPdp,
    payAllFeesUpfront,
    areFiltersLoading,
  } = useSearchForm();

  let contentElement = null;

  if (isSearchError) {
    contentElement = (
      <Grid item xs={12} textAlign="center">
        <InfoTile
          title="Oops, failed to search cars."
          selectorPrefix="searchPage_failedToFetchCarsTile"
          color="error"
        />
      </Grid>
    );
  } else if (isSearchLoading) {
    contentElement = (
      <>
        <Grid item xs={12} textAlign="center">
          <SearchSkeleton />
        </Grid>
      </>
    );
  } else if (searchResults) {
    contentElement = (
      <>
        <Grid container spacing={1.5}>
          {searchResults?.pages.map((page, key) => (
            <React.Fragment key={key}>
              {page?.items.map((searchResult) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} xxl={2} key={searchResult.id}>
                  <VehicleCard
                    dataCyPrefix="searchPage"
                    clickable
                    vehicle={searchResult}
                    onClick={handleNavigateToPdp}
                    persistState={false}
                  />
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
        <Stack alignItems="center" paddingY={10}>
          <Button
            variant="contained"
            data-cy="searchPage_loadMoreButton"
            ref={ref}
            onClick={handleFetchMore}
            disabled={loadMoreButtonDisabled}
          >
            {hasNextPage ? 'Load more' : 'Nothing more to load'}
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <form>
      <StyledStack direction="column">
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={9} xl={10}>
            <Stack direction="row" alignItems="center">
              <SearchInput />
            </Stack>
            <StyledGrid container spacing={2}>
              <Grid item xs={12}>
                <SearchFormHeader
                  currentDealership={currentDealership}
                  searchResultsCount={searchResultsCount}
                  excludedCount={excludedCount}
                />
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <FilterBlocks filters={searchFiltersData} isDisabled={areFiltersLoading} />
              </Grid>
              <Grid item xs={12} md={9} xl={10}>
                {contentElement}
              </Grid>
            </StyledGrid>
          </Grid>
          <Grid item xs={12} md={3} xl={2}>
            <CustomerBlock
              currentDealership={currentDealership}
              payAllFeesUpfront={payAllFeesUpfront}
            />
          </Grid>
        </Grid>
      </StyledStack>
    </form>
  );
};
