import { UseFormSetValue } from 'react-hook-form';
import { SetterOrUpdater } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { LeaseProgramGrid, LoanProgramGrid } from 'components/pdp/grid-view/table/GridTableService';

import { Pdp } from 'providers/PdpContextProvider';

import { PaymentCalculationType } from 'models/ExactPaymentModel';
import { ProgramSelectionMethod } from 'models/PaymentSelectionModel';

import {
  PaymentGridResult,
  LeaseIdentifier,
  LoanIdentifier,
  TtlPrecision,
} from 'services/payment-grid/PaymentGridApiModels.generated';
import {
  mapLeaseGridResult,
  mapLoanGridResult,
  mapLeaseLenderData,
  mapLoanLenderData,
  MapLenderDataRequest,
} from 'services/payment-grid/PaymentGridApiResponseService';
import {
  LeaseProgram,
  LoanProgram,
  Lease,
  Loan,
  DealType,
  Fee,
  Addon,
} from 'services/PdpApiService';

export interface ChangePaymentCalculationTypeRequest {
  newCalculationType: PaymentCalculationType;
  setDownPaymentCalculationType: SetterOrUpdater<PaymentCalculationType>;
  setMonthlyPaymentCalculationType: SetterOrUpdater<PaymentCalculationType>;
}

export function changeDownPaymentCalculationType({
  newCalculationType: downPaymentCalculationType,
  setDownPaymentCalculationType,
  setMonthlyPaymentCalculationType,
}: ChangePaymentCalculationTypeRequest): void {
  const monthlyPaymentCalculationType = getOtherPaymentCalculationType(downPaymentCalculationType);

  setDownPaymentCalculationType(downPaymentCalculationType);
  setMonthlyPaymentCalculationType(monthlyPaymentCalculationType);
}

export function changeMonthlyPaymentCalculationType({
  newCalculationType: monthlyPaymentCalculationType,
  setDownPaymentCalculationType,
  setMonthlyPaymentCalculationType,
}: ChangePaymentCalculationTypeRequest): void {
  const downPaymentCalculationType = getOtherPaymentCalculationType(monthlyPaymentCalculationType);

  setDownPaymentCalculationType(downPaymentCalculationType);
  setMonthlyPaymentCalculationType(monthlyPaymentCalculationType);
}

export interface UpdateProgramRequest {
  paymentGridResult: PaymentGridResult;
  creditScore: number;
  payAllFeesUpfront: boolean;
  payAllPackagesUpfront: boolean;
  payFirstLeasePaymentUpfront: boolean;
  downPayment: number;
  maxOutOfPocket: number;
  pdpContext: Pdp;
  currentlySelectedLease: LeaseProgram | undefined;
  currentlySelectedLoan: LoanProgram | undefined;
  leaseProgramSelectionMethod: ProgramSelectionMethod;
  loanProgramSelectionMethod: ProgramSelectionMethod;
  ttlPrecision: TtlPrecision;
  setLeaseGrid: SetterOrUpdater<LeaseProgramGrid[]>;
  setLoanGrid: SetterOrUpdater<LoanProgramGrid[]>;
  setValue: UseFormSetValue<PdpFormValues>;
}

export interface UpdateProgramResponse {
  selectedLeaseProgram: LeaseProgram | undefined;
  selectedLoanProgram: LoanProgram | undefined;
  leaseLenderData: Lease;
  loanLenderData: Loan;
}

function getOtherPaymentCalculationType(
  newCalculationType: PaymentCalculationType
): PaymentCalculationType {
  return newCalculationType === PaymentCalculationType.CALCULATED
    ? PaymentCalculationType.EXACT
    : PaymentCalculationType.CALCULATED;
}

export function updatePrograms({
  paymentGridResult: result,
  setLeaseGrid,
  setLoanGrid,
  setValue,
  currentlySelectedLease,
  currentlySelectedLoan,
  ...request
}: UpdateProgramRequest): UpdateProgramResponse {
  const leaseGrid = mapLeaseGridResult(result.leasesResult);
  const loanGrid = mapLoanGridResult(result.loansResult);
  const { bestLease } = result.leasesResult;
  const { bestLoan } = result.loansResult;

  const requestBase: MapLenderDataRequest = {
    creditScore: request.creditScore,
    downPayment: request.downPayment,
    payAllFeesUpfront: request.payAllFeesUpfront,
    payAllPackagesUpfront: request.payAllPackagesUpfront,
    payFirstLeasePaymentUpfront: request.payFirstLeasePaymentUpfront,
    maxOutOfPocket: request.maxOutOfPocket,
    nonTtlFees: request.pdpContext.pdpData.dealer.nonTtlFees,
    ttlPrecision: request.ttlPrecision,
    ttl: { notes: [] },
  };

  const leases = mapLeaseLenderData({
    ...requestBase,
    leaseResult: result.leasesResult,
    nonTtlFees: filterNonTtlFees(request.pdpContext.pdpData.dealer.nonTtlFees, DealType.Lease),
    ttl: result.ttlResult.lease,
  });

  const loans = mapLoanLenderData({
    ...requestBase,
    loanResult: result.loansResult,
    nonTtlFees: filterNonTtlFees(request.pdpContext.pdpData.dealer.nonTtlFees, DealType.Loan),
    ttl: result.ttlResult.loan,
  });

  setLeaseGrid(leaseGrid);
  setLoanGrid(loanGrid);
  setLenderPrograms(request.pdpContext, leases, loans);

  const selectedLeaseProgram = updateSelectedLeaseProgram(
    currentlySelectedLease,
    leases.programs,
    bestLease,
    request.leaseProgramSelectionMethod,
    setValue
  );

  const selectedLoanProgram = updateSelectedLoanProgram(
    currentlySelectedLoan,
    loans.programs,
    bestLoan,
    request.loanProgramSelectionMethod,
    setValue
  );

  return {
    selectedLeaseProgram,
    selectedLoanProgram,
    leaseLenderData: leases,
    loanLenderData: loans,
  };
}

function setLenderPrograms(pdpContext: Pdp, leases: Lease, loans: Loan): void {
  pdpContext.pdpData.lenderData.lease = leases;
  pdpContext.pdpData.lenderData.loan = loans;
  pdpContext.setPdpData(pdpContext.pdpData);
}

export function filterNonTtlFees(fees: Fee[], dealType: DealType): Fee[] {
  return fees.filter((x) => x.dealType === DealType.Any || x.dealType === dealType);
}

export function filterAddons(addon: Addon[], dealType: DealType): Addon[] {
  return addon.filter((x) => x.dealType === DealType.Any || x.dealType === dealType);
}

function updateSelectedLeaseProgram(
  currentlySelectedLease: LeaseProgram | undefined,
  leases: LeaseProgram[],
  bestLease: LeaseIdentifier | undefined,
  leaseProgramSelectionMethod: ProgramSelectionMethod,
  setValue: UseFormSetValue<PdpFormValues>
): LeaseProgram | undefined {
  let updatedSelectedLease = currentlySelectedLease
    ? leases.find((lease) => isSameLeaseProgram(currentlySelectedLease, lease))
    : undefined;

  if (bestLease && shouldSelectBestLease(leaseProgramSelectionMethod, updatedSelectedLease)) {
    updatedSelectedLease = leases.find((x) => leaseMatchesIdentifier(x, bestLease));
  }
  setValue('leaseProgram', updatedSelectedLease);
  setValue('calculationLeaseOutputData', updatedSelectedLease?.calculation);

  return updatedSelectedLease;
}

function shouldSelectBestLease(
  leaseProgramSelectionMethod: ProgramSelectionMethod,
  updatedSelectedLease: LeaseProgram | undefined
): boolean {
  return leaseProgramSelectionMethod !== ProgramSelectionMethod.Manual || !updatedSelectedLease;
}

function updateSelectedLoanProgram(
  currentlySelectedLoan: LoanProgram | undefined,
  loans: LoanProgram[],
  bestLoan: LoanIdentifier | undefined,
  loanProgramSelectionMethod: ProgramSelectionMethod,
  setValue: UseFormSetValue<PdpFormValues>
): LoanProgram | undefined {
  let updatedSelectedLoan = currentlySelectedLoan
    ? loans.find((loan) => isSameLoanProgram(currentlySelectedLoan, loan))
    : undefined;

  if (bestLoan && shouldSelectBestLoan(loanProgramSelectionMethod, updatedSelectedLoan)) {
    updatedSelectedLoan = loans.find((x) => loanMatchesIdentifier(x, bestLoan));
  }

  setValue('loanProgram', updatedSelectedLoan);
  setValue('calculationLoanOutputData', updatedSelectedLoan?.calculation);

  return updatedSelectedLoan;
}

function shouldSelectBestLoan(
  loanProgramSelectionMethod: ProgramSelectionMethod,
  updatedSelectedLoan: LoanProgram | undefined
): boolean {
  return loanProgramSelectionMethod !== ProgramSelectionMethod.Manual || !updatedSelectedLoan;
}

export function leaseMatchesIdentifier(x: LeaseProgram, selectedLease: LeaseIdentifier): boolean {
  return (
    x.programId === selectedLease.programId &&
    x.term === selectedLease.termMonths &&
    x.millage === selectedLease.mileage
  );
}

export function loanMatchesIdentifier(x: LoanProgram, selectedLoan: LoanIdentifier): boolean {
  return x.programId === selectedLoan.programId && x.term === selectedLoan.termMonths;
}

function isSameLeaseProgram(x: LeaseProgram, y: LeaseProgram): boolean {
  return x.programId === y.programId && x.term === y.term && x.millage === y.millage;
}

function isSameLoanProgram(x: LoanProgram, y: LoanProgram): boolean {
  return x.programId === y.programId && x.term === y.term;
}

export interface IsCalculationInvalidRequest {
  downPaymentCalculationType: PaymentCalculationType;
  paymentCalculationType: PaymentCalculationType;
  monthlyExactPayment: number | undefined;
}

export function isCalculationInvalid({
  downPaymentCalculationType,
  paymentCalculationType,
  monthlyExactPayment,
}: IsCalculationInvalidRequest): boolean {
  const bothCalculated =
    downPaymentCalculationType === PaymentCalculationType.CALCULATED &&
    paymentCalculationType === PaymentCalculationType.CALCULATED;

  const exactPaymentMissingAmount =
    paymentCalculationType === PaymentCalculationType.EXACT && !monthlyExactPayment;

  return bothCalculated || exactPaymentMissingAmount;
}
