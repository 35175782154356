import { AxiosRequestConfig } from 'axios';

import { CreditStatus, Customer as CustomerDomain } from './CustomerService';

export interface Location {
  zipCode: string;
  city: string;
  county: string;
  state: string;
}
export interface Customer {
  id: number;
  creditStatus: CreditStatus;
  creditTierId?: number;
  email: string;
  location?: Location;
  name?: string;
  phone?: string;
}

export interface PullCreditRequest {
  customerId: number;
  dealerId: number;
}

export function getFindCustomerUrl(): string {
  return 'customers';
}

export function getGetCustomerUrl(id: number): string {
  return `customers/${id}`;
}

export function getPullCreditRequest({
  customerId,
  dealerId,
}: PullCreditRequest): AxiosRequestConfig<any> {
  return {
    method: 'post',
    url: `customers/${customerId}/pull/credit`,
    params: { dealerId },
  };
}

export function mapCustomer(customer: Customer): CustomerDomain {
  return {
    ...customer,
    creditStatus: customer.creditStatus,
    email: customer.email,
    id: customer.id,
    name: customer.name ?? '',
    phone: customer.phone ?? '',
  };
}
