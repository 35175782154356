import { useCallback, useContext } from 'react';
import { useMutation } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';

import { deleteDealOfferUrl } from 'services/DealOfferApiService';

import { queryClient } from 'utils/queryClientUtils';

interface HookResult {
  handleDeleteDealOffer(id: string): Promise<void>;
}

export const useDeleteDealOfferAdmin = (): HookResult => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);

  const { mutateAsync: deleteDealOfferAsync } = useMutation(
    async (dealOfferId: string) => axiosClient.delete<string>(deleteDealOfferUrl(dealOfferId)),
    {
      onError: (error: any) => {
        enqueueSnackbar(
          error?.response?.data?.message ? error?.response?.data?.message : 'Error has occured.',
          {
            variant: 'error',
          }
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries('dealOffersQuery');
      },
    }
  );

  const handleDeleteDealOffer = useCallback(async (id: string) => {
    await deleteDealOfferAsync(id);
  }, []);

  return {
    handleDeleteDealOffer,
  };
};
