import React from 'react';

import { useConfiguration } from 'hooks/useConfiguration';

import { GlobalErrorAppInsightsErrorBoundary } from './GlobalAppInsightErrorBoundary';
import { GlobalReactErrorBoundary } from './GlobalReactErrorBoundary';

interface Props {
  children: React.ReactNode;
  errorComponent: React.ReactElement;
  enableNavigation?: boolean;
}

export const GlobalErrorBoundary: React.FC<Props> = ({
  children,
  errorComponent,
  enableNavigation = false,
}) => {
  const {
    appInsightOptions: { enabled: appInsightEnabled },
  } = useConfiguration();

  return (
    <>
      {appInsightEnabled ? (
        <GlobalErrorAppInsightsErrorBoundary
          errorComponent={errorComponent}
          enableNavigation={enableNavigation}
        >
          {children}
        </GlobalErrorAppInsightsErrorBoundary>
      ) : (
        <GlobalReactErrorBoundary
          errorComponent={errorComponent}
          enableNavigation={enableNavigation}
        >
          {children}
        </GlobalReactErrorBoundary>
      )}
    </>
  );
};
