import { useCallback, useState } from 'react';

interface HookResult {
  expand: boolean;
  handleCardClicked(value: boolean): void;
}

interface HookOptions {
  defaultExpanded: boolean;
}

export const useCollapsibleFilterBlock = ({ defaultExpanded }: HookOptions): HookResult => {
  const [expand, setExpand] = useState(defaultExpanded);

  const handleCardClicked = useCallback((value: boolean) => {
    setExpand(value);
  }, []);

  return {
    expand,
    handleCardClicked,
  };
};
