import React from 'react';

import FeedbackIcon from '@mui/icons-material/Feedback';
import { CardContent, Stack, styled, SvgIcon, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/types';

import { Card } from 'components/atoms/card';

const IconWrapper = styled('div')({
  margin: '20px',
});

interface Props {
  title: string;
  text?: string;
  children?: React.ReactElement;
  selectorPrefix: string;
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  icon?: OverridableComponent<SvgIconTypeMap>;
}

export const InfoTile: React.FC<Props> = ({
  title,
  text,
  children,
  selectorPrefix,
  color,
  icon = FeedbackIcon,
}) => (
  <Card elevation={13} data-cy={`${selectorPrefix}_infoTile`}>
    <CardContent>
      <Stack direction="column" alignItems="center" textAlign="center">
        <IconWrapper>
          <SvgIcon color={color || 'inherit'} component={icon} inheritViewBox />
        </IconWrapper>
        <Typography variant="body2" data-cy={`${selectorPrefix}_infoTileTitle`}>
          {title}
        </Typography>
        {text && (
          <Typography variant="body4" padding="8px 0" data-cy={`${selectorPrefix}_infoTileText`}>
            {text}
          </Typography>
        )}
        {children}
      </Stack>
    </CardContent>
  </Card>
);
