import React from 'react';

import { ExpandedType } from 'components/molecules/accordion';
import { DetailsCard } from 'components/pdp/details-card';

import { CalculationLeaseOutput } from 'services/CalculationService';

import { DATA_CY_LEASE_CARD_PREFIX } from './LeaseDetailCardService';
import { useLeaseDetailsCard } from './useLeaseDetailsCard';

// ToDo - Move remaining LoadDetails fields to CalculationOutput
export interface LeaseDetails {
  msrp: number;
  buyerZipCode: string;
  payAllPackagesUpfront?: boolean;
  payAllFeesUpfront?: boolean;
}

interface LeaseDetailsCardProps {
  leaseDetails: LeaseDetails;
  calculationOutput: CalculationLeaseOutput;
}

export const LeaseDetailsCard: React.FC<LeaseDetailsCardProps> = ({
  leaseDetails,
  calculationOutput,
}) => {
  const {
    expanded,
    leaseDetailsItems,
    leaseSigningCalculationItems,
    leaseSigningTtlCalculationItems,
    ttlNotes,
    salesTaxDetails,
  } = useLeaseDetailsCard({
    leaseDetails,
    calculationOutput,
  });

  return (
    <DetailsCard
      dataCyPrefix={DATA_CY_LEASE_CARD_PREFIX}
      dueAtSigning={calculationOutput?.paymentDueAtSigning}
      detailItems={leaseDetailsItems}
      calculationItems={leaseSigningCalculationItems}
      signingTtlCalculationItems={leaseSigningTtlCalculationItems}
      expanded={expanded}
      titleText="Lease Details"
      type={ExpandedType.Lease}
      ttlNotes={ttlNotes}
      salesTaxDetails={salesTaxDetails}
    />
  );
};
