import { useContext } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { AdministrationUser, getCreateUserUrl } from 'services/UserAdministrationApiService';

import { queryClient } from 'utils/queryClientUtils';
import { handleOnError } from 'utils/queryUtils';

export const useCreateUserMutation = (): UseMutationResult<
  AxiosResponse<string, unknown>,
  unknown,
  AdministrationUser,
  unknown
> => {
  const axiosClient = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const createUserMutation = useMutation(
    async (params: AdministrationUser) =>
      await axiosClient.post<string>(getCreateUserUrl(), params),
    {
      onError: (error: any) => {
        handleOnError(error, 'Failed to create user.', null);
      },
      onSuccess: async () => {
        enqueueSnackbar('User was created.', {
          variant: 'success',
        });
        await queryClient.invalidateQueries('usersQuery');
        navigate(`/administration/users`);
      },
    }
  );

  return createUserMutation;
};
