import React from 'react';
import { QueryClientProvider } from 'react-query';

import { useConfiguration } from 'hooks/useConfiguration';

import { queryClient } from 'utils/queryClientUtils';

const ReactQueryDevtoolsLazy = React.lazy(() =>
  import('react-query/devtools/development').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

interface Props {
  children: React.ReactNode;
}

export const ReactQueryProvider: React.FC<Props> = ({ children }) => {
  const { reactQueryDevToolsEnabled } = useConfiguration();

  return (
    <QueryClientProvider client={queryClient}>
      {reactQueryDevToolsEnabled ? <ReactQueryDevtoolsLazy initialIsOpen={false} /> : null}
      {children}
    </QueryClientProvider>
  );
};
