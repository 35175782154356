import { useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { Miles, PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { useCreateVirtualCar } from 'components/pdp/form/useCreateVirtualCar';
import { usePaymentCalculation } from 'components/pdp/form/usePaymentCalculation';

import { usePdpData } from 'hooks/usePdpData';

import { gridViewAtom, leasesViewAtom, loansViewAtom } from 'models/GridViewModel';
import {
  leaseProgramSelectionMethodAtom,
  loanProgramSelectionMethodAtom,
} from 'models/PaymentSelectionModel';
import { pdpQueryRefreshingAtom } from 'models/PdpQueryModel';

import { TtlPrecision } from 'services/payment-grid/PaymentGridApiModels.generated';

interface HookResult {
  vehicleDescription: string;
  miles: Miles[];
  leaseTermsOptions: { value: number; label: string }[];
  loanTermsOptions: { value: number; label: string }[];
  gridViewEnabled: boolean;
  loansViewEnabled: boolean;
  leasesViewEnabled: boolean;
  isRefreshing: boolean;
  showPaymentCustomizationBlock: boolean;
  handleCreateVirtualCar: () => Promise<string>;
  payAllFeesUpfront: boolean;
}

export const usePaymentCustomizationBlock = (): HookResult => {
  const gridViewEnabled = useRecoilValue(gridViewAtom);
  const loansViewEnabled = useRecoilValue(loansViewAtom);
  const leasesViewEnabled = useRecoilValue(leasesViewAtom);
  const leaseProgramSelectionMethod = useRecoilValue(leaseProgramSelectionMethodAtom);
  const loanProgramSelectionMethod = useRecoilValue(loanProgramSelectionMethodAtom);
  const isRefreshing = useRecoilValue(pdpQueryRefreshingAtom);
  const formValues = useWatch({}) as PdpFormValues;
  const { leaseTerms, loanTerms, miles, programFilters } = formValues;
  const pdpData = usePdpData();

  usePaymentCalculation({
    autoUpdateGrid: true,
    ttlPrecision: TtlPrecision.Low,
    leaseProgramSelectionMethod,
    loanProgramSelectionMethod,
  });

  const { handleCreateVirtualCar } = useCreateVirtualCar({ carId: pdpData.vehicle.id });

  return {
    vehicleDescription: `${pdpData.vehicle.year} ${pdpData.vehicle.make} ${pdpData.vehicle.model}`,
    miles,
    leaseTermsOptions: leaseTerms.map((term) => ({ label: `${term}`, value: term })),
    loanTermsOptions: loanTerms.map((term) => ({ label: `${term}`, value: term })),
    gridViewEnabled,
    loansViewEnabled,
    leasesViewEnabled,
    isRefreshing,
    showPaymentCustomizationBlock:
      programFilters.loanTerms !== undefined || loanTerms.length < 1 || gridViewEnabled,
    handleCreateVirtualCar,
    payAllFeesUpfront: formValues.payAllFeesUpfront,
  };
};
