import React from 'react';

import { Chip, Typography } from '@mui/material';

import { MRT_Row as Row } from 'material-react-table';

import { ProgramGrid } from 'components/pdp/grid-view/table/GridTableService';

import { ProgramType } from 'services/PdpApiService';

interface Props {
  row: Row<ProgramGrid>;
}
export const TypeTableCell: React.FC<Props> = ({
  row: {
    original: { type: programType },
  },
}) => (
  <Chip
    color={programType === ProgramType.Lease ? 'success' : 'info'}
    size="small"
    label={<Typography variant="overline">{programType}</Typography>}
  />
);
