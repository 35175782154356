import React from 'react';

import { Stack, styled, Tooltip } from '@mui/material';

import { ColorAvatar } from 'components/atoms/color-avatar';

interface Props {
  pictureUrl: string;
  rgbExterior: string;
  rgbInterior: string;
  dataCyPrefix: string;
  exteriorColor: string;
  interiorColor: string;
}

const Image = styled('img')(() => ({
  width: '100%',
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

export const VehicleImage: React.FC<Props> = ({
  pictureUrl,
  rgbExterior,
  rgbInterior,
  dataCyPrefix,
  exteriorColor,
  interiorColor,
}) => (
  <Stack direction="column" alignItems="center" justifyContent="center">
    <Image data-cy={`${dataCyPrefix}_carImage`} src={pictureUrl} />
    <StyledStack direction="row" alignItems="center" justifyContent="space-between" spacing={0.5}>
      <Tooltip
        data-cy={`${dataCyPrefix}_exteriorColorTooltip`}
        title={`Exterior color: ${exteriorColor}`}
      >
        <ColorAvatar color={rgbExterior} data-cy={`${dataCyPrefix}_exteriorColor`} />
      </Tooltip>
      <Tooltip
        data-cy={`${dataCyPrefix}_interiorColorTooltip`}
        title={`Interior color: ${interiorColor}`}
      >
        <ColorAvatar color={rgbInterior} data-cy={`${dataCyPrefix}_interiorColor`} />
      </Tooltip>
    </StyledStack>
  </Stack>
);
