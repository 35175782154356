import React from 'react';

import { Button, CardContent, Divider, Stack, styled } from '@mui/material';

import { Card } from 'components/atoms/card/Card';
import { Scrollbar } from 'components/atoms/scrollbar';
import { VehicleFeatures } from 'components/molecules/vehicle-features';
import { VehicleFooter } from 'components/molecules/vehicle-footer';
import { VehicleHeader } from 'components/molecules/vehicle-header';
import { VehicleImage } from 'components/molecules/vehicle-image';
import { VehiclePricing } from 'components/molecules/vehicle-pricing';

import { CalculationLeaseOutput, CalculationLoanOutput } from 'services/CalculationService';
import { Vehicle } from 'services/SearchApiService';

interface Props {
  vehicle: Vehicle;
  calculationLeaseOutputData?: CalculationLeaseOutput;
  calculationLoanOutputData?: CalculationLoanOutput;
  modifiedVehiclePrice?: number;
}

const StyledDivider = styled(Divider)({
  marginBottom: '15px',
  marginTop: '15px',
});

export const ResponderVehicleBlock: React.FC<Props> = ({
  vehicle: {
    year,
    make,
    model,
    trim,
    pictureUrl,
    price,
    state,
    status,
    rgbExterior,
    rgbInterior,
    topFeatures,
    allFeatures,
    stockNumber,
    daysInStock,
    location,
    vin,
    exteriorColor,
    interiorColor,
  },
  calculationLeaseOutputData,
  calculationLoanOutputData,
  modifiedVehiclePrice,
}) => {
  return (
    <Card>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <Stack direction="column" height="100%" divider={<StyledDivider />}>
            <VehicleHeader
              make={make}
              model={model}
              trim={trim}
              state={state}
              status={status}
              year={year}
              dataCyPrefix="responderPage_vehicleSection"
            />
            <VehicleImage
              pictureUrl={pictureUrl}
              rgbExterior={rgbExterior}
              rgbInterior={rgbInterior}
              dataCyPrefix="responderPage_vehicleSection"
              exteriorColor={exteriorColor}
              interiorColor={interiorColor}
            />
            <VehicleFeatures
              dataCyPrefix="responderPage_vehicleSection_topFeatures"
              features={topFeatures}
              withIcon
            />
            <Scrollbar maxheight="30vh">
              <VehicleFeatures
                dataCyPrefix="responderPage_vehicleSection_llFeatures"
                features={allFeatures}
              />
            </Scrollbar>
            <VehiclePricing
              price={modifiedVehiclePrice || price}
              lowestPaymentLease={calculationLeaseOutputData?.payment}
              lowestPaymentLoan={calculationLoanOutputData?.payment}
              dataCyPrefix="responderPage_vehicleSection"
            />
            <VehicleFooter
              stockNumber={stockNumber}
              vin={vin}
              location={location}
              daysInStock={daysInStock}
              status={status}
              dataCyPrefix="responderPage_vehicleSection"
            />
          </Stack>
          <Button variant="contained" data-cy="responderPage_vehicleSection_vehicleDetailsButton">
            Vehicle Details
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
