import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';

import { getCurrentDealershipApiUrl } from 'services/DealerApiService';
import { CurrentDealership } from 'services/SearchApiService';

export const useCurrentDealershipQuery = (): UseQueryResult<CurrentDealership, unknown> => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);

  const currentDealershipQuery = useQuery(
    'currentDealershipQuery',
    async () => {
      const { data } = await axiosClient.get<CurrentDealership>(getCurrentDealershipApiUrl());

      return data;
    },
    {
      onError: () => {
        enqueueSnackbar('Failed to fetch data for the current dealer', {
          variant: 'error',
        });
      },
    }
  );

  return currentDealershipQuery;
};
