import * as React from 'react';

import { IconButton, Popover as MaterialPopover } from '@mui/material';

import { usePopover } from './usePopover';

interface PopoverProps {
  icon: React.ReactNode;
  content: React.ReactNode;
}

export const Popover: React.FC<PopoverProps> = ({ icon, content }) => {
  const { anchorEl, open, id, handleClick, handleClose } = usePopover();

  return (
    <div onClick={(event): void => event.stopPropagation()}>
      <IconButton aria-describedby={id} onClick={handleClick}>
        {icon}
      </IconButton>
      <MaterialPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {content}
      </MaterialPopover>
    </div>
  );
};
