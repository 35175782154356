import React, { useEffect, useRef } from 'react';
import { InfiniteQueryObserverResult } from 'react-query';
import { useIntersection } from 'react-use';

interface HookOptions {
  fetchNextPage(): Promise<InfiniteQueryObserverResult<unknown, unknown>>;
}

interface HookResult {
  ref: React.RefObject<HTMLButtonElement>;
}

export const useScrollFormView = ({ fetchNextPage }: HookOptions): HookResult => {
  const ref = useRef<HTMLButtonElement>(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  const isIntersecting = intersection?.isIntersecting;

  useEffect(() => {
    if (isIntersecting) {
      fetchNextPage();
    }
  }, [isIntersecting]);

  return {
    ref,
  };
};
