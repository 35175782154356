import React from 'react';

import { Grid, Typography } from '@mui/material';

import { DealOffersTable } from 'components/administrations/deal-offers/table/DealOffersTable';
import { Layout } from 'components/molecules/layout';

import { useDealOfferListPage } from './useDealOffersListPage';

export const DealOffersListPage: React.FC = () => {
  const { isLoading, columns, tableData, handleDeleteDealOffer } = useDealOfferListPage();

  return (
    <Layout>
      <Grid container spacing={2} paddingTop="48px" paddingX="16px">
        <Grid item xs={12}>
          <Typography data-cy="dealoffer_listPage_pageTitle" variant="h6">
            Deal Offers
          </Typography>
        </Grid>
        <Grid item xs={12} paddingTop="40px">
          <DealOffersTable
            tableData={tableData}
            columns={columns}
            isLoading={isLoading}
            onDeleteDealOffer={handleDeleteDealOffer}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};
