import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Stack } from '@mui/material';

import { TriggeredModal } from 'components/molecules/modal';

import { LeaseReserveForm } from './LeaseReserveForm';
import { LoanReserveForm } from './LoanReserveForm';
import { useReserveModal } from './useReserveModal';

interface Props {
  trigger: React.ReactElement;
}

export const ReserveModal: React.FC<Props> = ({ trigger }) => {
  const { showLease, showLoan, leaseProgram, loanProgram, reserveForm, isReadOnly } =
    useReserveModal();

  return (
    <TriggeredModal
      title="Reserve and markups"
      displayCloseButton={false}
      trigger={trigger}
      content={
        <FormProvider {...reserveForm}>
          <Stack direction="row">
            {showLease && leaseProgram && (
              <LeaseReserveForm lease={leaseProgram} isReadOnly={isReadOnly}></LeaseReserveForm>
            )}
            {showLoan && loanProgram && (
              <LoanReserveForm loan={loanProgram} isReadOnly={isReadOnly}></LoanReserveForm>
            )}
          </Stack>
        </FormProvider>
      }
    />
  );
};
