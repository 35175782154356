import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  CardContent,
  Collapse,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { Card } from 'components/atoms/card';
import { CheckBoxGroupItems } from 'components/search/checkbox-group-items';

import { FeatureFilter } from 'services/SearchFiltersApiService';

import { useCollapsibleFilterBlock } from './useCollapsibleFilterBlock';

interface CollapsibleFilterBlockProps {
  title: string;
  dataCyPrefix: string;
  defaultExpanded?: boolean;
  featureItems: FeatureFilter[];
  disabled: boolean;
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
}));

const CollapseButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
}));

export const CollapsibleFilterBlock: React.FC<CollapsibleFilterBlockProps> = ({
  title,
  dataCyPrefix,
  defaultExpanded = false,
  featureItems,
  disabled,
}) => {
  const { expand, handleCardClicked } = useCollapsibleFilterBlock({ defaultExpanded });

  return (
    <Card>
      <StyledCardContent onClick={(): void => handleCardClicked(!expand)}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="overline" fontWeight={500} data-cy={`${dataCyPrefix}_title`}>
            {title}
          </Typography>
          <CollapseButton data-cy={`${dataCyPrefix}_collapseButton`}>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </CollapseButton>
        </Stack>
      </StyledCardContent>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <>
          <Divider variant="middle" />
          <CardContent>
            <CheckBoxGroupItems
              disabled={disabled}
              dataCyPrefix={dataCyPrefix}
              featureItems={featureItems}
            />
          </CardContent>
        </>
      </Collapse>
    </Card>
  );
};
