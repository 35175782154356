import React, { useContext, useMemo } from 'react';

import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { debounce } from 'lodash';

import { useDeleteUser } from 'pages/administration/users/useDeleteUser';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useUsersQuery } from 'hooks/useUsersQuery';

import { User } from 'services/UserAdministrationApiService';

import { UserPermission } from 'utils/UserPermission';

import {
  UserTableRow,
  filterTableData,
  mapTableData,
  userTableColumns,
} from './UsersListPageService';

interface HookResult {
  tableData: UserTableRow[];
  columns: ColumnDef<Partial<User>>[];
  isFetching: boolean;
  handleDeleteUser(id: string): Promise<void>;
  filterChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const debounceTimeMs = 100;

export const useUsersListPage = (): HookResult => {
  const [filterValue, setFilterValue] = React.useState('');
  const { data, isFetching } = useUsersQuery();
  const { handleDeleteUser } = useDeleteUser();
  const { dealerIds, permissions: currentUserPermissions } = useContext(PermissionsContext);

  const filterChangeHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, debounceTimeMs);

  const columns = useMemo<ColumnDef<Partial<UserTableRow>>[]>(() => userTableColumns, []);
  const tableData = mapTableData(
    data ?? [],
    currentUserPermissions.includes(UserPermission.editAllUserDealers) ||
      currentUserPermissions.includes(UserPermission.editUserDealerGroup),
    dealerIds
  );
  const filteredTableData = filterTableData(tableData ?? [], filterValue);

  return {
    tableData: filteredTableData,
    columns,
    isFetching,
    handleDeleteUser,
    filterChangeHandler,
  };
};
