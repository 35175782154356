import { useContext } from 'react';
import { UseMutationResult, useMutation } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { getSendDealOfferEmailUrl } from 'services/DealOfferApiService';

export interface SendDealOfferEmailRequest {
  dealOfferId: string;
  to: string;
  toName: string;
  subject: string;
  messageBody: string;
}

export const useSendDealOfferEmailMutation = (
  dealOfferId: string | undefined
): UseMutationResult<AxiosResponse, unknown, SendDealOfferEmailRequest, unknown> => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);
  const sendDealOfferMutation = useMutation(
    [dealOfferId],
    ({ dealOfferId, to, toName, subject, messageBody }: SendDealOfferEmailRequest) =>
      axiosClient.post(getSendDealOfferEmailUrl(dealOfferId), {
        to,
        toName,
        subject,
        messageBody,
      }),
    {
      onError: () => {
        enqueueSnackbar('Failed to send deal offer email', {
          variant: 'error',
          autoHideDuration: null,
        });
      },
      onSuccess: () => {
        enqueueSnackbar('Deal offer email sent', {
          variant: 'info',
        });
      },
      retry: false,
    }
  );

  return sendDealOfferMutation;
};
