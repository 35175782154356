import { useEffect } from 'react';

import { useSnackbar } from 'notistack';

interface HookOptions {
  showExpiredProgramsOrIncentivesWarning?: boolean;
}

const message = 'The program and/or incentives on this deal offer have expired and may be invalid';

export const useHandleExpiredProgramsOrIncentives = ({
  showExpiredProgramsOrIncentivesWarning,
}: HookOptions): void => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (showExpiredProgramsOrIncentivesWarning) {
      enqueueSnackbar(message, {
        variant: 'warning',
        key: 'InvalidIncentivesPdpAler',
        preventDuplicate: true,
        autoHideDuration: null,
      });
    }
  }, [showExpiredProgramsOrIncentivesWarning]);
};
