import { UseFormSetValue } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';
import { SetterOrUpdater } from 'recoil';

import { Buyer, PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { getCreditTierByCreditScore } from 'services/CreditTiersService';
import { CreditStatus, Customer } from 'services/CustomerService';

export enum DealOfferStatus {
  InvalidDeal = 'InvalidDeal',
  InvalidCreditScore = 'InvalidCreditScore',
  UnsavedCreditScore = 'UnsavedCreditScore',
}

export async function loadCustomer(
  findCustomer: () => Promise<QueryObserverResult<Customer | undefined, unknown>>,
  setCurrentCustomer: SetterOrUpdater<Customer>,
  setValue: UseFormSetValue<PdpFormValues>
): Promise<Customer | undefined> {
  return await findCustomer().then(({ data: customer }) => {
    if (customer) {
      setCurrentCustomer(customer);
      setBuyer(customer, setValue);
    }

    return customer;
  });
}

function setBuyer(customer: Customer, setValue: UseFormSetValue<PdpFormValues>): void {
  const buyer: Buyer = {
    id: customer.id,
    email: customer.email,
    name: customer.name,
    phone: customer.phone,
    location: customer.location
      ? {
          id: customer.location.id,
          city: customer.location.city,
          county: customer.location.county,
          state: customer.location.state,
          zipCode: customer.location.zipCode,
        }
      : undefined,
  };

  setValue('buyer', buyer);
  setValue('buyer.location', buyer.location);
}

export interface DealOfferStatusRequest {
  customer: Customer;
  creditScore: number;
  isCreditScoreUnsaved: boolean;
  isDealOffer: boolean;
}

export function getDealOfferStatus({
  customer,
  creditScore,
  isCreditScoreUnsaved,
  isDealOffer,
}: DealOfferStatusRequest): CreditStatus | DealOfferStatus {
  if (isCreditScoreInvalid(customer, creditScore)) {
    return isDealOffer ? DealOfferStatus.InvalidDeal : DealOfferStatus.InvalidCreditScore;
  }

  return isCreditScoreUnsaved ? DealOfferStatus.UnsavedCreditScore : customer.creditStatus;
}

function isCreditScoreInvalid(customer: Customer, creditScore: number): boolean {
  const dealCreditTier = getCreditTierByCreditScore(creditScore);

  return !!customer.creditTierId && dealCreditTier?.id !== customer.creditTierId;
}

export function resetCurrentCustomer(
  setCurrentCustomer: SetterOrUpdater<Customer>,
  setValue?: UseFormSetValue<PdpFormValues>
): void {
  setCurrentCustomer((existing) => {
    const updatedCustomer: Customer = {
      ...existing,
      id: 0,
      creditTierId: undefined,
      creditStatus: CreditStatus.Unverified,
    };

    return updatedCustomer;
  });

  if (setValue) {
    setValue('buyer.id', 0);
  }
}
