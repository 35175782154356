import { RowSelectionState, SortingState, OnChangeFn, Updater } from '@tanstack/react-table';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  MRT_ColumnDef as ColumnDef,
  MRT_Row as TableRow,
  MRT_TableInstance as TableInstance,
} from 'material-react-table';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { usePdpData } from 'hooks/usePdpData';

import {
  isExactCalculationSelector,
  isExactExactCalculationSelector,
} from 'models/ExactPaymentModel';
import { gridTableDataSelector } from 'models/GridViewModel';
import {
  leaseProgramSelectionMethodAtom,
  loanProgramSelectionMethodAtom,
  ProgramSelectionMethod,
  shouldSelectLowestPaymentLeaseAtom,
  shouldSelectLowestPaymentLoanAtom,
} from 'models/PaymentSelectionModel';
import { viewProfitAtom } from 'models/ViewProfitModel';

import { sendTagData } from 'services/GoogleAnalyticsService';
import { ProgramType } from 'services/PdpApiService';

import {
  getDefaultRowSelectionState,
  getNewLeaseProgram,
  getNewLoanProgram,
  getRowSelectionData,
  isLeaseProgramType,
  isLoanProgramType,
  ProgramGrid,
} from './GridTableService';

interface HookOptions {
  columns: ColumnDef<ProgramGrid>[];
}

interface HookResult {
  tableColumns: ColumnDef<ProgramGrid>[];
  tableData: ProgramGrid[];
  tableContainerRef: React.RefObject<HTMLDivElement>;
  rowSelection: RowSelectionState;
  handleSelectCheckbox(table: TableInstance<ProgramGrid>, row: TableRow<ProgramGrid>): void;
  getRowId(id?: string): string;
  isDisabled(table: TableInstance<ProgramGrid>, row: TableRow<ProgramGrid>): boolean;
  columnVisibility: Record<string, boolean>;
  sortingState: SortingState;
  handleSortingChange: OnChangeFn<SortingState>;
}

export const useGridTable = ({ columns }: HookOptions): HookResult => {
  const tableColumns = useMemo<ColumnDef<ProgramGrid>[]>(() => columns, []);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableData = useRecoilValue(gridTableDataSelector);
  const pdpData = usePdpData();
  const { control, setValue } = useFormContext<PdpFormValues>();
  const { loanProgram, leaseProgram } = useWatch({
    control,
  }) as PdpFormValues;
  const { isDeskingManager, isGeneralManager } = useContext(PermissionsContext);
  const setShouldSelectLowestPaymentLease = useSetRecoilState(shouldSelectLowestPaymentLeaseAtom);
  const setShouldSelectLowestPaymentLoan = useSetRecoilState(shouldSelectLowestPaymentLoanAtom);
  const setLeaseProgramSelectionMethod = useSetRecoilState(leaseProgramSelectionMethodAtom);
  const setLoanProgramSelectionMethod = useSetRecoilState(loanProgramSelectionMethodAtom);
  const isExactDownExactMonthlyPayment = useRecoilValue(isExactExactCalculationSelector);
  const isCalculatedDownExactMonthlyPayment = useRecoilValue(isExactCalculationSelector);
  const viewProfitEnabled = useRecoilValue(viewProfitAtom);

  const leaseProgramId = leaseProgram?.id;
  const loanProgramId = loanProgram?.id;
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    getDefaultRowSelectionState({
      leaseProgramId,
      loanProgramId,
    })
  );

  useEffect(() => {
    setRowSelection(
      getDefaultRowSelectionState({
        leaseProgramId,
        loanProgramId,
      })
    );
  }, [leaseProgramId, loanProgramId]);

  const [sortingState, setSorting] = useState([{ id: 'payment', desc: false }]);
  const leasePrograms = pdpData?.lenderData.lease.programs ?? [];
  const loanPrograms = pdpData?.lenderData.loan.programs ?? [];
  const columnVisibility = {
    'profitData.totalProfit': (isDeskingManager || isGeneralManager) && viewProfitEnabled,
  };

  useEffect(() => {
    if (isExactDownExactMonthlyPayment) {
      setSorting([{ id: 'profitData.totalProfit', desc: true }]);

      return;
    }
    if (isCalculatedDownExactMonthlyPayment) {
      setSorting([{ id: 'down', desc: false }]);

      return;
    }
    setSorting([{ id: 'payment', desc: false }]);
    sendTagData('grid_view', {});
  }, [isExactDownExactMonthlyPayment, isCalculatedDownExactMonthlyPayment]);

  const handleSortingChange = useCallback((x: Updater<SortingState>) => {
    setSorting((x as Function)());
  }, []);

  const handleSelectCheckbox = useCallback(
    (table: TableInstance<ProgramGrid>, { getValue, id: rowId }: TableRow<ProgramGrid>) => {
      const programType = getValue('type') as ProgramType;
      setRowSelection(
        (previousRowState): RowSelectionState =>
          getRowSelectionData({ previousRowState, programType, rowId, table })
      );

      if (isLeaseProgramType(programType)) {
        setShouldSelectLowestPaymentLease(false);
        setLeaseProgramSelectionMethod(ProgramSelectionMethod.Manual);
        const newLeaseProgram = getNewLeaseProgram(leasePrograms, rowId);
        setValue('leaseProgram', newLeaseProgram);
        setValue('calculationLeaseOutputData', newLeaseProgram?.calculation);
      }
      if (isLoanProgramType(programType)) {
        setShouldSelectLowestPaymentLoan(false);
        setLoanProgramSelectionMethod(ProgramSelectionMethod.Manual);
        const newLoanProgram = getNewLoanProgram(loanPrograms, rowId);
        setValue('loanProgram', newLoanProgram);
        setValue('calculationLoanOutputData', newLoanProgram?.calculation);
      }
    },
    [leasePrograms, loanPrograms]
  );

  const getRowId = useCallback((id?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return id!;
  }, []);

  const isDisabled = useCallback(
    ({ getSelectedRowModel }: TableInstance<ProgramGrid>, { id: rowId }: TableRow<ProgramGrid>) => {
      return getSelectedRowModel()
        .rows.map(({ id }) => id)
        .includes(rowId);
    },
    []
  );

  return {
    tableColumns,
    tableData,
    tableContainerRef,
    rowSelection,
    handleSelectCheckbox,
    getRowId,
    isDisabled,
    columnVisibility,
    sortingState,
    handleSortingChange,
  };
};
