import React, { ReactNode } from 'react';

import { Pending } from '@mui/icons-material';
import { Stack, Divider } from '@mui/material';

import { InfoTile } from 'components/pdp/info-tile';
import { LeaseDetailsCard } from 'components/pdp/lease-details-card';
import { LeaseMainBox } from 'components/pdp/lease-main-box';
import { LeaseProfitDetails } from 'components/pdp/lease-profit-details';
import { LoanDetailsCard } from 'components/pdp/loan-details-card';
import { LoanMainBox } from 'components/pdp/loan-main-box';
import { LoanProfitDetails } from 'components/pdp/loan-profit-details';

import { useInfoBlock } from './useInfoBlock';

const LoadingTile: React.FC<string> = (selectorPrefix: string) => {
  return <InfoTile icon={Pending} title="Loading" text="" selectorPrefix={selectorPrefix} />;
};

export const InfoBlock: React.FC = () => {
  const {
    formValues: {
      loanProgram,
      leaseProgram,
      calculationLeaseOutputData,
      calculationLoanOutputData,
      payAllFeesUpfront,
      msrp: modifiedMsrp,
      payAllPackagesUpfront,
      buyer: { location },
    },
    hasLeasePrograms,
    hasFilteredLeasePrograms,
    hasLoanPrograms,
    hasFilteredLoanPrograms,
    loansViewEnabled,
    leasesViewEnabled,
    vehicleMsrp,
    leaseProgramSelectionMethod,
    isLeaseCapCostInRangeOfMaxAdvance,
    isLoanTotalFinancedInRangeOfMaxAdvance,
    isInitialLoad,
  } = useInfoBlock();
  const {
    lenderName: loanLenderName,
    programName: loanProgramName,
    expiryDate: loanExpiryDate,
  } = loanProgram ?? {};
  const {
    lenderName: leaseLenderName,
    programName: leaseProgramName,
    expiryDate: leaseExpiryDate,
  } = leaseProgram ?? {};

  const { zipCode: buyerZipCode = '' } = location ?? {};

  const renderLeases = (): ReactNode => {
    if (!leasesViewEnabled) return;

    if (isInitialLoad) {
      return LoadingTile('pdpPage_lease_loading');
    }

    if (!hasLeasePrograms) {
      return (
        <InfoTile
          title="A lease on this vehicle isn’t available."
          text="Generally, this is because the car is used or is too old for a lease to make sense. Please consult your F&I team for more info."
          selectorPrefix="pdpPage_leaseUnavailableBox"
        />
      );
    }

    if (!hasFilteredLeasePrograms) {
      return (
        <InfoTile
          title="A lease on this vehicle isn’t available."
          text="This is due to the filters you’ve selected. Please adjust your filters and try again."
          selectorPrefix="pdpPage_lease_filters_notAvailable"
        />
      );
    }

    if (!isLeaseCapCostInRangeOfMaxAdvance || !calculationLeaseOutputData) {
      return (
        <InfoTile
          title="Lender program is not available because of exceeding max advance"
          text="Decrease net capitalized costs."
          selectorPrefix="pdpPage_lease_maxAdvance_invalid"
          color="error"
        />
      );
    }

    return (
      <>
        <LeaseMainBox
          data-cy="pdpPage_lease_mainBox"
          lenderName={leaseLenderName}
          isLeaseProgramAvailable={!!leaseProgram}
          leaseProgramName={leaseProgramName}
          expirationDate={leaseExpiryDate}
          programSelectionMethod={leaseProgramSelectionMethod}
          calculationOutput={calculationLeaseOutputData}
        />
        <LeaseDetailsCard
          data-cy="pdpPage_lease_detailsCard"
          calculationOutput={calculationLeaseOutputData}
          leaseDetails={{
            buyerZipCode,
            msrp: modifiedMsrp || vehicleMsrp,
            payAllPackagesUpfront,
            payAllFeesUpfront,
          }}
        />
        <LeaseProfitDetails data-cy="pdpPage_lease_profitDetails" />
      </>
    );
  };

  const renderLoans = (): ReactNode => {
    if (!loansViewEnabled) return;

    if (isInitialLoad) {
      return LoadingTile('pdpPage_loan_loading');
    }

    if (!hasLoanPrograms) {
      return (
        <InfoTile
          title="A loan on this vehicle isn’t available."
          text="Generally, this is because the car is used or is too old for a loan to make sense. Please consult your F&I team for more info."
          selectorPrefix="pdpPage_loanUnavailableBox"
        />
      );
    }

    if (!hasFilteredLoanPrograms) {
      return (
        <InfoTile
          title="A loan on this vehicle isn’t available."
          text="This is due to the filters you’ve selected. Please adjust your filters and try again."
          selectorPrefix="pdpPage_loan_filters_notAvailable"
        />
      );
    }

    if (!isLoanTotalFinancedInRangeOfMaxAdvance || !calculationLoanOutputData) {
      return (
        <InfoTile
          title="Lender program is not available because of exceeding max advance"
          text="Decrease total amount financed."
          selectorPrefix="pdpPage_loan_maxAdvance_invalid"
          color="error"
        />
      );
    }

    return (
      <>
        <LoanMainBox
          data-cy="pdpPage_loan_mainBox"
          lenderName={loanLenderName}
          isProgramAvailable={!!loanProgram}
          loanProgramName={loanProgramName}
          expirationDate={loanExpiryDate}
          calculationOutput={calculationLoanOutputData}
          term={loanProgram?.term ?? 0}
        />
        <LoanDetailsCard
          data-cy="pdpPage_loan_detailsCard"
          calculationOutput={calculationLoanOutputData}
          loanDetail={{
            buyerZipCode,
            msrp: modifiedMsrp || vehicleMsrp,
            payAllPackagesUpfront,
            payAllFeesUpfront,
          }}
        />
        <LoanProfitDetails data-cy="pdpPage_loan_profitDetails" />
      </>
    );
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
      width="100%"
    >
      <Stack direction="column" width="100%" spacing={2}>
        {renderLeases()}
      </Stack>
      <Stack direction="column" width="100%" spacing={2}>
        {renderLoans()}
      </Stack>
    </Stack>
  );
};
