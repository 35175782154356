import TagManager from 'react-gtm-module';

export function sendTagData(eventName: string, data: object): void {
  if (!eventName) {
    return;
  }

  const eventObject = { event: eventName };

  const dataLayer = {
    dataLayer: Object.assign(data, eventObject),
  };

  TagManager.dataLayer(dataLayer);
}
