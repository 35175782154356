import React, { useMemo } from 'react';

import { CircularProgress, Grid } from '@mui/material';

import { PdpPageLayout } from 'pages/pdp-page/PdpPageLayout';

import { PdpFormWrapper } from 'components/pdp';

import { PdpContextProvider } from 'providers/PdpContextProvider';

import { ValidatedDealOfferPdp } from 'services/DealOfferApiService';
import { PdpItem } from 'services/PdpApiService';
import { CarState } from 'services/SearchApiService';

import { useDealOfferPdpPage } from './useDealOfferPdpPage';

export const DealOfferPdpPage: React.FC = () => {
  const { isLoading, dealOffer } = useDealOfferPdpPage();

  let element = null;

  const pdpData = useMemo(() => getPdpData(dealOffer), [dealOffer]);

  if (isLoading) {
    element = (
      <Grid item xs={12} textAlign="center">
        <CircularProgress data-cy="dealOfferPdpPage_spinner" size={50} />
      </Grid>
    );
  } else if (dealOffer) {
    const {
      dealOffer: { paymentCustomization, vehicle },
      isLeaseProgramExpired,
      isLoanProgramExpired,
      isIncentivesExpired,
    } = dealOffer;

    const { state: vehicleState } = vehicle;

    element = (
      <PdpContextProvider
        shouldBeReadOnly
        pdpData={pdpData}
        paymentCalculationDisabled
        programSelectionDisabled
      >
        <PdpFormWrapper
          dealOfferValues={paymentCustomization}
          isLeaseProgramExpired={isLeaseProgramExpired}
          isLoanProgramExpired={isLoanProgramExpired}
          isCarSold={vehicleState === CarState.Sold}
          isIncentivesExpired={isIncentivesExpired}
        />
      </PdpContextProvider>
    );
  }

  return <PdpPageLayout>{element}</PdpPageLayout>;
};

function getPdpData(dealOffer: ValidatedDealOfferPdp | undefined): PdpItem {
  if (!dealOffer) {
    return {} as PdpItem;
  }

  const {
    dealOffer: { dealer, lenderData, vehicle },
  } = dealOffer;

  return { dealer, lenderData, vehicle, dealerDefaults: undefined };
}
