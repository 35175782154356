import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { CircularProgress, Grid } from '@mui/material';

import { PdpFormWrapper } from 'components/pdp';

import { PdpContextProvider } from 'providers/PdpContextProvider';

import { usePdpQuery } from 'hooks/usePdpQuery';

import { pdpQueryRefreshingAtom } from 'models/PdpQueryModel';

import { CarState } from 'services/SearchApiService';

import { PdpPageLayout } from './PdpPageLayout';
import { usePdpPage } from './usePdpPage';

export const PdpPage: React.FC = () => {
  const { data, isLoading, isRefetching } = usePdpQuery();
  const setPdpQueryRefreshing = useSetRecoilState(pdpQueryRefreshingAtom);

  useEffect(() => {
    setPdpQueryRefreshing(isRefetching);
  }, [isRefetching]);

  let element = null;

  if (isLoading) {
    element = (
      <Grid item xs={12} textAlign="center">
        <CircularProgress data-cy="pdpPage_spinner" size={50} />
      </Grid>
    );
  } else if (data) {
    const { state: vehicleState } = data.vehicle;
    element = (
      <PdpContextProvider shouldBeReadOnly={false} pdpData={data} isInitialLoadComplete={false}>
        <PdpFormWrapper isCarSold={vehicleState === CarState.Sold} />
      </PdpContextProvider>
    );
  }

  usePdpPage({ data, isLoading });

  return <PdpPageLayout>{element}</PdpPageLayout>;
};
