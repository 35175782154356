import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid, Stack } from '@mui/material';

import { VehicleCard } from 'components/molecules/vehicle-card';
import { CustomerBlock } from 'components/pdp/customer-block';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import {
  PaymentCustomizationBlock,
  PaymentCustomizationBlockFooter,
} from 'components/pdp/payment-customization-block';
import { PaymentInformationBox } from 'components/pdp/payment-information-box/PaymentInformationBox';
import { RecommendationCards } from 'components/pdp/recommendation/RecommendationCards';

import { PdpContext } from 'providers/PdpContextProvider';

import { PdpItem } from 'services/PdpApiService';

import { usePdpForm } from './usePdpForm';

interface Props {
  pdpData: PdpItem;
  isCarSold?: boolean;
  isLeaseProgramExpired?: boolean;
  isLoanProgramExpired?: boolean;
  isIncentivesExpired?: boolean;
  isDealOffer: boolean;
}

export const PdpForm: React.FC<Props> = ({
  pdpData,
  isCarSold = false,
  isIncentivesExpired,
  isLeaseProgramExpired,
  isLoanProgramExpired,
  isDealOffer,
}) => {
  const {
    dealer: { creditAppUrl },
  } = pdpData;

  const { calculationLeaseOutputData, calculationLoanOutputData } =
    useFormContext<PdpFormValues>().watch();

  const {
    isLoading,
    handleSaveDealOffer,
    handleOpenResponder,
    handlePushToCrm,
    isExportPdfGridButtonDisabled,
  } = usePdpForm({
    pdpData,
    isIncentivesExpired,
    isLeaseProgramExpired,
    isLoanProgramExpired,
  });

  const { shouldBeReadOnly } = useContext(PdpContext);

  return (
    <form onSubmit={handleSaveDealOffer}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Stack direction="column" spacing={2}>
            <VehicleCard
              dataCyPrefix="pdpPage"
              renderVehicleDetailsButton
              vehicle={{
                ...pdpData.vehicle,
                lowestPaymentLease:
                  calculationLeaseOutputData?.payment ?? pdpData.vehicle.lowestPaymentLease,
                lowestPaymentLoan:
                  calculationLoanOutputData?.payment ?? pdpData.vehicle.lowestPaymentLoan,
                profitLease: {
                  total: calculationLeaseOutputData?.profit.total,
                  tier: calculationLeaseOutputData?.profit.tier,
                },
                profitLoan: {
                  total: calculationLoanOutputData?.profit.total,
                  tier: calculationLoanOutputData?.profit.tier,
                },
              }}
              persistState
            />
            <RecommendationCards pdpData={pdpData}></RecommendationCards>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaymentInformationBox
                pdpData={pdpData}
                isDisabled={isLoading || shouldBeReadOnly}
                isDealOffer={isDealOffer}
              />
            </Grid>
            <Grid item xs={12} lg={9}>
              <PaymentCustomizationBlock
                isDisabled={isLoading || shouldBeReadOnly}
                isCarSold={isCarSold}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Stack direction="column" justifyContent="space-between" spacing={2} height="100%">
                <CustomerBlock
                  isDisabled={isCarSold || isLoading || isDealOffer}
                  isDealOffer={isDealOffer}
                />
                <PaymentCustomizationBlockFooter
                  isDisabled={isLoading}
                  creditAppUrl={creditAppUrl}
                  openResponder={handleOpenResponder}
                  onPushToCrm={handlePushToCrm}
                  isExportPdfGridButtonDisabled={isExportPdfGridButtonDisabled}
                  isSaveDealOfferAllowed={!isDealOffer && !isCarSold}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
