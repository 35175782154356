import { RebateTerm } from 'services/PdpApiService';
import { ExtendedRebate } from 'services/RebatesService';

import { formatToCurrency } from 'utils/formatterUtils';

export function getAmountIncentiveValue(
  { cash, rebateTerms }: Pick<ExtendedRebate, 'cash' | 'rebateTerms'>,
  programTerms: number[]
): string {
  if (rebateTerms?.length > 0) {
    const values = new Set(getTermValues(rebateTerms, programTerms, cash).sort((a, b) => a - b));

    return [...values].map((value) => formatToCurrency(value, 0)).join('/');
  }

  return formatToCurrency(cash, 0);
}

function getTermValues(rebateTerms: RebateTerm[], selectedTerms: number[], cash: number): number[] {
  const selectedCount = selectedTerms?.length;
  const filtered = selectedCount
    ? rebateTerms.filter(({ month }) => selectedTerms.includes(month))
    : rebateTerms;

  const filteredCount = filtered?.length;
  if (!filteredCount) {
    return [cash];
  }

  const filteredValues = filtered.map(({ value }) => value);
  const selectionContainsDefault = selectedCount > filteredCount;
  const includeDefault = !selectedCount || selectionContainsDefault;

  return includeDefault ? filteredValues.concat([cash]) : filteredValues;
}
