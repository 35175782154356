import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { resetCurrentCustomer } from 'components/pdp/customer-block/CustomerService';

import { useDealOfferPdpQuery } from 'hooks/useDealOfferPdpQuery';
import { useGetCustomerQuery } from 'hooks/useGetCustomerQuery';

import { currentCustomerAtom } from 'models/CurrentCustomerModel';
import { leaseProgramsGridAtom, loanProgramsGridAtom } from 'models/GridViewModel';

import {
  ValidatedDealOfferPdp,
  mapToPaymentGridLease,
  mapToPaymentGridLoan,
} from 'services/DealOfferApiService';
import { mapLeaseGrid, mapLoanGrid } from 'services/payment-grid/PaymentGridApiResponseService';

interface HookResult {
  isLoading: boolean;
  dealOffer: ValidatedDealOfferPdp | undefined;
}

export const useDealOfferPdpPage = (): HookResult => {
  const { isLoading: isDealOfferLoading, data: dealOffer } = useDealOfferPdpQuery();
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const setLeaseGrid = useSetRecoilState(leaseProgramsGridAtom);
  const setLoanGrid = useSetRecoilState(loanProgramsGridAtom);
  const customerId = dealOffer?.dealOffer.metadata.customerId ?? 0;
  const shouldRetrieveCustomer = customerId > 0;

  const {
    isLoading: isCustomerLoading,
    data: currentCustomer,
    isFetched: isCustomerFetched,
  } = useGetCustomerQuery(customerId);

  const needToFetchCustomer =
    dealOffer === undefined || (shouldRetrieveCustomer && !isCustomerFetched);

  useEffect(() => {
    if (currentCustomer) {
      setCurrentCustomer(currentCustomer);
      setIsCustomerLoaded(true);
    } else if (!needToFetchCustomer) {
      resetCurrentCustomer(setCurrentCustomer);
      setIsCustomerLoaded(true);
    }
  }, [currentCustomer, needToFetchCustomer]);

  useEffect(() => {
    const selectedLease = dealOffer?.dealOffer.selectedLease
      ? mapToPaymentGridLease(dealOffer?.dealOffer.selectedLease)
      : undefined;

    const selectedLoan = dealOffer?.dealOffer.selectedLoan
      ? mapToPaymentGridLoan(dealOffer?.dealOffer.selectedLoan)
      : undefined;

    const leaseGrid = selectedLease ? [mapLeaseGrid(selectedLease)] : [];
    const loanGrid = selectedLoan ? [mapLoanGrid(selectedLoan)] : [];

    setLeaseGrid(leaseGrid);
    setLoanGrid(loanGrid);
  }, [dealOffer]);

  return {
    isLoading: isDealOfferLoading || isCustomerLoading || !isCustomerLoaded,
    dealOffer,
  };
};
