import React from 'react';
import { Link } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { List, ListItem, ListItemButton, ListItemText, styled } from '@mui/material';

import MaterialReactTable, { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { ConfirmDialog } from 'components/atoms/confirm-dialog';
import { Popover } from 'components/atoms/popover';

import { DealOfferAdmin } from 'services/DealOfferApiService';
import { User } from 'services/UserAdministrationApiService';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'unset',
  width: '100%',
});

interface Props {
  tableData: DealOfferAdmin[];
  columns: ColumnDef<Partial<User>>[];
  isLoading: boolean;
  onDeleteDealOffer(id: string): Promise<void>;
}

export const DealOffersTable: React.FC<Props> = ({
  columns,
  onDeleteDealOffer,
  isLoading,
  tableData,
}) => (
  <MaterialReactTable
    columns={columns}
    enableRowActions
    renderRowActions={({ row: { index, id } }): React.ReactNode => (
      <Popover
        data-cy={`dealOfferListPage_table_row-${index}_popover`}
        icon={<MoreVertIcon data-cy={`dealOfferListPage_table_row-${index}_openPopoverIcon`} />}
        content={
          <List>
            <ListItem disablePadding>
              <StyledLink to={`/pdp/dealOffer/${id}`}>
                <ListItemButton
                  data-cy={`dealOfferListPage_table_row-${index}_openPdpForDealOffer_button_${id}`}
                >
                  <ListItemText
                    data-cy={`dealOfferListPage_table_row-${index}_popover_openPdpForDealOffer`}
                  >
                    Navigate to PDP
                  </ListItemText>
                </ListItemButton>
              </StyledLink>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton data-cy={`dealOfferListPage_table_row-${index}_deleteButton_${id}`}>
                <ConfirmDialog
                  triggerElement={
                    <ListItemText
                      data-cy={`dealOfferListPage_table_row-${index}_popover_deleteDealOffer`}
                    >
                      Delete Deal Offer
                    </ListItemText>
                  }
                  title="Delete Deal Offer"
                  contentText="Are you sure you want to delete this deal offer? This action is irreversible."
                  cancelText="Cancel"
                  confirmText="Yes, I am sure"
                  onConfirm={(): Promise<void> => onDeleteDealOffer(id)}
                />
              </ListItemButton>
            </ListItem>
          </List>
        }
      />
    )}
    data={tableData}
    enableColumnActions={false}
    enableTopToolbar={false}
    enableDensityToggle={false}
    enableFullScreenToggle={false}
    enableHiding={false}
    enableFilters={false}
    positionActionsColumn="last"
    displayColumnDefOptions={{
      'mrt-row-actions': {
        header: '',
      },
    }}
    enableSorting
    initialState={{ sorting: [{ desc: true, id: 'date' }] }}
    state={{
      isLoading,
    }}
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getRowId={({ id }): string => id!}
  />
);
