import React from 'react';

import { Star } from '@mui/icons-material';
import { CardContent, Icon, Stack, Typography, keyframes, styled } from '@mui/material';

import { Card } from 'components/atoms/card';

import { ProgramType } from 'services/PdpApiService';
import { Vehicle } from 'services/SearchApiService';

import { KeyRecommendationModal } from './KeyRecommendationModal';

interface Props {
  vehicle: Vehicle;
  programType: ProgramType;
}

const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'clickable' })<{
  clickable: boolean;
}>(({ clickable }) => ({
  '&:hover': clickable && {
    boxShadow:
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
  },
}));

const pulse = keyframes`
0% { opacity: 0.25 }
50% { opacity: 1 }
100% { opacity: 0.25 }`;

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.brandOrange.main,
}));

export const KeyRecommendationCard: React.FC<Props> = ({ vehicle, programType }) => {
  return (
    <StyledCard clickable={false} data-cy="key-recommendation-card">
      <StyledCardContent>
        <Stack direction="row" spacing={2} alignItems="center">
          <StyledIcon
            sx={{
              height: 30,
              width: 30,
              animation: `${pulse} 1.5s linear infinite`,
            }}
          >
            <Star sx={{ height: 30, width: 30 }}></Star>
          </StyledIcon>
          <Typography variant="subtitle3">Discover a deal with a higher MSRP and profit</Typography>
          <KeyRecommendationModal
            vehicle={vehicle}
            programType={programType}
          ></KeyRecommendationModal>
        </Stack>
      </StyledCardContent>
    </StyledCard>
  );
};
