interface GetTradeProfitOptions {
  actualCashValue: number;
  tradeAllowance: number;
}

export function getTradeProfit({ actualCashValue, tradeAllowance }: GetTradeProfitOptions): number {
  return actualCashValue - tradeAllowance;
}
interface GetTradeValueOptions {
  tradeAllowance: number;
  tradePayoff: number;
}

export function getTradeValue({ tradeAllowance, tradePayoff }: GetTradeValueOptions): number {
  return tradeAllowance - tradePayoff;
}
