import _ from 'lodash';

import { LeaseProgram, LoanProgram } from 'services/PdpApiService';

export function getLeaseProgramMilesFromProgram(
  leasePrograms: LeaseProgram[]
): { label: string; value: number }[] {
  const miles = leasePrograms.map((x) => x.millage);

  return getLeaseProgramMiles(miles);
}

export function getLeaseProgramMiles(miles: number[]): { label: string; value: number }[] {
  const uniqueMiles = sortUniqueValues(miles);

  const milesOptions = uniqueMiles.map((miles) => ({
    label: miles.toLocaleString(),
    value: miles,
  }));

  return milesOptions;
}

export function getProgramTerms(leasePrograms: LeaseProgram[] | LoanProgram[]): number[] {
  const terms = leasePrograms.map((x) => x.term);

  return sortUniqueValues(terms);
}

export function sortUniqueValues(terms: number[]): number[] {
  return _.uniq(terms).sort((a, b) => a - b);
}
