export interface PrintOptionsFormValues {
  showLoanGrid: boolean;
  loanMultipleGridPayments: boolean;
  showLoanGridApr: boolean;
  showLeaseGrid: boolean;
  leaseMultipleGridPayments: boolean;
  showLeaseGridMoneyFactor: boolean;
  showResidual: boolean;
  showLoanDetails: boolean;
  showLeaseDetails: boolean;
  showCreditScore: boolean;
  showLenderName: boolean;
  showAddons: boolean;
  showIncentives: boolean;
  showTradeIn: boolean;
  showVehiclePriceOrMsrp: boolean;
  showAcquisitionFee: boolean;
  showDealerFees: boolean;
  showNonTaxTtlFees: boolean;
  showSalesTax: boolean;
}

export function getDefaultFormValues(
  showLease: boolean,
  showLoan: boolean
): PrintOptionsFormValues {
  return {
    showLoanGrid: showLoan,
    loanMultipleGridPayments: true,
    showLoanGridApr: true,
    showLeaseGrid: showLease,
    leaseMultipleGridPayments: true,
    showLeaseGridMoneyFactor: true,
    showResidual: true,
    showLeaseDetails: showLease,
    showLoanDetails: showLoan,
    showCreditScore: true,
    showLenderName: true,
    showAddons: true,
    showIncentives: true,
    showTradeIn: true,
    showVehiclePriceOrMsrp: true,
    showAcquisitionFee: true,
    showDealerFees: true,
    showNonTaxTtlFees: true,
    showSalesTax: true,
  };
}
