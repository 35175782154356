import React from 'react';

import { AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { useAppInsight } from 'hooks/useAppInsight';
import { useResetErrorState } from 'hooks/useResetErrorState';

interface HookResult {
  reactPlugin: ReactPlugin;
  ref: React.RefObject<AppInsightsErrorBoundary>;
}

interface HookOptions {
  enableNavigation?: boolean;
}

export const useGlobalErrorAppInsightsErrorBoundary = ({
  enableNavigation,
}: HookOptions): HookResult => {
  const { reactPlugin } = useAppInsight();
  const ref = React.createRef<AppInsightsErrorBoundary>();

  useResetErrorState({ ref, enableNavigation });

  return {
    reactPlugin,
    ref,
  };
};
