import { useFormContext } from 'react-hook-form';

import { usePersistActualValue } from 'components/pdp/form/usePersistActualValue';
import {
  getTradeProfit,
  getTradeValue,
} from 'components/pdp/payment-information-box/deal-defaults/DealDefaultsService';

import { TradeOptionsModalFormValues } from './tradeOptionsModalService';

interface HookResult {
  tradeProfit: number;
  tradeValue: number;
}

export const useTradeOptionsForm = (): HookResult => {
  const { watch } = useFormContext<TradeOptionsModalFormValues>();

  const { tradeInValue, tradeInOutstandingBal, actualCashValue } = watch();
  const tradeProfit = getTradeProfit({
    actualCashValue: actualCashValue ?? 0,
    tradeAllowance: tradeInValue ?? 0,
  });

  const tradeValue = getTradeValue({
    tradeAllowance: tradeInValue ?? 0,
    tradePayoff: tradeInOutstandingBal ?? 0,
  });

  usePersistActualValue();

  return {
    tradeProfit,
    tradeValue,
  };
};
