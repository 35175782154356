import { useContext } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import {
  getNextSkipPageSearchParams,
  getSearchPayload,
  SearchOptionsPayload,
} from 'components/search/form-wrapper/SearchFormWrapperService';
import {
  CalculationOptions,
  SearchFormValues,
  SearchResult,
} from 'components/search/form-wrapper/useSearchFormWrapper';

import { AxiosContext } from 'providers/AxiosProvider';

import { sendTagData } from 'services/GoogleAnalyticsService';
import { getSearchApiUrl } from 'services/SearchApiService';

import { isEmptyOrValidZip } from 'utils/validationUtils';

import { useConfiguration } from './useConfiguration';

interface HookOptions {
  values?: SearchFormValues;
  enabled: boolean;
}

export const useSearchInfiniteQuery = ({
  values,
  enabled,
}: HookOptions): UseInfiniteQueryResult<SearchResult, unknown> => {
  const axiosClient = useContext(AxiosContext);
  const {
    searchOptions: { defaultMaxCount },
  } = useConfiguration();
  const searchValues = values
    ? getSearchPayload({ ...values, maxCount: defaultMaxCount })
    : undefined;

  const searchQuery = useInfiniteQuery(
    ['searchQuery', searchValues],
    async ({ signal, pageParam }) => {
      const skip = pageParam ?? 0;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const payload: SearchOptionsPayload = { ...searchValues!, skip };

      const { data } = await axiosClient.post<SearchResult>(getSearchApiUrl(), payload, {
        signal,
      });

      sendTagData('search', {
        skip,
        by_number:
          payload.vinOrStockNumber !== null &&
          payload.vinOrStockNumber !== undefined &&
          payload.vinOrStockNumber.length > 0,
      });

      return data;
    },
    {
      enabled: enabled && !!searchValues && shouldSearch(searchValues.calculationOptions),
      getNextPageParam: (page, allPages) => getNextSkipPageSearchParams(page, allPages),
    }
  );

  return searchQuery;
};

function shouldSearch(calculationOptions: CalculationOptions): boolean {
  return isEmptyOrValidZip(calculationOptions.location?.zipCode);
}
