import React, { useContext } from 'react';

import { TriggeredModal } from 'components/molecules/modal';

import { PdpContext } from 'providers/PdpContextProvider';

import { Addon } from 'services/PdpApiService';

import { AddOnsForm } from './AddOnsForm';
import { useAddOnsModal } from './useAddOnsModal';

interface AddOnsModalProps {
  addons: Addon[];
  trigger: React.ReactElement;
}

export const AddOnsModal: React.FC<AddOnsModalProps> = ({ addons, trigger }) => {
  const { addonsForm, customAddons, showPayPackagesUpfrontSwitch, handleOnSubmit } = useAddOnsModal(
    { addons }
  );
  const { shouldBeReadOnly } = useContext(PdpContext);

  return (
    <TriggeredModal
      title="Service and protection products"
      trigger={trigger}
      displayCloseButton={false}
      onConfirm={handleOnSubmit}
      content={
        <AddOnsForm
          showPayPackagesUpfrontSwitch={showPayPackagesUpfrontSwitch}
          formMethods={addonsForm}
          isReadOnly={shouldBeReadOnly}
          addons={addons}
          customAddons={customAddons}
        ></AddOnsForm>
      }
    />
  );
};
