import React from 'react';

import { MultiSelectFormField } from 'components/molecules/form-fields';

import { formatTermResultString } from './termFormFieldService';
import { useLoanTermFormField } from './useLoanTermFormField';

interface Props {
  isDisabled: boolean;
  gridViewEnabled: boolean;
}

// NOTE React won't rerender the Fields, rerendering is based on React key attribute: https://react-hook-form.com/faqs#Whyisdefaultvaluenotchangingcorrectlywithternaryoperator
export const LoanTerm: React.FC<Props> = ({ isDisabled }) => {
  const { options, current } = useLoanTermFormField();

  return (
    <MultiSelectFormField
      key="programFilters.loanTerms"
      name="programFilters.loanTerms"
      label="LENGTH OF LOAN"
      dataCyPrefix="pdpPage_paymentCustomization_loan_term"
      options={options}
      value={current}
      fullWidth
      disabled={isDisabled}
      resultStringFormatter={formatTermResultString}
    />
  );
};
