import { SalesTaxDetailItem } from './payment-grid/PaymentGridApiModels.generated';

interface RollUpFee {
  feeName: string;
  feeAmount: number;
}

export interface TtlNote {
  text: string;
  sourceType: string;
}

export enum Precision {
  Low = 0,
  High = 1,
}

interface TtlFee {
  taxRate: number;
  taxFee: number;
  baseTaxFee: number;
  rollupFees: RollUpFee[];
  notes: TtlNote[];
  precision: Precision;
  details: SalesTaxDetailItem[];
}

export interface LoanTtlFee extends TtlFee {}

export interface LeaseTtlFee extends TtlFee {
  monthlySaleTaxFee?: number;
  requireMonthlyTax?: boolean;
}
