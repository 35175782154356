import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { AxiosContext } from 'providers/AxiosProvider';

import { CustomAddonsAtom } from 'models/CustomAddonsModel';
import {
  downPaymentCalculationTypeAtom,
  monthlyPaymentCalculationTypeAtom,
} from 'models/ExactPaymentModel';

import {
  PaymentGridRequest,
  PaymentGridResult,
  TtlPrecision,
} from 'services/payment-grid/PaymentGridApiModels.generated';
import {
  createPaymentGridQuery,
  getPaymentGridApiUrl,
} from 'services/payment-grid/PaymentGridApiRequestService';
import { LeaseProgram, LoanProgram } from 'services/PdpApiService';

import { usePdpData } from './usePdpData';

interface HookOptions {
  ttlPrecision: TtlPrecision;
  selectedLease: LeaseProgram | undefined;
  selectedLoan: LoanProgram | undefined;
}

interface HookResult {
  query: UseQueryResult<PaymentGridResult, unknown>;
  request: PaymentGridRequest;
}

export const usePaymentGridQuery = ({
  ttlPrecision,
  selectedLease,
  selectedLoan,
}: HookOptions): HookResult => {
  const { carId = '' } = useParams<{ carId: string }>();
  const pdpFormValues = useWatch({}) as PdpFormValues;
  const pdpData = usePdpData();
  const customAddons = useRecoilValue(CustomAddonsAtom);
  const axiosClient = useContext(AxiosContext);
  const downPaymentCalculationType = useRecoilValue(downPaymentCalculationTypeAtom);
  const paymentCalculationType = useRecoilValue(monthlyPaymentCalculationTypeAtom);

  const request: PaymentGridRequest = createPaymentGridQuery({
    carId,
    pdpFormValues,
    pdpData,
    customAddons,
    downPaymentCalculationType,
    paymentCalculationType,
    ttlPrecision,
    selectedLease,
    selectedLoan,
  });

  const query = useQuery(
    ['paymentGridQuery', request],
    async () => {
      const { data } = await axiosClient.post<PaymentGridResult>(
        getPaymentGridApiUrl(carId),
        request
      );

      return data;
    },
    {
      enabled: false,
    }
  );

  return { query, request };
};
