import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { Grid, Stack, Typography, styled } from '@mui/material';

import { CustomAddOnFormWrapper } from 'components/molecules/custom-add-on-form/form-wrapper';
import { CheckboxFormField, SwitchFormField } from 'components/molecules/form-fields';
import { useAddOnsForm } from 'components/pdp/payment-information-box/add-ons-modal/useAddOnsForm';

import { Addon } from 'services/PdpApiService';

import { AddOnsFormValues } from './AddOnsFormService';

interface AddOnsFormProps {
  isReadOnly: boolean;
  showPayPackagesUpfrontSwitch?: boolean;
  addons: Addon[];
  customAddons: string[];
  formMethods: UseFormReturn<AddOnsFormValues>;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
}));

export const AddOnsForm: React.FC<AddOnsFormProps> = ({
  isReadOnly,
  showPayPackagesUpfrontSwitch,
  addons,
  customAddons,
  formMethods,
}) => {
  const { leaseAddons, loanAddons, anyDealTypeAddons, customCheckboxItems } = useAddOnsForm({
    addons,
    customAddons,
    addonsForm: formMethods,
  });

  return (
    <FormProvider {...formMethods}>
      <Grid container spacing={2} data-cy="addonsForm">
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle2">Optional service and protection products</Typography>
          {leaseAddons.length > 0 && (
            <Stack spacing={1}>
              <StyledTypography variant="subtitle3">Leases only</StyledTypography>
              <CheckboxFormField
                name="leaseAddonIds"
                items={leaseAddons}
                selectorPrefix="pdpPage_paymentCustomization_addonsModal_lease"
                labelTypographyVariant="caption"
                checkBoxSize="small"
                disabled={isReadOnly}
              />
            </Stack>
          )}
          {loanAddons.length > 0 && (
            <Stack spacing={1}>
              <StyledTypography variant="subtitle3">Loans only</StyledTypography>
              <CheckboxFormField
                name="loanAddonIds"
                items={loanAddons}
                selectorPrefix="pdpPage_paymentCustomization_addonsModal_loans"
                labelTypographyVariant="caption"
                checkBoxSize="small"
                disabled={isReadOnly}
              />
            </Stack>
          )}
          {anyDealTypeAddons.length > 0 && (
            <Stack spacing={1}>
              <StyledTypography variant="subtitle3">Leases or loans</StyledTypography>
              <CheckboxFormField
                name="anyDealTypeAddonIds"
                items={anyDealTypeAddons}
                selectorPrefix="pdpPage_paymentCustomization_addonsModal_any"
                labelTypographyVariant="caption"
                checkBoxSize="small"
                disabled={isReadOnly}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack alignItems="flex-end">
            {showPayPackagesUpfrontSwitch && (
              <SwitchFormField
                name="payAllPackagesUpfront"
                dataCyPrefix="pdpPage_paymentCustomization_addOnsModal"
                label="Pay upfront"
                disabled={isReadOnly}
              />
            )}
          </Stack>
        </Grid>
        {!!customCheckboxItems && (
          <Grid item xs={12}>
            <Stack spacing={2}>
              <StyledTypography variant="subtitle3" data-cy="addOnsModal_customAddOns">
                Custom add-ons
              </StyledTypography>
              <CheckboxFormField
                name="customAddonIds"
                items={customCheckboxItems}
                selectorPrefix="pdpPage_paymentCustomization_addonsModal"
                labelTypographyVariant="caption"
                checkBoxSize="small"
                disabled={isReadOnly}
              />
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack spacing={2}>
            <StyledTypography
              variant="subtitle3"
              data-cy="pdpPage_paymentCustomization_addOnsModal_customAddOnsTitle"
            >
              Add custom add-on
            </StyledTypography>
            <CustomAddOnFormWrapper />
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
