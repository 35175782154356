import { CarState, ProgramAvailability } from './SearchApiService';

export interface Filter<T> {
  code: T;
  name: string;
  count: number;
}

export interface ColorFilter extends Filter<number> {
  rgb: string;
}

export interface FeatureFilter {
  type: string;
  features: Filter<number>[];
}

export interface SearchFilters {
  canFilterDealers: boolean;
  dealers: Filter<number>[];
  programAvailability: Filter<ProgramAvailability>[];
  exteriorColors: ColorFilter[];
  featureTypes: FeatureFilter[];
  interiorColors: ColorFilter[];
  makes: Filter<string>[];
  models: Filter<string>[];
  carState: Filter<CarState>[];
  trims: Filter<string>[];
  bodyStyles: Filter<string>[];
  fuelTypes: Filter<string>[];
}

export function getSearchFiltersApiUrl(): string {
  return '/Search/Filters';
}
