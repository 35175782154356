import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { Button, CircularProgress, Divider, Grid, Stack, styled } from '@mui/material';

import { Layout } from 'components/molecules/layout';
import { ResponderVehicleBlock } from 'components/responder';
import { EmailEditorWrapper } from 'components/responder/email-editor-wrapper';
import { PhoneEditorWrapper } from 'components/responder/phone-editor-wrapper';

import { useResponderPage } from './useResponderPage';

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const BackButtonLink = styled(Link)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  textDecoration: 'none',
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  height: '100%',
}));

export const ResponderPage: React.FC = () => {
  const {
    dealOfferQuery: { data, isLoading },
    backNavigationLink,
  } = useResponderPage();
  let element = null;

  if (isLoading) {
    element = (
      <Grid item xs={12} textAlign="center">
        <CircularProgress data-cy="responderPage_spinner" size={50} />
      </Grid>
    );
  } else if (data) {
    const {
      vehicle,
      paymentCustomization: { calculationLeaseOutputData, calculationLoanOutputData, vehiclePrice },
    } = data;
    const { payment: leaseMonthlyPayment } = calculationLeaseOutputData ?? {};
    const { payment: loanMonthlyPayment } = calculationLoanOutputData ?? {};
    element = (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <ResponderVehicleBlock
            calculationLeaseOutputData={calculationLeaseOutputData}
            calculationLoanOutputData={calculationLoanOutputData}
            vehicle={vehicle}
            modifiedVehiclePrice={vehiclePrice}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <EmailEditorWrapper />
        </Grid>
        <Grid item xs={12} lg={3}>
          <PhoneEditorWrapper
            leaseMonthlyPayment={leaseMonthlyPayment}
            loanMonthlyPayment={loanMonthlyPayment}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Layout>
      <StyledStack direction="column">
        <Stack direction="row" spacing={4}>
          <BackButtonLink to={backNavigationLink}>
            <Button size="small" data-cy="responderPage_returnToDealButton">
              <ArrowBack fontSize="small" />
              Return to Deal
            </Button>
          </BackButtonLink>
        </Stack>
        <StyledDivider />
        {element}
      </StyledStack>
    </Layout>
  );
};
