import _ from 'lodash';

import { Lease, Loan } from './DealOfferApiService';
import { IncentiveRule, ProgramIncentive } from './payment-grid/PaymentGridApiModels.generated';
import { LeaseProgram, LoanProgram, ProgramRule, ProgramType, Rebate } from './PdpApiService';

// ToDo: move everything over to IncentivesService and rename over time.
export enum RebateApplicability {
  NotApplicable = 0,
  ApplicableToSelectedLease = 1,
  ApplicableToSelectedLoan = 2,
  ApplicableToSelectedLeaseAndLoan = 3,
  AppliedToSelectedLease = 4,
  AppliedToSelectedLoan = 5,
  AppliedToSelectedLeaseAndLoan = 9,
}

export interface ExtendedRebate extends Rebate {
  firstNotCompatibleRebate?: Rebate;
  currentProgramApplicability: RebateApplicability;
}

interface GetInitialFormRebateIdsOptions {
  leasePrograms: LeaseProgram[];
  loanPrograms: LoanProgram[];
  lenderRebates: Rebate[];
  leaseTerm: number | undefined;
  loanTerm: number | undefined;
}

export function getInitialFormRebateIds({
  lenderRebates,
}: GetInitialFormRebateIdsOptions): number[] {
  return lenderRebates.filter((x) => x.isGuaranteed).map((x) => x.programId);
}

export function getRebateAmount(rebate: Rebate, term: number | undefined): number {
  return rebate.rebateTerms?.find((rt) => rt.month === term)?.value ?? rebate.cash ?? 0;
}

export interface RebatesByProgramTypeResponse {
  leaseRebates: ExtendedRebate[];
  loanRebates: ExtendedRebate[];
  loanAndLeaseRebates: ExtendedRebate[];
}

export interface RebatesByProgramTypeRequest {
  rebates: Rebate[];
  leaseProgramId: number | undefined;
  loanProgramId: number | undefined;
  appliedLeaseRebates: number[];
  appliedLoanRebates: number[];
}

export function getRebatesByProgramType(
  request: RebatesByProgramTypeRequest
): RebatesByProgramTypeResponse {
  const extendedRebates: ExtendedRebate[] = request.rebates.map((rebate) => ({
    ...rebate,
    currentProgramApplicability: getRebateApplicability({
      ...request,
      rebate,
    }),
  }));

  const leaseRebates = extendedRebates.filter(
    (x) => x.programTypes.length === 1 && x.programTypes[0] === ProgramType.Lease
  );

  const loanRebates = extendedRebates.filter(
    (x) => x.programTypes.length === 1 && x.programTypes[0] === ProgramType.Loan
  );

  const loanAndLeaseRebates = extendedRebates.filter((x) => x.programTypes.length === 2);

  return {
    leaseRebates,
    loanRebates,
    loanAndLeaseRebates,
  };
}

interface RebateApplicabilityRequest {
  rebate: Rebate;
  leaseProgramId: number | undefined;
  loanProgramId: number | undefined;
  appliedLeaseRebates: number[];
  appliedLoanRebates: number[];
}

function getRebateApplicability(request: RebateApplicabilityRequest): RebateApplicability {
  const appliedResult = getRebateAppliedApplicability(request);

  if (appliedResult !== RebateApplicability.NotApplicable) {
    return appliedResult;
  }

  const notAppliedResult = getRebateNotAppliedApplicability(request);

  return notAppliedResult;
}

function getRebateAppliedApplicability({
  rebate,
  appliedLeaseRebates,
  appliedLoanRebates,
}: RebateApplicabilityRequest): RebateApplicability {
  const isAppliedToLease = appliedLeaseRebates.includes(rebate.programId);
  const isAppliedToLoan = appliedLoanRebates.includes(rebate.programId);

  if (isAppliedToLease && isAppliedToLoan) {
    return RebateApplicability.AppliedToSelectedLeaseAndLoan;
  }

  if (isAppliedToLease) {
    return RebateApplicability.AppliedToSelectedLease;
  }

  if (isAppliedToLoan) {
    return RebateApplicability.AppliedToSelectedLoan;
  }

  return RebateApplicability.NotApplicable;
}

function getRebateNotAppliedApplicability({
  rebate,
  leaseProgramId,
  loanProgramId,
}: RebateApplicabilityRequest): RebateApplicability {
  const isApplicableToLease =
    leaseProgramId && rebate.availableWithPrograms.includes(leaseProgramId);

  const isApplicableToLoan = loanProgramId && rebate.availableWithPrograms.includes(loanProgramId);

  if (isApplicableToLease && isApplicableToLoan) {
    return RebateApplicability.ApplicableToSelectedLeaseAndLoan;
  }

  if (isApplicableToLease) {
    return RebateApplicability.ApplicableToSelectedLease;
  }

  if (isApplicableToLoan) {
    return RebateApplicability.ApplicableToSelectedLoan;
  }

  return RebateApplicability.NotApplicable;
}

export function mapRebates(
  selectedLease: Lease | undefined,
  selectedLoan: Loan | undefined
): Rebate[] {
  const leaseIncentives = selectedLease?.incentives.programIncentives ?? [];
  const loanIncentives = selectedLoan?.incentives.programIncentives ?? [];
  const loanAndLeaseIncentives = _.intersectionBy(leaseIncentives, loanIncentives, (x) => x.id);
  const leaseOnlyIncentives = _.differenceBy(leaseIncentives, loanIncentives, (x) => x.id);
  const loanOnlyIncentives = _.differenceBy(loanIncentives, leaseIncentives, (x) => x.id);

  const loanAndLeaseRebates: Rebate[] = loanAndLeaseIncentives.map((x) =>
    mapRebate(x, [ProgramType.Lease, ProgramType.Loan])
  );

  const leaseRebates: Rebate[] = leaseOnlyIncentives.map((x) => mapRebate(x, [ProgramType.Lease]));
  const loanRebates: Rebate[] = loanOnlyIncentives.map((x) => mapRebate(x, [ProgramType.Loan]));

  const rebates = [...loanAndLeaseRebates, ...leaseRebates, ...loanRebates];

  return rebates;
}

function mapRebate(incentive: ProgramIncentive, programTypes: ProgramType[]): Rebate {
  return {
    availableWithIncentives: [],
    availableWithPrograms: [],
    cash: incentive.value,
    description: incentive.name,
    programNumber: incentive.programNumber,
    expiryDate: incentive.expirationDate.toString(),
    groupAffiliation: incentive.groupAffiliation ?? '',
    groupAffiliationDetail: incentive.groupAffiliationDetail ?? '',
    isGuaranteed: incentive.isGuaranteed,
    lenderNames: [],
    programId: incentive.id,
    programRules: incentive.rules.map((x) => mapRule(x)),
    programTypes,
    providerId: 0,
    rebateTerms: [],
  };
}

function mapRule(rule: IncentiveRule): ProgramRule {
  return { type: rule.type, description: rule.description };
}
