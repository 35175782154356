import React from 'react';

import { Stack, Typography } from '@mui/material';

import { CarStatus } from 'services/SearchApiService';

interface Props {
  stockNumber: string;
  vin: string;
  location: string;
  daysInStock: number;
  status: CarStatus;
  dataCyPrefix: string;
}

export const VehicleFooter: React.FC<Props> = ({
  daysInStock,
  location,
  stockNumber,
  vin,
  status,
  dataCyPrefix,
}) => (
  <Stack>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography lineHeight="0.5rem">
        <Typography fontWeight={700} variant="body4" data-cy={`${dataCyPrefix}_stockNumber`}>
          Stock&nbsp;
        </Typography>
        <Typography variant="body4" data-cy={`${dataCyPrefix}_stockNumberValue`}>
          {(status !== CarStatus.Virtual && stockNumber) || '-'}
        </Typography>
      </Typography>
      <Typography lineHeight="0.5rem">
        <Typography data-cy={`${dataCyPrefix}_vin`} fontWeight={700} variant="body4">
          VIN&nbsp;
        </Typography>
        <Typography data-cy={`${dataCyPrefix}_vinValue`} variant="body4">
          {vin}
        </Typography>
      </Typography>
    </Stack>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography lineHeight="0.5rem">
        <Typography data-cy={`${dataCyPrefix}_location`} fontWeight={700} variant="body4">
          Location&nbsp;
        </Typography>
        <Typography data-cy={`${dataCyPrefix}_locationValue`} variant="body4">
          {(status !== CarStatus.Virtual && location) || '-'}
        </Typography>
      </Typography>
      <Typography lineHeight="0.5rem">
        <Typography data-cy={`${dataCyPrefix}_daysOnLot`} fontWeight={700} variant="body4">
          Days on lot&nbsp;
        </Typography>
        <Typography data-cy={`${dataCyPrefix}_daysOnLotValue`} variant="body4">
          {(status !== CarStatus.Virtual && daysInStock) || '-'}
        </Typography>
      </Typography>
    </Stack>
  </Stack>
);
