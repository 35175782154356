import _, { differenceBy } from 'lodash';

import {
  getLeaseProgramMiles,
  getLeaseProgramMilesFromProgram,
  getProgramTerms,
  sortUniqueValues,
} from 'components/pdp/form/PdpFormService';
import { ProgramGrid } from 'components/pdp/grid-view/table/GridTableService';

import { CustomIncentive, CustomIncentiveAnyLender } from 'models/CustomIncentivesModel';

import { getDefaultAddons } from 'services/AddonsService';
import { Customer } from 'services/CustomerService';
import { PaymentCustomization } from 'services/DealOfferApiService';
import {
  Addon,
  ProgramVariations,
  DealerDefaults,
  Fee,
  Lease,
  LeaseProgram,
  Loan,
  LoanProgram,
  Rebate,
  TaxCreditTo,
} from 'services/PdpApiService';
import { getInitialFormRebateIds } from 'services/RebatesService';
import { ReserveType } from 'services/ReserveService';
import { LocationWithZip } from 'services/ZipCodeApiService';

import { distinct } from 'utils/arrayUtils';

import { PdpFormValues, PdpState, PriceType } from './usePdpFormWrapper';

export interface DefaultValuesOptions {
  maxOutOfPocket: number;
  payAllPackagesUpfront: boolean;
  payFirstLeasePaymentUpfront: boolean;
  downPayment: number;
  creditScore: number;
  tradeInValue: number;
  tradeInOutstandingBal: number;
  actualCashValue: number | undefined;
  nonTtlFees: Fee[];
  loan: Loan;
  lease: Lease;
  customer?: Customer;
  captive: boolean;
  lenderRebates: Rebate[];
  location?: LocationWithZip;
  msrp: number;
  vehiclePrice: number;
  vehicleInvoice: number;
  loanPrograms: LoanProgram[];
  loanProgramGrids: ProgramGrid[];
  leasePrograms: LeaseProgram[];
  leaseProgramGrids: ProgramGrid[];
  addons: Addon[];
  exactMonthlyPayment?: number;
  dealerDefaults: DealerDefaults | undefined;
  pdpState: PdpState | undefined;
  selectedLease?: LeaseProgram;
  selectedLoan?: LoanProgram;
  programVariations: ProgramVariations | undefined;
  selectedLeaseMileages: number[] | undefined;
  selectedLeaseTerms: number[] | undefined;
  selectedLoanTerms: number[] | undefined;
}

export function getDefaultValues({
  creditScore,
  nonTtlFees,
  downPayment,
  tradeInOutstandingBal,
  tradeInValue,
  actualCashValue,
  lease,
  loan,
  maxOutOfPocket,
  payAllPackagesUpfront,
  payFirstLeasePaymentUpfront,
  customer,
  captive,
  lenderRebates,
  location,
  msrp,
  vehiclePrice,
  vehicleInvoice,
  leasePrograms,
  loanPrograms,
  exactMonthlyPayment,
  addons,
  dealerDefaults,
  pdpState,
  programVariations,
  selectedLeaseMileages,
  selectedLeaseTerms,
  selectedLoanTerms,
}: DefaultValuesOptions): PdpFormValues {
  let loanTerms = programVariations?.loanTerms
    ? sortUniqueValues(programVariations.loanTerms)
    : getProgramTerms(loan.programs);

  let miles = programVariations?.leaseMileages
    ? getLeaseProgramMiles(programVariations.leaseMileages)
    : getLeaseProgramMilesFromProgram(lease.programs);

  let leaseTerms = programVariations?.leaseTerms
    ? sortUniqueValues(programVariations.leaseTerms)
    : getProgramTerms(lease.programs);

  const leaseTerm = Math.max(...leaseTerms);

  const initialRebates = getInitialFormRebateIds({
    lenderRebates,
    leasePrograms,
    loanPrograms,
    loanTerm: undefined,
    leaseTerm,
  });

  let matchedLoanTerms: number[] = [];
  let matchedLeaseTerms: number[] = [];
  let matchedLeaseMileages: number[] = [];

  if (selectedLoanTerms) {
    matchedLoanTerms =
      selectedLoanTerms.length === 0 ? loanTerms : _.intersection(selectedLoanTerms, loanTerms);

    // If none of the selected terms are available, add the selected terms to the list of filters to make it clear to the user why the filter returned no results.
    if (matchedLoanTerms.length === 0) {
      loanTerms = distinct([...loanTerms, ...(selectedLoanTerms ?? [])]).sort((a, b) => a - b);
      matchedLoanTerms = selectedLoanTerms ?? [];
    }
  }

  if (selectedLeaseMileages && selectedLeaseTerms) {
    const milesValues = miles.map((mileOption) => mileOption.value);

    matchedLeaseMileages =
      selectedLeaseMileages.length === 0
        ? milesValues
        : _.intersection(selectedLeaseMileages, milesValues);

    matchedLeaseTerms =
      selectedLeaseTerms.length === 0 ? leaseTerms : _.intersection(selectedLeaseTerms, leaseTerms);

    // If none of the selected terms or mileages are available, add the selected terms / mileages to the list of filters to make it clear to the user why the filter returned no results.
    if (matchedLeaseTerms.length === 0 || matchedLeaseMileages.length === 0) {
      const updatedMileages = distinct([...milesValues, ...selectedLeaseMileages]).sort(
        (a, b) => a - b
      );

      miles = getLeaseProgramMiles(updatedMileages);
      leaseTerms = distinct([...leaseTerms, ...selectedLeaseTerms]).sort((a, b) => a - b);
      matchedLeaseTerms = selectedLeaseTerms;
      matchedLeaseMileages = selectedLeaseMileages;
    }
  }

  const useSelectedFilters = leasePrograms.length === 0 && loanPrograms.length === 0;

  const programFilters = useSelectedFilters
    ? {
        leaseTerms: selectedLeaseTerms ?? [],
        loanTerms: selectedLoanTerms ?? [],
        mileages: selectedLeaseMileages ?? [],
      }
    : {
        leaseTerms: matchedLeaseTerms,
        loanTerms: matchedLoanTerms,
        mileages: matchedLeaseMileages,
      };

  const defaultAddons = getDefaultAddons(addons);

  return {
    payAllFeesUpfront: dealerDefaults?.payAllFeesUpFront ?? true,
    payFirstLeasePaymentUpfront,
    payAllPackagesUpfront,
    maxOutOfPocket,
    downPayment: pdpState?.downPayment ?? downPayment,
    creditScore,
    tradeInValue,
    tradeInOutstandingBal,
    nonTtlFees,
    leaseProgram: pdpState?.leaseProgramId
      ? leasePrograms.find((x) => x.id === pdpState.leaseProgramId)
      : undefined,
    loanProgram: pdpState?.loanProgramId
      ? loanPrograms.find((x) => x.id === pdpState.loanProgramId)
      : undefined,
    rebates: pdpState?.rebates ?? initialRebates,
    customIncentiveNames: pdpState?.customIncentiveNames ?? [],
    appliedCustomIncentives: pdpState?.appliedCustomIncentives ?? [],
    leaseTerms,
    loanTerms,
    miles,
    loanAprMarkupPercent: pdpState?.loanAprMarkupPercent,
    leaseMoneyFactorMarkup: pdpState?.leaseMoneyFactorMarkup,
    leaseDealerSplitPercent: undefined,
    loanDealerSplitPercent: undefined,
    buyer: customer
      ? {
          id: customer.id,
          email: customer.email ?? '',
          name: customer.name,
          phone: customer.phone ?? '',
          location,
        }
      : { email: '', name: '', phone: '', id: 0, location },
    captive: pdpState?.captive ?? captive,
    vehiclePrice: pdpState?.vehiclePrice ?? vehiclePrice,
    vehicleInvoice: pdpState?.vehicleInvoice ?? vehicleInvoice,
    addons: pdpState?.addons ?? defaultAddons,
    leaseRebates: [],
    loanAndLeaseRebates: [],
    loanRebates: [],
    customAddons: pdpState?.customAddons ?? [],
    actualCashValue: actualCashValue ?? tradeInValue,
    loanTaxCreditTo:
      pdpState?.loanTaxCreditTo ?? dealerDefaults?.loanTaxCreditTo ?? TaxCreditTo.TradeEquity,
    leaseTaxCreditTo:
      pdpState?.leaseTaxCreditTo ?? dealerDefaults?.leaseTaxCreditTo ?? TaxCreditTo.TradeEquity,
    leaseMarkedUpAcquisitionFee: pdpState?.leaseMarkedUpAcquisitionFee,
    programFilters: pdpState?.programFilters ?? programFilters,
    msrp: pdpState?.msrp ?? msrp,
    priceType: PriceType.FIXED,
    monthlyExactPayment: pdpState?.monthlyExactPayment ?? exactMonthlyPayment,
    tradeVehicle: pdpState?.tradeVehicle,
    loanReserveType: ReserveType.Auto,
    leaseReserveType: ReserveType.Auto,
  };
}

export interface DefaultValuesOfferOptions {
  nonTtlFees: Fee[];
  dealOfferValues: PaymentCustomization;
}

export function getDefaultValuesForDealOffer({
  nonTtlFees,
  dealOfferValues,
}: DefaultValuesOfferOptions): PdpFormValues {
  const {
    buyer,
    loanProgram,
    leaseProgram,
    payAllFeesUpfront,
    payAllPackagesUpfront,
    payFirstLeasePaymentUpfront,
    tradeInOutstandingBal,
    tradeInValue,
    actualCashValue,
    maxOutOfPocket,
    downPayment,
    creditScore,
    rebates,
    captive,
    vehiclePrice = dealOfferValues?.vehiclePrice ?? 0,
    vehicleInvoice = dealOfferValues?.vehicleInvoice ?? 0,
    addons,
    priceType,
    msrp,
    leaseTaxCreditTo,
    loanTaxCreditTo,
    leaseMarkedUpAcquisitionFee,
    monthlyExactPayment,
    tradeVehicle,
    leaseReserveType,
    loanReserveType,
    leaseDealerSplitPercent,
    loanDealerSplitPercent,
    calculationLeaseOutputData,
    calculationLoanOutputData,
  } = dealOfferValues;

  const { term: loanTerm = 0 } = loanProgram ?? {};
  const { millage: leaseMillage = 0, term: leaseTerm = 0 } = leaseProgram ?? {};
  const loanPrograms = loanProgram ? [loanProgram] : [];
  const leasePrograms = leaseProgram ? [leaseProgram] : [];
  const loanTerms = getProgramTerms(loanPrograms);
  const leaseTerms = getProgramTerms(leasePrograms);
  const miles = getLeaseProgramMilesFromProgram(leasePrograms);

  const applicableCustomIncentives = dealOfferValues?.appliedCustomIncentives?.filter(
    (aci) =>
      aci.incentiveLenderName === leaseProgram?.lenderName ||
      aci.incentiveLenderName === loanProgram?.lenderName ||
      aci.incentiveLenderName === CustomIncentiveAnyLender
  );

  // ZipFormField requires location to be not null
  const buyerLocation: LocationWithZip = buyer?.location ?? ({ zipCode: '' } as LocationWithZip);

  return {
    calculationLeaseOutputData,
    calculationLoanOutputData,
    payAllFeesUpfront,
    payFirstLeasePaymentUpfront,
    payAllPackagesUpfront,
    maxOutOfPocket,
    downPayment,
    creditScore,
    tradeInValue,
    tradeInOutstandingBal,
    nonTtlFees,
    leaseProgram,
    loanProgram,
    rebates,
    customIncentiveNames: applicableCustomIncentives?.map((aci) => aci.name) ?? [],
    appliedCustomIncentives: dealOfferValues?.appliedCustomIncentives ?? [],
    leaseTerms,
    loanTerms,
    miles,
    loanAprMarkupPercent: dealOfferValues?.loanAprMarkupPercent,
    leaseMoneyFactorMarkup: dealOfferValues?.leaseMoneyFactorMarkup,
    leaseReserveType: leaseReserveType ?? ReserveType.Auto,
    loanReserveType: loanReserveType ?? ReserveType.Auto,
    leaseDealerSplitPercent,
    loanDealerSplitPercent,
    buyer: {
      ...buyer,
      location: buyerLocation,
    },
    captive,
    vehiclePrice,
    vehicleInvoice,
    addons,
    leaseRebates: [],
    loanAndLeaseRebates: [],
    loanRebates: [],
    customAddons: (dealOfferValues?.customAddons ?? []).map((ca) => ca.name),
    actualCashValue,
    loanTaxCreditTo: leaseTaxCreditTo ?? TaxCreditTo.TradeEquity,
    leaseTaxCreditTo: loanTaxCreditTo ?? TaxCreditTo.TradeEquity,
    leaseMarkedUpAcquisitionFee,
    programFilters: {
      leaseTerms: [leaseTerm],
      loanTerms: [loanTerm],
      mileages: [leaseMillage],
    },
    priceType: priceType ?? PriceType.FIXED,
    msrp,
    monthlyExactPayment,
    tradeVehicle,
  };
}

export const updateCustomIncentives = (
  allCurrentCustomIncentives: CustomIncentive[],
  appliedCustomIncentives: CustomIncentive[]
): CustomIncentive[] => {
  const updatedCustomIncentives = allCurrentCustomIncentives.map(
    updateExistingCustomIncentives(appliedCustomIncentives)
  );

  const newIncentives = differenceBy(
    appliedCustomIncentives,
    updatedCustomIncentives,
    (x) => x.name
  );

  return updatedCustomIncentives.concat(newIncentives);
};

export const updateCustomAddons = (
  allCurrentCustomAddons: Addon[],
  appliedCustomAddons: Addon[]
): Addon[] => {
  const updatedCustomAddons = allCurrentCustomAddons.map(
    updateExistingCustomAddons(appliedCustomAddons)
  );

  const newAddons = differenceBy(appliedCustomAddons, updatedCustomAddons, (x) => x.name);

  return updatedCustomAddons.concat(newAddons);
};

function updateExistingCustomIncentives(
  appliedCustomIncentives: CustomIncentive[]
): (value: CustomIncentive, index: number, array: CustomIncentive[]) => CustomIncentive {
  return (existingCustomIncentive) => {
    const matchingIncentive = appliedCustomIncentives.filter(
      (aci) => aci.name === existingCustomIncentive.name
    );
    if (matchingIncentive.length > 0) {
      return matchingIncentive[0];
    }

    return existingCustomIncentive;
  };
}

function updateExistingCustomAddons(
  appliedCustomAddons: Addon[]
): (value: Addon, index: number, array: Addon[]) => Addon {
  return (existingCustomIncentive) => {
    const matchingIncentive = appliedCustomAddons.filter(
      (aci) => aci.name === existingCustomIncentive.name
    );
    if (matchingIncentive.length > 0) {
      return matchingIncentive[0];
    }

    return existingCustomIncentive;
  };
}
