import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { Modal } from 'components/molecules/modal';

export interface InvalidCreditScoreReadOnlyDealModalProps {
  customerCreditTierName: string;
  dealCreditTierName: string;
  isOpen: boolean;
  confirm: () => void;
  onImplicitClose: () => void;
}

export const InvalidCreditScoreReadOnlyDealModal: FC<InvalidCreditScoreReadOnlyDealModalProps> = ({
  customerCreditTierName,
  dealCreditTierName,
  isOpen,
  confirm,
  onImplicitClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      confirm={confirm}
      onImplicitClose={onImplicitClose}
      title="Credit Score Invalid"
      content={
        <Stack>
          <Stack spacing={2}>
            <Stack>
              <Typography variant="body1">
                Verified customer credit score does not match saved deal offer credit score.
              </Typography>
              <Typography variant="body1">
                Build new deal to use verified customer credit score.
              </Typography>
            </Stack>
            <Stack>
              <Stack direction="row" spacing={2}>
                <Typography fontWeight={700} variant="body1">
                  Customer credit score
                </Typography>
                <Typography variant="body1">{customerCreditTierName}</Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography fontWeight={700} variant="body1">
                  Deal credit score
                </Typography>
                <Typography variant="body1">{dealCreditTierName}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      }
      confirmText="Build New Deal"
    />
  );
};
