import { RowSelectionState } from '@tanstack/react-table';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { MRT_ColumnDef as ColumnDef, MRT_Row as TableRow } from 'material-react-table';

import _ from 'lodash';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { ExtendedRebate } from 'services/RebatesService';

import {
  getSelectedRebates,
  getSelectedTableRows,
  getTableColumns,
} from './IncentivesGridTableService';

interface HookOptions {
  allRebates: ExtendedRebate[];
}

interface HookResult {
  tableColumns: ColumnDef<ExtendedRebate>[];
  tableData: ExtendedRebate[];
  tableContainerRef: React.RefObject<HTMLDivElement>;
  rowSelection: RowSelectionState;
  handleSelectCheckbox(row: TableRow<ExtendedRebate>): void;
  getRowId(programId?: number): string;
}

export const useIncentivesGridTable = ({ allRebates }: HookOptions): HookResult => {
  const { rebates: currentRebateItems, programFilters } = useWatch({}) as PdpFormValues;
  const tableColumns = useMemo<ColumnDef<ExtendedRebate>[]>(
    () =>
      getTableColumns({
        leaseTerms: programFilters?.leaseTerms ?? [],
        loanTerms: programFilters?.loanTerms ?? [],
      }),
    []
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableData = allRebates;
  const { setValue } = useFormContext<PdpFormValues>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    _.zipObject(currentRebateItems, _.fill(Array(currentRebateItems.length), true))
  );

  const handleSelectCheckbox = useCallback(
    ({ id: rowId }: TableRow<ExtendedRebate>) => {
      const rebatesSelected = getSelectedRebates(currentRebateItems, rowId);
      const rowsSelected = getSelectedTableRows(rebatesSelected);
      setValue('rebates', rebatesSelected);
      setRowSelection(rowsSelected);
    },
    [currentRebateItems]
  );

  const getRowId = useCallback((id?: number) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return `${id!}`;
  }, []);

  return {
    tableColumns,
    tableData,
    tableContainerRef,
    rowSelection,
    handleSelectCheckbox,
    getRowId,
  };
};
