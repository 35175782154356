import React from 'react';

import { Typography } from '@mui/material';

import { MRT_Cell as Cell, MRT_Row as Row } from 'material-react-table';

import { ProgramGrid } from 'components/pdp/grid-view/table/GridTableService';

import { ProgramType } from 'services/PdpApiService';

import { formatToNumber, formatToPercentage } from 'utils/formatterUtils';

interface Props {
  cell: Cell<ProgramGrid>;
  row: Row<ProgramGrid>;
}
export const RateTableCell: React.FC<Props> = ({ cell, row }) => {
  const { type } = row.original;

  return (
    <Typography variant="body3">
      {type === ProgramType.Lease
        ? formatToNumber(cell.getValue() as number, 5)
        : formatToPercentage(cell.getValue() as number, 2)}
    </Typography>
  );
};
