import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { ProfitData } from 'components/pdp/grid-view/table/GridTableService';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { pdpCollapsibleSectionExpandedLoanProfitAtom } from 'models/PdpCollapsibleSectionModel';
import { viewProfitAtom } from 'models/ViewProfitModel';

import {
  FeeValueType,
  ReserveProfitMethod,
} from 'services/payment-grid/PaymentGridApiModels.generated';

interface HookResult extends ProfitData {
  displayProfitDetails: boolean;
  expanded: boolean;
  totalProfit: number;
}

export const useLoanProfitDetails = (): HookResult => {
  const { isDeskingManager, isGeneralManager } = useContext(PermissionsContext);
  const viewProfitEnabled = useRecoilValue(viewProfitAtom);
  const displayProfitDetails = (isDeskingManager || isGeneralManager) && viewProfitEnabled;
  const expanded = useRecoilValue(pdpCollapsibleSectionExpandedLoanProfitAtom);
  const { loanProgram } = useWatch({}) as PdpFormValues;
  const profit = loanProgram?.profit;

  if (!profit) {
    return {
      displayProfitDetails,
      expanded,
      vehicleProfit: 0,
      tradeInProfit: 0,
      splitProfit: 0,
      totalProfit: 0,
      reserveSplit: 0,
      serviceAndProductionProducts: 0,
      appliedReserveMethod: ReserveProfitMethod.Flat,
      reserveTotal: 0,
      flatValue: 0,
      flatValueType: FeeValueType.Amount,
    };
  }

  return {
    displayProfitDetails,
    expanded,
    vehicleProfit: profit.vehicle,
    tradeInProfit: profit.tradeIn,
    splitProfit: profit.reserve.rateMarkup.splitRate,
    totalProfit: profit.total,
    reserveSplit: profit.reserve.rateMarkup.total,
    serviceAndProductionProducts: profit.addons,
    appliedReserveMethod: profit.reserve.appliedMethod,
    reserveTotal: profit.reserve.total,
    flatValue: profit.reserve.flat.value,
    flatValueType: profit.reserve.flat.valueType,
  };
};
