import { useCallback, useContext, useEffect } from 'react';
import { FieldErrors, useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useCustomerCreditPolling } from 'components/pdp/customer-block/useCustomerCreditPolling';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { useHandleExpiredProgramsOrIncentives } from 'components/pdp/form/useHandleExpiredProgramsOrIncentives';
import { useOpenResponder } from 'components/pdp/form/useOpenResponder';
import { useSaveDealOffer } from 'components/pdp/form/useSaveDealOffer';

import { PdpContext } from 'providers/PdpContextProvider';

import { useBuildNewDeal } from 'hooks/useBuildNewDeal';
import { useLogger } from 'hooks/useLogger';
import { usePushToCrmMutation } from 'hooks/usePushToCrmMutation';

import { dealOfferCodeAtom } from 'models/DealOfferModel';
import { isMonthlyPaymentExactTypeSelector } from 'models/ExactPaymentModel';

import { PdpItem } from 'services/PdpApiService';

import { usePersistActualValue } from './usePersistActualValue';
import { useSyncFormValues } from './useSyncFormValues';

interface HookOptions {
  isIncentivesExpired?: boolean;
  isLoanProgramExpired?: boolean;
  isLeaseProgramExpired?: boolean;
  pdpData: PdpItem;
}

interface HookResult {
  handleSaveDealOffer(): Promise<void>;
  handleOpenResponder(): Promise<void>;
  handlePushToCrm(): Promise<void>;
  isLoading: boolean;
  isExportPdfGridButtonDisabled: boolean;
}

export const usePdpForm = ({
  isIncentivesExpired,
  isLoanProgramExpired,
  isLeaseProgramExpired,
  pdpData,
}: HookOptions): HookResult => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<PdpFormValues>();
  const values = useWatch({ control }) as PdpFormValues;
  const logger = useLogger();
  const isMonthlyPaymentExact = useRecoilValue(isMonthlyPaymentExactTypeSelector);
  const dealOfferId = useRecoilValue(dealOfferCodeAtom);
  const [searchParams] = useSearchParams();
  const shouldBuildNewDeal = searchParams.get('buildNewDeal')?.toLocaleLowerCase() === 'true';
  const { handleNavigate: buildNewDeal } = useBuildNewDeal({ openNewTab: false });

  const { handleOpenResponder } = useOpenResponder({
    pdpData,
  });

  const { handleSaveDealOffer, isSaving } = useSaveDealOffer({ pdpData });

  useHandleExpiredProgramsOrIncentives({
    showExpiredProgramsOrIncentivesWarning:
      isIncentivesExpired || isLoanProgramExpired || isLeaseProgramExpired,
  });

  const { mutateAsync: pushToCrm } = usePushToCrmMutation(dealOfferId);
  const handlePushToCrm = useCallback(() => {
    return pushToCrm(dealOfferId);
  }, [dealOfferId]);

  useSyncFormValues({ values });
  usePersistActualValue();
  useCustomerCreditPolling({ dealerId: pdpData.dealer.dealerId });

  // NOTE: just for debugging purposes, it will display 'hidden' PDP form error to console.
  const handleInvalid = useCallback((errors: FieldErrors) => {
    logger.consoleLog(errors);
  }, []);

  const { isInitialLoadComplete } = useContext(PdpContext);
  const isLoading = isSubmitting || isSaving || !isInitialLoadComplete;

  useEffect(() => {
    if (shouldBuildNewDeal && !isLoading) {
      buildNewDeal(pdpData.vehicle.id);
    }
  }, [shouldBuildNewDeal, isLoading]);

  return {
    handleSaveDealOffer: handleSubmit(handleSaveDealOffer, handleInvalid),
    handleOpenResponder: handleSubmit(handleOpenResponder, handleInvalid),
    handlePushToCrm: handleSubmit(handlePushToCrm, handleInvalid),
    isLoading,
    isExportPdfGridButtonDisabled: isMonthlyPaymentExact,
  };
};
