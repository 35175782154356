import { useContext } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { enqueueSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';

import { useConfiguration } from 'hooks/useConfiguration';

export const enum MinutesAgo {
  LastThirtyMinutes = 30,
  LastHour = 60,
  LastFourHours = 240,
  LastSixHours = 360,
  LastTwelveHours = 720,
  LeastTwentyFourHours = 1440,
  LastThreeDays = 4320,
  LastSevenDays = 10080,
  LastThirtyDays = 43200,
}

interface HookResult {
  reportQuery: UseQueryResult<any, unknown>;
}

export interface HookProps {
  minutesAgo: MinutesAgo;
}

export const useReportDataQuery = ({ minutesAgo }: HookProps): HookResult => {
  const reportPath = '/api/GenerateReport';
  const axiosClient = useContext(AxiosContext);

  const { reportServiceApiUrl } = useConfiguration();
  const reportQuery = useQuery(
    ['currentReportQuery', minutesAgo],
    async () => {
      const { data } = await axiosClient.get<any>(reportServiceApiUrl + reportPath, {
        params: { minutesAgo },
      });

      return data;
    },
    {
      onError: () => {
        enqueueSnackbar('Failed to fetch data for the report', {
          variant: 'error',
        });
      },
    }
  );

  return {
    reportQuery,
  };
};
