import { useRecoilValue } from 'recoil';

import { useConfiguration } from 'hooks/useConfiguration';

import { priceRangeAtom } from 'models/PriceRangeModel';

import { CalculationLoanOutput } from 'services/CalculationService';

import { formatToCurrency, getFormattedPrice, getSign } from 'utils/formatterUtils';

interface HookProps {
  calculationOutput?: CalculationLoanOutput;
  term: number;
}

interface HookResult {
  payment: string;
  dueAtSigning: string;
  dueAtSigningNote: string;
  programNote2: string;
}

export const useLoanMainBox = ({ calculationOutput, term }: HookProps): HookResult => {
  const priceRangeEnabled = useRecoilValue(priceRangeAtom);
  const {
    paymentsIntervalOptions: { defaultInterval, dueAtSigningInterval },
  } = useConfiguration();

  const payment =
    getFormattedPrice(priceRangeEnabled, defaultInterval, calculationOutput?.payment) || '0';
  const dueAtSigning =
    getFormattedPrice(
      priceRangeEnabled,
      dueAtSigningInterval,
      calculationOutput?.paymentDueAtSigning
    ) || '0';
  const dueAtSigningNote = calculationOutput
    ? getDueAtSigningNote({
        calculationOutput,
        priceRangeEnabled,
        intervalValue: defaultInterval,
      })
    : '';

  return {
    payment,
    dueAtSigning,
    dueAtSigningNote,
    programNote2: createTermProgramNote(term),
  };
};

interface PriceNoteOptions {
  calculationOutput: CalculationLoanOutput;
  priceRangeEnabled: boolean;
  intervalValue: number;
}

function getDueAtSigningNote({
  calculationOutput: {
    downPayment,
    maxOutOfPocket,
    fees: { upfrontFees },
    equityCoveredFees,
  },
  priceRangeEnabled,
  intervalValue,
}: PriceNoteOptions): string {
  const ttlNote = upfrontFees.ttl
    ? ` + ${getFormattedPrice(priceRangeEnabled, intervalValue, upfrontFees.ttl)} TTL`
    : '';
  const dealerNote = upfrontFees.dealer
    ? ` + ${formatToCurrency(upfrontFees.dealer)} dealer fees`
    : '';
  const downPaymentNote = downPayment ? getDownPayment(downPayment) : '';
  const maxOutOfPocketNote = maxOutOfPocket ? getMaxOop(maxOutOfPocket) : '';
  const addonsNote = upfrontFees.addons ? `${formatToCurrency(upfrontFees.addons)} Add-ons` : ''; // ToDo - Verify, we didn't include this previously.
  const equityNote = downPayment ? getEquitySum(equityCoveredFees) : '';

  const result = ttlNote.concat(
    dealerNote,
    downPaymentNote,
    maxOutOfPocketNote,
    addonsNote,
    equityNote
  );

  return result.startsWith(' + ') ? result.slice(3) : result.slice(1);
}

function getMaxOop(maxOutOfPocket: number): string {
  return ` ${getSign(maxOutOfPocket)} ${formatToCurrency(
    Math.abs(maxOutOfPocket),
    2
  )} Max out of Pocket`;
}

function getDownPayment(downPayment: number): string {
  return ` ${getSign(downPayment)} ${formatToCurrency(Math.abs(downPayment), 2)} Down Payment`;
}

function getEquitySum(equityCoveredFees: number): string {
  return equityCoveredFees
    ? ` ${getSign(equityCoveredFees * -1)} ${formatToCurrency(equityCoveredFees, 2)} equity`
    : '';
}

function createTermProgramNote(term: number): string {
  return `${term} months`;
}
