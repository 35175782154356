import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';

import {
  Customer as CustomerApi,
  getGetCustomerUrl,
  mapCustomer,
} from 'services/CustomerApiService';
import { Customer } from 'services/CustomerService';

export const useGetCustomerQuery = (id: number): UseQueryResult<Customer, unknown> => {
  const axiosClient = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();

  const getCustomerQuery = useQuery(
    ['getCustomer', id],
    async () => {
      const { data } = await axiosClient.get<CustomerApi>(getGetCustomerUrl(id));

      return mapCustomer(data);
    },
    {
      enabled: id > 0,
      cacheTime: 0,
      staleTime: 0,
      onError: (_e: any) => {
        enqueueSnackbar('Unable to retrieve customer');
      },
    }
  );

  return getCustomerQuery;
};
