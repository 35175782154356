import { useFormContext } from 'react-hook-form';

import _ from 'lodash';

import { MultiSelectOptions } from 'components/molecules/form-fields/multi-select-form-field/MultiSelectFormField';
import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

interface HookProps {
  loanTerms: number[];
}

interface HookResult {
  options: MultiSelectOptions[];
  current: number[];
}

export const useLoanTermFormField = ({ loanTerms }: HookProps): HookResult => {
  const {
    calculationOptions: { loanTerms: selectedLoanTerms = [] },
  } = useFormContext<SearchFormValues>().getValues();

  const options = loanTerms.map((term) => {
    return {
      label: `${term}`,
      value: term,
      isChecked: selectedLoanTerms.some((x) => term === x),
    };
  });

  const current = _.intersection(loanTerms, selectedLoanTerms);

  return { options, current };
};
