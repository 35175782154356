import { Location, LocationWithZip } from 'services/ZipCodeApiService';

export function getLocationDisplay(location: LocationWithZip): string {
  return `${location.city}, ${location.state}${getCountyDisplay(
    location.showCounty,
    location.county
  )}`;
}

export function mapLocations(locations: Location[], zipCode: string): LocationWithZip[] {
  return (
    locations.map((location) => {
      return {
        ...location,
        showCounty: optionShouldShowCounty(locations, location),
        zipCode,
      } as LocationWithZip;
    }) ?? []
  );
}

function getCountyDisplay(showCounty: boolean | undefined, county: string): string {
  return showCounty ? ` (${county} County)` : '';
}

function optionShouldShowCounty(options: Location[], option: Location): boolean {
  return options.filter((o) => o.city === option.city && o.state === option.state).length > 1;
}
