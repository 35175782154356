import React from 'react';

import { Button, styled } from '@mui/material';

import { TriggeredModal } from 'components/molecules/modal';

import { QuickQuoteForm } from './QuickQuoteForm';

interface QuickQuoteProps {
  quickQuoteButtonIsDisabled: boolean;
}

const StyledButton = styled(Button)({
  justifyContent: 'flex-start',
});

export const QuickQuoteModal: React.FC<QuickQuoteProps> = ({ quickQuoteButtonIsDisabled }) => {
  return (
    <TriggeredModal
      title="Quick quote"
      trigger={
        <StyledButton
          disabled={quickQuoteButtonIsDisabled}
          variant="contained"
          data-cy="QuickQuoteModal_triggerButton"
          fullWidth
        >
          Quick quote
        </StyledButton>
      }
      confirmText="Print"
      showConfirmButton={false}
      displayCloseButton
      content={<QuickQuoteForm />}
      maxWidth="lg"
    />
  );
};
