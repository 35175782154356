export interface Location {
  id?: number;
  city: string;
  county: string;
  state: string;
}

export interface LocationWithZip extends Location {
  zipCode: string;
  showCounty?: boolean;
}

export interface ZipCodeLocale {
  zipCode: string;
  locations: Location[];
}

export function getZipCodeUrl(zipCode: string): string {
  return `/ZipLocale/${zipCode}`;
}
