import React from 'react';
import { Controller } from 'react-hook-form';

import { Autocomplete, AutocompleteRenderInputParams, Stack, Typography } from '@mui/material';

import { useZipFormField } from './useZipFormField';
import { ZipMenuItem } from './zip-menu-item';
import { ZipTextInput } from './zip-text-input/ZipTextInput';
import { getLocationDisplay } from './ZipFormFieldService';

interface Props {
  isDisabled?: boolean;
  name: string;
  parentName: string;
  dataCy: string;
}

export const ZipFormField: React.FC<Props> = ({ parentName, isDisabled = false, dataCy, name }) => {
  const {
    control,
    isLoading,
    options,
    currentValue,
    displayedValue,
    handleZipChange,
    handleCityStateSelection,
    handleFilterOptions,
    getOptionLabel,
    error,
  } = useZipFormField({ fieldName: name, parentName });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur } }): React.ReactElement => (
        <Stack spacing={1}>
          <Autocomplete
            options={options}
            loading={isLoading}
            blurOnSelect
            fullWidth
            onChange={handleCityStateSelection}
            value={currentValue}
            onInputChange={handleZipChange}
            filterOptions={handleFilterOptions}
            getOptionLabel={(option): string => getOptionLabel(option)}
            disabled={isDisabled}
            renderOption={(htmlProps, location): React.ReactElement => (
              <ZipMenuItem
                key={location.id}
                dataCy={dataCy}
                location={location}
                htmlProps={htmlProps}
              />
            )}
            freeSolo
            renderInput={(params: AutocompleteRenderInputParams): React.ReactElement => (
              <ZipTextInput dataCy={dataCy} isLoading={isLoading} params={params} error={error} />
            )}
          />
          {displayedValue?.id && (
            <Typography variant="caption">{getLocationDisplay(displayedValue)}</Typography>
          )}
        </Stack>
      )}
    />
  );
};
