import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { AxiosContext } from 'providers/AxiosProvider';

import { getAllUsersUrl, User } from 'services/UserAdministrationApiService';

export const useUsersQuery = (): UseQueryResult<User[], unknown> => {
  const axiosClient = useContext(AxiosContext);

  const usersQuery = useQuery(
    'usersQuery',
    async () => {
      const { data } = await axiosClient.get<User[]>(getAllUsersUrl());

      return data;
    },
    {
      staleTime: 0,
    }
  );

  return usersQuery;
};
