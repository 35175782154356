/* eslint-disable react/prop-types */
import { RowSelectionState } from '@tanstack/react-table';
import React from 'react';

import { Typography } from '@mui/material';

import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import _ from 'lodash';

import {
  ProgramNameTableCell,
  IncentivesTypeTableCell,
} from 'components/pdp/grid-view/table-cells';
import { AmountIncentiveTableCell } from 'components/pdp/grid-view/table-cells/AmountIncentiveTableCell';

import { ProgramType } from 'services/PdpApiService';
import { ExtendedRebate, RebateApplicability } from 'services/RebatesService';

import { formatDate } from 'utils/formatterUtils';

interface TableColumnsOptions {
  leaseTerms: number[];
  loanTerms: number[];
}

export function getTableColumns({
  leaseTerms,
  loanTerms,
}: TableColumnsOptions): ColumnDef<ExtendedRebate>[] {
  return [
    {
      accessorKey: 'cash',
      header: 'AMOUNT',
      Cell: ({ row: { original: rebate } }) => (
        <AmountIncentiveTableCell
          rebate={rebate}
          programTerms={getProgramTerms({
            programTypes: rebate.programTypes,
            leaseTerms,
            loanTerms,
          })}
        />
      ),
      size: 69,
    },
    {
      accessorKey: 'programTypes',
      header: 'TYPE',
      Cell: ({
        row: {
          original: { programTypes },
        },
      }) => <IncentivesTypeTableCell programTypes={programTypes} />,
      size: 71,
    },
    {
      accessorKey: 'description',
      header: 'PROGRAM NAME',
      Cell: ({ row: { original: rebate } }) => <ProgramNameTableCell rebate={rebate} />,
      size: 80,
    },
    {
      accessorKey: 'currentProgramApplicability',
      header: 'APPLICABILITY',
      Cell: ({
        row: {
          original: { currentProgramApplicability },
        },
      }): React.ReactElement => (
        <Typography variant="body3">{formatApplicability(currentProgramApplicability)}</Typography>
      ),
      size: 75,
    },
    {
      accessorKey: 'programNumber',
      header: 'PROGRAM #',
      size: 35,
    },
    {
      accessorKey: 'expiryDate',
      header: 'END',
      Cell: ({
        row: {
          original: { expiryDate },
        },
      }): React.ReactElement => (
        <Typography variant="body3" fontWeight={700}>
          {formatDate(expiryDate)}
        </Typography>
      ),
      size: 80,
    },
    {
      accessorKey: 'groupAffiliation',
      header: 'SOURCE',
      size: 80,
    },
  ];
}

export function getSelectedRebates(currentRebateItems: number[], rowId: string): number[] {
  return currentRebateItems.includes(+rowId)
    ? _.pull(currentRebateItems, +rowId)
    : [...currentRebateItems, +rowId];
}

export function getSelectedTableRows(rebatesSelected: number[]): RowSelectionState {
  return _.zipObject(rebatesSelected, _.fill(Array(rebatesSelected.length), true));
}

interface ProgramTermsOptions {
  programTypes: ProgramType[];
  leaseTerms: number[];
  loanTerms: number[];
}

function getProgramTerms({ programTypes, leaseTerms, loanTerms }: ProgramTermsOptions): number[] {
  if (programTypes.length === 1) {
    return programTypes[0] === ProgramType.Lease ? leaseTerms : loanTerms;
  }

  return [...leaseTerms, ...loanTerms];
}

function formatApplicability(applicability: RebateApplicability): string {
  switch (applicability) {
    case RebateApplicability.ApplicableToSelectedLease:
      return 'Applicable to lease';
    case RebateApplicability.ApplicableToSelectedLoan:
      return 'Applicable to loan';
    case RebateApplicability.ApplicableToSelectedLeaseAndLoan:
      return 'Applicable to lease and loan';
    case RebateApplicability.AppliedToSelectedLease:
      return 'Applied to lease';
    case RebateApplicability.AppliedToSelectedLoan:
      return 'Applied to loan';
    case RebateApplicability.AppliedToSelectedLeaseAndLoan:
      return 'Applied to lease and loan';
    default:
      return '';
  }
}
