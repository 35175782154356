import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';

import {
  Customer as CustomerApi,
  getFindCustomerUrl,
  mapCustomer,
} from 'services/CustomerApiService';
import { Customer } from 'services/CustomerService';

export const useFindCustomerQuery = ({
  email,
  phone,
}: {
  email?: string;
  phone?: string;
}): UseQueryResult<Customer | undefined, unknown> => {
  const axiosClient = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();

  const findCustomerQuery = useQuery(
    ['findCustomer', email, phone],
    async () => {
      if (!email && !phone) {
        return Promise.resolve(undefined);
      }

      const { data } = await axiosClient.get<CustomerApi[]>(getFindCustomerUrl(), {
        params: { email, phone },
      });
      const customer = data.length === 1 ? mapCustomer(data[0]) : undefined;

      return customer;
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      onError: (_e: any) => {
        enqueueSnackbar('Unable to search for customer');
      },
    }
  );

  return findCustomerQuery;
};
