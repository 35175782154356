import { FormState } from 'react-hook-form';
import { UseQueryResult } from 'react-query';

import _ from 'lodash';

import { MultiSelectOptions } from 'components/molecules/form-fields/multi-select-form-field/MultiSelectFormField';

import { Dealer } from 'services/DealerApiService';
import { DealerGroupSummary } from 'services/DealerGroupApiService';
import { AdministrationUser, UserDealer } from 'services/UserAdministrationApiService';

import { UserRole, userRoles } from 'utils/userRoles';

export interface DealerGroupOption {
  label: string;
  value: string | number;
}

export function getUserRolesOptions(
  currentUserRoles: UserRole[],
  initialUserData?: AdministrationUser
): {
  label: string;
  value: string | number;
  isChecked: boolean;
}[] {
  const filteredRoles = filterRolesByCurrentUserAccess(currentUserRoles, userRoles.all);

  return filteredRoles.map((role) => {
    return {
      label: role.name,
      value: role.name,
      isChecked: initialUserData?.roles ? initialUserData.roles.includes(role.name) : false,
    };
  });
}

export function mapDealerGroupOptions(dealerGroups: DealerGroupSummary[]): DealerGroupOption[] {
  return dealerGroups
    .map((dealerGroup) => {
      return {
        label: dealerGroup.name,
        value: dealerGroup.publicId.toString(),
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function mapDealerOptions(
  dealers: Dealer[],
  currentDealerIds: string[],
  currentUserCanEditAllUserDealers: boolean,
  currentUserDealerIds: string[]
): MultiSelectOptions[] {
  return (
    dealers.map((x) => {
      return {
        label: x.name,
        value: x.publicId,
        isChecked: !!currentDealerIds?.find((current) => current === x.publicId),
        disabled: !currentUserCanEditAllUserDealers && !currentUserDealerIds.includes(x.publicId),
      };
    }) ?? []
  ).sort((a, b) => a.label.localeCompare(b.label));
}

export function mapUserFormValues(values: AdministrationUser): AdministrationUser {
  return {
    ...values,
    dealerIds:
      values.dealers?.map((dealer) => {
        return dealer.publicId;
      }) ?? [],
  };
}

export function selectedDealersAreInaccessibleToUser(
  dealerMultiSelectOptions: MultiSelectOptions[]
): boolean {
  return dealerMultiSelectOptions.some((option) => option.disabled && option.isChecked);
}

export function isSubmitDisabled(
  formState: FormState<AdministrationUser>,
  dealersQuery: UseQueryResult<Dealer[], unknown>,
  selectedDealerIds: string[] | undefined,
  selectedDealers: UserDealer[]
): boolean {
  return (
    formState.isSubmitting ||
    dealersQuery.isFetching ||
    (!selectedDealerIds?.length && !selectedDealers?.length)
  );
}

function filterRolesByCurrentUserAccess(
  currentUserRoles: UserRole[],
  allRoles: UserRole[]
): UserRole[] {
  const minRole = _.minBy(currentUserRoles, (x) => x.value)?.value ?? 0;

  return allRoles.filter((role) => role.value >= minRole);
}
