import { ProgramVariations } from './PdpApiService';

export enum ProgramAvailability {
  available = 0,
  outOfRange = 1,
  noneAvailable = 2,
}

export enum CarState {
  New = 0,
  Used = 1,
  Sold = 2,
  CPO = 3,
}

export enum CarStatus {
  Active = 0,
  InTransit = 1,
  Virtual = 3,
}

export enum OrderBy {
  MinPayment = 0,
  MinPaymentLease = 1,
  MinPaymentLoan = 2,
  Price = 3,
  DaysInStock = 4,
  BestDealLoan = 5,
  BestDealLease = 6,
  Year = 7,
  Mileage = 8,
  Profit = 9,
  ProfitLease = 10,
  ProfitLoan = 11,
}

export enum OrderDirection {
  Asc = 0,
  Desc = 1,
}

export const OrderByIsProfit: Record<OrderBy, boolean> = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: true,
  10: true,
  11: true,
};

export const OrderByLabels: Record<OrderBy, string> = {
  0: 'Best payment',
  1: 'Loan',
  2: 'Lease',
  3: 'Price',
  4: 'Days on lot',
  5: 'Best deal loan',
  6: 'Best deal lease',
  7: 'Year',
  8: 'Mileage',
  9: 'Profit',
  10: 'Profit lease',
  11: 'Profit loan',
};

const lowToHigh = 'Low to high';
const highToLow = 'High to low';
const bestToWorst = 'Best to worst';
const worstToBest = 'Worst to best';

export const OrderDirectionLabels: Record<OrderDirection, Record<OrderBy, string>> = {
  0: {
    0: lowToHigh,
    1: lowToHigh,
    2: lowToHigh,
    3: lowToHigh,
    4: 'Less to more',
    5: bestToWorst,
    6: bestToWorst,
    7: 'Older to newer',
    8: lowToHigh,
    9: lowToHigh,
    10: lowToHigh,
    11: lowToHigh,
  },
  1: {
    0: highToLow,
    1: highToLow,
    2: highToLow,
    3: highToLow,
    4: 'More to less',
    5: worstToBest,
    6: worstToBest,
    7: 'Newer to older',
    8: highToLow,
    9: highToLow,
    10: highToLow,
    11: highToLow,
  },
};

export const OrderByDefaultDirection: Record<OrderBy, OrderDirection> = {
  0: OrderDirection.Asc,
  1: OrderDirection.Asc,
  2: OrderDirection.Asc,
  3: OrderDirection.Asc,
  4: OrderDirection.Desc,
  5: OrderDirection.Asc,
  6: OrderDirection.Asc,
  7: OrderDirection.Desc,
  8: OrderDirection.Asc,
  9: OrderDirection.Desc,
  10: OrderDirection.Desc,
  11: OrderDirection.Desc,
};

export type OrderByValue = keyof typeof OrderByLabels;
export type OrderDirectionValue = keyof typeof OrderDirectionLabels;

export enum ProfitTier {
  negative = -1,
  low = 0,
  medium = 1,
  high = 2,
  veryHigh = 3,
}

export interface Profit {
  total?: number;
  tier?: ProfitTier;
}

export interface ComparisonDifference {
  current: number;
  comparison: number;
  isBetter: boolean;
  delta: number;
}
export interface ComparisonResults {
  higherTrim: boolean;
  msrp: ComparisonDifference;
  leasePayment: ComparisonDifference;
  loanPayment: ComparisonDifference;
  leaseProfit: ComparisonDifference;
  loanProfit: ComparisonDifference;
}

export interface Vehicle {
  id: string;
  vin: string;
  stockNumber: string;
  pictureUrl: string;
  picturesCount: number;
  year: number;
  make: string;
  model: string;
  trim: string;
  drivetrain: string;
  engine: string;
  transmission: string;
  lowestPaymentLoan: number;
  lowestPaymentLease: number;
  profitLease: Profit;
  profitLoan: Profit;
  price: number;
  msrp: number;
  mileage: number;
  daysInStock: number;
  mpg: number;
  state: CarState;
  status: CarStatus;
  topFeatures: string[];
  rgbInterior: string;
  rgbExterior: string;
  location: string;
  allFeatures: string[];
  bodyType: string;
  cityMpg: number;
  hwyMpg: number;
  vehicleStyle: string;
  engineFuelType: string;
  bodyStyle: string;
  interiorColor: string;
  exteriorColor: string;
  invoice: number;
  comparison?: ComparisonResults;
}

export function getSearchApiUrl(): string {
  return '/search';
}

export interface DealershipProgramVariations {
  all: ProgramVariations;
  default: ProgramVariations;
}

export interface CurrentDealership {
  creditScore: number;
  downPayment: number;
  payAllFeesUpfront: boolean;
  programVariations: DealershipProgramVariations | undefined;
}
