import { AddonType, DealType } from 'services/PdpApiService';

import { CustomAddonFormValues } from './useCustomAddOnFormWrapper';

interface SearchOptions extends Omit<CustomAddonFormValues, 'featureTypes' | 'customer' | 'skip'> {
  maxCount?: number;
  features: number[];
}

export interface SearchOptionsPayload extends SearchOptions {
  skip?: number;
}

export function getDefaultFormValues(): CustomAddonFormValues {
  return {
    name: '',
    price: 0,
    wholesale: 0,
    type: AddonType.Addition,
    dealType: DealType.Any,
  };
}

export function getCustomAddonFormValues(values: CustomAddonFormValues): CustomAddonFormValues {
  return {
    ...values,
  };
}
