import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { useViewProfitSwitch } from 'components/molecules/app-bar/view-profit-switch/useViewProfitSwitch';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useConfiguration } from 'hooks/useConfiguration';

import { priceRangeAtom } from 'models/PriceRangeModel';

import { ProgramType } from 'services/PdpApiService';
import { ComparisonResults } from 'services/SearchApiService';

import { UserPermission } from 'utils/UserPermission';

import { createReasonListText } from './KeyRecommendationService';

interface Props {
  comparisonResults: ComparisonResults | undefined;
  programType: ProgramType;
}

interface HookResult {
  reasons: string[];
  showProfit: boolean;
  allowViewProfitAmount: boolean;
  showRanges: boolean;
  paymentInterval: number;
}

export const useKeyRecommendationReasons = ({
  comparisonResults,
  programType,
}: Props): HookResult => {
  const { viewProfitValue: showProfit } = useViewProfitSwitch();
  const showRanges = useRecoilValue(priceRangeAtom);
  const {
    paymentsIntervalOptions: { defaultInterval },
  } = useConfiguration();

  const { permissions } = useContext(PermissionsContext);
  const allowViewProfitAmount = permissions.includes(UserPermission.viewProfitAmount);

  if (!comparisonResults) {
    return {
      reasons: [],
      showProfit,
      allowViewProfitAmount,
      showRanges,
      paymentInterval: defaultInterval,
    };
  }
  const reasons = createReasonListText(
    comparisonResults,
    programType,
    showProfit,
    allowViewProfitAmount,
    showRanges
  );

  return {
    reasons,
    showProfit,
    allowViewProfitAmount,
    showRanges,
    paymentInterval: defaultInterval,
  };
};
