import { UseFormGetValues } from 'react-hook-form';

import _ from 'lodash';

import { PdpFormValues, PdpState } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { PaymentCalculationType } from 'models/ExactPaymentModel';

export const allPersistStateFields: (keyof PdpState)[] = [
  'addons',
  'appliedCustomIncentives',
  'captive',
  'customAddons',
  'customIncentiveNames',
  'downPayment',
  'leaseMarkedUpAcquisitionFee',
  'leaseMoneyFactorMarkup',
  'leaseProgramId',
  'leaseTaxCreditTo',
  'loanAprMarkupPercent',
  'loanProgramId',
  'loanTaxCreditTo',
  'monthlyExactPayment',
  'msrp',
  'programFilters',
  'rebates',
  'tradeVehicle',
  'vehicleInvoice',
  'vehiclePrice',
];

export const recommendationsPersistStateFieldsBlackList: (keyof PdpState)[] = [
  'addons',
  'appliedCustomIncentives',
  'customAddons',
  'customIncentiveNames',
  'leaseMarkedUpAcquisitionFee',
  'leaseMoneyFactorMarkup',
  'leaseProgramId',
  'leaseTaxCreditTo',
  'loanAprMarkupPercent',
  'loanProgramId',
  'loanTaxCreditTo',
  'msrp',
  'rebates',
  'vehiclePrice',
  'vehicleInvoice',
];

export function getBuildNewDealFields(blacklist: (keyof PdpState)[]): (keyof PdpState)[] {
  return _.difference(allPersistStateFields, blacklist);
}

export interface CreateRedirectUrlRequest {
  carId: string;
  pdpState: PdpState;
  persistState: boolean;
  fromDealOfferId: string | undefined;
  utmSource: string | null;
}

export function createRedirectUrl({
  carId,
  pdpState,
  persistState,
  fromDealOfferId,
  utmSource,
}: CreateRedirectUrlRequest): string {
  const url = `${window.location.origin}/pdp/${carId}`;
  const params = new URLSearchParams();

  if (fromDealOfferId) {
    params.append('fromDealOffer', fromDealOfferId);
  }

  if (utmSource) {
    params.append('utm_source', utmSource);
  }

  if (persistState) {
    params.append('pdpFormValues', JSON.stringify(pdpState));
  }

  const paramsString = params.toString();

  return paramsString ? `${url}?${params.toString()}` : url;
}

export interface CreatePdpStateRequest {
  getValues: UseFormGetValues<PdpFormValues>;
  fields: (keyof PdpState)[];
  monthlyPaymentCalculationType: PaymentCalculationType;
  downPaymentCalculationType: PaymentCalculationType;
}

export function createPdpState({
  getValues,
  fields,
  monthlyPaymentCalculationType,
  downPaymentCalculationType,
}: CreatePdpStateRequest): PdpState {
  const pdpState: PdpState = {
    addons: getValues('addons'),
    appliedCustomIncentives: getValues('appliedCustomIncentives'),
    captive: getValues('captive'),
    customAddons: getValues('customAddons'),
    customIncentiveNames: getValues('customIncentiveNames'),
    downPayment: getValues('downPayment'),
    downPaymentCalculationType,
    leaseMarkedUpAcquisitionFee: getValues('leaseMarkedUpAcquisitionFee'),
    leaseMoneyFactorMarkup: getValues('leaseMoneyFactorMarkup'),
    leaseProgramId: getValues('leaseProgram')?.id,
    leaseTaxCreditTo: getValues('leaseTaxCreditTo'),
    loanAprMarkupPercent: getValues('loanAprMarkupPercent'),
    loanProgramId: getValues('loanProgram')?.id,
    loanTaxCreditTo: getValues('loanTaxCreditTo'),
    monthlyExactPayment: getValues('monthlyExactPayment'),
    monthlyPaymentCalculationType,
    msrp: getValues('msrp'),
    programFilters: getValues('programFilters'),
    rebates: getValues('rebates'),
    tradeVehicle: getValues('tradeVehicle'),
    vehicleInvoice: getValues('vehicleInvoice'),
    vehiclePrice: getValues('vehiclePrice'),
  };

  const filteredState = Object();

  fields.forEach((field) => {
    filteredState[field] = pdpState[field];
  });

  return filteredState;
}
