import React, { useCallback, useState } from 'react';
import { Control, FieldValues, useController, useFormContext } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';

import { debounce } from 'lodash';

import { useZipCodeQuery } from 'hooks/useZipCodeQuery';

import { LocationWithZip, ZipCodeLocale } from 'services/ZipCodeApiService';

import { isEmptyOrValidZip } from 'utils/validationUtils';

import { mapLocations } from './ZipFormFieldService';

const zipErrorMessage: string = 'Enter a valid value.';

interface HookResult {
  isLoading: boolean;
  options: LocationWithZip[];
  control: Control<FieldValues, any>;
  handleCityStateSelection(
    e: React.SyntheticEvent,
    locationWithZip: string | LocationWithZip | null
  ): void;
  handleZipChange(e: React.SyntheticEvent, zipCode: string): void;
  handleFilterOptions(options: LocationWithZip[]): LocationWithZip[];
  getOptionLabel(option: LocationWithZip | string): string;
  currentValue?: LocationWithZip;
  displayedValue?: LocationWithZip;
  error: FieldError | undefined;
}

interface HookOptions {
  fieldName: string;
  parentName: string;
  debounceTimeMs?: 50;
}

export const useZipFormField = ({
  fieldName,
  parentName,
  debounceTimeMs,
}: HookOptions): HookResult => {
  const { control, setValue } = useFormContext();
  const [error, setError] = useState(undefined as FieldError | undefined);

  const {
    fieldState: { error: zipError },
    field: { onChange },
  } = useController({ control, name: fieldName });
  const {
    field: { value: parentValue },
  } = useController({ control, name: parentName });

  const [currentZipCode, setCurrentZipCode] = useState(parentValue?.zipCode ?? '');

  const handleZipChange = useCallback((_e: React.SyntheticEvent, zipCode: string) => {
    if (isEmptyOrValidZip(zipCode)) {
      debounce(() => {
        setValue(fieldName, zipCode);
      }, debounceTimeMs);
      setCurrentZipCode(zipCode);
      setError(undefined);
    } else {
      setError({ message: zipErrorMessage } as FieldError);
    }
  }, []);

  const handleFilterOptions = useCallback((options: LocationWithZip[]) => {
    return options.map((option) => {
      return {
        ...option,
        showCounty: optionShouldShowCounty(options, option),
      } as LocationWithZip;
    });
  }, []);

  const getOptionLabel = useCallback(({ zipCode }: LocationWithZip) => {
    return zipCode;
  }, []);

  const handleCityStateSelection = useCallback(
    (e: React.SyntheticEvent, locationWithZip: LocationWithZip | null) => {
      onChange(e);
      setValue(parentName, { ...locationWithZip, zipCode: locationWithZip?.zipCode ?? '' });
    },
    []
  );

  const handleZipSuccessCallback = useCallback((response: ZipCodeLocale) => {
    const { locations, zipCode } = response;
    if (locations.length === 1) {
      const [location] = locations;
      setValue(parentName, { ...location, zipCode });
    }
  }, []);

  const handleZipErrorCallback = useCallback(() => {
    setError({ message: zipErrorMessage } as FieldError);
  }, []);

  const { data, isLoading } = useZipCodeQuery({
    zipCode: currentZipCode,
    onErrorCallback: handleZipErrorCallback,
    onSuccessCallback: handleZipSuccessCallback,
  });

  const options = mapLocations(data?.locations ?? [], data?.zipCode ?? '');
  const onlyOneOptions = options.length === 1;

  const displayedValue = options.find(
    ({ city, county, state, zipCode }) =>
      parentValue &&
      city === parentValue.city &&
      county === parentValue.county &&
      state === parentValue.state &&
      zipCode === parentValue.zipCode
  );

  return {
    isLoading,
    options: !zipError ? (onlyOneOptions ? [] : options) : [],
    control,
    handleZipChange,
    handleFilterOptions,
    getOptionLabel,
    handleCityStateSelection,
    currentValue: parentValue,
    displayedValue,
    error,
  };
};

function optionShouldShowCounty(options: LocationWithZip[], option: LocationWithZip): boolean {
  return options.filter((o) => o.city === option.city && o.state === option.state).length > 1;
}
