import { useCallback } from 'react';
import { UseFormReturn, useForm, useFormContext, useWatch } from 'react-hook-form';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { Addon, DealType } from 'services/PdpApiService';
import { getAllCheckedAddons } from 'services/ProfitService';

import { AddOnsFormValues } from './AddOnsFormService';

interface HookProps {
  addons: Addon[];
}
interface HookResult {
  showPayPackagesUpfrontSwitch?: boolean;
  customAddons: string[];
  addonsForm: UseFormReturn<AddOnsFormValues>;
  handleOnSubmit: () => Promise<void>;
}

export const useAddOnsModal = ({ addons }: HookProps): HookResult => {
  const { control: pdpFormControl, setValue: pdpSetValue } = useFormContext<PdpFormValues>();
  const {
    payAllFeesUpfront,
    customAddons: selectedCustomAddonNames,
    addons: selectedAddonNames,
  } = useWatch({ control: pdpFormControl });

  const selectedAddons = getAllCheckedAddons({
    customAddons: [],
    customAddonsValues: [],
    addons,
    addonsValues: selectedAddonNames ?? [],
  });

  const addonsForm = useForm<AddOnsFormValues>({
    defaultValues: {
      leaseAddonIds: selectedAddons
        .filter((x) => x.dealType === DealType.Lease)
        .map((addon) => addon.name),
      loanAddonIds: selectedAddons
        .filter((x) => x.dealType === DealType.Loan)
        .map((addon) => addon.name),
      anyDealTypeAddonIds: selectedAddons
        .filter((x) => x.dealType === DealType.Any)
        .map((addon) => addon.name),
      customAddonIds: selectedCustomAddonNames,
    },
  });

  const handleOnSubmit = useCallback(async () => {
    addonsForm.handleSubmit((values) => {
      pdpSetValue('addons', [
        ...(values?.leaseAddonIds ?? []),
        ...(values?.loanAddonIds ?? []),
        ...(values?.anyDealTypeAddonIds ?? []),
      ]);
      pdpSetValue('customAddons', values.customAddonIds);
    })();
  }, []);

  return {
    showPayPackagesUpfrontSwitch: payAllFeesUpfront,
    customAddons: selectedCustomAddonNames ?? [],
    addonsForm,
    handleOnSubmit,
  };
};
