import { atom } from 'recoil';

export interface ResponderState {
  dealOfferId: string;
  customerEmail?: string;
  customerName?: string;
  year: number;
  make: string;
  model: string;
  trim: string;
  location: string;
  leaseMonthlyPayment: number;
  loanMonthlyPayment: number;
}

export const responderAtom = atom<ResponderState>({
  key: 'responder',
});
