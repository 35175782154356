/* eslint-disable react/prop-types */
import React from 'react';

import { CheckboxProps, Typography } from '@mui/material';

import MaterialReactTable, { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import {
  LenderNameTableCell,
  ProfitTableCell,
  TypeTableCell,
  RateTableCell,
  ResidualTableCell,
} from 'components/pdp/grid-view/table-cells';
import { useGridTable } from 'components/pdp/grid-view/table/useGridTable';

import { formatToCurrency } from 'utils/formatterUtils';

import { ProgramGrid } from './GridTableService';

const columns: ColumnDef<ProgramGrid>[] = [
  {
    accessorKey: 'type',
    header: 'TYPE',
    Header: <span data-cy="pdp-grid-header-type">TYPE</span>,
    Cell: ({ row }) => <TypeTableCell row={row} />,
    size: 71,
  },
  {
    accessorKey: 'lenderName',
    header: 'LENDER',
    Header: <span data-cy="pdp-grid-header-lender">LENDER</span>,
    Cell: ({ cell, row }) => <LenderNameTableCell cell={cell} row={row} />,
    size: 100,
  },
  {
    accessorKey: 'term',
    header: 'TERM',
    Header: <span data-cy="pdp-grid-header-TERM">TERM</span>,
    size: 35,
  },
  {
    accessorKey: 'payment',
    header: 'PAYMENT',
    Header: <span data-cy="pdp-grid-header-payment">PAYMENT</span>,
    Cell: ({ cell }): React.ReactElement => (
      <Typography variant="body3" fontWeight={700}>
        {formatToCurrency(cell.getValue() as number)}
      </Typography>
    ),
    size: 69,
  },
  {
    accessorKey: 'down',
    header: 'CASH DUE',
    Header: <span data-cy="pdp-grid-header-cash-due">CASH DUE</span>,
    Cell: ({ cell }): React.ReactElement => (
      <Typography variant="body3" fontWeight={700}>
        {formatToCurrency(cell.getValue() as number)}
      </Typography>
    ),
    size: 67,
  },
  {
    accessorKey: 'millage',
    header: 'MILEAGE',
    Header: <span data-cy="pdp-grid-header-type-mileage">MILEAGE</span>,
    size: 59,
  },
  {
    accessorKey: 'maxAdvance',
    header: 'MAX ADV',
    Header: <span data-cy="pdp-grid-header-max-adv">MAX ADV</span>,
    Cell: ({ cell }): React.ReactElement => (
      <Typography variant="body3">{formatToCurrency(cell.getValue() as number)}</Typography>
    ),
    size: 72,
  },
  {
    accessorKey: 'rate',
    header: 'RATE',
    Header: <span data-cy="pdp-grid-header-rate">RATE</span>,
    Cell: ({ cell, row }): React.ReactElement => <RateTableCell row={row} cell={cell} />,
    size: 42,
  },
  {
    accessorKey: 'residualAmount',
    header: 'RESIDUAL',
    Header: <span data-cy="pdp-grid-header-residual">RESIDUAL</span>,
    Cell: ({ row }): React.ReactElement => <ResidualTableCell row={row} />,
    size: 42,
  },
  {
    accessorKey: 'rebates',
    header: 'REBATES',
    Header: <span data-cy="pdp-grid-header-rebates">REBATES</span>,
    Cell: ({ cell }): React.ReactElement => (
      <Typography variant="body3" fontWeight={700}>
        {formatToCurrency(cell.getValue() as number, 0)}
      </Typography>
    ),
    size: 50,
  },
  {
    accessorKey: 'vehiclePrice',
    header: 'VEHICLE PRICE',
    Header: <span data-cy="pdp-grid-header-vehicle-price">VEHICLE PRICE</span>,
    Cell: ({ cell }): React.ReactElement => (
      <Typography variant="body3" fontWeight={700}>
        {formatToCurrency(cell.getValue() as number, 0)}
      </Typography>
    ),
    size: 50,
  },
  {
    accessorKey: 'profitData.totalProfit',
    header: 'PROFIT',
    Header: <span data-cy="pdp-grid-header-profit">PROFIT</span>,
    Cell: ({ cell, row }) => <ProfitTableCell cell={cell} row={row} />,
    size: 95,
  },
];

export const GridTable: React.FC = () => {
  const {
    handleSelectCheckbox,
    rowSelection,
    tableColumns,
    tableData,
    tableContainerRef,
    getRowId,
    isDisabled,
    columnVisibility,
    sortingState,
    handleSortingChange,
  } = useGridTable({
    columns,
  });

  return (
    <MaterialReactTable
      columns={tableColumns}
      enableRowActions={false}
      data={tableData}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting
      enableBottomToolbar
      enableTopToolbar={false}
      muiTableContainerProps={{
        ref: tableContainerRef,
        sx: { maxHeight: '600px' },
      }}
      muiTableHeadCellProps={{
        sx: (theme) => ({
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.caption.fontWeight,
          padding: 0,
          paddingBottom: theme.spacing(0.5),
          paddingTop: theme.spacing(0.5),
        }),
      }}
      muiTableBodyCellProps={{
        sx: (theme) => ({
          fontSize: theme.typography.body3.fontSize,
          fontWeight: theme.typography.body3.fontWeight,
          padding: 0,
          paddingBottom: theme.spacing(0.75),
          paddingTop: theme.spacing(0.75),
        }),
      }}
      enableRowSelection
      enableSelectAll={false}
      onSortingChange={handleSortingChange}
      state={{ rowSelection, sorting: sortingState, columnVisibility }}
      muiSelectCheckboxProps={({ row, table }): CheckboxProps => ({
        onClick: () => handleSelectCheckbox(table, row),
        disabled: isDisabled(table, row),
        size: 'small',
        title: '',
      })}
      getRowId={({ id }): string => getRowId(id)}
      initialState={{ columnVisibility, sorting: sortingState }}
      displayColumnDefOptions={{ 'mrt-row-select': { header: '', minSize: 5, size: 5 } }}
    />
  );
};
