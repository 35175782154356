import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';

import { getTemplateUrl, TemplatePayload, TemplateType } from 'services/TemaplateService';

interface HookResult {
  templateQuery: UseQueryResult<string, unknown>;
}

interface HookOptions {
  templateType: TemplateType;
}

export const useResponderTemplate = ({ templateType }: HookOptions): HookResult => {
  const { dealOfferId } = useParams<{ dealOfferId: string }>();
  const axiosClient = useContext(AxiosContext);

  const templateQuery = useQuery(
    ['templateQuery', dealOfferId, templateType],
    async () => {
      const { data } = await axiosClient.get<string>(getTemplateUrl(), {
        params: { dealOfferCode: dealOfferId, templateType } as TemplatePayload,
      });

      return data;
    },
    {
      enabled: !!dealOfferId,
      onError: () => {
        enqueueSnackbar('Failed to fetch template', {
          variant: 'error',
        });
      },
    }
  );

  return {
    templateQuery,
  };
};
