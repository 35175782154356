import React, { useContext } from 'react';

import { CheckboxProps, TableCellProps } from '@mui/material';

import MaterialReactTable from 'material-react-table';

import { useIncentivesGridTable } from 'components/pdp/grid-view/incentives-table/useIncentivesGridTable';

import { PdpContext } from 'providers/PdpContextProvider';

import { ExtendedRebate } from 'services/RebatesService';

interface IncentivesGridTableProps {
  allRebates: ExtendedRebate[];
}

export const IncentivesGridTable: React.FC<IncentivesGridTableProps> = ({ allRebates }) => {
  const {
    handleSelectCheckbox,
    rowSelection,
    tableColumns,
    tableData,
    tableContainerRef,
    getRowId,
  } = useIncentivesGridTable({
    allRebates,
  });
  const { shouldBeReadOnly } = useContext(PdpContext);

  return (
    <MaterialReactTable
      columns={tableColumns}
      enableRowActions={false}
      data={tableData}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableSorting
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTableContainerProps={{
        ref: tableContainerRef,
        sx: { maxHeight: '600px' },
      }}
      muiTableHeadCellProps={{
        sx: (theme) => ({
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.caption.fontWeight,
          padding: 0,
          paddingBottom: theme.spacing(0.5),
          paddingTop: theme.spacing(0.5),
          paddingRight: theme.spacing(0.25),
        }),
      }}
      muiTableBodyCellProps={({ row }): TableCellProps => ({
        sx: (theme) => ({
          fontSize: theme.typography.body3.fontSize,
          fontWeight: theme.typography.body3.fontWeight,
          padding: 0,
          paddingBottom: theme.spacing(0.75),
          paddingTop: theme.spacing(0.75),
          paddingRight: theme.spacing(0.25),
        }),
      })}
      enableRowSelection={!shouldBeReadOnly}
      enableSelectAll={false}
      enableStickyHeader
      state={{ rowSelection }}
      muiSelectCheckboxProps={({ row }): CheckboxProps => ({
        onClick: () => handleSelectCheckbox(row),
        size: 'small',
        title: '',
      })}
      getRowId={({ programId }): string => getRowId(programId)}
      initialState={{ sorting: [{ desc: true, id: 'cash' }] }}
      displayColumnDefOptions={{ 'mrt-row-select': { header: '', minSize: 5, size: 5 } }}
      enablePagination={false}
    />
  );
};
