import React from 'react';

import {
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

import { NumberFormField, SelectFormField, TextFormField } from 'components/molecules/form-fields';
import { NumberHandlingMode } from 'components/molecules/form-fields/text-form-field/useTextFormField';

import { TaxCreditTo } from 'services/PdpApiService';

import { formatToCurrency } from 'utils/formatterUtils';

import { useTradeOptionsForm } from './useTradeOptionsForm';

const PriceTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.grey[600],
  textTransform: 'uppercase',
}));

interface TradeOptionsModalProps {
  selectorPrefix?: string;
  editActualCashValue: boolean;
  showProfit: boolean;
  tradeInUrl: string | undefined;
}

export const TradeOptionsForm: React.FC<TradeOptionsModalProps> = ({
  selectorPrefix,
  editActualCashValue,
  showProfit,
  tradeInUrl,
}) => {
  const { tradeValue, tradeProfit } = useTradeOptionsForm();

  return (
    <form data-cy="custom-trade-options-form">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Stack spacing={2}>
            <Typography variant="subtitle3" data-cy="tradeOptionsModal_title">
              Trade valuation
            </Typography>
            {tradeInUrl && tradeInUrl !== '' && (
              <>
                <Button variant="contained" data-cy="tradeOptionsModal_openTradeValuationButton">
                  <Link
                    color="inherit"
                    underline="none"
                    href={tradeInUrl}
                    target="_blank"
                    rel="noopener"
                  >
                    Get Trade-in cash offer →
                  </Link>
                </Button>
                <Divider />
                <Typography variant="subtitle3" align="center">
                  OR
                </Typography>
                <Divider />
              </>
            )}
            <NumberFormField
              name="tradeInOutstandingBal"
              label="Trade Payoff"
              dataCy={`${selectorPrefix}_tradeOptionsModal_tradePayoffInput`}
              fullWidth
              focused
              numberHandlingMode={NumberHandlingMode.AsZero}
            />
            {editActualCashValue && (
              <NumberFormField
                name="actualCashValue"
                label="Actual cash value"
                dataCy={`${selectorPrefix}_tradeOptionsModal_cashValue`}
                fullWidth
                focused
                numberHandlingMode={NumberHandlingMode.AsZero}
              />
            )}
            <NumberFormField
              name="tradeInValue"
              label="Customer trade allowance"
              dataCy={`${selectorPrefix}_tradeOptionsModal_tradeAllowance`}
              fullWidth
              focused
              numberHandlingMode={NumberHandlingMode.AsZero}
            />
            <Divider />
            <Stack spacing={2} direction="row">
              <SelectFormField
                fullWidth
                name="leaseTaxCreditTo"
                label="Apply lease tax credit to"
                dataCyPrefix={`${selectorPrefix}_tradeOptionsModal`}
                options={[
                  { label: 'Trade equity', value: TaxCreditTo.TradeEquity },
                  { label: 'Customer allowance', value: TaxCreditTo.CustomerAllowance },
                  { label: 'No credit', value: TaxCreditTo.NoCredit },
                  { label: 'Payoff', value: TaxCreditTo.Payoff },
                ]}
                focused
                disabled={false}
              />
              <SelectFormField
                fullWidth
                name="loanTaxCreditTo"
                label="Apply loan tax credit to"
                dataCyPrefix={`${selectorPrefix}_tradeOptionsModal`}
                options={[
                  { label: 'Trade equity', value: 1 },
                  { label: 'Customer allowance', value: 2 },
                  { label: 'No credit', value: 3 },
                  { label: 'Payoff', value: 4 },
                ]}
                focused
                disabled={false}
              />
            </Stack>
            <Divider />
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Stack direction="column">
                  <PriceTitle
                    color="primary"
                    variant="overline"
                    data-cy={`${selectorPrefix}_tradeOptionsModal_tradeValueTitle`}
                  >
                    Trade Value
                  </PriceTitle>
                  <Typography
                    variant="body3"
                    data-cy={`${selectorPrefix}_tradeOptionsModal_tradeValuePrice`}
                  >
                    {formatToCurrency(tradeValue, 0)}
                  </Typography>
                </Stack>
              </Grid>
              {showProfit && (
                <Grid item xs={12} sm={6}>
                  <Stack direction="column">
                    <PriceTitle
                      color="primary"
                      variant="overline"
                      data-cy={`${selectorPrefix}_tradeOptionsModal_tradeProfitTitle`}
                    >
                      Trade Profit
                    </PriceTitle>
                    <Typography
                      variant="body3"
                      data-cy={`${selectorPrefix}_tradeOptionsModal_tradeProfitPrice`}
                    >
                      {tradeProfit
                        ? formatToCurrency(tradeProfit, 0)
                        : 'Enter cash value higher than trade payoff'}
                    </Typography>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Divider />
            <Typography variant="body3" data-cy={`${selectorPrefix}_tradeOptionsModal_note`}>
              <strong>Note:</strong> please verify your jurisdiction’s tax treatment if this deal
              involves vehicles outside of your immediate area.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack spacing={2}>
            <Typography
              variant="subtitle3"
              data-cy={`${selectorPrefix}_tradeOptionsModal_leaseIncentivesTitle`}
            >
              Trade vehicle information
            </Typography>
            <TextFormField
              name="vin"
              label="Vin"
              placeholder="Enter trade VIN"
              dataCy={`${selectorPrefix}_tradeOptionsModal_vin`}
              fullWidth
              focused
            />
            <Divider />
            <NumberFormField
              name="mileage"
              label="Odometer mileage"
              placeholder="Enter trade odometer"
              dataCy={`${selectorPrefix}_tradeOptionsModal_odometerMileage`}
              numberHandlingMode={NumberHandlingMode.AsUndefined}
              fullWidth
              focused
            />
            <Divider />
            <NumberFormField
              name="year"
              label="Year"
              placeholder="Enter trade year"
              dataCy={`${selectorPrefix}_tradeOptionsModal_year`}
              numberHandlingMode={NumberHandlingMode.AsUndefined}
              fullWidth
              focused
            />
            <TextFormField
              name="make"
              label="Make"
              placeholder="Enter trade make"
              dataCy={`${selectorPrefix}_tradeOptionsModal_make`}
              fullWidth
              focused
            />
            <TextFormField
              name="model"
              label="Model"
              placeholder="Enter trade model"
              dataCy={`${selectorPrefix}_tradeOptionsModal_model`}
              fullWidth
              focused
            />
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
