import React from 'react';

import { InfoBlock } from './InfoBlock';
import { useInfoBlockWrapper } from './useInfoBlockWrapper';

export const InfoBlockWrapper: React.FC = () => {
  const { shouldRender } = useInfoBlockWrapper();

  return shouldRender ? <InfoBlock /> : null;
};
