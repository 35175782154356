import React, { useContext } from 'react';

import { Button, Grid, InputAdornment, Typography } from '@mui/material';

import { NumberFormField, SelectFormField, TextFormField } from 'components/molecules/form-fields';

import { PdpContext } from 'providers/PdpContextProvider';

import { CustomIncentiveAnyLender } from 'models/CustomIncentivesModel';

import { useCustomIncentiveForm } from './useCustomIncentiveForm';

export const CustomIncentiveForm: React.FC = () => {
  const {
    handleSaveCustomIncentive,
    incentiveLenderNameOptions,
    incentiveProgramTypeOptions,
    isSubmitting,
  } = useCustomIncentiveForm();
  const { shouldBeReadOnly } = useContext(PdpContext);

  return (
    <form data-cy="custom-incentive-form" onSubmit={handleSaveCustomIncentive}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle3"
            data-cy="incentivesModal_customIncentiveTitle"
            paddingBottom={4}
          >
            Add custom incentive
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            name="name"
            label="Incentive name"
            focused
            placeholder="Enter add-on name"
            dataCy="incentivesModal_customIncentive_nameInput"
            fullWidth
            disabled={shouldBeReadOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberFormField
            name="amount"
            label="Incentive Amount"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            dataCy="incentivesModal_customIncentive_amountInput"
            fullWidth
            focused
            disabled={shouldBeReadOnly}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectFormField
            name="incentiveLenderName"
            defaultValue={CustomIncentiveAnyLender}
            label="Apply incentive to lender"
            dataCyPrefix="incentivesModal_customIncentive"
            options={incentiveLenderNameOptions}
            focused
            fullWidth
            disabled={shouldBeReadOnly}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectFormField
            name="incentiveProgramType"
            defaultValue={0}
            label="Apply incentive to program type"
            dataCyPrefix="incentivesModal_customIncentive"
            options={incentiveProgramTypeOptions}
            focused
            fullWidth
            disabled={shouldBeReadOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            data-cy="add_customIncentiveButton"
            disabled={isSubmitting || shouldBeReadOnly}
          >
            Add custom incentive
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
