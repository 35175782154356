import React from 'react';
import { FormProvider } from 'react-hook-form';

import { CircularProgress, Grid } from '@mui/material';

import { EmailEditorCard } from 'components/responder/email-editor-card';

import { useEmailEditorWrapper } from './useEmailEditorWrapper';

export const EmailEditorWrapper: React.FC = () => {
  const { values, template, leaseMonthlyPayment, loanMonthlyPayment, isLoading } =
    useEmailEditorWrapper();

  let element = null;

  if (isLoading) {
    element = (
      <Grid item xs={12} textAlign="center">
        <CircularProgress data-cy="responderPage_emailTemplate_spinner" size={50} />
      </Grid>
    );
  } else if (template) {
    element = (
      <>
        <EmailEditorCard
          defaultValue={template}
          isPlainText={false}
          leaseMonthlyPayment={leaseMonthlyPayment}
          loanMonthlyPayment={loanMonthlyPayment}
          toolbarEnabled
          elementPathEnabled
        />
      </>
    );
  }

  return <FormProvider {...values}>{element}</FormProvider>;
};
