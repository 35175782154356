import React, { ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button, Grid, Stack, styled, Typography } from '@mui/material';

import { useUserAdministrationForm } from 'components/administrations/users/form/useUserAdministrationForm';
import { ConfirmDialog } from 'components/atoms/confirm-dialog';
import {
  MultiSelectFormField,
  TextFormField,
  SelectFormField,
} from 'components/molecules/form-fields';

import { DealerGroupSummary } from 'services/DealerGroupApiService';
import { AdministrationUser } from 'services/UserAdministrationApiService';

import { UserDealerAssignmentModal } from './user-dealer-assignment-modal/UserDealerAssignmentModal';

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const StyledButton = styled(Button)({
  fontWeight: 'bold',
});

interface Props {
  initialUserData?: AdministrationUser;
  dealerGroups: DealerGroupSummary[] | undefined;
  userID?: string;
}

export const UserAdministrationForm: React.FC<Props> = ({
  initialUserData,
  userID,
  dealerGroups,
}) => {
  const {
    methods,
    handleCreateUser,
    handleEditUser,
    handleDeleteUser,
    isEditForm,
    userRoleOptions,
    defaultUserRolesValue,
    dealerGroupOptions,
    isLoadingDealers,
    submitDisabled,
    canEditDealerGroup,
    deleteDisabled,
    dealers,
  } = useUserAdministrationForm({
    initialUserData,
    dealerGroups: dealerGroups ?? [],
    userID,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(isEditForm ? handleEditUser : handleCreateUser)}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Stack direction="column" spacing={4}>
              <Typography
                data-cy="userAdministrationPage_userAdministrationForm_formTitle"
                variant="body2"
              >
                Misc
              </Typography>
              <TextFormField
                name="firstName"
                label="First Name"
                dataCy="userAdministrationPage_userAdministrationForm_firstNameInput"
                autoCompleteOff={isEditForm}
              />
              <TextFormField
                name="lastName"
                label="Last Name"
                dataCy="userAdministrationPage_userAdministrationForm_lastNameInput"
                autoCompleteOff={isEditForm}
              />
              <TextFormField
                name="email"
                label="Email"
                dataCy="userAdministrationPage_userAdministrationForm_emailInput"
                autoCompleteOff={isEditForm}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack direction="column" spacing={4}>
              <Typography
                data-cy="userAdministrationPage_userAdministrationForm_formTitle"
                variant="body2"
              >
                Contact info
              </Typography>
              <TextFormField
                name="userDealershipPhone"
                label="Dealership phone"
                dataCy="userAdministrationPage_userAdministrationForm_userDealershipPhoneInput"
                autoCompleteOff={isEditForm}
              />
              <TextFormField
                name="userPreferedText"
                label="Mobile Phone"
                dataCy="userAdministrationPage_userAdministrationForm_userPreferedTextInput"
                autoCompleteOff={isEditForm}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack direction="column" spacing={4}>
              <Typography
                data-cy="userAdministrationPage_userAdministrationForm_formTitle"
                variant="body2"
              >
                Work info
              </Typography>
              <SelectFormField
                name="dealerGroupId"
                label="Dealer Group"
                dataCyPrefix="userAdministrationPage_userAdministrationForm_dealerGroupIdInput"
                options={dealerGroupOptions ?? []}
                focused
                disabled={!canEditDealerGroup}
                fullWidth
              />
              {getDealerSelectList()}
              <TextFormField
                label="Job title"
                name="jobTitle"
                dataCy="userAdministrationPage_userAdministrationForm_customerJobTitleInput"
                autoCompleteOff={isEditForm}
              />
              <MultiSelectFormField
                label="CARMATIC ROLES"
                name="roles"
                dataCyPrefix="userAdministrationPage_userAdministrationForm_userRolesSelect"
                options={userRoleOptions}
                defaultValue={defaultUserRolesValue}
                labelVariant="caption2"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <Stack direction="row" spacing={4} justifyContent="center">
              <StyledLink to="/administration/users">
                <StyledButton
                  disabled={isSubmitting}
                  variant="outlined"
                  size="large"
                  data-cy="userAdministrationPage_userAdministrationForm_cancelButton"
                >
                  Cancel
                </StyledButton>
              </StyledLink>
              {isEditForm && (
                <ConfirmDialog
                  disabled={deleteDisabled}
                  triggerElement={
                    <StyledButton
                      disabled={deleteDisabled}
                      variant="contained"
                      color="error"
                      size="large"
                      data-cy="userAdministrationPage_userAdministrationForm_deleteButton"
                    >
                      Delete
                    </StyledButton>
                  }
                  title="Delete user"
                  contentText="Are you sure you want to delete this user? This action is irreversible."
                  cancelText="Cancel"
                  confirmText="Yes, I am sure"
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  onConfirm={(): Promise<void> => handleDeleteUser(userID!)}
                />
              )}
              <StyledButton
                disabled={submitDisabled}
                variant="contained"
                size="large"
                type="submit"
                data-cy="userAdministrationPage_userAdministrationForm_submitButton"
              >
                Submit
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );

  function getDealerSelectList(): ReactNode {
    return (
      <UserDealerAssignmentModal
        isLoadingDealers={isLoadingDealers}
        dealers={dealers}
        triggerButtonIsDisabled={isSubmitting}
      />
    );
  }
};
