export enum Permission {
  CarsNew = 'CarsNew',
  CarsUsed = 'CarsUsed',
}
export interface UserDealer {
  publicId: string;
  permissions: Permission[];
}

export interface AdministrationUser {
  firstName: string;
  lastName: string;
  email: string;
  userDealershipPhone: string;
  userPreferedText: string;
  dealerGroupId: string;
  dealers: UserDealer[];
  dealerIds: string[];
  jobTitle: string;
  roles?: string[];
}

// TODO: dealerID becomes obsolete once we finish 1294
export interface AdministrationUserEdit {
  firstName: string;
  lastName: string;
  email: string;
  userDealershipPhone: string;
  userPreferedText: string;
  dealerGroupId: string;
  dealers: UserDealer[];
  dealerIds: string[];
  jobTitle: string;
  roles?: string[];
}

export function getAllUsersUrl(): string {
  return `/Account/All`;
}

export function getDetailUserUrl(id: string): string {
  return `/Account/${id}`;
}

export function getDeleteUserUrl(id: string): string {
  return `/Account/${id}`;
}

export function getEditUserUrl(id: string): string {
  return `/Account/${id}`;
}

export function getCreateUserUrl(): string {
  return `/Account`;
}

export function getUserBulkImportUrl(): string {
  return `/Account/bulk`;
}

export function getUserBulkImportSampleUrl(): string {
  return `/Account/bulk/sample.csv`;
}

export interface UserViewDealer {
  publicId: string;
  name: string;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  lastLoginDate: string;
  dealerGroupId: string;
  dealers: UserViewDealer[];
  id: string;
  roles?: [];
}
