import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { pdpCollapsibleSectionExpandedSelector } from 'models/PdpCollapsibleSectionModel';

import { ExpandedType, getCollapsibleSectionState } from './AccordionService';

interface HookOptions {
  type: ExpandedType;
}
interface HookResult {
  handleAccordionClick(): void;
}

export const useAccordion = ({ type }: HookOptions): HookResult => {
  const updateCollapsibleSectionExpanded = useSetRecoilState(pdpCollapsibleSectionExpandedSelector);

  const handleAccordionClick = useCallback(() => {
    updateCollapsibleSectionExpanded((currentValue) =>
      getCollapsibleSectionState(type, currentValue)
    );
  }, [type]);

  return {
    handleAccordionClick,
  };
};
