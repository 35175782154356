import { useCallback } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useRecoilValue } from 'recoil';

import { useSnackbar } from 'notistack';

import { dealOfferCodeAtom } from 'models/DealOfferModel';

interface HookResult {
  handleCopyToClipBoard(): void;
  dealCode?: string;
}

export const usePdpPageLayout = (): HookResult => {
  const dealCode = useRecoilValue(dealOfferCodeAtom);
  const [, copyToClipboard] = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyToClipBoard = useCallback(() => {
    if (dealCode) {
      copyToClipboard(dealCode);
      enqueueSnackbar('Deal code copied to clipboard', {
        variant: 'info',
      });
    }
  }, [dealCode]);

  return {
    handleCopyToClipBoard,
    dealCode,
  };
};
