import { useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ProgramGrid } from 'components/pdp/grid-view/table/GridTableService';
import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { CustomAddonsAtom } from 'models/CustomAddonsModel';
import { CustomIncentive, CustomIncentivesAtom } from 'models/CustomIncentivesModel';
import {
  downPaymentCalculationTypeAtom,
  exactMonthlyPaymentAtom,
  monthlyPaymentCalculationTypeAtom,
  PaymentCalculationType,
} from 'models/ExactPaymentModel';
import {
  leaseProgramsGridAtom,
  leasesViewAtom,
  loanProgramsGridAtom,
  loansViewAtom,
  payFirstLeasePaymentUpfrontAtom,
  useOnlyCaptiveLendersAtom,
} from 'models/GridViewModel';
import { searchValuesAtom } from 'models/SearchModel';

import { CalculationLeaseOutput, CalculationLoanOutput } from 'services/CalculationService';
import { PaymentCustomization } from 'services/DealOfferApiService';
import { Fee, LeaseProgram, LoanProgram, PdpItem, TaxCreditTo } from 'services/PdpApiService';
import { ExtendedRebate } from 'services/RebatesService';
import { ReserveType } from 'services/ReserveService';
import { LocationWithZip } from 'services/ZipCodeApiService';

import {
  getDefaultValues,
  getDefaultValuesForDealOffer,
  updateCustomAddons,
  updateCustomIncentives,
} from './PdpFormWrapperService';
import { getPdpValidationResolver } from './validation';

export interface Miles {
  label: string;
  value: number;
}

export enum PriceType {
  FIXED,
  DYNAMIC,
}

export interface Buyer {
  name: string;
  phone: string | undefined;
  email: string | undefined;
  location?: LocationWithZip;
  id: number;
}

export interface PdpFilter {
  leaseTerms: number[];
  loanTerms: number[];
  mileages: number[];
}

export interface TradeVehicle {
  vin: string | undefined;
  mileage: number | undefined;
  year: number | undefined;
  make: string | undefined;
  model: string | undefined;
}

export interface PdpFormValues {
  payAllFeesUpfront: boolean;
  payFirstLeasePaymentUpfront: boolean;
  payAllPackagesUpfront: boolean;
  maxOutOfPocket: number;
  downPayment: number;
  creditScore: number;
  tradeInValue: number; // ToDo - Should this be renamed to customerTradeAllowance for consistency.
  tradeInOutstandingBal: number;
  nonTtlFees: Fee[];
  loanProgram?: LoanProgram;
  leaseProgram?: LeaseProgram;
  rebates: number[];
  customIncentiveNames: string[];
  appliedCustomIncentives: CustomIncentive[];
  calculationLoanOutputData?: CalculationLoanOutput;
  calculationLeaseOutputData?: CalculationLeaseOutput;
  leaseTerms: number[];
  loanTerms: number[];
  miles: Miles[];
  leaseReserveType: ReserveType;
  loanReserveType: ReserveType;
  loanAprMarkupPercent: number | undefined;
  leaseMoneyFactorMarkup: number | undefined;
  leaseDealerSplitPercent: number | undefined;
  loanDealerSplitPercent: number | undefined;
  buyer: Buyer;
  captive: boolean;
  vehiclePrice?: number;
  vehicleInvoice: number;
  msrp?: number;
  addons: string[];
  customAddons: string[];
  leaseRebates: ExtendedRebate[];
  loanAndLeaseRebates: ExtendedRebate[];
  loanRebates: ExtendedRebate[];
  actualCashValue: number;
  loanTaxCreditTo: TaxCreditTo;
  leaseTaxCreditTo: TaxCreditTo;
  leaseMarkedUpAcquisitionFee?: number;
  programFilters: PdpFilter;
  monthlyExactPayment?: number;
  downPaymentLease?: number;
  downPaymentLoan?: number;
  maxOutOfPocketLease?: number;
  maxOutOfPocketLoan?: number;
  loanVehiclePrice?: number;
  leaseVehiclePrice?: number;
  priceType: PriceType;
  tradeVehicle: TradeVehicle | undefined;
}

export interface PdpState
  extends Pick<
    PdpFormValues,
    | 'addons'
    | 'appliedCustomIncentives'
    | 'captive'
    | 'customAddons'
    | 'customIncentiveNames'
    | 'downPayment'
    | 'leaseMarkedUpAcquisitionFee'
    | 'leaseMoneyFactorMarkup'
    | 'leaseTaxCreditTo'
    | 'loanAprMarkupPercent'
    | 'loanTaxCreditTo'
    | 'monthlyExactPayment'
    | 'msrp'
    | 'programFilters'
    | 'rebates'
    | 'tradeVehicle'
    | 'vehicleInvoice'
    | 'vehiclePrice'
  > {
  downPaymentCalculationType: PaymentCalculationType | undefined;
  leaseProgramId: string | undefined;
  loanProgramId: string | undefined;
  monthlyPaymentCalculationType: PaymentCalculationType | undefined;
}

interface HookProps {
  pdpData: PdpItem;
  dealOfferValues?: PaymentCustomization;
}

interface HookResult {
  methods: UseFormReturn<PdpFormValues>;
}

interface DefaultValuesOptions {
  pdpData: PdpItem;
  leaseProgramGrids: ProgramGrid[];
  loanProgramGrids: ProgramGrid[];
  searchFormValues: SearchFormValues | undefined;
  dealOfferValues: PaymentCustomization | undefined;
  exactMonthlyPayment: number | undefined;
  payFirstLeasePaymentUpfront: boolean;
  useOnlyCaptiveLenders: boolean;
  pdpState?: PdpState;
}

export const usePdpFormWrapper = ({ pdpData, dealOfferValues }: HookProps): HookResult => {
  const exactMonthlyPayment = useRecoilValue(exactMonthlyPaymentAtom);
  const leaseProgramGrids = useRecoilValue(leaseProgramsGridAtom);
  const loanProgramGrids = useRecoilValue(loanProgramsGridAtom);
  const payFirstLeasePaymentUpfront = useRecoilValue(payFirstLeasePaymentUpfrontAtom);
  const searchFormValues = useRecoilValue(searchValuesAtom);
  const useOnlyCaptiveLenders = useRecoilValue(useOnlyCaptiveLendersAtom);

  const setMonthlyPaymentCalculationType = useSetRecoilState(monthlyPaymentCalculationTypeAtom);
  const setDownPaymentCalculationType = useSetRecoilState(downPaymentCalculationTypeAtom);
  const setShowLeasesValue = useSetRecoilState(leasesViewAtom);
  const setShowLoanValue = useSetRecoilState(loansViewAtom);
  const [customIncentiveValue, setCustomIncentiveValue] = useRecoilState(CustomIncentivesAtom);
  const [customAddonsValue, setCustomAddonsValue] = useRecoilState(CustomAddonsAtom);
  const [searchParams] = useSearchParams();
  const queryFormValues = searchParams.get('pdpFormValues');
  const pdpState: PdpState = JSON.parse(queryFormValues ?? 'null');
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const defaultValues = useMemo(
    () =>
      getPdpFormDefaultValues({
        pdpData,
        leaseProgramGrids,
        loanProgramGrids,
        searchFormValues,
        dealOfferValues,
        exactMonthlyPayment,
        payFirstLeasePaymentUpfront,
        useOnlyCaptiveLenders,
        pdpState,
      }),
    []
  );

  const methods = useForm<PdpFormValues>({
    defaultValues,
    resolver: getPdpValidationResolver(),
    mode: 'all',
  });

  useEffect(() => {
    if (dealOfferValues) {
      setShowLeasesValue(dealOfferValues.showLease);
      setShowLoanValue(dealOfferValues.showLoan);

      if (dealOfferValues.actualCashValue) {
        methods.setValue('actualCashValue', dealOfferValues.actualCashValue, { shouldDirty: true });
      }

      if (
        dealOfferValues.appliedCustomIncentives &&
        dealOfferValues.appliedCustomIncentives?.length > 0
      ) {
        const updatedCustomIncentives = updateCustomIncentives(
          dealOfferValues.appliedCustomIncentives,
          customIncentiveValue
        );
        setCustomIncentiveValue(updatedCustomIncentives);
      }

      if (dealOfferValues.customAddons && dealOfferValues.customAddons?.length > 0) {
        const updatedCustomAddons = updateCustomAddons(
          customAddonsValue,
          dealOfferValues?.customAddons ?? []
        );
        setCustomAddonsValue(updatedCustomAddons);
      }
    }

    setMonthlyPaymentCalculationType(
      pdpState?.monthlyPaymentCalculationType ??
        dealOfferValues?.monthlyPaymentCalculationType ??
        PaymentCalculationType.CALCULATED
    );

    setDownPaymentCalculationType(
      pdpState?.downPaymentCalculationType ??
        dealOfferValues?.downPaymentCalculationType ??
        PaymentCalculationType.EXACT
    );
  }, [
    dealOfferValues,
    pdpState?.downPaymentCalculationType,
    pdpState?.monthlyPaymentCalculationType,
  ]);

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);

      return;
    }

    // HACK: This useEffect is needed to reinitialize specific form values after the initial payment grid calculation has completed.
    // At the time this was the simplest way to migrate to payment grid calculations without completely reworking the pdp form.
    const updatedValues = getPdpFormDefaultValues({
      pdpData,
      leaseProgramGrids,
      loanProgramGrids,
      searchFormValues,
      dealOfferValues: undefined,
      exactMonthlyPayment,
      payFirstLeasePaymentUpfront,
      useOnlyCaptiveLenders,
      pdpState,
    });

    const { setValue } = methods;

    if (updatedValues.leaseProgram) {
      setValue('leaseProgram', updatedValues.leaseProgram);
    }

    if (updatedValues.loanProgram) {
      setValue('loanProgram', updatedValues.loanProgram);
    }

    setValue('leaseTerms', updatedValues.leaseTerms);
    setValue('miles', updatedValues.miles);
    setValue('loanTerms', updatedValues.loanTerms);
  }, [pdpData, pdpData.lenderData.lease, pdpData.lenderData.loan]);

  return { methods };
};

const getPdpFormDefaultValues = ({
  pdpData,
  leaseProgramGrids,
  loanProgramGrids,
  searchFormValues,
  dealOfferValues,
  exactMonthlyPayment,
  payFirstLeasePaymentUpfront,
  useOnlyCaptiveLenders,
  pdpState,
}: DefaultValuesOptions): PdpFormValues => {
  const {
    lenderData,
    dealer: { creditScore, downPayment, nonTtlFees, payAllPackagesUpfront, addonsV2: addons },
    dealerDefaults,
    vehicle: { msrp, price: vehiclePrice, invoice: vehicleInvoice },
    programVariations,
  } = pdpData;
  const { rebates: lenderRebates, lease, loan } = lenderData;
  const { programs: leasePrograms } = lease;
  const { programs: loanPrograms } = loan;

  const { calculationOptions, customer } = searchFormValues ?? {};

  const {
    maxOutOfPocket: calculationMaxOutOfPocket,
    creditScore: calculationCreditScore,
    tradeIn: calculationTradeIn,
    tradeInOutstandingBalance: calculationTradeOutstandingBalance,
    actualCashValue: calculationActualCashValue,
    downPayment: calculationDownPayment,
    location,
    leaseMileages: searchLeaseMileages,
    leaseTerms: searchLeaseTerms,
    loanTerms: searchLoanTerms,
  } = calculationOptions ?? {};

  const defaultValues = dealOfferValues
    ? getDefaultValuesForDealOffer({ dealOfferValues, nonTtlFees })
    : getDefaultValues({
        maxOutOfPocket: calculationMaxOutOfPocket ?? 0,
        payAllPackagesUpfront,
        payFirstLeasePaymentUpfront,
        creditScore: calculationCreditScore ?? creditScore,
        nonTtlFees,
        downPayment: calculationDownPayment ?? downPayment,
        lease,
        leaseProgramGrids,
        loan,
        loanProgramGrids,
        tradeInOutstandingBal: calculationTradeOutstandingBalance ?? 0,
        tradeInValue: calculationTradeIn ?? 0,
        actualCashValue: calculationActualCashValue,
        customer,
        captive: useOnlyCaptiveLenders,
        location,
        msrp,
        vehiclePrice,
        vehicleInvoice,
        leasePrograms,
        lenderRebates,
        loanPrograms,
        addons,
        exactMonthlyPayment,
        dealerDefaults,
        pdpState,
        programVariations,
        selectedLeaseMileages: searchLeaseMileages ?? dealerDefaults?.leaseMileages,
        selectedLeaseTerms: searchLeaseTerms ?? dealerDefaults?.leaseTerms,
        selectedLoanTerms: searchLoanTerms ?? dealerDefaults?.loanTerms,
      });

  return defaultValues;
};
