import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import CancelIcon from '@mui/icons-material/Cancel';
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  styled,
} from '@mui/material';

const StyledOutlinedInput = styled(OutlinedInput)<OutlinedInputProps>(({ theme }) => ({
  height: '54px',
  boxShadow:
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
  borderRadius: '10px',
  width: '100%',
  outline: 'none',
  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
    {
      transition: 'background-color 5000s ease-in-out 0s',
    },
}));

const StyledFormControl = styled(FormControl)<FormControlProps>(({ theme }) => ({
  flexGrow: 1,
  width: '25ch',
}));

const INPUT_DATA_CY = 'searchPage_inputSearchField';
const INPUT_CLEAR_ICON_DATA_CY = 'searchPage_inputSearchField_clearIcon';

export const SearchInput: React.FC = () => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="vinOrStockNumber"
      render={({ field: { onChange, onBlur, value } }): React.ReactElement => (
        <StyledFormControl variant="outlined">
          <StyledOutlinedInput
            name="vinOrStockNumber"
            data-cy={INPUT_DATA_CY}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            endAdornment={
              value && (
                <InputAdornment position="end">
                  <IconButton data-cy={INPUT_CLEAR_ICON_DATA_CY} onClick={(): void => onChange('')}>
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'vin',
              style: {
                fontSize: '24px',
                lineHeight: '38.5px',
                color: 'rgba(0, 0, 0, 0.6)',
              },
            }}
            fullWidth
            autoFocus
            color="primary"
            placeholder="Search by stock number, VIN"
          />
        </StyledFormControl>
      )}
    />
  );
};
