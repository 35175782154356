import { format } from 'date-fns';

import { PrintOptionsFormValues } from 'components/pdp/payment-customization-block/print-modal/PrintModalService';

import { LeaseProgram, LoanProgram } from 'services/PdpApiService';

export function getDownloadConfigurablePdfUrl(dealOfferId: string): string {
  return `dealoffer/${dealOfferId}/pdf`;
}

export interface ProgramTermApr {
  program: LoanProgram;
  term: number;
  apr: number;
}

export interface ProgramTermMoneyFactor {
  program: LeaseProgram;
  term: number;
  moneyFactor: number;
}

export interface CapCostAdjustmentOptions {
  showAddons: boolean;
  showIncentives: boolean;
  showTrade: boolean;
  showVehiclePriceOrMsrp: boolean;
}

export interface FeesOptions {
  showAcquisitionFee: boolean;
  showDealerFees: boolean;
  showNonTaxTtlFees: boolean;
  showSalesTax: boolean;
}

export interface LeaseOptions {
  singlePayment: boolean;
  showPaymentGrid: boolean;
  showDetails: boolean;
  showMoneyFactor: boolean;
  showResidual: boolean;
}

export interface LoanOptions {
  singlePayment: boolean;
  showPaymentGrid: boolean;
  showDetails: boolean;
  showApr: boolean;
}

export interface PrintOptions {
  capCostAdjustments: CapCostAdjustmentOptions;
  defaultInterval: number;
  dueAtSigningInterval: number;
  fees: FeesOptions;
  isPriceRangeEnabled: boolean;
  lease: LeaseOptions;
  loan: LoanOptions;
  showCreditScore: boolean;
  showLenderName: boolean;
}

export interface MapPrintOptionsRequest {
  printOptionsFormValues: PrintOptionsFormValues;
  isPriceRangeEnabled: boolean;
  defaultInterval: number;
  dueAtSigningInterval: number;
}

export function mapPrintOptions({
  printOptionsFormValues,
  isPriceRangeEnabled,
  defaultInterval,
  dueAtSigningInterval,
}: MapPrintOptionsRequest): PrintOptions {
  return {
    capCostAdjustments: {
      showAddons: printOptionsFormValues.showAddons,
      showIncentives: printOptionsFormValues.showIncentives,
      showTrade: printOptionsFormValues.showTradeIn,
      showVehiclePriceOrMsrp: printOptionsFormValues.showVehiclePriceOrMsrp,
    },
    defaultInterval,
    dueAtSigningInterval,
    fees: {
      showAcquisitionFee: printOptionsFormValues.showAcquisitionFee,
      showDealerFees: printOptionsFormValues.showDealerFees,
      showNonTaxTtlFees: printOptionsFormValues.showNonTaxTtlFees,
      showSalesTax: printOptionsFormValues.showSalesTax,
    },
    isPriceRangeEnabled,
    lease: {
      showDetails: printOptionsFormValues.showLeaseDetails,
      showMoneyFactor: printOptionsFormValues.showLeaseGridMoneyFactor,
      showPaymentGrid: printOptionsFormValues.showLeaseGrid,
      showResidual: printOptionsFormValues.showResidual,
      singlePayment: !printOptionsFormValues.leaseMultipleGridPayments,
    },
    loan: {
      showApr: printOptionsFormValues.showLoanGridApr,
      showDetails: printOptionsFormValues.showLoanDetails,
      showPaymentGrid: printOptionsFormValues.showLoanGrid,
      singlePayment: !printOptionsFormValues.loanMultipleGridPayments,
    },
    showCreditScore: printOptionsFormValues.showCreditScore,
    showLenderName: printOptionsFormValues.showLenderName,
  };
}

export interface MoneyDown {
  downPayment: number;
  maxOutOfPocket: number;
  cashDownTotal: number;
}

export interface MoneysDown {
  moneyDown1: MoneyDown;
  moneyDown2: MoneyDown;
  moneyDown3: MoneyDown;
  moneyDown4: MoneyDown;
}

export interface PaymentOption {
  cashDown: number;
  payment: number;
  moneyDown: MoneyDown;
}

export interface LeaseTermPayments {
  term: number;
  mileage: number;
  moneyFactor: number;
  residual: number;
  residualRate: number;
  payments: PaymentOption[];
}

export interface LoanTermPayments {
  term: number;
  apr: number;
  payments: PaymentOption[];
}

export function getMoneyDownValues(
  payAllFeesUpfront: boolean,
  currentDownPayment: number,
  currentMaxOutOfPocket: number
): MoneyDown[] {
  const adjustments = createAdjustments(
    payAllFeesUpfront,
    currentDownPayment,
    currentMaxOutOfPocket
  );

  return adjustments.map((adj) =>
    createMoneyValue(payAllFeesUpfront, currentDownPayment, currentMaxOutOfPocket, adj)
  );
}

function createAdjustments(
  payAllFeesUpfront: boolean,
  downPayment: number,
  maxOutOfPocket: number
): number[] {
  const valueToAdjust = payAllFeesUpfront ? downPayment : maxOutOfPocket;

  return valueToAdjust < 1000 ? [0, 1000, 2000, 3000] : [-1000, 0, 1000, 2000];
}

function createMoneyValue(
  payAllFeesUpfront: boolean,
  downPayment: number,
  maxOutOfPocket: number,
  adjustment: number
): MoneyDown {
  const calculatedDownPayment = payAllFeesUpfront ? downPayment + adjustment : 0;
  const calculatedMaxOutOfPocket = payAllFeesUpfront ? 0 : maxOutOfPocket + adjustment;

  return {
    downPayment: calculatedDownPayment,
    maxOutOfPocket: calculatedMaxOutOfPocket,
    cashDownTotal: payAllFeesUpfront ? calculatedDownPayment : calculatedMaxOutOfPocket,
  };
}

export function getTermValues(firstTerm: number, availableTerms: number[]): number[] {
  const sortedAvailableTerms = availableTerms.sort((a, b) => a - b);
  const secondTerm = getSecondTerm(firstTerm, sortedAvailableTerms, [firstTerm]);
  if (!secondTerm) {
    return [firstTerm];
  }

  const thirdTerm = getSecondTerm(firstTerm, sortedAvailableTerms, [firstTerm, secondTerm]);

  if (!thirdTerm) {
    return [firstTerm, secondTerm].sort((a, b) => a - b);
  }

  const fourthTerm = getSecondTerm(firstTerm, sortedAvailableTerms, [
    firstTerm,
    secondTerm,
    thirdTerm,
  ]);

  if (!fourthTerm) {
    return [firstTerm, secondTerm, thirdTerm].sort((a, b) => a - b);
  }

  return [firstTerm, secondTerm, thirdTerm, fourthTerm].sort((a, b) => a - b);
}

export function getSecondTerm(
  firstTerm: number,
  availableTerms: number[],
  notAvailableTerms: number[]
): number | undefined {
  const sortedNotAvailableTerms = notAvailableTerms.sort((a, b) => a - b);

  const lowerBy12 = availableTerms.find((term) => term === firstTerm - 12);
  if (lowerBy12 && !notAvailableTerms.includes(lowerBy12)) {
    return lowerBy12;
  }

  const biggerBy12 = availableTerms.find((term) => term === firstTerm + 12);
  if (biggerBy12 && !notAvailableTerms.includes(biggerBy12)) {
    return biggerBy12;
  }

  const lowerBy24 = availableTerms.find((term) => term === firstTerm - 24);
  if (lowerBy24 && !notAvailableTerms.includes(lowerBy24)) {
    return lowerBy24;
  }

  const biggerBy24 = availableTerms.find((term) => term === firstTerm + 24);
  if (biggerBy24 && !notAvailableTerms.includes(biggerBy24)) {
    return biggerBy24;
  }

  return availableTerms.find((term) => !sortedNotAvailableTerms.includes(term));
}

interface ConfigurablePdfFileOptions {
  description: string;
  vin: string;
}

export function getConfigurablePdfGridFileName({
  description,
  vin,
}: ConfigurablePdfFileOptions): string {
  return `${description}_${vin}_${format(new Date(), 'yyyyMMdd_HHmm')}.pdf`;
}
