import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'priceRange',
  storage: localStorage,
});

export const priceRangeAtom = atom<boolean>({
  key: 'priceRange',
  default: false,
  effects: [persistAtom],
});
