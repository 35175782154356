import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';

import { ErrorBoundary } from 'components/ErrorBoundary';

interface HookOptions {
  ref: React.RefObject<AppInsightsErrorBoundary | ErrorBoundary>;
  enableNavigation?: boolean;
}

export const useResetErrorState = ({ ref, enableNavigation }: HookOptions): void => {
  const location = useLocation();

  useEffect(() => {
    if (!enableNavigation) {
      return;
    }
    if (ref.current?.state.hasError) {
      ref.current?.setState({ hasError: false });
    }
  }, [location.key]);
};
