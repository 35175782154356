import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { useAuth0 } from '@auth0/auth0-react';

import { useConfiguration } from 'hooks/useConfiguration';

export const useTracking = (): void => {
  const { googleTagManagerOptions } = useConfiguration();
  const { user } = useAuth0();
  useEffect(() => {
    if (user && googleTagManagerOptions.enabled) {
      googleTagManagerOptions.dataLayer = {
        user_id: user.sub,
        auth0_id: user.sub,
        dealer_id: Array.isArray(user.app_metadata?.DealerId)
          ? user.app_metadata.DealerId[0]
          : undefined,
      };
      TagManager.initialize(googleTagManagerOptions);
    }
  }, [user?.sub, googleTagManagerOptions.enabled]);
};
