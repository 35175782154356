import React from 'react';

import { createTheme } from '@mui/material/styles';
import { Variant } from '@mui/material/styles/createTypography';

export type TypographyVariants =
  | 'body3'
  | 'body4'
  | 'subtitle3'
  | 'tag'
  | 'caption2'
  | 'overline2'
  | Variant;

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    tag: React.CSSProperties;
    caption2: React.CSSProperties;
    overline2: React.CSSProperties;
  }

  // eslint-disable-next-line no-unused-vars
  interface TypographyVariantsOptions {
    subtitle3: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    tag: React.CSSProperties;
    caption2: React.CSSProperties;
    overline2: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    subtitle3: true;
    tag: true;
    caption2: true;
    overline2: true;
  }
}

declare module 'notistack' {
  // eslint-disable-next-line no-unused-vars
  interface VariantOverrides {
    info: {
      buttonMessage?: string;
      buttonCopyText?: string;
    };
    error: {
      buttonMessage?: string;
      buttonCopyText?: string;
    };
    success: {
      buttonMessage?: string;
      buttonCopyText?: string;
    };
    warning: {
      buttonMessage?: string;
      buttonCopyText?: string;
    };
    default: {
      buttonMessage?: string;
      buttonCopyText?: string;
    };
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    brandOrange: Palette['primary'];
    blue700: Palette['primary'];
    blue300: Palette['primary'];
    black: Palette['primary'];
    gray600: Palette['primary'];
    gray700: Palette['primary'];
  }
  interface PaletteOptions {
    brandOrange: PaletteOptions['primary'];
    blue700: PaletteOptions['primary'];
    blue300: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    gray600: PaletteOptions['primary'];
    gray700: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  // eslint-disable-next-line no-unused-vars
  interface ChipPropsColorOverrides {
    brandOrange: true;
    blue700: true;
    black: true;
    gray600: true;
    gray700: true;
  }
}

declare module '@mui/material/SvgIcon' {
  // eslint-disable-next-line no-unused-vars
  interface SvgIconPropsColorOverrides {
    brandOrange: true;
  }
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 620, // default = 600
      md: 1090, // default = 900
      lg: 1452, // default = 1200
      xl: 1564, // default = 1536
      xxl: 2336, // additional
    },
  },
  typography: {
    h1: {
      fontSize: '3.5rem',
      lineHeight: '4.5rem',
      fontWeight: 700,
      fontFamily: 'Poppins',
      letterSpacing: 'unset',
    },
    h2: {
      fontSize: '2.5rem',
      lineHeight: '3.5rem',
      fontWeight: 700,
      fontFamily: 'Poppins',
      letterSpacing: 'unset',
    },
    h3: {
      fontSize: '2rem',
      lineHeight: '3rem',
      fontWeight: 700,
      fontFamily: 'Poppins',
      letterSpacing: 'unset',
    },
    h4: {
      fontSize: '1.625rem',
      lineHeight: '2rem',
      fontWeight: 700,
      fontFamily: 'Poppins',
      letterSpacing: 'unset',
    },
    h5: {
      fontSize: '1.375rem',
      lineHeight: '1.75rem',
      fontWeight: 700,
      fontFamily: 'Poppins',
      letterSpacing: 'unset',
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
      fontFamily: 'Poppins',
      letterSpacing: 'unset',
    },
    subtitle1: {
      fontSize: '1.25rem',
      lineHeight: '2rem',
      fontWeight: 700,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    subtitle2: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    subtitle3: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 700,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    body3: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    body4: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    button: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    caption2: {
      fontSize: '1rem',
      lineHeight: '1rem',
      fontWeight: 400,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
    },
    tag: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 500,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
      textTransform: 'uppercase',
    },
    overline: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 500,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
      textTransform: 'uppercase',
    },
    overline2: {
      fontSize: '.75rem',
      lineHeight: '.75rem',
      fontWeight: 500,
      fontFamily: 'DM Sans',
      letterSpacing: 'unset',
      textTransform: 'uppercase',
    },
  },
  palette: {
    primary: {
      main: '#0C3453',
      light: '#91BED3',
    },
    blue300: {
      main: '#E6EAFF',
      contrastText: '#000000',
    },
    blue700: {
      main: '#0B30E1',
      contrastText: '#fff',
    },
    brandOrange: {
      main: '#D2582A',
      contrastText: '#fff',
    },
    gray600: {
      main: '#656565',
      contrastText: '#fff',
    },
    gray700: {
      main: '#525252',
      contrastText: '#fff',
    },
    error: {
      main: '#BA0000',
      contrastText: '#fff',
    },
    success: {
      main: '#008A48',
      contrastText: '#fff',
    },
    warning: {
      main: '#C79400',
      contrastText: '#fff',
    },
    info: {
      main: '#0C3453',
      contrastText: '#fff',
    },
    black: {
      main: '#000000',
      contrastText: '#fff',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderWidth: '1px',
            },
          },
        },
      },
    },
  },
});
