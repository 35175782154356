import { Dealer } from './DealerApiService';

export function getDealerGroupsApiUrl(): string {
  return '/api/dealergroups';
}

export function getDealerGroupApiUrl(dealerGroupPublicId: string): string {
  return `/api/v2/dealer-groups/${dealerGroupPublicId}`;
}

export interface DealerGroupSummary {
  id: string;
  name: string;
  publicId: string;
  // TODO: remove this when multiselect work is complete (1294)
  code: string;
  legacyId: number;
}

export interface DealerGroup {
  dealers: Dealer[];
}
