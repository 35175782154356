/* eslint-disable no-unused-vars */
export enum TemplateType {
  Email = 0,
  Phone = 1,
}

export interface TemplatePayload {
  dealOfferCode: string;
  templateType: TemplateType;
}

export function getTemplateUrl(): string {
  return '/template';
}
