import { CustomIncentive } from 'models/CustomIncentivesModel';

import { Addon } from 'services/PdpApiService';

export function isEmptyOrValidZip(zipCode: string | undefined): boolean {
  return !zipCode || zipCode.length === 0 || (zipCode.length === 5 && !isNaN(+zipCode));
}

export function isOptionalOrGreaterOrEqualThenZero(value?: number): boolean {
  return !value || value >= 0;
}

export function isOptionalOrLessThanOrEqualTo(value?: number, max?: number): boolean {
  return !value || !max || value <= max;
}

export function isGreaterThanToday(expirationDate?: string): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return expirationDate ? today > new Date(expirationDate) : false;
}

export function isUniqueCustomAddon(customAddons: Addon[], addonName?: string): boolean {
  return !!addonName && !customAddons.map(({ name }) => name).includes(addonName);
}

export function isUniqueCustomIncentive(
  customIncentives: CustomIncentive[],
  name?: string
): boolean {
  return !!name && !customIncentives.map(({ name }) => name).includes(name);
}
