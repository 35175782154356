import { useFormContext } from 'react-hook-form';

import _ from 'lodash';

import { MultiSelectOptions } from 'components/molecules/form-fields/multi-select-form-field/MultiSelectFormField';
import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

interface HookProps {
  leaseMileages: number[];
}

interface HookResult {
  options: MultiSelectOptions[];
  current: number[];
}

export const useLeaseMileageFormField = ({ leaseMileages }: HookProps): HookResult => {
  const {
    calculationOptions: { leaseMileages: selectedMileages = [] },
  } = useFormContext<SearchFormValues>().getValues();

  const options = leaseMileages.map((mileage) => {
    return {
      label: mileage.toLocaleString(),
      value: mileage,
      isChecked: selectedMileages.includes(mileage),
    };
  });

  const current = _.intersection(leaseMileages, selectedMileages);

  return { options, current };
};
