import React from 'react';

import { TextField, MenuItem, styled } from '@mui/material';

import { useDownPaymentSelect } from './useDownPaymentSelect';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.common.white,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#ababab',
  },
}));

interface Props {
  isDisabled: boolean;
}

export const DownPaymentSelect: React.FC<Props> = ({ isDisabled }) => {
  const { downPaymentCalculationTypeValue, handleDownPaymentChange, options } =
    useDownPaymentSelect();

  return (
    <StyledTextField
      value={downPaymentCalculationTypeValue}
      onChange={handleDownPaymentChange}
      select
      fullWidth
      size="small"
      variant="outlined"
      disabled={isDisabled}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledTextField>
  );
};
