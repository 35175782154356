import React from 'react';

import { CircularProgress, Grid, styled } from '@mui/material';

import { DealerAdminForm } from 'pages/administration/dealers/dealer-admin-form/DealerAdminForm';

import { Layout } from 'components/molecules/layout';

import { useDealerDetailPage } from './useDealerDetailPage';

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
}));

export const DealerDetailPage: React.FC = () => {
  const { dealer } = useDealerDetailPage();

  return (
    <Layout>
      {dealer ? (
        <StyledGrid container spacing={2}>
          <Grid item xs={12}>
            <h1>Dealer Administration for {dealer?.name}</h1>
          </Grid>
          <Grid item xs={12}>
            <DealerAdminForm dealer={dealer}></DealerAdminForm>
          </Grid>
        </StyledGrid>
      ) : (
        <CircularProgress></CircularProgress>
      )}
    </Layout>
  );
};
