import React from 'react';

import { MultiSelectFormField } from 'components/molecules/form-fields';
import { formatTermResultString } from 'components/pdp/payment-customization-block/filter-fields/termFormFieldService';

import { useLeaseTermFormField } from './useLeaseTermFormField';

interface Props {
  leaseTerms: number[];
}

// NOTE React won't rerender the Fields, rerendering is based on React key attribute: https://react-hook-form.com/faqs#Whyisdefaultvaluenotchangingcorrectlywithternaryoperator
export const LeaseTerm: React.FC<Props> = ({ leaseTerms }) => {
  const { options, current } = useLeaseTermFormField({ leaseTerms });

  return (
    <MultiSelectFormField
      key="calculationOptions.leaseTerms"
      name="calculationOptions.leaseTerms"
      label="LEASE TERMS"
      dataCyPrefix="searchPage_customerSection_lease_term"
      options={options}
      value={current}
      fullWidth
      resultStringFormatter={formatTermResultString}
    />
  );
};
