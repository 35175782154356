import _ from 'lodash';

import { LeaseProgramGrid, LoanProgramGrid } from 'components/pdp/grid-view/table/GridTableService';

import { CalculationLeaseOutputData, CalculationLoanOutputData } from 'services/CalculationService';
import {
  Fee,
  Lease as LeaseLenderData,
  Loan as LoanLenderData,
  LeaseProgram,
  LoanProgram,
  ProgramType,
  DealType,
} from 'services/PdpApiService';
import { Precision, TtlNote as TtlApiNote } from 'services/TtlApiService';

import {
  LeaseResult,
  LeaseGridResult,
  LoanResult,
  LoanGridResult,
  TtlPrecision,
  TtlNote,
  ProgramTtl,
} from './PaymentGridApiModels.generated';

export function mapLeaseGridResult(leasesResult: LeaseGridResult): LeaseProgramGrid[] {
  return mapLeaseGrids(leasesResult.leases ?? []);
}

function mapLeaseGrids(leaseResults: LeaseResult[]): LeaseProgramGrid[] {
  return leaseResults.filter((x) => !x.maxAdvanceExceeded).map((x) => mapLeaseGrid(x));
}

export function mapLeaseGrid(lease: LeaseResult): LeaseProgramGrid {
  return {
    id: getLeaseId(lease),
    down: lease.paymentDueAtSigning,
    expiryDate: lease.program.expirationDate,
    lenderName: lease.program.lenderName,
    maxAdvance: lease.maxAdvance,
    millage: lease.identifier.mileage.toLocaleString(),
    payment: lease.payment,
    profitData: {
      reserveSplit: lease.profit.reserve.rateMarkup.total,
      serviceAndProductionProducts: lease.profit.addons,
      splitProfit: lease.profit.reserve.rateMarkup.splitRate,
      totalProfit: lease.profit.total,
      tradeInProfit: lease.profit.tradeIn,
      vehicleProfit: lease.profit.vehicle,
      acquisitionProfit: lease.profit.acquisition,
      appliedReserveMethod: lease.profit.reserve.appliedMethod,
      reserveTotal: lease.profit.reserve.total,
      flatValue: lease.profit.reserve.flat.value,
      flatValueType: lease.profit.reserve.flat.valueType,
    },
    programName: lease.program.name,
    rate: lease.moneyFactor.value,
    rebates: lease.incentives.total,
    residualAmount: lease.residualAmount,
    residualRate: lease.residualRate,
    term: lease.identifier.termMonths,
    type: ProgramType.Lease,
    vehiclePrice: lease.vehiclePrice,
  };
}

function getLeaseId({ identifier: { programId, termMonths, mileage } }: LeaseResult): string {
  return `${programId}-${termMonths}-${mileage}`;
}

export function mapLoanGridResult(loanResult: LoanGridResult): LoanProgramGrid[] {
  return mapLoanGrids(loanResult.loans ?? []);
}

function mapLoanGrids(loanResults: LoanResult[]): LoanProgramGrid[] {
  return loanResults.filter((x) => !x.maxLoanToValueExceeded).map((x) => mapLoanGrid(x));
}

export function mapLoanGrid(loan: LoanResult): LoanProgramGrid {
  return {
    id: getLoanId(loan),
    down: loan.paymentDueAtSigning,
    expiryDate: loan.program.expirationDate,
    lenderName: loan.program.lenderName,
    maxAdvance: loan.maxLoanToValue,
    millage: 'N/A',
    payment: loan.payment,
    profitData: {
      reserveSplit: loan.profit.reserve.rateMarkup.total ?? 0,
      serviceAndProductionProducts: loan.profit.addons,
      splitProfit: loan.profit.reserve.rateMarkup.splitRate ?? 100,
      totalProfit: loan.profit.total,
      tradeInProfit: loan.profit.tradeIn,
      vehicleProfit: loan.profit.vehicle,
      appliedReserveMethod: loan.profit.reserve.appliedMethod,
      reserveTotal: loan.profit.reserve.total,
      flatValue: loan.profit.reserve.flat.value,
      flatValueType: loan.profit.reserve.flat.valueType,
    },
    programName: loan.program.name,
    rate: loan.apr.value,
    rebates: loan.incentives.total,
    residualAmount: 0,
    residualRate: undefined,
    term: loan.identifier.termMonths,
    type: ProgramType.Loan,
    vehiclePrice: loan.vehiclePrice,
  };
}

export interface MapLenderDataRequest {
  creditScore: number;
  downPayment: number;
  maxOutOfPocket: number;
  payAllFeesUpfront: boolean;
  payAllPackagesUpfront: boolean;
  payFirstLeasePaymentUpfront: boolean;
  nonTtlFees: Fee[];
  ttlPrecision: TtlPrecision;
  ttl: ProgramTtl;
}

export interface MapLeasesLenderDataRequest extends MapLenderDataRequest {
  leaseResult: LeaseGridResult | undefined;
}

export interface MapLeaseLenderDataRequest extends MapLenderDataRequest {
  lease: LeaseResult;
}

export function mapLeaseLenderData(options: MapLeasesLenderDataRequest): LeaseLenderData {
  const firstLease = options.leaseResult?.leases.length ? options.leaseResult.leases[0] : undefined;
  const fees = options.payAllFeesUpfront ? firstLease?.fees.upFront : firstLease?.fees.rolledIn;
  const titleLicenseFees = fees?.titleLicense ?? [];

  return {
    programs: mapLeasePrograms(options),
    ttlFees: {
      notes: mapNotes(options.ttl),
      precision: mapTtlPrecision(options.ttlPrecision),
      rollupFees: titleLicenseFees.map((x) => ({ feeName: x.name, feeAmount: x.value })),
      taxFee: fees?.salesTax.fee ?? 0,
      baseTaxFee: fees?.salesTax.baseFee ?? 0,
      taxRate: fees?.salesTax.rate ?? 0,
      details: fees?.salesTax.details ?? [],
    },
  };
}

function mapLeasePrograms(options: MapLeasesLenderDataRequest): LeaseProgram[] {
  return (options.leaseResult?.leases ?? []).map((lease) => mapLeaseProgram({ ...options, lease }));
}

export function mapLeaseProgram(options: MapLeaseLenderDataRequest): LeaseProgram {
  const { lease } = options;

  return {
    incentives: lease.incentives,
    calculation: mapLeaseCalculation(options),
    creditScore: options.creditScore,
    expiryDate: lease.program.expirationDate,
    id: getLeaseId(lease),
    isMaxAdvanceExceeded: lease.maxAdvanceExceeded,
    lenderId: '', // ToDo: remove after migration (NOTE: still required by API during save deal offer)
    lenderName: lease.program.lenderName,
    maxAdvance: lease.maxAdvance,
    millage: lease.identifier.mileage,
    moneyFactorValues: {
      markedUpValue: lease.moneyFactor.value,
      maxMarkup: lease.moneyFactor.maxMarkup,
      originalValue: lease.moneyFactor.value - lease.moneyFactor.markupApplied,
    },
    nonTtlFees: [
      {
        name: 'Acquisition Fee',
        value: lease.fees.rolledIn.acquisition - lease.profit.acquisition,
        dealType: DealType.Lease,
      },
      { name: 'Disposition Fee', value: lease.fees.dispositionFee, dealType: DealType.Lease },
      { name: 'Overmileage Fee', value: lease.fees.overMileageFee, dealType: DealType.Lease },
    ],
    profit: lease.profit,
    programId: lease.identifier.programId,
    programName: lease.program.name,
    residualAmount: lease.residualAmount,
    residualRate: lease.residualRate,
    term: lease.identifier.termMonths,
  };
}

export function mapLeaseCalculation({
  lease,
  ...options
}: MapLeaseLenderDataRequest): CalculationLeaseOutputData {
  const rolledInFees = lease.fees.rolledIn;
  const upfrontFees = lease.fees.upFront;
  const incentivesTotal = lease.incentives.total;
  const fees = options.payAllFeesUpfront ? upfrontFees : rolledInFees;

  return {
    capitalizedCost: lease.netCapitalizedCost,
    downPayment: lease.downPayment,
    maxOutOfPocket: lease.maxOutOfPocket,
    equityCoveredFees: lease.equityCoveredFees,
    fees: {
      addons: options.payAllPackagesUpfront ? upfrontFees.addons : rolledInFees.addons,
      leaseFees: {
        acquisitionFee: rolledInFees.acquisition,
        dispositionFee: lease.fees.dispositionFee,
        originalAcquisitionFee: rolledInFees.acquisition - lease.profit.acquisition,
        overMilageFee: lease.fees.overMileageFee,
      },
      rolledInFees: {
        addons: rolledInFees.addons,
        dealer: rolledInFees.dealer,
        sum: rolledInFees.total,
        ttl: _.sumBy(rolledInFees.titleLicense, (x) => x.value) + rolledInFees.salesTax.fee,
      },
      upfrontFees: {
        addons: upfrontFees.addons,
        dealer: upfrontFees.dealer,
        sum: upfrontFees.total,
        ttl: _.sumBy(upfrontFees.titleLicense, (x) => x.value) + upfrontFees.salesTax.fee,
      },
      totalFees: rolledInFees.total + upfrontFees.total,
    },
    firstPayment:
      options.payAllFeesUpfront || options.payFirstLeasePaymentUpfront ? lease.payment : 0,
    incentives: incentivesTotal,
    mileage: lease.identifier.mileage,
    moneyFactor: lease.moneyFactor.value,
    nonTtlFees: options.nonTtlFees,
    outstandingRolledInFees: lease.outstandingRolledInFees,
    payment: lease.payment,
    paymentDueAtSigning: lease.paymentDueAtSigning,
    rebates: incentivesTotal,
    residualAmount: lease.residualAmount,
    residualRate: lease.residualRate,
    term: lease.identifier.termMonths,
    ttlFee: {
      notes: mapNotes(options.ttl),
      precision: mapTtlPrecision(options.ttlPrecision),
      rollupFees: fees.titleLicense.map((x) => ({ feeName: x.name, feeAmount: x.value })),
      taxFee: fees.salesTax.fee,
      baseTaxFee: fees.salesTax.baseFee,
      taxRate: fees.salesTax.rate,
      monthlySaleTaxFee: lease.fees.monthlySalesTax,
      details: fees.salesTax.details,
    },
    vehiclePrice: lease.vehiclePrice,
    profit: lease.profit,
  };
}

export interface MapLoansLenderDataRequest extends MapLenderDataRequest {
  loanResult: LoanGridResult | undefined;
}

export interface MapLoanLenderDataRequest extends MapLenderDataRequest {
  loan: LoanResult;
}

export function mapLoanLenderData(options: MapLoansLenderDataRequest): LoanLenderData {
  const firstLoan = options.loanResult?.loans.length ? options.loanResult.loans[0] : undefined;
  const fees = options.payAllFeesUpfront ? firstLoan?.fees.upFront : firstLoan?.fees.rolledIn;
  const titleLicenseFees = fees?.titleLicense ?? [];

  return {
    programs: mapLoanPrograms(options),
    ttlFees: {
      notes: mapNotes(options.ttl),
      precision: mapTtlPrecision(options.ttlPrecision),
      rollupFees: titleLicenseFees.map((x) => ({ feeName: x.name, feeAmount: x.value })),
      taxFee: fees?.salesTax.fee ?? 0,
      baseTaxFee: fees?.salesTax.baseFee ?? 0,
      taxRate: fees?.salesTax.rate ?? 0,
      details: fees?.salesTax.details ?? [],
    },
  };
}

function mapLoanPrograms(options: MapLoansLenderDataRequest): LoanProgram[] {
  return (options.loanResult?.loans ?? []).map((loan) => mapLoanProgram({ ...options, loan }));
}

export function mapLoanProgram(options: MapLoanLenderDataRequest): LoanProgram {
  const { loan } = options;

  return {
    aprValues: {
      markedUpValue: loan.apr.value,
      maxMarkup: loan.apr.maxMarkup,
      originalValue: loan.apr.value - loan.apr.markupApplied,
    },
    incentives: loan.incentives,
    calculation: mapLoanCalculation(options),
    creditScore: options.creditScore,
    expiryDate: loan.program.expirationDate,
    id: getLoanId(loan),
    isMaxLtvExceeded: loan.maxLoanToValueExceeded,
    lenderId: '', // ToDo: remove after migration (NOTE: still required by API during save deal offer)
    lenderName: loan.program.lenderName,
    ltv: [], // ToDo: remove after migration (NOTE: still required by API during save deal offer)
    nonTtlFees: options.nonTtlFees,
    profit: loan.profit,
    programId: loan.identifier.programId,
    programName: loan.program.name,
    term: loan.identifier.termMonths,
  };
}

export function mapLoanCalculation(options: MapLoanLenderDataRequest): CalculationLoanOutputData {
  const { loan } = options;
  const rolledInFees = loan.fees.rolledIn;
  const upfrontFees = loan.fees.upFront;
  const incentivesTotal = loan.incentives.total;
  const fees = options.payAllFeesUpfront ? upfrontFees : rolledInFees;

  return {
    apr: loan.apr.value,
    downPayment: loan.downPayment,
    maxOutOfPocket: loan.maxOutOfPocket,
    equityCoveredFees: loan.equityCoveredFees,
    fees: {
      addons: options.payAllPackagesUpfront ? upfrontFees.addons : rolledInFees.addons,
      loanFees: { originationFee: 0 }, // ToDo: remove after migration
      rolledInFees: {
        addons: rolledInFees.addons,
        dealer: rolledInFees.dealer,
        sum: rolledInFees.total,
        ttl: _.sumBy(rolledInFees.titleLicense, (x) => x.value) + rolledInFees.salesTax.fee,
      },
      upfrontFees: {
        addons: upfrontFees.addons,
        dealer: upfrontFees.dealer,
        sum: upfrontFees.total,
        ttl: _.sumBy(upfrontFees.titleLicense, (x) => x.value) + upfrontFees.salesTax.fee,
      },
      totalFees: rolledInFees.total + upfrontFees.total,
    },
    incentives: incentivesTotal,
    ltv: loan.loanToValue,
    nonTtlFees: options.nonTtlFees,
    outstandingRolledInFees: loan.outstandingRolledInFees,
    payment: loan.payment,
    paymentDueAtSigning: loan.paymentDueAtSigning,
    rebates: incentivesTotal,
    totalAmountFinanced: loan.totalAmountFinanced,
    transactionAmount: loan.totalAmountFinanced,
    ttlFee: {
      notes: mapNotes(options.ttl),
      precision: mapTtlPrecision(options.ttlPrecision),
      rollupFees: fees.titleLicense.map((x) => ({ feeName: x.name, feeAmount: x.value })),
      taxFee: fees.salesTax.fee,
      baseTaxFee: fees.salesTax.baseFee,
      taxRate: fees.salesTax.rate,
      details: fees.salesTax.details,
    },
    vehiclePrice: loan.vehiclePrice,
    profit: loan.profit,
  };
}

function getLoanId({ identifier: { programId, termMonths } }: LoanResult): string {
  return `${programId}-${termMonths}`;
}

function mapTtlPrecision(ttlPrecision: TtlPrecision): Precision {
  return ttlPrecision === TtlPrecision.High ? Precision.High : Precision.Low;
}

function mapNotes(ttl: ProgramTtl): TtlApiNote[] {
  return ttl.notes.map((x) => mapNote(x));
}

function mapNote(note: TtlNote): TtlApiNote {
  return {
    sourceType: note.category,
    text: note.text,
  };
}
