import React, { useCallback } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

import { useSaveDealerMutation } from 'hooks/useSaveDealerMutation';

import { Dealer } from 'services/DealerApiService';

import {
  DealerAdminFormValues,
  mapFormLenderSettings,
  mapLenderSettings,
  getDefaultLeaseMileageOptions,
  getDefaultLeaseTermOptions,
  getDefaultLoanTermOptions,
} from './DealerAdminFormService';

interface HookProps {
  dealer: Dealer;
}

interface HookResult {
  dealerAdminForm: UseFormReturn<DealerAdminFormValues>;
  handleOnSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isSubmitting: boolean;
  getDefaultLeaseMileageOptions: typeof getDefaultLeaseMileageOptions;
  getDefaultLeaseTermOptions: typeof getDefaultLeaseTermOptions;
  getDefaultLoanTermOptions: typeof getDefaultLoanTermOptions;
}

export const useDealerAdminForm = ({ dealer }: HookProps): HookResult => {
  const dealerAdminForm = useForm<DealerAdminFormValues>({
    defaultValues: {
      lenderSettings: mapFormLenderSettings(dealer.featuredLendersSettings),
      dealer: dealer,
    },
  });

  const saveDealerMutation = useSaveDealerMutation();

  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.stopPropagation();
      dealerAdminForm.handleSubmit(async (values) => {
        const lenderSettings = mapLenderSettings(values.lenderSettings);

        const updatedDealer = {
          ...values.dealer,
          featuredLendersSettings: Object.fromEntries(lenderSettings),
        };
        await saveDealerMutation.mutateAsync(updatedDealer);
      })(event);
    },
    [dealer]
  );

  return {
    dealerAdminForm,
    handleOnSubmit,
    isSubmitting: saveDealerMutation.isLoading,
    getDefaultLeaseMileageOptions,
    getDefaultLeaseTermOptions,
    getDefaultLoanTermOptions,
  };
};
