import React from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import { RadioFormField } from 'components/molecules/form-fields';

import { LoanProgram, ProgramType } from 'services/PdpApiService';

import {
  createReserveTypeRadioOptions,
  getProfitHeaderText,
  getProgramHeader,
} from './ReserveModalService';
import { useLoanReserveForm } from './useLoanReserveForm';

interface Props {
  loan: LoanProgram;
  isReadOnly: boolean;
}

export const LoanReserveForm: React.FC<Props> = ({ loan, isReadOnly }) => {
  const { reserveType } = useLoanReserveForm();

  const reserveTypeRadioOptions = createReserveTypeRadioOptions(
    loan.profit.reserve,
    loan.aprValues.maxMarkup,
    ProgramType.Loan,
    reserveType,
    isReadOnly
  );

  return (
    <Stack minWidth="50%" spacing={2}>
      <Typography variant="subtitle3">Loan reserve</Typography>
      <Stack>
        {getProgramHeader('Lender:', loan.lenderName)}
        {getProgramHeader('Program:', loan.programName)}
        {getProgramHeader(
          'Profit:',
          getProfitHeaderText(
            loan.profit.reserve,
            undefined,
            loan.calculation.totalAmountFinanced,
            ProgramType.Loan
          )
        )}
      </Stack>
      <Divider></Divider>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="subtitle3">Dealer reserve type</Typography>
          <RadioFormField
            name="loanReserveType"
            items={reserveTypeRadioOptions}
            labelTypographyVariant="overline2"
            isNumber
            selectorPrefix="loanReserveModal"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
