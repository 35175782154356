import { PdpCollapsibleExpandableSectionState } from 'models/PdpCollapsibleSectionModel';

export enum ExpandedType {
  Loan,
  Lease,
  ProfitLease,
  ProfitLoan,
}

export function getCollapsibleSectionState(
  type: ExpandedType,
  currentValue: PdpCollapsibleExpandableSectionState
): PdpCollapsibleExpandableSectionState {
  const { lease, loan, profitLease, profitLoan } = currentValue;

  if (type === ExpandedType.Lease) {
    return { ...currentValue, lease: !lease };
  }
  if (type === ExpandedType.Loan) {
    return { ...currentValue, loan: !loan };
  }
  if (type === ExpandedType.ProfitLease) {
    return { ...currentValue, profitLease: !profitLease };
  }

  return { ...currentValue, profitLoan: !profitLoan };
}
