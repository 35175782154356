import React from 'react';
import { RecoilState } from 'recoil';

import { SwitchFormField } from 'components/molecules/form-fields';
import { SwitchFormFieldProps } from 'components/molecules/form-fields/switch-form-field/SwitchFormField';
import { usePersistedSwitch } from 'components/molecules/switch/usePersistedSwitch';

export type PersistedSwitchFormFieldProps = SwitchFormFieldProps & {
  state: RecoilState<boolean>;
};

export const PersistedSwitchFormField: React.FC<PersistedSwitchFormFieldProps> = ({
  state,
  ...props
}) => {
  const { handleChange, value } = usePersistedSwitch({ recoilValue: state });

  return <SwitchFormField {...props} checked={value} onChange={handleChange} />;
};
