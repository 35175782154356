import React from 'react';

import { Divider, Grid, InputAdornment, Stack, Typography } from '@mui/material';

import { NumberFormField, RadioFormField } from 'components/molecules/form-fields';
import { NumberHandlingMode } from 'components/molecules/form-fields/text-form-field/useTextFormField';

import { LeaseProgram, ProgramType } from 'services/PdpApiService';

import {
  createReserveTypeRadioOptions,
  getProfitHeaderText,
  getProgramHeader,
} from './ReserveModalService';
import { useLeaseReserveForm } from './useLeaseReserveForm';

interface Props {
  lease: LeaseProgram;
  isReadOnly: boolean;
}

export const LeaseReserveForm: React.FC<Props> = ({ lease, isReadOnly }) => {
  const { reserveType } = useLeaseReserveForm();

  return (
    <Stack minWidth="50%" spacing={2}>
      <Typography variant="subtitle3">Lease reserve</Typography>
      <Stack>
        {getProgramHeader('Lender:', lease.lenderName)}
        {getProgramHeader('Program:', lease.programName)}
        {getProgramHeader(
          'Profit:',
          getProfitHeaderText(
            lease.profit.reserve,
            lease.profit.acquisition,
            lease.calculation.capitalizedCost,
            ProgramType.Lease
          )
        )}
      </Stack>
      <Divider></Divider>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="subtitle3">Dealer reserve type</Typography>
          <RadioFormField
            name="leaseReserveType"
            items={createReserveTypeRadioOptions(
              lease.profit.reserve,
              lease.moneyFactorValues.maxMarkup,
              ProgramType.Lease,
              reserveType,
              isReadOnly
            )}
            labelTypographyVariant="overline2"
            isNumber
            selectorPrefix="leaseReserveModal"
          />
        </Stack>
        <Stack spacing={2}>
          <Typography variant="subtitle3">Acquisition fee markup</Typography>
          <Grid>
            <Stack direction="row" spacing={2}>
              <NumberFormField
                name="markedUpAcquisitionFee"
                label="Updated acquisition fee"
                dataCy="reserveModal_acquisitionFee_input"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  disabled: isReadOnly,
                }}
                numberHandlingMode={NumberHandlingMode.AsUndefined}
                focused
              ></NumberFormField>
              <Stack>
                <Typography
                  fontWeight={700}
                  variant="body4"
                  data-cy="reserveModal_origAcquisitionFee"
                >
                  ORIGINAL FEE
                </Typography>
                <Typography variant="body4" data-cy="reserveModal_origAcquisitionFee_value">
                  ${lease.calculation.fees.leaseFees.originalAcquisitionFee}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};
