import { useFormContext } from 'react-hook-form';

import { ReserveType } from 'services/ReserveService';

import { ReserveFormValues } from './ReserveModalService';

interface HookResult {
  reserveType: ReserveType;
}

export const useLeaseReserveForm = (): HookResult => {
  const { leaseReserveType } = useFormContext<ReserveFormValues>().watch();

  return {
    reserveType: leaseReserveType,
  };
};
