import { ChipPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

import { CarState, CarStatus } from 'services/SearchApiService';

type Color = OverridableStringUnion<
  'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning',
  ChipPropsColorOverrides
>;

const chipColorByState: Record<CarState, Color> = {
  [CarState.New]: 'blue700',
  [CarState.Used]: 'brandOrange',
  [CarState.Sold]: 'gray600',
  [CarState.CPO]: 'success',
};

const chipColorByStatus: Record<CarStatus, Color | null> = {
  [CarStatus.InTransit]: 'gray700',
  [CarStatus.Virtual]: null,
  [CarStatus.Active]: null,
};

const chipTextByStatus: Record<CarStatus, string | null> = {
  [CarStatus.InTransit]: 'Transit',
  [CarStatus.Virtual]: null,
  [CarStatus.Active]: null,
};

interface HookResult {
  chipColor: Color;
  chipLabel: string;
}

interface HookProps {
  state: CarState;
  status: CarStatus;
}

export const useVehicleHeader = ({ state, status }: HookProps): HookResult => {
  if (state === CarState.Sold) {
    return {
      chipColor: chipColorByState[state],
      chipLabel: Object.values(CarState)[state].toLocaleString(),
    };
  }

  return {
    chipColor: chipColorByStatus[status] ?? chipColorByState[state],
    chipLabel:
      chipTextByStatus[status]?.toLocaleString() ?? Object.values(CarState)[state].toLocaleString(),
  };
};
