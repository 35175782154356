import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { dealOfferCodeAtom } from 'models/DealOfferModel';

import { getResponderUrl, PdpItem } from 'services/PdpApiService';

interface HookOptions {
  pdpData: PdpItem;
}

interface HookResult {
  handleOpenResponder(values: PdpFormValues): Promise<void>;
}

export const useOpenResponder = ({ pdpData }: HookOptions): HookResult => {
  const navigate = useNavigate();

  const dealOfferId = useRecoilValue(dealOfferCodeAtom);

  const handleOpenResponder = useCallback(async () => {
    if (dealOfferId) {
      navigate(getResponderUrl(dealOfferId));
    }
  }, [pdpData, dealOfferId]);

  return { handleOpenResponder };
};
