import { UseFormSetValue } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';

import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { getCreditTierById } from 'services/CreditTiersService';
import { CreditStatus, Customer } from 'services/CustomerService';

export async function loadCustomer(
  findCustomer: () => Promise<QueryObserverResult<Customer | undefined, unknown>>,
  setValue: UseFormSetValue<SearchFormValues>
): Promise<Customer | undefined> {
  return await findCustomer().then(({ data: customer }) => {
    if (customer) {
      setValue('customer', customer);
      setValue('calculationOptions.location', customer.location);
      updateCreditTier(customer, setValue);
    }

    return customer;
  });
}

function updateCreditTier(customer: Customer, setValue: UseFormSetValue<SearchFormValues>): void {
  if (customer.creditTierId) {
    const creditTierOption = getCreditTierById(customer.creditTierId);

    if (creditTierOption) {
      setValue('calculationOptions.creditScore', creditTierOption.value);
    }
  }
}

export function resetCustomer(
  customer: Customer,
  setValue: UseFormSetValue<SearchFormValues>
): void {
  setValue('customer', {
    ...customer,
    id: 0,
    creditTierId: undefined,
    creditStatus: CreditStatus.Unverified,
  });
}
