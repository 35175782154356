import React from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import { Button, Grid, Stack, Typography } from '@mui/material';

import { SingleCheckboxFormField } from 'components/molecules/form-fields/single-checkbox-form-field/SingleCheckboxFormField';

import { UserDealerAssignmentFormValues } from './UserDealerAssignmentFormService';
import { useUserDealerAssignmentForm } from './useUserDealerAssignmentForm';

interface Props {
  fields: FieldArrayWithId<UserDealerAssignmentFormValues, 'userDealers', 'id'>[];
}

export const UserDealerAssignmentForm: React.FC<Props> = ({ fields }: Props) => {
  const { handleSelectAll } = useUserDealerAssignmentForm({ fields });

  return (
    <form data-cy="user-dealer-assignment-form">
      <Stack>
        <Grid container>
          <Grid item xs={2}>
            <Button variant="contained" onClick={handleSelectAll} component="label">
              Select All
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} paddingLeft={2} paddingRight={2} marginTop={0}>
          <Grid item xs={4}>
            <></>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">New Cars</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Used Cars</Typography>
          </Grid>
          {fields.map((field, i) => (
            <Grid item xs={12} key={field.publicId}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography>{field.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <SingleCheckboxFormField
                    name={`userDealers[${i}].carsNew`}
                    dataCyPrefix={`userDealerAssignmentForm_${field.publicId}_carsNew_checkbox`}
                    disabled={field.disabled}
                    label=""
                  />
                </Grid>
                <Grid item xs={2}>
                  <SingleCheckboxFormField
                    name={`userDealers[${i}].carsUsed`}
                    dataCyPrefix={`userDealerAssignmentForm_${field.publicId}_carsUsed_checkbox`}
                    disabled={field.disabled}
                    label=""
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </form>
  );
};
