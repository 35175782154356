import React from 'react';

import { Card, Grid, Skeleton } from '@mui/material';

const DEFAULT_SKELETON_ITEMS = 4;
const DEFAULT_SKELETON_HEIGHT = 300;

export const SearchSkeleton: React.FC = () => (
  <Grid container spacing={2}>
    {Array.from(Array(DEFAULT_SKELETON_ITEMS).keys()).map((index) => (
      <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3}>
        <Card>
          <Skeleton
            data-cy={`searchPage_spinner${index}`}
            variant="rectangular"
            width="100%"
            height={DEFAULT_SKELETON_HEIGHT}
            animation="wave"
          />
        </Card>
      </Grid>
    ))}
  </Grid>
);
