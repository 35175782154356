import { useContext } from 'react';
import { UseMutationResult, useMutation } from 'react-query';

import { AxiosError, AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import {
  CreditApplicationResponse,
  getCreditApplicationUrl as createSaveCreditApplicationRequest,
} from 'services/CreditApplicationService';

import { handleOnError } from 'utils/queryUtils';

export interface PushToCrmRequest {
  dealOfferId: string | undefined;
}

export const useSaveCreditApplicationMutation = (): UseMutationResult<
  AxiosResponse,
  unknown,
  string,
  unknown
> => {
  const axiosClient = useContext(AxiosContext);
  const creditApplicationMutation = useMutation(
    (dealOfferId: string) =>
      axiosClient.request<CreditApplicationResponse>(
        createSaveCreditApplicationRequest(dealOfferId)
      ),
    {
      onError: (error: AxiosError) => {
        handleOnError(error, 'Failed to save credit application');
      },
      onSuccess: ({ data }) => {
        if (data.link.href) {
          window.open(data.link.href, '_blank', 'noopener');
        }
      },
      retry: false,
    }
  );

  return creditApplicationMutation;
};
