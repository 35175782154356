import React from 'react';

import { ExpandedType } from 'components/molecules/accordion';
import { DetailsCard } from 'components/pdp/details-card';

import { CalculationLoanOutput } from 'services/CalculationService';

import { DATA_CY_LOAN_CARD_PREFIX } from './LoanDetailsCardService';
import { useLoanDetailsCard } from './useLoanDetailsCard';

// ToDo - Move remaining LoadDetails fields to CalculationOutput
export interface LoanDetails {
  msrp: number;
  buyerZipCode: string;
  payAllPackagesUpfront?: boolean;
  payAllFeesUpfront?: boolean;
}

interface LoanDetailsCardProps {
  loanDetail: LoanDetails;
  calculationOutput: CalculationLoanOutput;
}

export const LoanDetailsCard: React.FC<LoanDetailsCardProps> = ({
  loanDetail,
  calculationOutput,
}) => {
  const {
    expanded,
    loanDetailsItems,
    loanSigningCalculationItems,
    loanSigningTtlCalculationItems,
    ttlNotes,
    salesTaxDetails,
  } = useLoanDetailsCard({
    loanDetail,
    calculationOutput,
  });

  return (
    <DetailsCard
      dataCyPrefix={DATA_CY_LOAN_CARD_PREFIX}
      dueAtSigning={calculationOutput?.paymentDueAtSigning}
      detailItems={loanDetailsItems}
      calculationItems={loanSigningCalculationItems}
      signingTtlCalculationItems={loanSigningTtlCalculationItems}
      titleText="Loan Details"
      expanded={expanded}
      type={ExpandedType.Loan}
      ttlNotes={ttlNotes}
      salesTaxDetails={salesTaxDetails}
    />
  );
};
