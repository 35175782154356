import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Layout } from 'components/molecules/layout';

export const NotFoundPage: React.FC = () => (
  <Layout>
    <Stack justifyContent="center" alignItems="center" paddingTop={5}>
      <Typography variant="h4">Page not found</Typography>
    </Stack>
  </Layout>
);
