import React, { useCallback } from 'react';
import { RecoilState, useRecoilState } from 'recoil';

interface HookOptions {
  recoilValue: RecoilState<boolean>;
}

interface HookResult {
  value: boolean;
  handleChange(e: React.ChangeEvent, checked: boolean): void;
}

export const usePersistedSwitch = ({ recoilValue }: HookOptions): HookResult => {
  const [value, setValue] = useRecoilState(recoilValue);

  const handleChange = useCallback((_e: React.ChangeEvent, checked: boolean) => {
    setValue(checked);
  }, []);

  return {
    value,
    handleChange,
  };
};
