import { useContext } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { AxiosError } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { Dealer, getDealerApiUrl } from 'services/DealerApiService';

import { handleOnError } from 'utils/queryUtils';

import { useConfiguration } from './useConfiguration';

interface HookProps {
  dealerId: string;
}

interface HookResult {
  dealerQuery: UseQueryResult<Dealer, unknown>;
}

export const useDealerQuery = ({ dealerId }: HookProps): HookResult => {
  const axiosClient = useContext(AxiosContext);
  const staleTime = 3600000;
  const { dealerApiUrl } = useConfiguration();

  const url = getDealerApiUrl(dealerId);
  const dealerQuery = useQuery(
    url,
    async () => {
      const { data } = await axiosClient.get<Dealer>(url, {
        baseURL: dealerApiUrl,
      });

      return data;
    },
    {
      staleTime,
      onError: (error: AxiosError) => {
        handleOnError(error, `Failed to load dealer: ${dealerId}`);
      },
    }
  );

  return {
    dealerQuery,
  };
};
