import React from 'react';

import { SearchFilters } from 'services/SearchFiltersApiService';

import { ColorItem, FilterItem, getColorItems, getFilterItems } from './FilterBlocksService';

interface HookOptions {
  filters?: SearchFilters;
  getColorLabel(name: string, rgb: string, count: number): React.ReactElement;
}

interface HookResult {
  showDealerFilter: boolean;
  dealerItems: FilterItem<number>[];
  programAvailabilityItems: FilterItem<number>[];
  carStateItems: FilterItem<number>[];
  makeItems: FilterItem<string>[];
  bodyStyleItems: FilterItem<string>[];
  modelItems: FilterItem<string>[];
  trimItems: FilterItem<string>[];
  interiorColorItems: ColorItem[];
  exteriorColorItems: ColorItem[];
  fuelTypeItems: FilterItem<string>[];
}

export const useFilterBlocks = ({ filters, getColorLabel }: HookOptions): HookResult => {
  const dealerItems = getFilterItems(filters?.dealers);
  const programAvailabilityItems = getFilterItems(filters?.programAvailability);
  const carStateItems = getFilterItems(filters?.carState);
  const makeItems = getFilterItems(filters?.makes);
  const bodyStyleItems = getFilterItems(filters?.bodyStyles);
  const modelItems = getFilterItems(filters?.models);
  const trimItems = getFilterItems(filters?.trims);
  const interiorColorItems = getColorItems(getColorLabel, filters?.interiorColors);
  const exteriorColorItems = getColorItems(getColorLabel, filters?.exteriorColors);
  const fuelTypeItems = getFilterItems(filters?.fuelTypes);

  return {
    showDealerFilter: filters?.canFilterDealers ?? false,
    dealerItems,
    programAvailabilityItems,
    carStateItems,
    makeItems,
    bodyStyleItems,
    modelItems,
    trimItems,
    interiorColorItems,
    exteriorColorItems,
    fuelTypeItems,
  };
};
