import { useRecoilValue } from 'recoil';

import { pdpCollapsibleSectionExpandedLoanAtom } from 'models/PdpCollapsibleSectionModel';

import { CalculationLoanOutput } from 'services/CalculationService';
import { SalesTaxDetailItem } from 'services/payment-grid/PaymentGridApiModels.generated';
import { TtlNote } from 'services/TtlApiService';

import { LoanDetails } from './LoanDetailsCard';
import {
  getLoanDetailItems,
  getLoanSigningCalculationItems,
  getLoanSigningTtlCalculationItems,
  LoanDetailItem,
  SigningCalculationItems,
} from './LoanDetailsCardService';

interface HookOptions {
  loanDetail: LoanDetails;
  calculationOutput: CalculationLoanOutput;
}

interface HookResult {
  expanded: boolean;
  loanSigningCalculationItems: SigningCalculationItems[];
  loanSigningTtlCalculationItems: SigningCalculationItems[];
  loanDetailsItems: LoanDetailItem[];
  ttlNotes?: TtlNote[];
  salesTaxDetails: SalesTaxDetailItem[];
}

export const useLoanDetailsCard = ({ loanDetail, calculationOutput }: HookOptions): HookResult => {
  const expanded = useRecoilValue(pdpCollapsibleSectionExpandedLoanAtom);

  return {
    loanSigningCalculationItems: getLoanSigningCalculationItems(calculationOutput),
    loanSigningTtlCalculationItems: getLoanSigningTtlCalculationItems(calculationOutput),
    loanDetailsItems: getLoanDetailItems(loanDetail, calculationOutput),
    expanded,
    ttlNotes: calculationOutput.ttlFee?.notes,
    salesTaxDetails: calculationOutput.ttlFee?.details ?? [],
  };
};
