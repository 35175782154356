import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { AxiosContext } from 'providers/AxiosProvider';

import { AdministrationUser, getDetailUserUrl } from 'services/UserAdministrationApiService';

interface HookOptions {
  userID: string;
}
interface HookResult {
  getUserQuery: UseQueryResult<AdministrationUser, unknown>;
}

export const useUserDetailQuery = ({ userID }: HookOptions): HookResult => {
  const axiosClient = useContext(AxiosContext);

  const getUserQuery = useQuery(
    ['userQuery', userID],
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { data } = await axiosClient.get<AdministrationUser>(getDetailUserUrl(userID!));

      return data;
    },
    { enabled: !!userID, staleTime: 0 }
  );

  return {
    getUserQuery,
  };
};
