import React, { useCallback, useContext, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { pdpCollapsibleSectionExpandedSelector } from 'models/PdpCollapsibleSectionModel';
import { priceRangeAtom } from 'models/PriceRangeModel';

interface HookResult {
  priceRangeValue: boolean;
  handlePriceRangeChange(e: React.ChangeEvent, checked: boolean): void;
  showPriceRangeToggle: boolean;
}

export const usePriceRangeSwitch = (): HookResult => {
  const [priceRangeValue, setPriceRangeValue] = useRecoilState(priceRangeAtom);
  const updateCollapsibleSectionExpanded = useSetRecoilState(pdpCollapsibleSectionExpandedSelector);
  const { isBdcRepresentative } = useContext(PermissionsContext);

  useEffect(() => {
    if (isBdcRepresentative) {
      setPriceRangeValue(isBdcRepresentative);
    }
  }, [isBdcRepresentative]);

  const handlePriceRangeChange = useCallback((e: React.ChangeEvent, checked: boolean) => {
    setPriceRangeValue(checked);
    updateCollapsibleSectionExpanded({
      lease: !checked,
      loan: !checked,
      profitLease: !checked,
      profitLoan: !checked,
    });
  }, []);

  return {
    priceRangeValue: isBdcRepresentative || priceRangeValue,
    handlePriceRangeChange,
    showPriceRangeToggle: shouldShowPriceRangeToggle(isBdcRepresentative),
  };
};

function shouldShowPriceRangeToggle(isBdcRepresentative: boolean): boolean {
  return !isBdcRepresentative;
}
