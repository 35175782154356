import React from 'react';

import { Stack } from '@mui/material';

import { Scrollbar } from 'components/atoms/scrollbar';
import { AppBar } from 'components/molecules/app-bar';
import { Footer } from 'components/molecules/footer';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Scrollbar maxheight="100vh">
    <Stack direction="column" justifyContent="space-between" height="100vh">
      <div>
        <AppBar />
        {children}
      </div>
      <Footer />
    </Stack>
  </Scrollbar>
);
