import { TagManagerArgs } from 'react-gtm-module';

interface SnackBarOptions {
  autoHideDurationMs: number;
  maxSnackAcount: number;
}

interface TinyMceEditor {
  apiKey: string;
}

interface AuthProviderOptions {
  domain: string;
  clientId: string;
  audience: string;
}

interface GoogleTagManagerOptions extends TagManagerArgs {
  enabled: boolean;
}

interface SearchOptions {
  defaultMaxCount: number;
}

interface AppInsightOptions {
  connectionString: string;
  enabled: boolean;
}

interface LaunchDarklyOptions {
  clientSideID: string;
  flags?: Map<string, boolean>;
}

interface IntervalOptions {
  defaultInterval: number;
  dueAtSigningInterval: number;
}

export interface Configuration {
  apiUrl: string;
  dealerApiUrl: string;
  reportServiceApiUrl: string;
  reactQueryDevToolsEnabled: boolean;
  snackBarOptions: SnackBarOptions;
  refreshTokenMinutesInterval: number;
  tinyMceEditor: TinyMceEditor;
  authProviderOptions: AuthProviderOptions;
  googleTagManagerOptions: GoogleTagManagerOptions;
  searchOptions: SearchOptions;
  browserConsoleLoggingEnabled: boolean;
  appInsightOptions: AppInsightOptions;
  launchDarklyOptions: LaunchDarklyOptions;
  paymentsIntervalOptions: IntervalOptions;
  calculationLoggingEnabled: boolean;
}

export const useConfiguration = (): Configuration => {
  const configuration = (window as any).env;

  return configuration;
};
