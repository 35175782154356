import { UserRole, UserRoles } from './userRoles';

export enum UserPermission {
  adminUsers,
  editUserDealerGroup,
  editAllUserDealers,
  viewDealers,
  editDealers,
  editDealersLenders,
  viewReports,
  viewProfitAmount,
}

export interface RoleBasedPermission {
  permission: UserPermission;
  grantedRoles: UserRole[];
}

export const RoleBasedPermissions: RoleBasedPermission[] = [
  {
    permission: UserPermission.adminUsers,
    grantedRoles: [UserRoles.carmaticAdmin, UserRoles.dealerGroupAdmin, UserRoles.dealerAdmin],
  },
  {
    permission: UserPermission.editUserDealerGroup,
    grantedRoles: [UserRoles.carmaticAdmin],
  },
  {
    permission: UserPermission.editAllUserDealers,
    grantedRoles: [UserRoles.carmaticAdmin, UserRoles.dealerGroupAdmin],
  },
  {
    permission: UserPermission.viewDealers,
    grantedRoles: [UserRoles.carmaticAdmin, UserRoles.dealerGroupAdmin, UserRoles.dealerAdmin],
  },
  {
    permission: UserPermission.editDealers,
    grantedRoles: [UserRoles.carmaticAdmin],
  },
  {
    permission: UserPermission.editDealersLenders,
    grantedRoles: [UserRoles.carmaticAdmin, UserRoles.dealerGroupAdmin, UserRoles.dealerAdmin],
  },
  {
    permission: UserPermission.viewReports,
    grantedRoles: [
      UserRoles.carmaticAdmin,
      UserRoles.dealerGroupAdmin,
      UserRoles.dealerAdmin,
      UserRoles.generalManager,
    ],
  },
  {
    permission: UserPermission.viewProfitAmount,
    grantedRoles: [UserRoles.deskingManager, UserRoles.generalManager],
  },
];
