import React from 'react';

import {
  Button,
  CardContent,
  CardProps,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { Card } from 'components/atoms/card';
import { OverlaySpinner } from 'components/atoms/overlaySpinner';
import { SwitchFormField } from 'components/molecules/form-fields';
import { PersistedSwitchFormField } from 'components/molecules/switch/PersistedSwitchFormField';
import { GridTableWrapper, GridSwitch, LeasesSwitch, LoansSwitch } from 'components/pdp/grid-view';
import { InfoTile } from 'components/pdp/info-tile/InfoTile';

import { payFirstLeasePaymentUpfrontAtom, useOnlyCaptiveLendersAtom } from 'models/GridViewModel';

import { LeaseTerm, LoanTerm, LeaseMileage } from './filter-fields';
import { InfoBlockWrapper } from './info-block/InfoBlockWrapper';
import { usePaymentCustomizationBlock } from './usePaymentCustomizationBlock';

const CardStyled = styled(Card)<CardProps>(() => ({
  height: 'auto',
  width: 'auto',
}));

const StyledTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{
  isDisabled?: boolean;
}>(({ isDisabled, theme }) => ({
  color: isDisabled ? `${theme.palette.grey[400]}` : 'initial',
  userSelect: isDisabled ? 'none' : 'initial',
}));

interface Props {
  isCarSold?: boolean;
  isDisabled: boolean;
}

const StyledButton = styled(Button)({
  justifyContent: 'flex-start',
});

export const PaymentCustomizationBlock: React.FC<Props> = ({ isCarSold, isDisabled }) => {
  const {
    vehicleDescription,
    gridViewEnabled,
    loansViewEnabled,
    leasesViewEnabled,
    isRefreshing,
    showPaymentCustomizationBlock,
    handleCreateVirtualCar,
    payAllFeesUpfront,
  } = usePaymentCustomizationBlock();

  return (
    <Grid container spacing={2} height="100%">
      {showPaymentCustomizationBlock && (
        <>
          {isCarSold ? (
            <Grid item xs={12}>
              <OverlaySpinner showSpinner={isRefreshing}>
                <InfoTile
                  title="The car has been sold."
                  text="You can look for similar vehicles on the left side that are in stock."
                  selectorPrefix="carUnavailableBox"
                >
                  <>
                    <Typography
                      variant="body4"
                      padding="16px 0 8px 0"
                      data-cy="build_virtual_vehicle_text"
                    >
                      Or, you can create a virtual vehicle for {vehicleDescription}
                    </Typography>
                    <StyledButton
                      variant="contained"
                      onClick={handleCreateVirtualCar}
                      data-cy="build_virtual_vehicle_button"
                    >
                      Build Virtual Vehicle
                    </StyledButton>
                  </>
                </InfoTile>
              </OverlaySpinner>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <CardStyled elevation={13} data-cy="paymentInformationBox">
                <CardContent>
                  <Stack direction="column" spacing={2}>
                    <Stack
                      direction="row"
                      spacing={2}
                      divider={<Divider orientation="vertical" flexItem />}
                      width="100%"
                    >
                      <Stack direction="column" width="100%" spacing={2}>
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          spacing={2}
                          alignItems="center"
                        >
                          <StyledTitle
                            variant="subtitle3"
                            data-cy="paymentInformationBox_lease_title"
                            isDisabled={!leasesViewEnabled}
                          >
                            Lease
                          </StyledTitle>
                          <LeasesSwitch />
                        </Stack>
                        <Stack direction="row" width="100%" alignItems="center" spacing={2}>
                          <LeaseMileage isDisabled={isDisabled || !leasesViewEnabled} />
                          <LeaseTerm isDisabled={isDisabled || !leasesViewEnabled} />
                        </Stack>
                      </Stack>
                      <Stack
                        direction="column"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <StyledTitle
                            variant="subtitle3"
                            data-cy="paymentInformationBox_loan_title"
                            isDisabled={!loansViewEnabled}
                          >
                            Loan
                          </StyledTitle>
                          <LoansSwitch />
                        </Stack>
                        <Stack
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <LoanTerm
                            gridViewEnabled={gridViewEnabled}
                            isDisabled={isDisabled || !loansViewEnabled}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="row" width="100%" spacing={2}>
                      <Stack
                        direction="row"
                        width="100%"
                        justifyContent="flex-start"
                        spacing={2}
                        alignItems="center"
                      >
                        <SwitchFormField
                          name="payAllFeesUpfront"
                          dataCyPrefix="pdpPage_paymentCustomization_payAllFeesUpfront"
                          label="Pay All Fees Upfront"
                          disabled={isDisabled}
                        />
                        {leasesViewEnabled && !payAllFeesUpfront && (
                          <PersistedSwitchFormField
                            name="payFirstLeasePaymentUpfront"
                            dataCyPrefix="pdpPage_paymentCustomization_payFirstLeasePaymentUpfront"
                            label="Pay 1st Lease Payment Upfront"
                            state={payFirstLeasePaymentUpfrontAtom}
                            disabled={isDisabled}
                          />
                        )}
                        <PersistedSwitchFormField
                          name="captive"
                          dataCyPrefix="pdpPage_paymentCustomization_captive"
                          label="Use only captive lenders"
                          state={useOnlyCaptiveLendersAtom}
                          disabled={isDisabled}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        width="100%"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <GridSwitch />
                      </Stack>
                    </Stack>
                    <OverlaySpinner showSpinner={isRefreshing}>
                      <GridTableWrapper />
                      <InfoBlockWrapper />
                    </OverlaySpinner>
                  </Stack>
                </CardContent>
              </CardStyled>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
