import { useFormContext } from 'react-hook-form';

import _ from 'lodash';

import { MultiSelectOptions } from 'components/molecules/form-fields/multi-select-form-field/MultiSelectFormField';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

interface HookResult {
  options: MultiSelectOptions[];
  current: number[];
}

export const useLoanTermFormField = (): HookResult => {
  const {
    loanTerms,
    programFilters: { loanTerms: selectedLoanTerms },
  } = useFormContext<PdpFormValues>().getValues();

  const options = loanTerms.map((term) => {
    return {
      label: `${term}`,
      value: term,
      isChecked: selectedLoanTerms.some((x) => term === x),
    };
  });
  const current = _.intersection(loanTerms, selectedLoanTerms);

  return { options, current };
};
