import React, { useCallback, useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { viewProfitAtom } from 'models/ViewProfitModel';

interface HookResult {
  viewProfitValue: boolean;
  handleViewProfitChange(e: React.ChangeEvent, checked: boolean): void;
  showViewProfitToggle: boolean;
}

export const useViewProfitSwitch = (): HookResult => {
  const [viewProfitValue, setViewProfitValue] = useRecoilState(viewProfitAtom);
  const { isBdcRepresentative } = useContext(PermissionsContext);

  useEffect(() => {
    if (isBdcRepresentative) {
      setViewProfitValue(isBdcRepresentative);
    }
  }, [isBdcRepresentative]);

  const handleViewProfitChange = useCallback((_e: React.ChangeEvent, checked: boolean) => {
    setViewProfitValue(checked);
  }, []);

  return {
    viewProfitValue: isBdcRepresentative || viewProfitValue,
    handleViewProfitChange,
    showViewProfitToggle: shouldShowViewProfitToggle(isBdcRepresentative),
  };
};

function shouldShowViewProfitToggle(isBdcRepresentative: boolean): boolean {
  return !isBdcRepresentative;
}
