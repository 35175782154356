import { useCallback } from 'react';
import { useToggle } from 'react-use';

import { formatToCurrency } from 'utils/formatterUtils';

interface HookOptions {
  price: number;
}

interface HookResult {
  handleCancelDialog(): void;
  handleOpenDialog(): void;
  dialogOpen: boolean;
  priceValue: string;
}

export const useVehicleCard = ({ price }: HookOptions): HookResult => {
  const [dialogOpen, toggleDialog] = useToggle(false);

  const handleCancelDialog = useCallback(() => {
    toggleDialog(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    toggleDialog(true);
  }, []);

  return {
    handleCancelDialog,
    handleOpenDialog,
    dialogOpen,
    priceValue: formatToCurrency(price),
  };
};
