import React from 'react';

import { Stack } from '@mui/material';

import { NumberFormField } from 'components/molecules/form-fields';

interface Props {
  fromName: string;
  toName: string;
  fromLabel: string;
  toLabel: string;
  placeholderFrom: string;
  placeholderTo: string;
}

export const RangeFilterBlock: React.FC<Props> = ({
  fromLabel,
  fromName,
  toLabel,
  toName,
  placeholderFrom,
  placeholderTo,
}) => (
  <Stack spacing={2}>
    <NumberFormField
      name={fromName}
      label={fromLabel}
      dataCy={`filterBlocks_${fromName}_input`}
      focused
      placeholder={placeholderFrom}
    />
    <NumberFormField
      name={toName}
      label={toLabel}
      dataCy={`filterBlocks_${toName}_input`}
      focused
      placeholder={placeholderTo}
    />
  </Stack>
);
