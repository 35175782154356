import styled from '@emotion/styled';

import { Card as MUICard } from '@mui/material';

export const Card = styled(MUICard)(() => ({
  width: '100%',
  height: '100%',
  boxShadow: '0px 4px 20px 3.363px rgba(0, 0, 0, 0.12)',
  borderRadius: '8px',
}));
