import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Card, Grid, Stack, styled, Typography } from '@mui/material';

import { RadioFormField } from 'components/molecules/form-fields';
import { SortBlock } from 'components/search/sort-block';

import { CurrentDealership } from 'services/SearchApiService';

import { useSearchFormHeader } from './useSearchFormHeader';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(1),
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'transform',
})<{
  transform?: string;
}>(({ transform, theme }) => ({
  transform,
  minWidth: 0,
  padding: theme.spacing(1.25),
}));

const LowerCaseButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  textTransform: 'none',
}));

interface SearchFormHeaderProps {
  currentDealership: CurrentDealership;
  searchResultsCount: number;
  excludedCount: number;
}

export const SearchFormHeader: React.FC<SearchFormHeaderProps> = ({
  currentDealership,
  searchResultsCount,
  excludedCount,
}) => {
  const {
    options,
    titleText,
    excludedText,
    directionText,
    sortTitleText,
    transform,
    handleOrderDirectionChange,
    handleClearFilters,
  } = useSearchFormHeader({
    currentDealership,
    searchResultsCount,
    excludedCount,
  });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      spacing={2}
      data-cy="searchPage_header"
    >
      <Grid item xs={12} md={3} xl={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle3">Filters</Typography>
          <LowerCaseButton endIcon={<CloseIcon />} onClick={(): void => handleClearFilters()}>
            <Typography variant="body4">Clear filters</Typography>
          </LowerCaseButton>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9} xl={10}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0.5}
        >
          <div>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="subtitle3" data-cy="searchPage_resultsTitle">
                {titleText}
              </Typography>
              {directionText && (
                <Typography variant="body3" data-cy="searchPage_directionText">
                  {directionText}
                </Typography>
              )}
            </Stack>
            {excludedText && (
              <Typography variant="body3" data-cy="searchPage_excludedText">
                {excludedText}
              </Typography>
            )}
          </div>
          <Stack alignItems="center" direction="row" spacing={1.5}>
            <StyledCard elevation={13}>
              <StyledButton
                transform={transform}
                onClick={handleOrderDirectionChange}
                data-cy="searchPage_changeDirectionButton"
              >
                <FilterListIcon data-cy="searchPage_changeDirectionIcon" />
              </StyledButton>
            </StyledCard>
            <SortBlock
              title={sortTitleText}
              content={
                <RadioFormField
                  labelTypographyVariant="body4"
                  name="orderBy"
                  items={options}
                  selectorPrefix="sort-blocks"
                  size="small"
                />
              }
              dataCyPrefix="sort"
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
