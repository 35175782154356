import { useFlags } from 'launchdarkly-react-client-sdk';

interface FeatureFlags {
  elendIntegration: boolean;
  pushToCrm: boolean;
}

export const useFeatureFlags = (): FeatureFlags => {
  const flags = useFlags<FeatureFlags>();

  return flags;
};
