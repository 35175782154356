import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn, UseFormSetValue } from 'react-hook-form';

import {
  LeaseTermPayments,
  LoanTermPayments,
  MoneyDown,
  MoneysDown,
} from 'components/pdp/form/DownloadPdfService';

import { LeaseProgram, LoanProgram } from 'services/PdpApiService';
import { ResidualValueType } from 'services/quick-quote/QuickQuoteApiRequestService';
import {
  QuickQuoteResponse,
  TtlResult,
  mapToGridRow,
} from 'services/quick-quote/QuickQuoteApiResponseService';

import { formatToCurrency, formatToCurrencyInterval } from 'utils/formatterUtils';
import { rounded, roundedToTwoDecimalPlaces } from 'utils/numberUtils';

export interface ProgramTermMoneyFactorHeader {
  program: LeaseProgram | undefined;
  term: number;
  moneyFactor: number;
  residualValue: number;
  residualValueType: ResidualValueType;
}

export interface ProgramTermAprHeader {
  program: LoanProgram | undefined;
  term: number;
  apr: number;
}
export interface GridRow {
  cashDown: number;
  payments: number[];
}

export interface QuickQuoteFormValues {
  leaseHeader1: ProgramTermMoneyFactorHeader;
  leaseHeader2: ProgramTermMoneyFactorHeader;
  leaseHeader3: ProgramTermMoneyFactorHeader;
  leaseHeader4: ProgramTermMoneyFactorHeader;
  loanHeader1: ProgramTermAprHeader;
  loanHeader2: ProgramTermAprHeader;
  loanHeader3: ProgramTermAprHeader;
  loanHeader4: ProgramTermAprHeader;
  leaseRow1: GridRow;
  leaseRow2: GridRow;
  leaseRow3: GridRow;
  leaseRow4: GridRow;
  loanRow1: GridRow;
  loanRow2: GridRow;
  loanRow3: GridRow;
  loanRow4: GridRow;
  leaseTermPayments: LeaseTermPayments[];
  loanTermPayments: LoanTermPayments[];
  showLease: boolean;
  showLoan: boolean;
}

export interface GetDefaultValuesRequest {
  leaseMoneysDown: MoneysDown;
  leaseProgram: LeaseProgram | undefined;
  loanMoneysDown: MoneysDown;
  loanProgram: LoanProgram | undefined;
  showLease: boolean;
  showLoan: boolean;
}

export function getDefaultValues({
  showLease,
  showLoan,
  leaseProgram: {
    moneyFactorValues: { markedUpValue: moneyFactor },
    term,
    residualAmount,
    residualRate,
  } = {
    moneyFactorValues: { markedUpValue: 0, originalValue: 0 },
    term: 0,
    residualAmount: 0,
    residualRate: 0,
  } as LeaseProgram,
  loanProgram: { aprValues: { markedUpValue: apr } } = {
    aprValues: { originalValue: 0, markedUpValue: 0 },
  } as LoanProgram,
  leaseMoneysDown,
  loanMoneysDown,
}: GetDefaultValuesRequest): QuickQuoteFormValues {
  const residualValue = residualRate ?? residualAmount;
  const residualValueType =
    residualRate === undefined ? ResidualValueType.Amount : ResidualValueType.Percentage;

  return {
    leaseHeader1: createLeaseHeader({
      term: 24,
      moneyFactor,
      residualBaseTerm: term,
      residualValue,
      residualValueType,
    }),
    leaseHeader2: createLeaseHeader({
      term: 36,
      moneyFactor,
      residualBaseTerm: term,
      residualValue,
      residualValueType,
    }),
    leaseHeader3: createLeaseHeader({
      term: 48,
      moneyFactor,
      residualBaseTerm: term,
      residualValue,
      residualValueType,
    }),
    leaseHeader4: createLeaseHeader({
      term: 60,
      moneyFactor,
      residualBaseTerm: term,
      residualValue,
      residualValueType,
    }),
    loanHeader1: createLoanHeader({ term: 24, apr }),
    loanHeader2: createLoanHeader({ term: 48, apr }),
    loanHeader3: createLoanHeader({ term: 60, apr }),
    loanHeader4: createLoanHeader({ term: 72, apr }),
    leaseRow1: createGridRow(leaseMoneysDown.moneyDown1),
    leaseRow2: createGridRow(leaseMoneysDown.moneyDown2),
    leaseRow3: createGridRow(leaseMoneysDown.moneyDown3),
    leaseRow4: createGridRow(leaseMoneysDown.moneyDown4),
    loanRow1: createGridRow(loanMoneysDown.moneyDown1),
    loanRow2: createGridRow(loanMoneysDown.moneyDown2),
    loanRow3: createGridRow(loanMoneysDown.moneyDown3),
    loanRow4: createGridRow(loanMoneysDown.moneyDown4),
    leaseTermPayments: [],
    loanTermPayments: [],
    showLease,
    showLoan,
  };
}

interface CreateLeaseHeaderRequest {
  term: number;
  moneyFactor: number;
  residualBaseTerm: number;
  residualValue: number;
  residualValueType: ResidualValueType;
}

function createLeaseHeader({
  term,
  moneyFactor,
  residualBaseTerm,
  residualValue,
  residualValueType,
}: CreateLeaseHeaderRequest): ProgramTermMoneyFactorHeader {
  return {
    moneyFactor,
    term,
    residualValue:
      residualValueType === ResidualValueType.Amount
        ? estimateResidualAmount(residualValue, residualBaseTerm, term)
        : estimateResidualRate(residualValue, residualBaseTerm, term),
    residualValueType,
    program: undefined,
  };
}

function estimateResidualRate(
  residualValue: number,
  residualBaseTerm: number,
  term: number
): number {
  return residualValue > 0
    ? roundedToTwoDecimalPlaces(residualValue + (residualBaseTerm - term) * 0.0035)
    : 0;
}

function estimateResidualAmount(
  residualValue: number,
  residualBaseTerm: number,
  term: number
): number {
  return residualValue > 0
    ? rounded(residualValue + (residualBaseTerm - term) * 0.026 * residualValue)
    : 0;
}

interface CreateLoanHeaderRequest {
  term: number;
  apr: number;
}

function createLoanHeader({ apr, term }: CreateLoanHeaderRequest): ProgramTermAprHeader {
  return {
    apr: apr * 100,
    term,
    program: undefined,
  };
}

function createGridRow(moneyDown: MoneyDown): GridRow {
  return {
    cashDown: moneyDown.cashDownTotal,
    payments: [],
  };
}

export function getRowPayments(form: UseFormReturn<any>, rowFieldName: string): number[] {
  return form.getValues(rowFieldName)?.payments ?? [];
}

export function formatPayment(
  payment: number,
  isPriceRangeEnabled: boolean,
  range: number
): string {
  if (payment <= 0 || payment === Infinity) {
    return '';
  }

  return isPriceRangeEnabled
    ? formatToCurrencyInterval(payment, range)
    : formatToCurrency(payment, 0);
}

export function updateGrid(
  setFormValue: UseFormSetValue<QuickQuoteFormValues>,
  setTtl: Dispatch<SetStateAction<TtlResult | undefined>>,
  quickQuoteFormValues: QuickQuoteFormValues,
  response: QuickQuoteResponse
): void {
  setFormValue('leaseRow1', mapToGridRow(quickQuoteFormValues.leaseRow1, response.lease?.row1));
  setFormValue('leaseRow2', mapToGridRow(quickQuoteFormValues.leaseRow2, response.lease?.row2));
  setFormValue('leaseRow3', mapToGridRow(quickQuoteFormValues.leaseRow3, response.lease?.row3));
  setFormValue('leaseRow4', mapToGridRow(quickQuoteFormValues.leaseRow4, response.lease?.row4));
  setFormValue('loanRow1', mapToGridRow(quickQuoteFormValues.loanRow1, response.loan?.row1));
  setFormValue('loanRow2', mapToGridRow(quickQuoteFormValues.loanRow2, response.loan?.row2));
  setFormValue('loanRow3', mapToGridRow(quickQuoteFormValues.loanRow3, response.loan?.row3));
  setFormValue('loanRow4', mapToGridRow(quickQuoteFormValues.loanRow4, response.loan?.row4));
  setTtl(response.ttl);
}
