import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { useSnackbar } from 'notistack';

import { getSearchFiltersPayload } from 'components/search/form-wrapper/SearchFormWrapperService';
import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { AxiosContext } from 'providers/AxiosProvider';

import { getSearchFiltersApiUrl, SearchFilters } from 'services/SearchFiltersApiService';

interface HookOptions {
  values?: SearchFormValues;
  enabled: boolean;
}

export const useSearchFilterQuery = ({
  values,
  enabled,
}: HookOptions): UseQueryResult<SearchFilters, unknown> => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);
  const payload = values ? getSearchFiltersPayload(values) : undefined;

  const searchFiltersQuery = useQuery(
    ['searchFiltersQuery', payload],
    async ({ signal }) => {
      const { data } = await axiosClient.post<SearchFilters>(getSearchFiltersApiUrl(), payload, {
        signal,
      });

      return data;
    },
    {
      onError: () => {
        enqueueSnackbar('Failed to fetch data for filters', {
          variant: 'error',
        });
      },
      enabled: enabled && !!payload,
      keepPreviousData: true,
    }
  );

  return searchFiltersQuery;
};
