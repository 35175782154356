import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { formatPayment } from 'components/pdp/payment-customization-block/quick-quote-modal/QuickQuoteService';

import { ProgramType } from 'services/PdpApiService';
import { getProfitDisplay } from 'services/ProfitDisplayService';
import { ComparisonDifference, Profit, Vehicle } from 'services/SearchApiService';

import { theme } from 'utils/muiThemeUtils';

import { createDeltaText } from './KeyRecommendationService';
import { useKeyRecommendationReasons } from './useKeyRecommendationReasons';

interface Props {
  vehicle: Vehicle;
  programType: ProgramType;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(2),
}));

export const KeyRecommendationReasons: React.FC<Props> = ({ vehicle, programType }) => {
  const { reasons, showProfit, allowViewProfitAmount, showRanges, paymentInterval } =
    useKeyRecommendationReasons({
      comparisonResults: vehicle.comparison,
      programType,
    });

  return (
    <Stack height="90%" justifyContent="space-between">
      <List flex-direction="column" disablePadding sx={{ listStyleType: 'disc' }}>
        {reasons.map((reason, key) => (
          <StyledListItem key={`${reason}_${key}`} disablePadding disableGutters>
            <StyledListItemIcon>
              <CircleIcon color="success" sx={{ fontSize: '12px' }}></CircleIcon>
            </StyledListItemIcon>
            <ListItemText
              primary={reason}
              primaryTypographyProps={{
                variant: 'body3',
                color: theme.palette.text.secondary,
                fontSize: '14px',
              }}
            ></ListItemText>
          </StyledListItem>
        ))}
      </List>
      <Stack direction="row" justifyContent="space-around" alignItems="center">
        {programType === ProgramType.Lease ? (
          <Stack justifyContent="space-around" alignItems="center">
            <Typography variant="overline">Lease</Typography>
            {vehicle.comparison?.leasePayment &&
              createDeltaDetails(
                vehicle.comparison.leasePayment,
                '/mo.',
                showRanges,
                paymentInterval
              )}
            {vehicle.comparison?.leaseProfit &&
              showProfit &&
              createProfitDeltaDetails(
                vehicle.comparison.leaseProfit,
                allowViewProfitAmount,
                vehicle.profitLease,
                'Profit'
              )}
          </Stack>
        ) : (
          <Stack justifyContent="space-around" alignItems="center">
            <Typography variant="overline">Loan</Typography>
            {vehicle.comparison?.loanPayment &&
              createDeltaDetails(
                vehicle.comparison.loanPayment,
                '/mo.',
                showRanges,
                paymentInterval
              )}
            {vehicle.comparison?.loanProfit &&
              showProfit &&
              createProfitDeltaDetails(
                vehicle.comparison.loanProfit,
                allowViewProfitAmount,
                vehicle.profitLoan,
                'Profit'
              )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

function createDeltaDetails(
  comparison: ComparisonDifference,
  unit: string,
  showRange: boolean,
  interval: number
): React.ReactNode {
  return (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      <Typography variant="subtitle3">
        {formatPayment(comparison.current, showRange, interval)}
      </Typography>
      <Typography variant="body4">{` ${unit} `}</Typography>
      {!showRange && (
        <Typography variant="body2">{`( ${createDeltaText(comparison.delta)})`}</Typography>
      )}
    </Stack>
  );
}

function createProfitDeltaDetails(
  comparison: ComparisonDifference,
  allowViewProfitAmount: boolean,
  profit: Profit,
  unit: string
): React.ReactNode {
  return (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      <Typography variant="subtitle3">{getProfitDisplay(profit, allowViewProfitAmount)}</Typography>
      <Typography variant="body4">{` ${unit} `}</Typography>
      {allowViewProfitAmount && (
        <Typography variant="body2">{`( ${createDeltaText(comparison.delta)})`}</Typography>
      )}
    </Stack>
  );
}
