import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import {
  getCustomIncentiveLenderNameOptions,
  getCustomIncentiveProgramTypeOptions,
} from 'components/pdp/payment-information-box/incentives-modal/IncentivesModalService';

import { usePdpData } from 'hooks/usePdpData';

import { CustomIncentivesAtom } from 'models/CustomIncentivesModel';

import { CustomIncentiveFormValues } from './form-wrapper/useCustomIncentiveFormWrapper';

interface HookResult {
  handleSaveCustomIncentive: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  incentiveLenderNameOptions: { label: string; value: string | number }[];
  incentiveProgramTypeOptions: { label: string; value: string | number }[];
  isSubmitting: boolean;
}

export const useCustomIncentiveForm = (): HookResult => {
  const [customIncentiveValue, setCustomIncentiveValue] = useRecoilState(CustomIncentivesAtom);
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useFormContext<CustomIncentiveFormValues>();
  const pdpData = usePdpData();
  const handleOnSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.stopPropagation();
      handleSubmit((values) => {
        setCustomIncentiveValue([...customIncentiveValue, { ...values }]);
        reset();
      })(event);
    },
    [customIncentiveValue]
  );
  const incentiveLenderNameOptions = getCustomIncentiveLenderNameOptions(pdpData?.lenderData ?? {});
  const incentiveProgramTypeOptions = getCustomIncentiveProgramTypeOptions();

  return {
    handleSaveCustomIncentive: handleOnSubmit,
    incentiveLenderNameOptions,
    incentiveProgramTypeOptions,
    isSubmitting,
  };
};
