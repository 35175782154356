import React from 'react';

import { Stack } from '@mui/material';

import { CheckboxFormField } from 'components/molecules/form-fields';
import { CollapsibleFilterBlock } from 'components/search/collapsible-filter-block';
import { FilterBlock } from 'components/search/filter-block';

import { SearchFilters } from 'services/SearchFiltersApiService';

import { ColorItemLabel } from './ColorItemLabel';
import {
  MileageFromLabel,
  MileageToLabel,
  PaymentFromLabel,
  PaymentToLabel,
  PriceFromLabel,
  PriceToLabel,
  YearFromLabel,
  YearToLabel,
} from './contants';
import { RangeFilterBlock } from './range-filter-block';
import { useFilterBlocks } from './useFilterBlocks';

interface FilterBlockProps {
  filters?: SearchFilters;
  isDisabled: boolean;
}

export const FilterBlocks: React.FC<FilterBlockProps> = ({ filters, isDisabled }) => {
  const {
    showDealerFilter,
    dealerItems,
    programAvailabilityItems,
    bodyStyleItems,
    exteriorColorItems,
    interiorColorItems,
    makeItems,
    modelItems,
    carStateItems,
    trimItems,
    fuelTypeItems,
  } = useFilterBlocks({
    filters,
    getColorLabel: (name, rgb, count) => <ColorItemLabel count={count} name={name} rgb={rgb} />,
  });

  return (
    <Stack direction="column" justifyContent="center" spacing={1.5} alignItems="center">
      {showDealerFilter && (
        <FilterBlock
          title="Dealer"
          content={
            <CheckboxFormField
              labelTypographyVariant="body4"
              name="dealer"
              items={dealerItems}
              selectorPrefix="filterBlocks"
              checkBoxSize="small"
              disabled={isDisabled}
            />
          }
          dataCyPrefix="filterBlocks_dealer"
        />
      )}
      <FilterBlock
        title="Lender Program Availability"
        content={
          <CheckboxFormField
            labelTypographyVariant="body4"
            name="programAvailability"
            items={programAvailabilityItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_programAvailability"
      />
      <FilterBlock
        title="New/Used"
        content={
          <CheckboxFormField
            labelTypographyVariant="body4"
            name="carState"
            items={carStateItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_carState"
      />
      <FilterBlock
        title="Monthly payment"
        content={
          <RangeFilterBlock
            fromLabel={PaymentFromLabel}
            fromName="monthlyPayment.from"
            toLabel={PaymentToLabel}
            toName="monthlyPayment.to"
            placeholderFrom="Enter payment from"
            placeholderTo="Enter payment to"
          />
        }
        dataCyPrefix="filterBlocks_monthlyPayment"
        defaultExpanded
      />
      <CollapsibleFilterBlock
        title="Features"
        dataCyPrefix="filterBlocks_features"
        featureItems={filters?.featureTypes ?? []}
        disabled={isDisabled}
        defaultExpanded
      />
      <FilterBlock
        title="Body style"
        content={
          <CheckboxFormField
            name="bodyStyle"
            labelTypographyVariant="body4"
            items={bodyStyleItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_bodyStyle"
        scrollable={bodyStyleItems.length > 6}
      />
      <FilterBlock
        title="Fuel type"
        content={
          <CheckboxFormField
            name="fuelType"
            labelTypographyVariant="body4"
            items={fuelTypeItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_bodyStyle"
        scrollable={bodyStyleItems.length > 6}
      />
      <FilterBlock
        title="Year"
        content={
          <RangeFilterBlock
            fromLabel={YearFromLabel}
            fromName="year.from"
            toLabel={YearToLabel}
            toName="year.to"
            placeholderFrom="Enter year from"
            placeholderTo="Enter year to"
          />
        }
        dataCyPrefix="filterBlocks_year"
      />
      <FilterBlock
        title="Make"
        content={
          <CheckboxFormField
            labelTypographyVariant="body4"
            name="make"
            items={makeItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_make"
        scrollable={makeItems.length > 6}
      />
      <FilterBlock
        title="Model"
        content={
          <CheckboxFormField
            labelTypographyVariant="body4"
            name="model"
            items={modelItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_model"
        scrollable={modelItems.length > 6}
      />
      <FilterBlock
        title="Trim"
        content={
          <CheckboxFormField
            labelTypographyVariant="body4"
            name="trim"
            items={trimItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_trim"
        scrollable={trimItems.length > 6}
      />
      <FilterBlock
        title="Mileage"
        content={
          <RangeFilterBlock
            fromLabel={MileageFromLabel}
            fromName="mileage.from"
            toLabel={MileageToLabel}
            toName="mileage.to"
            placeholderFrom="Enter mileage from"
            placeholderTo="Enter mileage to"
          />
        }
        dataCyPrefix="filterBlocks_mileage"
      />
      <FilterBlock
        title="Price"
        content={
          <RangeFilterBlock
            fromLabel={PriceFromLabel}
            fromName="price.from"
            toLabel={PriceToLabel}
            toName="price.to"
            placeholderFrom="Enter price from"
            placeholderTo="Enter price to"
          />
        }
        dataCyPrefix="filterBlocks_price"
      />
      <FilterBlock
        title="Interior color"
        content={
          <CheckboxFormField
            labelTypographyVariant="body4"
            name="interiorColor"
            items={interiorColorItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_interiorColor"
        scrollable
      />
      <FilterBlock
        title="Exterior color"
        content={
          <CheckboxFormField
            labelTypographyVariant="body4"
            name="exteriorColor"
            items={exteriorColorItems}
            selectorPrefix="filterBlocks"
            checkBoxSize="small"
            disabled={isDisabled}
          />
        }
        dataCyPrefix="filterBlocks_exteriorColor"
        scrollable
      />
    </Stack>
  );
};
