import { useFormContext } from 'react-hook-form';

import { ReserveType } from 'services/ReserveService';

import { ReserveFormValues } from './ReserveModalService';

interface HookResult {
  reserveType: ReserveType;
}

export const useLoanReserveForm = (): HookResult => {
  const { loanReserveType } = useFormContext<ReserveFormValues>().watch();

  return {
    reserveType: loanReserveType,
  };
};
