import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Button, CircularProgress, styled } from '@mui/material';

import { TriggeredModal } from 'components/molecules/modal';

import { Dealer } from 'services/DealerApiService';

import { UserDealerAssignmentForm } from './UserDealerAssignmentForm';
import { useUserDealerAssignmentModal } from './useUserDealerAssignmentModal';

interface Props {
  isLoadingDealers: boolean;
  dealers: Dealer[];
  triggerButtonIsDisabled: boolean;
}

const StyledButton = styled(Button)({
  justifyContent: 'flex-start',
});

export const UserDealerAssignmentModal: React.FC<Props> = ({
  isLoadingDealers: isFetchingDealers,
  dealers,
  triggerButtonIsDisabled,
}) => {
  const { handleOnSubmit, formMethods, userDealerFields } = useUserDealerAssignmentModal({
    dealers,
  });

  return (
    <TriggeredModal
      title="Dealer Access"
      onConfirm={handleOnSubmit}
      displayCloseButton={false}
      trigger={
        <StyledButton
          disabled={triggerButtonIsDisabled}
          variant="contained"
          data-cy="UserDealerAssignmentModal_triggerButton"
        >
          Dealer Access
        </StyledButton>
      }
      content={
        isFetchingDealers ? (
          <CircularProgress data-cy="overlay-spinner" color="primary" />
        ) : (
          <FormProvider {...formMethods}>
            <UserDealerAssignmentForm fields={userDealerFields} />
          </FormProvider>
        )
      }
    />
  );
};
