import { atom, DefaultValue, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom: persistLoan } = recoilPersist({
  key: 'pdpCollapsibleSectionExpandedLoan',
  storage: localStorage,
});

export const pdpCollapsibleSectionExpandedLoanAtom = atom<boolean>({
  key: 'pdpCollapsibleSectionExpandedLoanAtom',
  default: true,
  effects: [persistLoan],
});

const { persistAtom: persistLease } = recoilPersist({
  key: 'pdpCollapsibleSectionExpandedLease',
  storage: localStorage,
});

export const pdpCollapsibleSectionExpandedLeaseAtom = atom<boolean>({
  key: 'pdpCollapsibleSectionExpandedLeaseAtom',
  default: true,
  effects: [persistLease],
});

const { persistAtom: persistLoanProfit } = recoilPersist({
  key: 'pdpCollapsibleSectionExpandedProfitLease',
  storage: localStorage,
});

export const pdpCollapsibleSectionExpandedLoanProfitAtom = atom<boolean>({
  key: 'pdpCollapsibleSectionExpandedLoanProfitAtom',
  default: true,
  effects: [persistLoanProfit],
});

const { persistAtom: persistLeaseProfit } = recoilPersist({
  key: 'pdpCollapsibleSectionExpandedLeaseProfit',
  storage: localStorage,
});

export const pdpCollapsibleSectionExpandedLeaseProfitAtom = atom<boolean>({
  key: 'pdpCollapsibleSectionExpandedLeaseProfitAtom',
  default: true,
  effects: [persistLeaseProfit],
});

export interface PdpCollapsibleExpandableSectionState {
  loan: boolean;
  lease: boolean;
  profitLease: boolean;
  profitLoan: boolean;
}

export const pdpCollapsibleSectionExpandedSelector = selector<PdpCollapsibleExpandableSectionState>(
  {
    key: 'pdpCollapsibleSectionExpandedSelector',
    get: ({ get }) => ({
      lease: get(pdpCollapsibleSectionExpandedLeaseAtom),
      loan: get(pdpCollapsibleSectionExpandedLoanAtom),
      profitLease: get(pdpCollapsibleSectionExpandedLeaseProfitAtom),
      profitLoan: get(pdpCollapsibleSectionExpandedLoanProfitAtom),
    }),
    set: (
      { set },
      newValue:
        | DefaultValue
        | { loan: boolean; lease: boolean; profitLease: boolean; profitLoan: boolean }
    ) => {
      set(
        pdpCollapsibleSectionExpandedLoanAtom,
        newValue instanceof DefaultValue ? true : newValue.loan
      );
      set(
        pdpCollapsibleSectionExpandedLeaseAtom,
        newValue instanceof DefaultValue ? true : newValue.lease
      );
      set(
        pdpCollapsibleSectionExpandedLoanProfitAtom,
        newValue instanceof DefaultValue ? true : newValue.profitLoan
      );
      set(
        pdpCollapsibleSectionExpandedLeaseProfitAtom,
        newValue instanceof DefaultValue ? true : newValue.profitLease
      );
    },
  }
);
