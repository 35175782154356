/* eslint-disable react/prop-types */
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, PaletteColor, Stack, styled, Typography } from '@mui/material';

import { CustomContentProps, SnackbarContent } from 'notistack';

import { theme } from 'utils/muiThemeUtils';

import { useCustomSnackbar } from './useSnackbar';

const StyledSnackbarContent = styled(SnackbarContent)({
  color: theme.palette.common.white,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  padding: '10px 6px 10px 16px',
});

const StyledButton = styled(Button)({
  color: theme.palette.common.white,
  margin: '0 12px',
});

const StyledIconButton = styled(IconButton)({
  color: theme.palette.common.white,
});

export interface CustomSnackbarProps extends CustomContentProps {
  buttonMessage?: string;
  buttonCopyText?: string;
  color: PaletteColor;
}

// eslint-disable-next-line react/display-name
export const CustomSnackbar = React.forwardRef<HTMLDivElement, CustomSnackbarProps>(
  (props, ref) => {
    const {
      id,
      message,
      buttonMessage,
      buttonCopyText,
      hideIconVariant,
      iconVariant,
      autoHideDuration,
      anchorOrigin,
      persist,
      color = theme.palette.primary,
      ...other
    } = props;
    const shouldDisplayButton = buttonMessage && buttonCopyText;
    const { handleDismiss, handleCopyToClipBoard } = useCustomSnackbar({ id, buttonCopyText });

    return (
      <StyledSnackbarContent ref={ref} role="alert" {...other} style={{ background: color.main }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Stack direction="row" alignItems="center">
            <Typography data-cy="snackbar_bodyMessage" variant="body2">
              {message}
            </Typography>
            {shouldDisplayButton && (
              <StyledButton
                onClick={handleCopyToClipBoard}
                size="small"
                variant="outlined"
                color="inherit"
                data-cy="snackbar_button"
              >
                {buttonMessage}
              </StyledButton>
            )}
          </Stack>
          <StyledIconButton data-cy="snackbar_closeButton" size="small" onClick={handleDismiss}>
            <CloseIcon fontSize="small" />
          </StyledIconButton>
        </Stack>
      </StyledSnackbarContent>
    );
  }
);
