export interface UserRole {
  name: string;
  value: number;
}

export const UserRoles = {
  carmaticAdmin: { name: 'Carmatic Admin', value: 0 } as UserRole,
  dealerGroupAdmin: { name: 'Dealer Group Admin', value: 10 } as UserRole,
  dealerAdmin: { name: 'Dealer Admin', value: 20 } as UserRole,
  deskingManager: { name: 'Desking Manager', value: 30 } as UserRole,
  generalManager: { name: 'General Manager', value: 40 } as UserRole,
  salesRepresentative: { name: 'Sales Representative', value: 50 } as UserRole,
  bdcRepresentative: { name: 'Business Development Center Representative', value: 60 } as UserRole,
};

export const userRoles = {
  all: [
    UserRoles.carmaticAdmin,
    UserRoles.dealerGroupAdmin,
    UserRoles.dealerAdmin,
    UserRoles.deskingManager,
    UserRoles.generalManager,
    UserRoles.salesRepresentative,
    UserRoles.bdcRepresentative,
  ],
};
