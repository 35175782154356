import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import {
  getConfigurablePdfGridFileName,
  getMoneyDownValues,
} from 'components/pdp/form/DownloadPdfService';

import { useConfiguration } from 'hooks/useConfiguration';
import { usePdpData } from 'hooks/usePdpData';
import { useQuickQuotePdfQuery } from 'hooks/useQuickQuotePdfQuery';
import { useQuickQuoteQuery } from 'hooks/useQuickQuoteQuery';

import { leasesViewAtom, loansViewAtom } from 'models/GridViewModel';
import { priceRangeAtom } from 'models/PriceRangeModel';

import { handleDownloadFile } from 'services/FileDownloadService';
import {
  QuickQuoteRequest,
  ResidualValueType,
  createQuickQuoteRequest,
} from 'services/quick-quote/QuickQuoteApiRequestService';
import { TtlResult } from 'services/quick-quote/QuickQuoteApiResponseService';

import {
  QuickQuoteFormValues,
  getDefaultValues,
  updateGrid,
  GetDefaultValuesRequest,
} from './QuickQuoteService';

interface HookResult {
  quickQuoteForm: UseFormReturn<QuickQuoteFormValues>;
  isBusy: boolean;
  handleOnPrint(): Promise<void>;
  isPriceRangeEnabled: boolean;
  defaultInterval: number;
  showLease: boolean;
  showLoan: boolean;
  residualValueType: ResidualValueType;
}

export const useQuickQuoteForm = (): HookResult => {
  const [isBusy, setIsBusy] = useState(false);

  const pdpData = usePdpData();
  const pdpFormValues = useFormContext<PdpFormValues>().getValues();
  const showLeaseState = useRecoilValue(leasesViewAtom);
  const showLoanState = useRecoilValue(loansViewAtom);

  const isPriceRangeEnabled = useRecoilValue(priceRangeAtom);
  const {
    paymentsIntervalOptions: { defaultInterval, dueAtSigningInterval },
  } = useConfiguration();

  const leaseMoneysDown = getMoneyDownValues(
    pdpFormValues.payAllFeesUpfront,
    pdpFormValues.downPayment,
    pdpFormValues.maxOutOfPocket
  );
  const loanMoneysDown = getMoneyDownValues(
    pdpFormValues.payAllFeesUpfront,
    pdpFormValues.downPayment,
    pdpFormValues.maxOutOfPocket
  );

  const defaultValuesRequest: GetDefaultValuesRequest = {
    showLease: showLeaseState,
    showLoan: showLoanState,
    leaseMoneysDown: {
      moneyDown1: leaseMoneysDown[0],
      moneyDown2: leaseMoneysDown[1],
      moneyDown3: leaseMoneysDown[2],
      moneyDown4: leaseMoneysDown[3],
    },
    loanMoneysDown: {
      moneyDown1: loanMoneysDown[0],
      moneyDown2: loanMoneysDown[1],
      moneyDown3: loanMoneysDown[2],
      moneyDown4: loanMoneysDown[3],
    },
    leaseProgram: pdpFormValues.leaseProgram,
    loanProgram: pdpFormValues.loanProgram,
  };

  const defaultValues = getDefaultValues(defaultValuesRequest);

  const quickQuoteForm = useForm<QuickQuoteFormValues>({ defaultValues });

  const quickQuoteFormValues = useWatch<QuickQuoteFormValues>({
    control: quickQuoteForm.control,
  }) as QuickQuoteFormValues;

  const [ttl, setTtl] = useState<TtlResult>();

  const showLease = !!pdpFormValues.leaseProgram && quickQuoteFormValues.showLease;
  const showLoan = !!pdpFormValues.loanProgram && quickQuoteFormValues.showLoan;

  const request: QuickQuoteRequest = createQuickQuoteRequest({
    includeLease: showLease,
    includeLoan: showLoan,
    pdpFormValues,
    quickQuoteFormValues,
    dueAtSigningInterval,
    paymentInterval: defaultInterval,
    paymentRangeEnabled: isPriceRangeEnabled,
    ttl,
  });

  const requestJson = JSON.stringify(request);
  const carId = parseInt(pdpData.vehicle.id);

  const { refetch: getQuickQuote } = useQuickQuoteQuery({ carId, request });
  const { refetch: downloadPdf } = useQuickQuotePdfQuery({ carId, request });

  useEffect(() => {
    let isSubscribed = true;

    (async (): Promise<void> => {
      const response = (await getQuickQuote())?.data;

      if (response && isSubscribed) {
        updateGrid(quickQuoteForm.setValue, setTtl, quickQuoteFormValues, response);
      }
    })();

    return () => {
      isSubscribed = false;
    };
  }, [requestJson]);

  const handleOnPrint = useCallback(async () => {
    setIsBusy(true);

    const data = (await downloadPdf())?.data;

    if (data) {
      handleDownloadFile(
        data,
        getConfigurablePdfGridFileName({ description: 'QuickQuote', vin: pdpData.vehicle.vin })
      );
    }

    setIsBusy(false);
  }, [requestJson]);

  return {
    quickQuoteForm,
    isBusy,
    handleOnPrint,
    isPriceRangeEnabled,
    defaultInterval,
    showLease,
    showLoan,
    residualValueType: quickQuoteFormValues.leaseHeader1.residualValueType,
  };
};
