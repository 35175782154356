import React from 'react';

import { InfoOutlined } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Button,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  styled,
  Card as MuiCard,
  Grid,
  Grow,
} from '@mui/material';

import { Card } from 'components/atoms/card';
import { Scrollbar } from 'components/atoms/scrollbar';
import { VehicleCard } from 'components/molecules/vehicle-card';
import { InfoTile } from 'components/pdp/info-tile';
import {
  getSortTitleText,
  getTransform,
} from 'components/search/form/form-header/SearchFormHeaderService';
import { SortBlock } from 'components/search/sort-block';

import { recommendationsPersistStateFieldsBlackList } from 'services/BuildNewDealService';
import { PdpItem } from 'services/PdpApiService';
import { Vehicle } from 'services/SearchApiService';

import { KeyRecommendationCard } from './KeyRecommendationCard';
import { useRecommendationCards } from './useRecommendationCards';

interface Props {
  pdpData: PdpItem;
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
}));

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderRadius: theme.spacing(1),
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'transform',
})<{
  transform?: string;
}>(({ transform, theme }) => ({
  transform,
  minWidth: 0,
  padding: theme.spacing(1.25),
}));

export const RecommendationCards: React.FC<Props> = ({ pdpData }) => {
  const {
    isSameMakeModelChecked,
    isSameYearChecked,
    orderBy,
    orderByOptions,
    orderDirection,
    handleOnIsSameMakeModelChecked,
    handleOnIsSameYearChecked,
    handleOrderByChanged,
    handleOrderDirectionChanged,
    vehicles,
    keyRecommendation,
    keyRecommendationProgramType,
    isFetchingData,
    hasFetchedData,
  } = useRecommendationCards({ pdpData });

  return (
    <Card>
      <StyledCardContent>
        <Stack direction="row" spacing={2} alignItems="center" paddingBottom={1.25}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography data-cy="recommendations_title" variant="overline2" fontWeight={500}>
                Recommendations
              </Typography>
            </Grid>
            <Grid item flexGrow={1} paddingLeft={1}>
              {isFetchingData ? (
                <CircularProgress data-cy="overlay-spinner" color="primary" size={22} />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={1.5} paddingBottom={1}>
                <StyledCard elevation={2}>
                  <StyledButton
                    transform={getTransform(orderDirection)}
                    onClick={handleOrderDirectionChanged}
                    data-cy="recommendations_changeDirectionButton"
                  >
                    <FilterListIcon data-cy="recommendations_changeDirectionIcon" />
                  </StyledButton>
                </StyledCard>
                <SortBlock
                  elevation={2}
                  title={getSortTitleText(orderBy, '')}
                  content={
                    <RadioGroup value={orderBy} onChange={handleOrderByChanged}>
                      {orderByOptions.map(({ label, value: itemValue }) => (
                        <React.Fragment key={itemValue}>
                          <FormControlLabel
                            key={itemValue}
                            value={itemValue}
                            control={
                              <Radio
                                size="small"
                                inputProps={
                                  {
                                    'data-cy': `recommendation-sort-blocks-radio-group-input-${label}`,
                                  } as any
                                }
                              />
                            }
                            label={<Typography variant="body4">{label}</Typography>}
                          />
                        </React.Fragment>
                      ))}
                    </RadioGroup>
                  }
                  dataCyPrefix="sort"
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Divider />
        <Stack spacing={0.75} paddingTop={0.75}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" onClick={handleOnIsSameMakeModelChecked}>
              <Checkbox
                checked={isSameMakeModelChecked}
                inputProps={
                  {
                    'data-cy': 'recommendation-same-make-model',
                  } as any
                }
              />
              <Typography variant="body3" data-cy="recommendation-same-make-model">
                Same Make/Model
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" onClick={handleOnIsSameYearChecked}>
              <Checkbox
                checked={isSameYearChecked}
                inputProps={
                  {
                    'data-cy': 'recommendation-same-year',
                  } as any
                }
              />
              <Typography variant="body3" data-cy="recommendation-same-year">
                Year {pdpData.vehicle.year}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          {!!keyRecommendation && !!keyRecommendationProgramType && (
            <>
              <Grow in timeout={2500}>
                <div>
                  <KeyRecommendationCard
                    vehicle={keyRecommendation}
                    programType={keyRecommendationProgramType}
                  ></KeyRecommendationCard>
                </div>
              </Grow>
              <Divider />
            </>
          )}
        </Stack>
      </StyledCardContent>
      <Scrollbar maxheight="50vh">
        <Stack direction="column">
          <CardContent>{getVehicleCards(vehicles, hasFetchedData)}</CardContent>
        </Stack>
      </Scrollbar>
    </Card>
  );
};
function getVehicleCards(vehicles: Vehicle[], hasFetchedData: boolean): React.ReactNode {
  if (!hasFetchedData) {
    return <></>;
  }

  return vehicles.length > 0 ? (
    <Stack direction="column" spacing={1.5}>
      {vehicles.map((vehicle, i) => (
        <VehicleCard
          dataCyPrefix={`$recommendations-card_${i}`}
          renderVehicleDetailsButton
          key={vehicle.id}
          vehicle={vehicle}
          persistState
          persistStateFieldsBlacklist={recommendationsPersistStateFieldsBlackList}
        />
      ))}
    </Stack>
  ) : (
    <InfoTile
      title="There is no other vehicle."
      text="Expand your search criteria to see more results."
      selectorPrefix="pdpPage_searchRecommendationNotAvailable"
      icon={InfoOutlined}
      color="primary"
    />
  );
}
