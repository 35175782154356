import { formatToCurrency, formatToCurrencyInterval } from 'utils/formatterUtils';

export function getLowestPaymentLeaseOrLoanValue(
  priceRangeEnabled: boolean,
  intervalValue: number,
  lowestPaymentLeaseOrLoan?: number
): string | undefined {
  return lowestPaymentLeaseOrLoan !== undefined
    ? priceRangeEnabled
      ? formatToCurrencyInterval(lowestPaymentLeaseOrLoan, intervalValue)
      : formatToCurrency(lowestPaymentLeaseOrLoan)
    : undefined;
}
