import { useContext, useMemo } from 'react';

import { PdpContext } from 'providers/PdpContextProvider';

import { useDealOfferPdpQuery } from 'hooks/useDealOfferPdpQuery';

import { DealOfferPdp } from 'services/DealOfferApiService';
import { PdpItem } from 'services/PdpApiService';

// NOTE use this hook anywhere under PdpPage, PdpDealOfferPage to access PDP data fetched from API to avoid props drilling
export const usePdpData = (): PdpItem => {
  const { pdpData } = useContext(PdpContext);
  const { data: dealOfferPdpData } = useDealOfferPdpQuery();

  return useMemo(
    () => (dealOfferPdpData ? getPdpData(dealOfferPdpData.dealOffer) : pdpData),
    [dealOfferPdpData, pdpData]
  );
};

function getPdpData(dealOffer: DealOfferPdp): PdpItem {
  return {
    dealer: dealOffer.dealer,
    dealerDefaults: undefined,
    lenderData: dealOffer.lenderData,
    vehicle: dealOffer.vehicle,
  };
}
