import React from 'react';

import { useBuildVersionProvider } from './useBuildVersionProvider';

interface Props {
  children: React.ReactNode;
}

export const BuildVersionProvider: React.FC<Props> = ({ children }) => {
  const { isBuildVersionFetched } = useBuildVersionProvider();

  return <>{isBuildVersionFetched ? children : null}</>;
};
