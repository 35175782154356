import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@mui/material';

import { TriggeredModal } from 'components/molecules/modal';

import { TradeOptionsForm } from './TradeOptionsForm';
import { useTradeOptionsModal } from './useTradeOptionsModal';

interface TradeOptionsModalProps {
  selectorPrefix?: string;
  isDisabled: boolean;
}

export const TradeOptionsModal: React.FC<TradeOptionsModalProps> = ({
  selectorPrefix,
  isDisabled,
}) => {
  const { formMethods, handleOnSubmit, showProfit, editActualCashValue, tradeInUrl } =
    useTradeOptionsModal();

  return (
    <TriggeredModal
      title="Trade options"
      trigger={
        <Button
          disabled={isDisabled}
          variant="contained"
          data-cy="TradeOptionsModal_triggerButton"
          fullWidth
        >
          Trade options
        </Button>
      }
      displayCloseButton={false}
      onConfirm={handleOnSubmit}
      content={
        <FormProvider {...formMethods}>
          <TradeOptionsForm
            showProfit={showProfit}
            editActualCashValue={editActualCashValue}
            selectorPrefix={selectorPrefix}
            tradeInUrl={tradeInUrl}
          ></TradeOptionsForm>
        </FormProvider>
      }
    />
  );
};
