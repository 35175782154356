import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { AxiosContext } from 'providers/AxiosProvider';

import { getZipCodeUrl, ZipCodeLocale } from 'services/ZipCodeApiService';

interface HookOptions {
  zipCode: string;
  onSuccessCallback(response: ZipCodeLocale): void;
  onErrorCallback(): void;
}

export const useZipCodeQuery = ({
  zipCode,
  onErrorCallback,
  onSuccessCallback,
}: HookOptions): UseQueryResult<ZipCodeLocale, unknown> => {
  const axiosClient = useContext(AxiosContext);

  const zipCodeQuery = useQuery(
    ['zipCodeQuery', zipCode],
    async ({ signal }) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { data } = await axiosClient.get<ZipCodeLocale>(getZipCodeUrl(zipCode!), {
        params: { zipCode },
        signal,
      });

      return data;
    },
    {
      enabled: zipCode.length === 5,
      onSuccess: onSuccessCallback,
      onError: () => onErrorCallback(),
      useErrorBoundary: false,
      cacheTime: 0,
      staleTime: 0,
      retry: 1,
    }
  );

  return zipCodeQuery;
};
