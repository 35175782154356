import { InfiniteData } from 'react-query';

import _ from 'lodash';

import {
  PaymentFromLabel,
  PaymentToLabel,
  YearFromLabel,
  YearToLabel,
  PriceFromLabel,
  PriceToLabel,
  MileageFromLabel,
  MileageToLabel,
} from 'components/search/filter-blocks/contants';
import {
  CalculationOptions,
  Interval,
  SearchFormValues,
  SearchResult,
} from 'components/search/form-wrapper/useSearchFormWrapper';

import { Customer } from 'services/CustomerService';
import { DealerDefaults, ProgramType } from 'services/PdpApiService';
import {
  CarState,
  OrderBy as OrderBySearch,
  OrderDirection as OrderDirectionSearch,
  Vehicle,
} from 'services/SearchApiService';
import { SearchFilters } from 'services/SearchFiltersApiService';

/* TODO: Determine if we need and want to keep this.
The intent of this implementation seems to be
that it counts out search results starting with the current PDP car.
But if a PDP was loaded from a page of search results >= second page
the PDP car will not be among the search results except for possibly
by make or body style recommendation searches.
So this implementation is either extraneous or ineffectual.
*/
const RANGE = 10;

export enum OrderBy {
  MinPayment = 'MinPayment',
  MinPaymentLease = 'MinPaymentLease',
  MinPaymentLoan = 'MinPaymentLoan',
  Price = 'Price',
  DaysInStock = 'DaysInStock',
  BestDealLoan = 'BestDealLoan',
  BestDealLease = 'BestDealLease',
  Year = 'Year',
  Mileage = 'Mileage',
}

export enum OrderDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export function getIndexFrom(currentIndex: number): number {
  return currentIndex - RANGE >= 0 ? currentIndex - RANGE : 0;
}

export function getIndexTo(currentIndex: number, maxIndex: number): number {
  return currentIndex + RANGE + 1 <= maxIndex ? currentIndex + RANGE + 1 : maxIndex;
}

export function getRecommendedVehicles(
  carId: string,
  searchData: InfiniteData<SearchResult> | undefined
): Vehicle[] {
  const flattenSearchVehicleData = _.flatten(searchData?.pages.map(({ items }) => items));
  const currentCarIndex = _.findIndex(flattenSearchVehicleData, ({ id }) => id === carId);
  const numberOfCars = flattenSearchVehicleData.length;
  const numbersFrom = getIndexFrom(currentCarIndex);
  const numbersTo = getIndexTo(currentCarIndex, numberOfCars);

  const subVehicles = flattenSearchVehicleData.slice(numbersFrom, numbersTo);
  const myVehicle = subVehicles.find(({ id }) => id === carId);

  if (myVehicle) {
    return _.without(subVehicles, myVehicle);
  }

  return subVehicles;
}

export function getKeyRecommendation(
  searchData: InfiniteData<SearchResult> | undefined,
  showLease: boolean | undefined,
  showLoan: boolean | undefined
): {
  keyRecommendation: Vehicle | undefined;
  keyRecommendationProgramType: ProgramType;
} {
  const keyRecommendations = searchData?.pages[0].keyRecommendation;
  const flattenedSearchVehicleData = _.flatten(searchData?.pages.map(({ items }) => items));

  const lease = flattenedSearchVehicleData.find((x) => x.id === keyRecommendations?.leaseCarId);
  const leaseIndex = lease ? flattenedSearchVehicleData.indexOf(lease) : 0;

  const loan = flattenedSearchVehicleData.find((x) => x.id === keyRecommendations?.loanCarId);
  const loanIndex = loan ? flattenedSearchVehicleData.indexOf(loan) : 0;

  const keyRecommendation = getKeyRecommendationVehicle(
    showLease,
    showLoan,
    lease,
    leaseIndex,
    loan,
    loanIndex
  );

  return keyRecommendation;
}

function getKeyRecommendationVehicle(
  showLease: boolean | undefined,
  showLoan: boolean | undefined,
  lease: Vehicle | undefined,
  leaseIndex: number,
  loan: Vehicle | undefined,
  loanIndex: number
): {
  keyRecommendation: Vehicle | undefined;
  keyRecommendationProgramType: ProgramType;
} {
  if (showLease && showLoan) {
    return (leaseIndex <= loanIndex && lease) || !loan
      ? { keyRecommendation: lease, keyRecommendationProgramType: ProgramType.Lease }
      : { keyRecommendation: loan, keyRecommendationProgramType: ProgramType.Loan };
  }

  if (showLease) {
    return { keyRecommendation: lease, keyRecommendationProgramType: ProgramType.Lease };
  }

  return { keyRecommendation: loan, keyRecommendationProgramType: ProgramType.Loan };
}

export function getFiltersText(
  searchValues: SearchFormValues,
  searchFiltersData: SearchFilters
): string[] {
  const filtersText = [];

  const { exteriorColors, interiorColors, featureTypes } = searchFiltersData;
  const { from: monthlyPaymentFrom, to: monthlyPaymentTo } = searchValues.monthlyPayment;
  const { from: mileageFrom, to: mileageTo } = searchValues.mileage;
  const { from: yearFrom, to: yearTo } = searchValues.year;
  const { from: priceFrom, to: priceTo } = searchValues.price;
  const features = _.flatten(featureTypes.map((type) => type.features));

  searchValues.carState?.forEach((item) =>
    filtersText.push(Object.values(CarState)[item].toLocaleString())
  );
  searchValues.make?.forEach((item) => filtersText.push(item));
  searchValues.bodyStyle?.forEach((item) => filtersText.push(item));
  searchValues.model?.forEach((item) => filtersText.push(item));
  searchValues.trim?.forEach((item) => filtersText.push(item));
  searchValues.fuelType?.forEach((item) => filtersText.push(item));

  if (monthlyPaymentFrom) {
    filtersText.push(`${PaymentFromLabel} ${monthlyPaymentFrom}`);
  }
  if (monthlyPaymentTo) {
    filtersText.push(`${PaymentToLabel} ${monthlyPaymentTo}`);
  }

  if (yearFrom) {
    filtersText.push(`${YearFromLabel} ${yearFrom}`);
  }
  if (yearTo) {
    filtersText.push(`${YearToLabel} ${yearTo}`);
  }

  if (priceFrom) {
    filtersText.push(`${PriceFromLabel} ${priceFrom}`);
  }
  if (priceTo) {
    filtersText.push(`${PriceToLabel} ${priceTo}`);
  }

  if (mileageFrom) {
    filtersText.push(`${MileageFromLabel} ${mileageFrom}`);
  }
  if (mileageTo) {
    filtersText.push(`${MileageToLabel} ${mileageTo}`);
  }

  searchValues.featureTypes?.forEach((item) => {
    const feature = features.find(({ code }) => code === item)?.name;
    if (feature) {
      filtersText.push(feature);
    }
  });
  searchValues.interiorColor?.forEach((item) => {
    const color = interiorColors.find(({ code }) => code === item)?.name;
    if (color) {
      filtersText.push(color);
    }
  });
  searchValues.exteriorColor?.forEach((item) => {
    const color = exteriorColors.find(({ code }) => code === item)?.name;
    if (color) {
      filtersText.push(color);
    }
  });

  return filtersText;
}

const defaultPaymentRange = 50;

export function createSearchFilterPaymentRanges(
  leasePayment: number,
  loanPayment: number,
  range?: number
): {
  leaseMonthlyPayment: Interval | undefined;
  loanMonthlyPayment: Interval | undefined;
  monthlyPayment: Interval;
} {
  return {
    leaseMonthlyPayment: leasePayment > 0 ? createInterval(leasePayment, range) : undefined,
    loanMonthlyPayment: loanPayment > 0 ? createInterval(loanPayment, range) : undefined,
    // ToDo: monthlyPayment is not used for recommendations. Remove the field after search is updated to use separate lease and loan filters.
    monthlyPayment: { from: undefined, to: undefined },
  };
}

function createInterval(payment: number, range?: number): Interval {
  return {
    from: Math.max(Math.ceil(payment - (range ?? defaultPaymentRange)), 1),
    to: Math.ceil(payment + (range ?? defaultPaymentRange)),
  };
}

function createBaseSearchFilters(
  comparisonCarId: number,
  leasePayment: number,
  loanPayment: number,
  calculationOptions: CalculationOptions,
  customer: Customer,
  featureTypes: number[],
  dealerDefaults: DealerDefaults | undefined
): SearchFormValues {
  return {
    comparisonCarId,
    vinOrStockNumber: undefined,
    dealer: [],
    programAvailability: [],
    bodyStyle: [],
    calculationOptions,
    carState: [CarState.CPO, CarState.New, CarState.Used],
    customer,
    exteriorColor: [],
    featureTypes,
    fuelType: [],
    interiorColor: [],
    make: [],
    model: [],
    mileage: { from: undefined, to: undefined },
    ...createSearchFilterPaymentRanges(
      leasePayment,
      loanPayment,
      dealerDefaults?.recommendationsPaymentRange
    ),
    leaseProfit: undefined,
    loanProfit: undefined,
    orderBy: OrderBySearch[dealerDefaults?.recommendationsOrderBy ?? OrderBy.Year] as OrderBySearch,
    orderDirection:
      OrderDirectionSearch[dealerDefaults?.recommendationsOrderDirection ?? OrderDirection.Desc],
    price: { from: undefined, to: undefined },
    trim: [],
    year: { from: undefined, to: undefined },
  };
}

export function createMakeModelSearchFilters(
  comparisonCarId: number,
  leasePayment: number,
  loanPayment: number,
  make: string,
  model: string,
  featureTypes: number[],
  calculationOptions: CalculationOptions,
  customer: Customer,
  dealerDefaults: DealerDefaults | undefined
): SearchFormValues {
  return {
    ...createBaseSearchFilters(
      comparisonCarId,
      leasePayment,
      loanPayment,
      calculationOptions,
      customer,
      featureTypes,
      dealerDefaults
    ),
    make: [make],
    model: [model],
  } as SearchFormValues;
}

interface CreateSearchFiltersRequest {
  comparisonCarId: number;
  leasePayment: number;
  loanPayment: number;
  leaseProfit: number | undefined;
  loanProfit: number | undefined;
  bodyStyle: string;
  make: string;
  model: string;
  year: number;
  featureTypes: number[];
  calculationOptions: CalculationOptions;
  customer: Customer;
  dealerDefaults: DealerDefaults | undefined;
  orderBy: OrderBySearch;
  orderDirection: OrderDirectionSearch;
  isSameMakeModelChecked: boolean;
  isSameYearChecked: boolean;
}

export function createSearchFilters(request: CreateSearchFiltersRequest): SearchFormValues {
  const searchFilters = createBodyStyleSearchFilters(
    request.comparisonCarId,
    request.leasePayment,
    request.loanPayment,
    request.bodyStyle,
    request.featureTypes,
    request.calculationOptions,
    request.customer,
    request.dealerDefaults
  );

  if (request.isSameMakeModelChecked) {
    searchFilters.make = [request.make];
    searchFilters.model = [request.model];
  }

  if (request.isSameYearChecked) {
    searchFilters.year = { from: request.year, to: request.year };
  }

  searchFilters.orderBy = request.orderBy;
  searchFilters.orderDirection = request.orderDirection;
  searchFilters.carState = request.dealerDefaults?.recommendationsFilter.onlyNew
    ? [CarState.New]
    : searchFilters.carState;

  const { leaseProfit, loanProfit } = createProfitSearchFilters(
    request.dealerDefaults?.recommendationsFilter.higherProfit,
    request.leaseProfit,
    request.loanProfit
  );
  searchFilters.leaseProfit = leaseProfit;
  searchFilters.loanProfit = loanProfit;

  return searchFilters;
}

function createProfitSearchFilters(
  isHigherProfit: boolean | undefined,
  leaseProfit: number | undefined,
  loanProfit: number | undefined
): { leaseProfit: Interval | undefined; loanProfit: Interval | undefined } {
  if (!isHigherProfit) {
    return { leaseProfit: undefined, loanProfit: undefined };
  }

  return {
    leaseProfit: {
      from: leaseProfit ? Math.ceil(leaseProfit) : undefined,
      to: undefined,
    },
    loanProfit: {
      from: loanProfit ? Math.ceil(loanProfit) : undefined,
      to: undefined,
    },
  };
}

export function createBodyStyleSearchFilters(
  comparisonCarId: number,
  leasePayment: number,
  loanPayment: number,
  bodyStyle: string,
  featureTypes: number[],
  calculationOptions: CalculationOptions,
  customer: Customer,
  dealerDefaults: DealerDefaults | undefined
): SearchFormValues {
  return {
    ...createBaseSearchFilters(
      comparisonCarId,
      leasePayment,
      loanPayment,
      calculationOptions,
      customer,
      featureTypes,
      dealerDefaults
    ),
    bodyStyle: [bodyStyle],
  } as SearchFormValues;
}
