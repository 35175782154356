import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { MenuItem, TextField, TextFieldProps } from '@mui/material';

interface Option {
  label: string;
  value: string | number;
}

export type SelectFormFieldProps = TextFieldProps & {
  name: string;
  dataCyPrefix?: string;
  autoCompleteOff?: boolean;
  options: Option[];
};

export const SelectFormField: React.FC<SelectFormFieldProps> = (props) => {
  const { control } = useFormContext();
  const { dataCyPrefix, autoCompleteOff, ...textInputProps } = props;

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, name }, fieldState: { error } }): React.ReactElement => (
        <TextField
          {...textInputProps}
          name={name}
          value={props.options?.length ? value : ''}
          label={typeof props.label === 'string' ? props.label?.toUpperCase() : props.label}
          onChange={onChange}
          inputProps={{
            'data-cy': `${dataCyPrefix}_${name}Select`,
            autoComplete: autoCompleteOff && 'disabled',
          }}
          select
          error={!!error}
          helperText={error?.message}
        >
          {props.options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              data-cy={`${dataCyPrefix}_${name}Select_option_${option.value}`}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

SelectFormField.defaultProps = {
  variant: 'outlined',
  size: 'small',
};
