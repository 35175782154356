import React from 'react';

import { ErrorBoundary } from 'components/ErrorBoundary';

import { useGlobalReactErrorBoundary } from './useGlobalReactErrorBoundary';

interface Props {
  children: React.ReactNode;
  errorComponent: React.ReactElement;
  enableNavigation?: boolean;
}

export const GlobalReactErrorBoundary: React.FC<Props> = ({
  children,
  errorComponent,
  enableNavigation,
}) => {
  const { ref } = useGlobalReactErrorBoundary({ enableNavigation });

  return (
    <ErrorBoundary ref={ref} errorComponent={errorComponent}>
      {children}
    </ErrorBoundary>
  );
};
