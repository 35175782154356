import React, { useCallback, useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { gridViewAtom } from 'models/GridViewModel';

interface HookResult {
  gridViewValue: boolean;
  handleGridViewChange(e: React.ChangeEvent, checked: boolean): void;
  showGridToggle: boolean;
}

export const useGridSwitch = (): HookResult => {
  const [gridViewValue, setGridViewValue] = useRecoilState(gridViewAtom);
  const { isBdcRepresentative } = useContext(PermissionsContext);

  useEffect(() => {
    if (isBdcRepresentative) {
      setGridViewValue(false);
    }
  }, [isBdcRepresentative]);

  const handleGridViewChange = useCallback((e: React.ChangeEvent, checked: boolean) => {
    setGridViewValue(isBdcRepresentative ? false : checked);
  }, []);

  return {
    gridViewValue: shouldShowGridToggle(isBdcRepresentative) && gridViewValue,
    handleGridViewChange,
    showGridToggle: shouldShowGridToggle(isBdcRepresentative),
  };
};
function shouldShowGridToggle(isBdcRepresentative: any): boolean {
  return !isBdcRepresentative;
}
