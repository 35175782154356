import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { useConfiguration } from './useConfiguration';

interface HookResult {
  reactPlugin: ReactPlugin;
  appInsights: ApplicationInsights;
}

export const useAppInsight = (): HookResult => {
  const {
    appInsightOptions: { connectionString, enabled },
  } = useConfiguration();

  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      enableAutoRouteTracking: false,
      extensions: [reactPlugin],
    },
  });

  if (enabled) {
    appInsights.loadAppInsights();
  }

  return {
    reactPlugin,
    appInsights,
  };
};
