import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { useIsMounted } from 'hooks/useIsMounted';

export const usePersistActualValue = (): void => {
  const {
    control,
    formState: { dirtyFields },
    setValue,
  } = useFormContext<PdpFormValues>();
  const { tradeInValue } = useWatch({ control }) as PdpFormValues;
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted && !dirtyFields.actualCashValue) {
      setValue('actualCashValue', tradeInValue);
    }
  }, [tradeInValue]);
};
