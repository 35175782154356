import * as React from 'react';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useConfirmDialog } from './useConfirmDialog';

interface ConfirmDialogProps {
  triggerElement: React.ReactNode;
  title: string;
  contentText: string;
  cancelText: string;
  confirmText: string;
  onConfirm(): Promise<void>;
  disabled?: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  triggerElement,
  title,
  contentText,
  cancelText,
  confirmText,
  onConfirm,
  disabled,
}) => {
  const { open, handleClickOpen, handleConfirm, handleClose } = useConfirmDialog({ onConfirm });

  return (
    <div>
      <div onClick={handleClickOpen}>{triggerElement}</div>
      <Dialog
        open={!disabled && open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title" data-cy="confirm-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            <Typography variant="body2" data-cy="confirm-dialog-description">
              {contentText}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} data-cy="confirm-dialog-cancelButton">
            {cancelText}
          </Button>
          <Button onClick={handleConfirm} autoFocus data-cy="confirm-dialog-confirmButton">
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
