export interface CreditTierOption {
  id: number;
  label: string;
  value: number;
}

export const creditTierOptions: CreditTierOption[] = [
  { label: 'Excellent (740-900)', value: 820, id: 1 },
  { label: 'Very Good (700-739)', value: 720, id: 2 },
  { label: 'Good (670-699)', value: 685, id: 3 },
  { label: 'Fair (630-669)', value: 650, id: 4 },
  { label: 'Rebuilding (580-629)', value: 605, id: 5 },
];

export function getCreditTierById(id: number): CreditTierOption | undefined {
  return creditTierOptions.find((x) => x.id === id);
}

export function getCreditTierByCreditScore(creditScore: number): CreditTierOption | undefined {
  return creditTierOptions.find((x) => x.value === creditScore);
}
