import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { getSearchFormValues } from 'components/search/form-wrapper/SearchFormWrapperService';
import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { searchValuesAtom } from 'models/SearchModel';

interface HookOptions {
  values: SearchFormValues;
}

export const useSyncFormValues = ({ values }: HookOptions): void => {
  const setFormValues = useSetRecoilState(searchValuesAtom);

  useEffect(() => {
    const formValues = getSearchFormValues(values);
    setFormValues(formValues);
  }, [values]);
};
