import { useRecoilValue } from 'recoil';

import { getDefaultFormValues } from 'components/search/form-wrapper/SearchFormWrapperService';
import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

import { searchValuesAtom } from 'models/SearchModel';

import { CurrentDealership } from 'services/SearchApiService';

import { useCurrentDealershipQuery } from './useCurrentDealershipQuery';

interface HookResult {
  searchFormValues: SearchFormValues;
}

export const useSearchFormValues = (): HookResult => {
  const { data: currentDealership } = useCurrentDealershipQuery();
  const savedSearchFormValues = useRecoilValue(searchValuesAtom);

  const searchFormValues =
    savedSearchFormValues ??
    getDefaultFormValues({
      currentDealership: currentDealership ?? ({} as CurrentDealership),
      searchFormValues: undefined,
    });

  return { searchFormValues };
};
