import { useMutation, UseMutationResult } from 'react-query';

import axios from 'axios';

interface VersionData {
  version: string;
}

export const useBuildVersionQuery = (): UseMutationResult<VersionData, unknown, void, unknown> => {
  const buildVersionMutation = useMutation(
    ['buildVersionMutation'],
    async () => {
      const { data } = await axios.get<VersionData>('/meta.json');

      return data;
    },
    { useErrorBoundary: true }
  );

  return buildVersionMutation;
};
