import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'viewProfit',
  storage: localStorage,
});

export const viewProfitAtom = atom<boolean>({
  key: 'viewProfit',
  default: false,
  effects: [persistAtom],
});
