import { useCallback, useState } from 'react';

interface HookResult {
  isOpen: boolean;
  open: () => void;
  confirm: () => void;
  onImplicitClose: () => void;
}

interface HookProps {
  onConfirm?: () => void;
  confirmButtonDisabled?: boolean;
  implicitCloseDisabled?: boolean;
  openIsDisabled?: boolean;
}

export const useModal = ({
  onConfirm,
  confirmButtonDisabled = false,
  openIsDisabled = false,
  implicitCloseDisabled = false,
}: HookProps): HookResult => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback((): void => {
    if (!openIsDisabled) {
      setIsOpen(true);
    }
  }, [openIsDisabled]);

  const confirm = useCallback((): void => {
    if (!confirmButtonDisabled) {
      onConfirm?.();
      setIsOpen(false);
    }
  }, [confirmButtonDisabled, onConfirm]);

  const onImplicitClose = useCallback((): void => {
    if (!implicitCloseDisabled) {
      confirm();
    }
  }, [confirmButtonDisabled, confirm]);

  return { isOpen, open, confirm, onImplicitClose };
};
