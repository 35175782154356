import { formatToCurrency } from 'utils/formatterUtils';

import { Profit, ProfitTier } from './SearchApiService';

export function getProfitDisplay(
  profit: Profit | undefined,
  allowViewProfitAmount: boolean
): string | undefined {
  if (profit === undefined) {
    return undefined;
  }

  return allowViewProfitAmount && profit.total !== undefined
    ? formatToCurrency(profit.total, 0)
    : getProfitTierDisplay(profit.tier);
}

export function getProfitTierDisplay(tier?: ProfitTier): string {
  switch (tier) {
    case ProfitTier.negative:
      return '-$';
    case ProfitTier.low:
      return '$';
    case ProfitTier.medium:
      return '$$';
    case ProfitTier.high:
      return '$$$';
    case ProfitTier.veryHigh:
      return '$$$$';
    default:
      return '';
  }
}
