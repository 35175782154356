import { useContext } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { AdministrationUserEdit, getEditUserUrl } from 'services/UserAdministrationApiService';

import { queryClient } from 'utils/queryClientUtils';
import { handleOnError } from 'utils/queryUtils';

export const useEditUserMutation = (
  userID?: string
): UseMutationResult<AxiosResponse<string, unknown>, unknown, AdministrationUserEdit, unknown> => {
  const axiosClient = useContext(AxiosContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const editUserMutation = useMutation(
    async (params: AdministrationUserEdit) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await axiosClient.put(getEditUserUrl(userID!), params),
    {
      onError: (error: any) => {
        handleOnError(error, 'Failed to update the user.', null);
      },
      onSuccess: async () => {
        enqueueSnackbar('Changes for the user has been applied.', {
          variant: 'success',
        });
        await queryClient.invalidateQueries('usersQuery');
        navigate(`/administration/users`);
      },
    }
  );

  return editUserMutation;
};
