import React from 'react';

interface State {
  hasError: boolean;
}

interface Props {
  children: React.ReactNode;
  errorComponent: React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(): State {
    return {
      hasError: true,
    };
  }

  render(): React.ReactNode {
    return this.state.hasError ? this.props.errorComponent : this.props.children;
  }
}
