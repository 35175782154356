import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  flexWrap: 'wrap',
  overflow: 'hidden',
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    width: '35px',
    height: '35px',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      borderLeft: `1px solid ${theme.palette.primary.main}!important`,
      marginLeft: 0,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
    },
  },
}));

export type ToggleFieldProps = {
  name: string;
  dataCy?: string;
  boxes: number[] | string[];
};

export const ToggleFormField: React.FC<ToggleFieldProps> = (props: ToggleFieldProps) => {
  const { control } = useFormContext();
  const { boxes, dataCy, name } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }): React.ReactElement => (
        <StyledToggleButtonGroup
          onChange={(e, currentValue): void => {
            if (currentValue) {
              onChange(currentValue);
            }
          }}
          value={value}
          color="primary"
          data-cy={`${dataCy}boxes_buttonGroup`}
          exclusive
        >
          {boxes.map((box, key) => (
            <ToggleButton key={key} value={box} data-cy={`${dataCy}boxes_buttonGroupButton_${box}`}>
              <Typography variant="body2"> {box}</Typography>
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      )}
    />
  );
};
