import { useState } from 'react';

interface HookProps {
  onConfirm(): Promise<void>;
}

interface HookResult {
  open: boolean;
  handleClickOpen(): void;
  handleConfirm(): Promise<void>;
  handleClose(): void;
}

export const useConfirmDialog = ({ onConfirm }: HookProps): HookResult => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleConfirm = async (): Promise<void> => {
    setOpen(false);
    await onConfirm();
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return {
    open,
    handleClickOpen,
    handleConfirm,
    handleClose,
  };
};
