import React from 'react';

import { ImageList, ImageListItem, Link, styled } from '@mui/material';

const StyledImageList = styled(ImageList)(({ theme }) => ({
  maxHeight: '50vh',
  marginTop: 0,
  [theme.breakpoints.down('xs')]: {
    maxHeight: '70vh',
  },
}));

interface Props {
  pictureUrls: string[];
  dataCyPrefix: string;
}

export const VehicleImages: React.FC<Props> = ({ pictureUrls, dataCyPrefix }) => (
  <StyledImageList cols={1}>
    {pictureUrls.map((url, key) => (
      <Link key={key} href={url} target="_blank" rel="noopener">
        <ImageListItem>
          <img
            src={`${url}`}
            srcSet={`${url}`}
            loading="lazy"
            data-cy={`${dataCyPrefix}_carImage_${key}`}
          />
        </ImageListItem>
      </Link>
    ))}
  </StyledImageList>
);
