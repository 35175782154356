import { useContext } from 'react';
import { useMutation, UseMutationResult } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosError, AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { getPostVirtualVehicleUrl } from 'services/VirtualVehicleService';

import { handleOnError } from 'utils/queryUtils';

export interface CreateVirtualVehicleRequest {
  sourceCarId: string;
}

export interface VirtualVehicleDto {
  carId: string;
}

export const useCreateVirtualCarQuery = (): UseMutationResult<
  AxiosResponse<VirtualVehicleDto, unknown>,
  unknown,
  CreateVirtualVehicleRequest,
  unknown
> => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);

  const createVirtualCarMutation = useMutation(
    (params: CreateVirtualVehicleRequest) =>
      axiosClient.post<VirtualVehicleDto>(getPostVirtualVehicleUrl(), params),
    {
      onError: (error: AxiosError) => {
        handleOnError(error, 'Failed to create virtual vehicle');
      },
      onSuccess: async () => {
        enqueueSnackbar('Virtual vehicle created', {
          variant: 'info',
        });
      },
    }
  );

  return createVirtualCarMutation;
};
