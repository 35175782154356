import { useContext, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useLdcIdentify } from 'hooks/useLdcIdentify';

import { UserPermission } from 'utils/UserPermission';

interface HookResult {
  error: any;
  isLoading: boolean;
  isAuthenticated: boolean;
  userPermissions: UserPermission[];
}

export const useProtectedRoute = (): HookResult => {
  const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0();
  useLdcIdentify();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
          target: window.location.href,
        },
      });
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  const { permissions } = useContext(PermissionsContext);

  return {
    error,
    isLoading,
    isAuthenticated,
    userPermissions: permissions,
  };
};
