import { RowSelectionState } from '@tanstack/react-table';

import { MRT_Row as TableRow, MRT_TableInstance as TableInstance } from 'material-react-table';

import {
  FeeValueType,
  ReserveProfitMethod,
} from 'services/payment-grid/PaymentGridApiModels.generated';
import { LeaseProgram, LoanProgram, ProgramType } from 'services/PdpApiService';

export enum GridType {
  Lease = 'LEASE',
  Loan = 'LOAN',
  Both = 'Both',
  None = 'None',
}

export interface ProfitData {
  vehicleProfit: number;
  tradeInProfit: number;
  totalProfit: number;
  reserveSplit: number;
  acquisitionProfit?: number;
  serviceAndProductionProducts: number;
  splitProfit: number;
  appliedReserveMethod: ReserveProfitMethod;
  reserveTotal: number;
  flatValue: number;
  flatValueType: FeeValueType;
}

export interface ProgramGrid {
  type: ProgramType;
  down: number;
  profitData: ProfitData;
  rebates: number;
  maxAdvance: number;
  residualAmount: number;
  residualRate?: number;
  rate: number;
  lenderName: string;
  term: number;
  payment: number;
  millage: string;
  id: string;
  programName: string;
  expiryDate: string;
  vehiclePrice: number;
}

// ToDo - Can this just reference CalculationLeaseOutput instead of copying all of the properties?
export interface LeaseProgramGrid extends ProgramGrid {}

// ToDo - Can this just reference CalculationLeaseOutput instead of copying all of the properties?
export interface LoanProgramGrid extends ProgramGrid {}

export function getSelectedLeaseAndLoanRow({ getSelectedRowModel }: TableInstance<ProgramGrid>): {
  selectedLoan?: TableRow<ProgramGrid>;
  selectedLease?: TableRow<ProgramGrid>;
} {
  const { rows: selectedRows } = getSelectedRowModel();
  const selectedLease = selectedRows.find(({ getValue }) => getValue('type') === ProgramType.Lease);
  const selectedLoan = selectedRows.find(({ getValue }) => getValue('type') === ProgramType.Loan);

  return { selectedLease, selectedLoan };
}

export function isLeaseProgramType(programType: ProgramType): boolean {
  return programType === ProgramType.Lease;
}

export function isLoanProgramType(programType: ProgramType): boolean {
  return programType === ProgramType.Loan;
}

interface RowSelectionDataOptions {
  previousRowState: RowSelectionState;
  programType: ProgramType;
  table: TableInstance<ProgramGrid>;
  rowId: string;
}

export function getRowSelectionData({
  programType,
  previousRowState,
  table,
  rowId,
}: RowSelectionDataOptions): RowSelectionState {
  const previous = { ...previousRowState };

  const { selectedLease, selectedLoan } = getSelectedLeaseAndLoanRow(table);

  if (selectedLease && isLeaseProgramType(programType)) {
    delete previous[selectedLease.id];
  }
  if (selectedLoan && isLoanProgramType(programType)) {
    delete previous[selectedLoan.id];
  }

  return {
    ...previous,
    [rowId]: !previousRowState[rowId],
  };
}

interface DefaultRowSelectionOptions {
  loanProgramId?: string;
  leaseProgramId?: string;
}

export function getDefaultRowSelectionState({
  leaseProgramId,
  loanProgramId,
}: DefaultRowSelectionOptions): RowSelectionState {
  let defaultRowSelectionState: RowSelectionState = {};

  if (leaseProgramId) {
    defaultRowSelectionState = { ...defaultRowSelectionState, [leaseProgramId]: true };
  }

  if (loanProgramId) {
    defaultRowSelectionState = { ...defaultRowSelectionState, [loanProgramId]: true };
  }

  return defaultRowSelectionState;
}

export function getNewLeaseProgram(
  leasePrograms: LeaseProgram[],
  rowId: string
): LeaseProgram | undefined {
  return leasePrograms.find(({ id }) => id === rowId);
}

export function getNewLoanProgram(
  loanPrograms: LoanProgram[],
  rowId: string
): LoanProgram | undefined {
  return loanPrograms.find(({ id }) => id === rowId);
}
