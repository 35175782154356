import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { AxiosContext } from 'providers/AxiosProvider';

import { DealOffer, getDealOfferUrl } from 'services/DealOfferApiService';

export interface HookProps {
  dealOfferId: string;
}

export const useDealOfferQuery = ({
  dealOfferId,
}: HookProps): UseQueryResult<DealOffer, unknown> => {
  const axiosClient = useContext(AxiosContext);

  const dealOfferQuery = useQuery(
    ['dealOfferQuery', dealOfferId],
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { data } = await axiosClient.get<DealOffer>(getDealOfferUrl(dealOfferId));

      return data;
    },
    {
      enabled: !!dealOfferId,
      staleTime: 0,
    }
  );

  return dealOfferQuery;
};
