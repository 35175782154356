import React, { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { changeDownPaymentCalculationType } from 'components/pdp/form/PaymentCalculationService';

import {
  downPaymentCalculationTypeAtom,
  monthlyPaymentCalculationTypeAtom,
  PaymentCalculationType,
} from 'models/ExactPaymentModel';

const options = [
  { value: PaymentCalculationType.EXACT, label: 'EXACT AMT' },
  { value: PaymentCalculationType.CALCULATED, label: 'CALCULATED' },
];

interface HookResult {
  downPaymentCalculationTypeValue: PaymentCalculationType;
  handleDownPaymentChange(e: React.ChangeEvent): void;
  options: { value: PaymentCalculationType; label: string }[];
}

export const useDownPaymentSelect = (): HookResult => {
  const [downPaymentCalculationTypeValue, setDownPaymentCalculationType] = useRecoilState(
    downPaymentCalculationTypeAtom
  );

  const setMonthlyPaymentCalculationType = useSetRecoilState(monthlyPaymentCalculationTypeAtom);

  const handleDownPaymentChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const newCalculationType = +value as PaymentCalculationType;

      changeDownPaymentCalculationType({
        newCalculationType,
        setMonthlyPaymentCalculationType,
        setDownPaymentCalculationType,
      });
    },
    []
  );

  return {
    downPaymentCalculationTypeValue,
    handleDownPaymentChange,
    options,
  };
};
