import { useCallback, useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { PdpContext } from 'providers/PdpContextProvider';

import { usePaymentGridQuery } from 'hooks/usePaymentGridQuery';

import {
  downPaymentCalculationTypeAtom,
  monthlyPaymentCalculationTypeAtom,
} from 'models/ExactPaymentModel';
import { leaseProgramsGridAtom, loanProgramsGridAtom } from 'models/GridViewModel';
import { ProgramSelectionMethod } from 'models/PaymentSelectionModel';

import {
  PaymentGridUpdate,
  TtlPrecision,
} from 'services/payment-grid/PaymentGridApiModels.generated';

import { isCalculationInvalid, updatePrograms } from './PaymentCalculationService';

interface HookOptions {
  autoUpdateGrid: boolean;
  ttlPrecision: TtlPrecision;
  leaseProgramSelectionMethod: ProgramSelectionMethod;
  loanProgramSelectionMethod: ProgramSelectionMethod;
}

interface HookResult {
  updatePaymentGrid: () => Promise<PaymentGridUpdate | undefined>;
}

export const usePaymentCalculation = ({
  autoUpdateGrid,
  ttlPrecision,
  leaseProgramSelectionMethod,
  loanProgramSelectionMethod,
}: HookOptions): HookResult => {
  const { control, setValue } = useFormContext<PdpFormValues>();

  const {
    leaseProgram: currentlySelectedLease,
    loanProgram: currentlySelectedLoan,
    payAllFeesUpfront,
    payAllPackagesUpfront,
    payFirstLeasePaymentUpfront,
    creditScore,
    downPayment,
    maxOutOfPocket,
    monthlyExactPayment,
  } = useWatch({ control }) as PdpFormValues;

  const setLeaseGrid = useSetRecoilState(leaseProgramsGridAtom);
  const setLoanGrid = useSetRecoilState(loanProgramsGridAtom);
  const downPaymentCalculationType = useRecoilValue(downPaymentCalculationTypeAtom);
  const paymentCalculationType = useRecoilValue(monthlyPaymentCalculationTypeAtom);

  const {
    query: { refetch: getPaymentGrid },
    request,
  } = usePaymentGridQuery({
    ttlPrecision,
    selectedLease:
      leaseProgramSelectionMethod === ProgramSelectionMethod.Manual
        ? currentlySelectedLease
        : undefined,
    selectedLoan:
      loanProgramSelectionMethod === ProgramSelectionMethod.Manual
        ? currentlySelectedLoan
        : undefined,
  });

  const pdpContext = useContext(PdpContext);

  const updatePaymentGridInternal = useCallback(
    async (isSubscribed: () => boolean): Promise<PaymentGridUpdate | undefined> => {
      if (
        isCalculationInvalid({
          downPaymentCalculationType,
          paymentCalculationType,
          monthlyExactPayment,
        })
      ) {
        return;
      }

      const result = (await getPaymentGrid())?.data;
      pdpContext.initialLoadCompleted();
      const shouldProcessResult = !!result && isSubscribed();

      if (!shouldProcessResult) {
        return undefined;
      }

      const updateProgramsResponse = updatePrograms({
        paymentGridResult: result,
        creditScore,
        payAllFeesUpfront,
        payAllPackagesUpfront,
        payFirstLeasePaymentUpfront,
        downPayment,
        maxOutOfPocket,
        pdpContext,
        currentlySelectedLease,
        currentlySelectedLoan,
        leaseProgramSelectionMethod,
        loanProgramSelectionMethod,
        ttlPrecision,
        setLeaseGrid,
        setLoanGrid,
        setValue,
      });

      return { result, updateProgramsResponse };
    },
    [
      currentlySelectedLease?.id,
      currentlySelectedLoan?.id,
      leaseProgramSelectionMethod,
      loanProgramSelectionMethod,
      downPaymentCalculationType,
      paymentCalculationType,
      monthlyExactPayment,
      getPaymentGrid,
      JSON.stringify(request),
    ]
  );

  const updatePaymentGrid = useCallback(
    (): Promise<PaymentGridUpdate | undefined> => updatePaymentGridInternal(() => true),
    [updatePaymentGridInternal]
  );

  useEffect(() => {
    if (!autoUpdateGrid || pdpContext.isPaymentCalculationDisabled) {
      return;
    }

    let isSubscribed = true;

    (async (): Promise<void> => {
      await updatePaymentGridInternal(() => isSubscribed);
    })();

    return () => {
      isSubscribed = false;
    };
  }, [autoUpdateGrid, pdpContext.isPaymentCalculationDisabled, updatePaymentGridInternal]);

  return {
    updatePaymentGrid,
  };
};
