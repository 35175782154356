import React, { ChangeEventHandler } from 'react';

import { TextField, TextFieldProps } from '@mui/material';

import { NumberHandlingMode, useTextFormField } from './useTextFormField';

const DEFAULT_DEBOUNCE_TIME_MS = 250;

export type TextFormFieldProps = TextFieldProps & {
  name: string;
  dataCy?: string;
  autoCompleteOff?: boolean;
  debounceEnabled?: boolean;
  debounceTimeMs?: number;
  numberHandlingMode?: NumberHandlingMode;
  step?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const TextFormField: React.FC<TextFormFieldProps> = (props) => {
  const { name, label, debounceTimeMs = DEFAULT_DEBOUNCE_TIME_MS } = props;
  const valueAsNumber = props.InputProps?.type === 'number';

  const { isError, helperText, handleChange, ref } = useTextFormField({
    name,
    debounceTimeMs,
    debounceEnabled: props.debounceEnabled ?? true,
    valueAsNumber,
    numberHandlingMode: props.numberHandlingMode,
    onChange: props.onChange,
  });
  const { dataCy, numberHandlingMode, debounceEnabled, autoCompleteOff, ...textInputProps } = props;

  return (
    <TextField
      {...textInputProps}
      label={typeof label === 'string' ? label?.toUpperCase() : props.label}
      ref={ref}
      onChange={handleChange}
      inputProps={{
        ...props.inputProps,
        'data-cy': dataCy,
        autoComplete: autoCompleteOff && 'disabled',
        step: props.step,
      }}
      error={isError}
      helperText={helperText}
    />
  );
};

TextFormField.defaultProps = {
  variant: 'outlined',
  size: 'small',
};
