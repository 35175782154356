import React from 'react';

import { MenuItem } from '@mui/material';

import { getLocationDisplay } from 'components/molecules/form-fields/zip-form-field/ZipFormFieldService';

import { LocationWithZip } from 'services/ZipCodeApiService';

interface Props {
  htmlProps: React.HtmlHTMLAttributes<HTMLElement>;
  location: LocationWithZip;
  dataCy: string;
  showCounty?: boolean;
}

export const ZipMenuItem: React.FC<Props> = ({ location, htmlProps, dataCy }) => (
  <MenuItem {...htmlProps} data-cy={`${dataCy}_menuItem_${location.id}`} key={location.id}>
    {getLocationDisplay(location)}
  </MenuItem>
);
