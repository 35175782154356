import { useCallback } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { UserDealerAssignmentFormValues } from './UserDealerAssignmentFormService';

interface Props {
  fields: FieldArrayWithId<UserDealerAssignmentFormValues, 'userDealers', 'id'>[];
}

interface HookResult {
  handleSelectAll: () => void;
}

export const useUserDealerAssignmentForm = ({ fields }: Props): HookResult => {
  const { setValue } = useFormContext();

  const handleSelectAll = useCallback(() => {
    fields.forEach((field, i) => {
      if (!field.disabled) {
        field.carsNew = true;
        field.carsUsed = true;
        setValue(`userDealers[${i}].carsNew`, true);
        setValue(`userDealers[${i}].carsUsed`, true);
      }
    });
  }, [fields]);

  return {
    handleSelectAll,
  };
};
