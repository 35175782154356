import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { CustomIncentive } from 'models/CustomIncentivesModel';

import { Addon } from 'services/PdpApiService';

import { isEmptyOrValidZip, isUniqueCustomAddon, isUniqueCustomIncentive } from './validationUtils';

export function getSearchValidationResolver(): ReturnType<typeof yupResolver> {
  return yupResolver(
    Yup.object().shape({
      year: Yup.object().shape(
        {
          from: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .min(0, 'Year from has to be greater than 0'),
          to: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when(['from'], {
              is: (value: number) => !!value,
              then: Yup.number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .nullable()
                .min(Yup.ref('from'), 'Year to has to be greater than Year from'),
            })
            .min(0, 'Year to has to be greater than 0'),
        },
        ['from', 'to'] as any
      ),
      price: Yup.object().shape(
        {
          from: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .min(0, 'Price from has to be greater than 0'),
          to: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when(['from'], {
              is: (value: number) => !!value,
              then: Yup.number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .nullable()
                .min(Yup.ref('from'), 'Price to has to be greater than Price from'),
            })
            .min(0, 'Price to has to be greater than 0'),
        },
        ['from', 'to'] as any
      ),
      mileage: Yup.object().shape(
        {
          from: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .min(0, 'Mileage from has to be greater than 0'),
          to: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when(['from'], {
              is: (value: number) => !!value,
              then: Yup.number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .nullable()
                .min(Yup.ref('from'), 'Mileage to has to be greater than Milleage from'),
            })
            .min(0, 'Mileage to has to be greater than 0'),
        },
        ['from', 'to'] as any
      ),
      monthlyPayment: Yup.object().shape(
        {
          from: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .min(0, 'Monthly payment from has to be greater than 0'),
          to: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when(['from'], {
              is: (value: number) => !!value,
              then: Yup.number()
                .transform((value) => (isNaN(value) ? undefined : value))
                .nullable()
                .min(
                  Yup.ref('from'),
                  'Monthly payment to has to be greater than Monthly payment from'
                ),
            })
            .min(0, 'Monthly payment to has to be greater than 0'),
        },
        ['from', 'to'] as any
      ),
      calculationOptions: Yup.object().shape({
        zipCode: Yup.string().test('zipLength', 'Enter a valid value.', (zipCode) =>
          isEmptyOrValidZip(zipCode)
        ),
        downPayment: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .when(['payAllFeesUpfront'], {
            is: (value: boolean) => value,
            then: Yup.number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .nullable()
              .min(0, 'Enter a valid value.'),
          }),
        tradeIn: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .min(0, 'Enter a valid value.'),
        tradeInOutstandingBalance: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .min(0, 'Enter a valid value.'),
        maxOutOfPocket: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .when(['payAllFeesUpfront'], {
            is: (value: boolean) => !value,
            then: Yup.number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .nullable()
              .min(0, 'Enter a valid value.'),
          }),
        location: Yup.object().shape({
          zipCode: Yup.string().test('zipLength', 'Enter a valid value.', (zipCode) =>
            isEmptyOrValidZip(zipCode)
          ),
        }),
      }),
      customer: Yup.object().shape({
        email: Yup.string().email('Email must be in a valid format'),
      }),
    })
  );
}

export function getTradeOptionsModalValidationResolver(): ReturnType<typeof yupResolver> {
  return yupResolver(
    Yup.object().shape({
      tradeInValue: Yup.number()
        .min(0, 'Trade in value must be greater or equal to 0')
        .typeError('Trade in value must be number')
        .required('Trade in value is required'),
      tradeInOutstandingBal: Yup.number()
        .min(0, 'Owed on Trade-In value must be greater or equal to 0')
        .typeError('Owed on Trade-In value must be number')
        .required('Owed on Trade-In value is required'),
    })
  );
}

export function getCustomAddonValidationResolver(
  customAddons: Addon[]
): ReturnType<typeof yupResolver> {
  return yupResolver(
    Yup.object().shape({
      name: Yup.string()
        .required('Add-on name is required.')
        .test('customAddonsUniqueness', 'Name is already being used.', (name) =>
          isUniqueCustomAddon(customAddons, name)
        ),
      price: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .min(0, 'Retail price from has to be greater than 0')
        .required('Retail price is required.'),
      wholesale: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .min(0, 'Wholesale price from has to be greater than 0')
        .required('Wholesale cost is required.'),
    })
  );
}

export function getCustomIncentivesValidationResolver(
  customIncentives: CustomIncentive[]
): ReturnType<typeof yupResolver> {
  return yupResolver(
    Yup.object().shape({
      name: Yup.string()
        .required('Incentive name is required.')
        .test('customIncentivesUniqueness', 'Name is already being used.', (name) =>
          isUniqueCustomIncentive(customIncentives, name)
        ),
      amount: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .min(0, 'Amount must be greater than 0.')
        .required('Amount is required.'),
    })
  );
}

export function getUserValidationResolver(): ReturnType<typeof yupResolver> {
  const phoneRegExpression = /^(?:(\d{3}))?[-. ]?(\d{3})[-. ]?(\d{4})$/;

  return yupResolver(
    Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().required('Email is required').email('Email must be in a valid format'),
      userDealershipPhone: Yup.string()
        .required('Dealership phone is required')
        .matches(phoneRegExpression, 'Dealership phone must be a valid phone number'),
      userPreferedText: Yup.string()
        .required('Mobile Phone is required')
        .matches(phoneRegExpression, 'Mobile phone must be a valid phone number'),
      dealerIds: Yup.array().required('Dealers are required'),
      jobTitle: Yup.string().required('Job title is required'),
    })
  );
}

export function getResponderValidationResolver(): ReturnType<typeof yupResolver> {
  return yupResolver(
    Yup.object().shape({
      to: Yup.string().email().required('To is required'),
      from: Yup.string().email().required('From is required'),
      subject: Yup.string().required('Subject is required'),
    })
  );
}
