import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { TypographyVariants } from 'utils/muiThemeUtils';

export type RadioFormFieldProps = {
  name: string;
  selectorPrefix?: string;
  items: {
    value: string | number;
    label: string;
    nestedElement?: React.ReactElement;
    isDisabled?: boolean;
  }[];
  isNumber?: boolean;
  size?: 'small' | 'medium';
  labelTypographyVariant?: TypographyVariants;
  showTooltTip?: boolean;
};

export const RadioFormField: React.FC<RadioFormFieldProps> = ({
  name,
  selectorPrefix,
  items,
  size = 'small',
  isNumber,
  labelTypographyVariant,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, name }, fieldState: { error } }): React.ReactElement => (
        <FormControl error={!!error}>
          <RadioGroup
            aria-labelledby="controlled-radio-buttons-group"
            name={name}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              isNumber
                ? onChange({ ...e, target: { ...e.target, value: parseInt(e.target.value) } })
                : onChange(e);
            }}
            data-cy={`${selectorPrefix}-radio-group`}
          >
            {items.map(({ label, value: itemValue, nestedElement, isDisabled = false }) => (
              <React.Fragment key={itemValue}>
                <FormControlLabel
                  key={itemValue}
                  value={itemValue}
                  control={
                    <Radio
                      size={size}
                      inputProps={
                        {
                          'data-cy': `${selectorPrefix}-radio-group-input-${label}`,
                        } as any
                      }
                    />
                  }
                  label={<Typography variant={labelTypographyVariant}>{label}</Typography>}
                  disabled={isDisabled}
                />
                {nestedElement}
              </React.Fragment>
            ))}
          </RadioGroup>
          {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
