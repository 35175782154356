import { useContext } from 'react';
import { UseMutationResult, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { AxiosError, AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { Dealer, getDealerApiUrl, getPutDealerApiUrl } from 'services/DealerApiService';

import { queryClient } from 'utils/queryClientUtils';
import { handleOnError } from 'utils/queryUtils';

import { useConfiguration } from './useConfiguration';

export const useSaveDealerMutation = (): UseMutationResult<
  AxiosResponse<string, Dealer>,
  unknown,
  Dealer,
  unknown
> => {
  const axiosClient = useContext(AxiosContext);
  const { dealerApiUrl } = useConfiguration();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const url = getPutDealerApiUrl();
  const saveDealerMutation = useMutation(
    async (dealer: Dealer) => await axiosClient.put(url, dealer, { baseURL: dealerApiUrl }),
    {
      onError: (error: AxiosError) => {
        handleOnError(error, 'Failed to save the dealer configuration');
      },
      onSuccess: async (_, dealer: Dealer) => {
        enqueueSnackbar('Dealer configuration saved.', {
          variant: 'success',
        });
        await queryClient.invalidateQueries(getDealerApiUrl(dealer.publicId));
        navigate(`/administration/dealers`);
      },
      retry: false,
    }
  );

  return saveDealerMutation;
};
