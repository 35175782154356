import { useForm, UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { CustomAddonsAtom } from 'models/CustomAddonsModel';

import { AddonType, DealType } from 'services/PdpApiService';

import { getCustomAddonValidationResolver } from 'utils/validationResolvers';

import { getDefaultFormValues } from './CustomAddOnFormWrapperService';

export interface CustomAddonFormValues {
  name: string;
  price: number;
  wholesale: number;
  type: AddonType;
  dealType: DealType;
}

interface HookResult {
  methods: UseFormReturn<CustomAddonFormValues>;
}

export const useCustomAddOnFormWrapper = (): HookResult => {
  const defaultValues = getDefaultFormValues();
  const customAddonsValue = useRecoilValue(CustomAddonsAtom);
  const methods = useForm<CustomAddonFormValues>({
    defaultValues,
    resolver: getCustomAddonValidationResolver(customAddonsValue),
    mode: 'all',
  });

  return {
    methods,
  };
};
