import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAppInsight } from 'hooks/useAppInsight';

import { searchValuesAtom } from 'models/SearchModel';

import { PdpItem } from 'services/PdpApiService';

import { distinct } from 'utils/arrayUtils';

interface HookProps {
  data: PdpItem | undefined;
  isLoading: boolean;
}

export const usePdpPage = ({ data, isLoading }: HookProps): void => {
  const { carId: id } = useParams<{ carId: string }>();
  const { appInsights } = useAppInsight();
  const searchFormValues = useRecoilValue(searchValuesAtom);
  const zipCode = searchFormValues?.calculationOptions?.location?.zipCode;

  useEffect(() => {
    if (data) {
      const eventData = {
        id,
        zipCode,
        programIds: [
          ...distinct(data?.lenderData.lease.programs.map((lease) => lease.programId)),
          ...distinct(data?.lenderData.loan.programs.map((loan) => loan.programId)),
        ],
      };
      appInsights.trackEvent({ name: 'PDP page loaded' }, eventData);
    }
  }, [isLoading]);
};
