import _ from 'lodash';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import {
  filterNonTtlFees,
  leaseMatchesIdentifier,
  loanMatchesIdentifier,
} from 'components/pdp/form/PaymentCalculationService';

import { PaymentCalculationType } from 'models/ExactPaymentModel';

import { distinct } from 'utils/arrayUtils';

import {
  DealerIncentive,
  LeaseBase,
  LeaseResult,
  LoanBase,
  PaymentGridUpdate,
  ProgramIncentive,
  TtlPrecision,
  StackedIncentives as StackedIncentivesPaymentGrid,
  LoanResult,
  IncentiveMap,
} from './payment-grid/PaymentGridApiModels.generated';
import {
  MapLenderDataRequest,
  MapLeasesLenderDataRequest,
  MapLoansLenderDataRequest,
  mapLeaseProgram,
  MapLoanLenderDataRequest,
  mapLeaseLenderData,
  mapLoanLenderData,
  mapLeaseCalculation,
  mapLoanProgram,
  mapLoanCalculation,
  MapLeaseLenderDataRequest,
} from './payment-grid/PaymentGridApiResponseService';
import { Addon, Dealer, DealType, LenderData, PdpItem } from './PdpApiService';
import { mapRebates } from './RebatesService';
import { Vehicle } from './SearchApiService';

const baseUrl = `/dealoffer`;
const schemaVersion = '2.5.0';

export function getPostDealOfferUrl(): string {
  return baseUrl;
}

export function getDealOfferUrl(dealOfferId: string): string {
  return `${baseUrl}/${dealOfferId}`;
}

export function getAllDealOffersUrl(customerId: number | null): string {
  const url = `${baseUrl}?version=2.0`;

  return customerId ? `${url}&customerId=${customerId}` : url;
}

export function deleteDealOfferUrl(dealOfferId: string): string {
  return getDealOfferUrl(dealOfferId);
}

export function getSendDealOfferEmailUrl(dealOfferId: string): string {
  return `${getDealOfferUrl(dealOfferId)}/send/email`;
}

export function getDealOfferPdpUrl(dealOfferCode: string, schemaVersion: string): string {
  return `${baseUrl}/validate/${dealOfferCode}?schema=${schemaVersion}`;
}

export interface Metadata {
  createdAt: string;
  createdByUserName: string;
  id: string;
  customerId: number;
  fromDealOfferId?: string;
}

export type PaymentCustomization = Omit<
  PdpFormValues,
  | 'miles'
  | 'leaseTerms'
  | 'loanTerms'
  | 'loanApr'
  | 'leaseRebates'
  | 'loanRebates'
  | 'loanAndLeaseRebates'
  | 'programFilters'
  | 'customAddons'
> & {
  leaseBonusIncentive?: number;
  loanBonusIncentive?: number;
  monthlyPaymentCalculationType?: PaymentCalculationType;
  downPaymentCalculationType?: PaymentCalculationType;
  customAddons?: Addon[];
  showLease: boolean;
  showLoan: boolean;
};

export interface ProfitDetails {
  vehicleProfit: number;
  tradeInProfit: number;
  splitProfit: number;
  totalProfit: number;
  reserveSplit: number;
  serviceAndProductionProducts: number;
}

export interface LeaseProfitDetails extends ProfitDetails {
  acquisitionProfit: number;
}

export interface DealOfferBase {
  metadata: Metadata;
  dealer: Dealer;
  vehicle: Vehicle;
  paymentCustomization: PaymentCustomization;
}

export interface StackedIncentives {
  total: number;
  programIncentives: ProgramIncentive[];
  dealerIncentives: DealerIncentive[];
}

export interface Lease extends LeaseBase<StackedIncentives> {}

export interface Loan extends LoanBase<StackedIncentives> {}

export interface DealOffer extends DealOfferBase {
  schemaVersion: string;
  selectedLease?: Lease;
  selectedLoan?: Loan;
}

export interface DealOfferPdp extends Omit<DealOffer, 'leaseProfitDetails' | 'loanProfitDetails'> {
  lenderData: LenderData;
}

export interface ValidatedDealOfferPdp {
  isIncentivesExpired: boolean;
  isLeaseProgramExpired: boolean;
  isLoanProgramExpired: boolean;
  dealOffer: DealOfferPdp;
}

export interface DealOfferAdmin {
  id: string;
  vin: string;
  stockNumber: string;
  name: string;
  email: string | undefined;
  phone: string | undefined;
  ymmt: string;
  lease: number;
  loan: number;
  date: string;
  createdBy: string;
}

export function mapDealOfferToDealOfferAdmin(dealOffers: DealOfferBase[]): DealOfferAdmin[] {
  const dealerOfferAdminRecords = dealOffers.map(
    ({
      metadata: { createdAt, createdByUserName, id },
      vehicle: {
        vin: vehicleVin,
        year: vehicleYear,
        model: vehicleModel,
        trim: vehicleTrim,
        stockNumber: vehicleStockNumber,
        make: vehicleMake,
      },
      paymentCustomization: {
        buyer: { name: buyerName, email: buyerEmail, phone: buyerPhone },
        calculationLeaseOutputData,
        calculationLoanOutputData,
      },
    }) => {
      const dealOfferAdmin: DealOfferAdmin = {
        id,
        vin: vehicleVin,
        stockNumber: vehicleStockNumber,
        name: buyerName,
        email: buyerEmail,
        phone: buyerPhone,
        ymmt: `${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`,
        lease: calculationLeaseOutputData?.payment ?? 0,
        loan: calculationLoanOutputData?.payment ?? 0,
        date: createdAt,
        createdBy: createdByUserName,
      };

      return dealOfferAdmin;
    }
  );

  return dealerOfferAdminRecords;
}

export interface MapDealOfferRequest {
  allCustomAddons: Addon[];
  downPaymentCalculationType: PaymentCalculationType;
  monthlyPaymentCalculationType: PaymentCalculationType;
  paymentGridUpdate: PaymentGridUpdate;
  pdpData: PdpItem;
  pdpFormValues: PdpFormValues;
  showLease: boolean;
  showLoan: boolean;
  fromDealOfferId: string | undefined;
}

export function mapDealOffer({
  paymentGridUpdate: {
    result: paymentGridResult,
    updateProgramsResponse: { selectedLeaseProgram, selectedLoanProgram },
  },
  pdpFormValues,
  ...request
}: MapDealOfferRequest): DealOffer {
  const { incentives, leasesResult, loansResult } = paymentGridResult;

  // Any values that depend on the latest payment grid calculation (ex: selected programs, calculations, ttl)
  // cannot be retrieved from pdpFormValues because they will not be up to date until the UI rerender cycle
  // has completed. Saving deal offer should not be dependent on UI updates.
  const selectedLease = selectedLeaseProgram
    ? leasesResult.leases.find((x) => leaseMatchesIdentifier(selectedLeaseProgram, x.identifier))
    : undefined;

  const selectedLoan = selectedLoanProgram
    ? loansResult.loans.find((x) => loanMatchesIdentifier(selectedLoanProgram, x.identifier))
    : undefined;

  const appliedLeaseRebates = selectedLease?.incentives.programIncentives ?? [];
  const appliedLoanRebates = selectedLoan?.incentives.programIncentives ?? [];
  const appliedRebates = distinct([...appliedLeaseRebates, ...appliedLoanRebates]);

  const customAddons = _.intersectionWith(
    request.allCustomAddons,
    pdpFormValues.customAddons,
    (x, y) => x.name === y
  );

  const { dealer } = request.pdpData;

  return {
    schemaVersion,
    dealer: {
      creditScore: dealer.creditScore,
      downPayment: dealer.downPayment,
      nonTtlFees: dealer.nonTtlFees,
      creditAppUrl: dealer.creditAppUrl,
      tradeInUrl: dealer.tradeInUrl,
      addonsV2: dealer.addonsV2,
      customAddons: dealer.customAddons,
      payAllPackagesUpfront: dealer.payAllPackagesUpfront,
      dealerId: dealer.dealerId,
    },
    metadata: { fromDealOfferId: request.fromDealOfferId } as Metadata,
    paymentCustomization: {
      actualCashValue: pdpFormValues.actualCashValue,
      addons: pdpFormValues.addons,
      appliedCustomIncentives: pdpFormValues.appliedCustomIncentives,
      buyer: pdpFormValues.buyer,
      calculationLeaseOutputData: selectedLeaseProgram?.calculation,
      calculationLoanOutputData: selectedLoanProgram?.calculation,
      captive: pdpFormValues.captive,
      creditScore: pdpFormValues.creditScore,
      customAddons,
      customIncentiveNames: pdpFormValues.customIncentiveNames,
      downPayment: pdpFormValues.downPayment,
      downPaymentLease: selectedLease?.downPayment,
      downPaymentLoan: selectedLoan?.downPayment,
      downPaymentCalculationType: request.downPaymentCalculationType,
      leaseBonusIncentive: _.sumBy(selectedLease?.incentives.dealerIncentives, (x) => x.value),
      leaseDealerSplitPercent: pdpFormValues.leaseDealerSplitPercent,
      leaseMarkedUpAcquisitionFee: pdpFormValues.leaseMarkedUpAcquisitionFee,
      leaseMoneyFactorMarkup: pdpFormValues.leaseMoneyFactorMarkup,
      leaseProgram: selectedLeaseProgram,
      leaseTaxCreditTo: pdpFormValues.leaseTaxCreditTo,
      leaseVehiclePrice: selectedLease?.vehiclePrice,
      loanAprMarkupPercent: pdpFormValues.loanAprMarkupPercent,
      loanBonusIncentive: _.sumBy(selectedLoan?.incentives.dealerIncentives, (x) => x.value),
      loanDealerSplitPercent: pdpFormValues.loanDealerSplitPercent,
      loanProgram: selectedLoanProgram,
      loanTaxCreditTo: pdpFormValues.loanTaxCreditTo,
      loanVehiclePrice: selectedLoan?.vehiclePrice,
      maxOutOfPocket: pdpFormValues.maxOutOfPocket,
      maxOutOfPocketLease: selectedLease?.maxOutOfPocket,
      maxOutOfPocketLoan: selectedLoan?.maxOutOfPocket,
      monthlyExactPayment: pdpFormValues.monthlyExactPayment,
      monthlyPaymentCalculationType: request.monthlyPaymentCalculationType,
      msrp: pdpFormValues.msrp,
      nonTtlFees: pdpFormValues.nonTtlFees,
      payAllFeesUpfront: pdpFormValues.payAllFeesUpfront,
      payAllPackagesUpfront: pdpFormValues.payAllPackagesUpfront,
      payFirstLeasePaymentUpfront: pdpFormValues.payFirstLeasePaymentUpfront,
      priceType: pdpFormValues.priceType,
      rebates: appliedRebates,
      leaseReserveType: pdpFormValues.leaseReserveType,
      loanReserveType: pdpFormValues.loanReserveType,
      showLease: request.showLease,
      showLoan: request.showLoan,
      tradeInOutstandingBal: pdpFormValues.tradeInOutstandingBal,
      tradeInValue: pdpFormValues.tradeInValue,
      tradeVehicle: pdpFormValues.tradeVehicle,
      vehicleInvoice: pdpFormValues.vehicleInvoice,
      vehiclePrice: pdpFormValues.vehiclePrice,
    },
    selectedLease: selectedLease ? mapFromPaymentGridLease(selectedLease, incentives) : undefined,
    selectedLoan: selectedLoan ? mapFromPaymentGridLoan(selectedLoan, incentives) : undefined,
    vehicle: request.pdpData.vehicle,
  };
}

export function initializeDealOffer(dealOffer: ValidatedDealOfferPdp): ValidatedDealOfferPdp {
  const { dealer, paymentCustomization, selectedLease, selectedLoan } = dealOffer.dealOffer;

  const requestBase: MapLenderDataRequest = {
    creditScore: paymentCustomization.creditScore,
    downPayment: paymentCustomization.downPayment,
    payAllFeesUpfront: paymentCustomization.payAllFeesUpfront,
    payAllPackagesUpfront: paymentCustomization.payAllPackagesUpfront,
    maxOutOfPocket: paymentCustomization.maxOutOfPocket,
    nonTtlFees: dealer.nonTtlFees,
    payFirstLeasePaymentUpfront: paymentCustomization.payFirstLeasePaymentUpfront,
    ttl: { notes: [] },
    ttlPrecision: TtlPrecision.High,
  };

  const mappedSelectedLease = selectedLease ? mapToPaymentGridLease(selectedLease) : undefined;
  const mappedSelectedLoan = selectedLoan ? mapToPaymentGridLoan(selectedLoan) : undefined;

  const leasesRequest: MapLeasesLenderDataRequest = {
    ...requestBase,
    leaseResult: {
      leases: mappedSelectedLease ? [mappedSelectedLease] : [],
    },
    nonTtlFees: filterNonTtlFees(dealer.nonTtlFees, DealType.Lease),
    ttl: {
      notes: [],
    },
  };

  const loansRequest: MapLoansLenderDataRequest = {
    ...requestBase,
    loanResult: {
      loans: mappedSelectedLoan ? [mappedSelectedLoan] : [],
    },
    nonTtlFees: filterNonTtlFees(dealer.nonTtlFees, DealType.Loan),
    ttl: {
      notes: [],
    },
  };

  return {
    ...dealOffer,
    dealOffer: {
      ...dealOffer.dealOffer,
      lenderData: {
        ...dealOffer.dealOffer.lenderData,
        lease: mapLeaseLenderData(leasesRequest),
        loan: mapLoanLenderData(loansRequest),
        rebates: mapRebates(selectedLease, selectedLoan),
      },
      paymentCustomization: initializePaymentCustomization({
        dealOffer: dealOffer.dealOffer,
        leasesRequest,
        loansRequest,
      }),
    },
  };
}

interface InitializePaymentCustomizationRequest {
  dealOffer: DealOffer;
  leasesRequest: MapLeasesLenderDataRequest;
  loansRequest: MapLoansLenderDataRequest;
}

function initializePaymentCustomization({
  dealOffer,
  leasesRequest,
  loansRequest,
}: InitializePaymentCustomizationRequest): PaymentCustomization {
  let {
    paymentCustomization: {
      calculationLeaseOutputData,
      calculationLoanOutputData,
      leaseProgram,
      loanProgram,
    },
    selectedLease,
    selectedLoan,
  } = dealOffer;

  if (selectedLease) {
    const leaseRequest: MapLeaseLenderDataRequest = {
      ...leasesRequest,
      lease: mapToPaymentGridLease(selectedLease),
    };

    leaseProgram = mapLeaseProgram(leaseRequest);
    calculationLeaseOutputData = mapLeaseCalculation(leaseRequest);
  }

  if (selectedLoan) {
    const loanRequest: MapLoanLenderDataRequest = {
      ...loansRequest,
      loan: mapToPaymentGridLoan(selectedLoan),
    };
    loanProgram = mapLoanProgram(loanRequest);
    calculationLoanOutputData = mapLoanCalculation(loanRequest);
  }

  return {
    ...dealOffer.paymentCustomization,
    calculationLeaseOutputData,
    calculationLoanOutputData,
    leaseProgram,
    loanProgram,
  };
}

export function mapToPaymentGridLease(lease: Lease): LeaseResult {
  return {
    ...lease,
    incentives: mapToPaymentGridIncentives(lease.incentives),
  };
}

function mapFromPaymentGridLease(lease: LeaseResult, incentiveMap: IncentiveMap): Lease {
  return {
    ...lease,
    incentives: mapFromPaymentGridIncentives(lease.incentives, incentiveMap),
  };
}

export function mapToPaymentGridLoan(loan: Loan): LoanResult {
  return {
    ...loan,
    incentives: mapToPaymentGridIncentives(loan.incentives),
  };
}

function mapFromPaymentGridLoan(loan: LoanResult, incentiveMap: IncentiveMap): Loan {
  return {
    ...loan,
    incentives: mapFromPaymentGridIncentives(loan.incentives, incentiveMap),
  };
}

function mapToPaymentGridIncentives(incentives: StackedIncentives): StackedIncentivesPaymentGrid {
  return {
    dealerIncentives: incentives.dealerIncentives,
    programIncentives: incentives.programIncentives.map((x) => x.id),
    total: incentives.total,
  };
}

function mapFromPaymentGridIncentives(
  incentives: StackedIncentivesPaymentGrid,
  incentiveMap: IncentiveMap
): StackedIncentives {
  return {
    dealerIncentives: incentives.dealerIncentives,
    programIncentives: incentives.programIncentives.map((x) => incentiveMap[x]),
    total: incentives.total,
  };
}
