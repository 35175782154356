import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { useDealOffersQuery } from 'hooks/useDealOffersQuery';

import { DealOfferAdmin } from 'services/DealOfferApiService';

import { dealOffersTableColumns } from './DealOffersListPageService';
import { useDeleteDealOfferAdmin } from './useDeleteDealOfferAdmin';

interface HookResult {
  isLoading: boolean;
  columns: ColumnDef<Partial<DealOfferAdmin>>[];
  tableData: DealOfferAdmin[];
  handleDeleteDealOffer(id: string): Promise<void>;
}

export const useDealOfferListPage = (): HookResult => {
  const [searchParams] = useSearchParams();
  const customer = searchParams.get('customer');
  const customerId = customer ? parseInt(customer) : 0;
  const { handleDeleteDealOffer } = useDeleteDealOfferAdmin();
  const { data, isFetching } = useDealOffersQuery(customerId);
  const columns = useMemo<ColumnDef<Partial<DealOfferAdmin>>[]>(() => dealOffersTableColumns, []);
  const tableData = data ?? [];

  return {
    isLoading: isFetching,
    columns,
    tableData,
    handleDeleteDealOffer,
  };
};
