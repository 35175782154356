import { ChangeEvent, useCallback } from 'react';
import { useFormContext, RefCallBack } from 'react-hook-form';

import { SelectChangeEvent } from '@mui/material';

import { debounce } from 'lodash';

import { MultiSelectOptions } from './MultiSelectFormField';

interface HookOptions {
  name: string;
  options: MultiSelectOptions[];
  debounceTimeMs: number;
  debounceEnabled: boolean;
}

interface HookResult {
  handleChange(e: SelectChangeEvent<unknown>): void;
  handleSelectAll(): void;
  ref: RefCallBack;
}

export const useMultiSelectFormField = ({
  name,
  options,
  debounceTimeMs,
  debounceEnabled,
}: HookOptions): HookResult => {
  const { register, setValue } = useFormContext();

  const { onChange, ref } = register(name);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    debounceEnabled ? handleDebounce(e) : onChange(e);
  }, []);
  const handleDebounce = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    }, debounceTimeMs),
    []
  );

  const handleSelectAll = useCallback(() => {
    if (debounceEnabled) {
      debounce(() => {
        setMultiSelectValue(setValue, name, options);
      }, debounceTimeMs)();

      return;
    }
    setMultiSelectValue(setValue, name, options);
  }, [name, options]);

  return { handleChange, handleSelectAll, ref };
};

function setMultiSelectValue(
  setValue: Function,
  name: string,
  options: MultiSelectOptions[]
): void {
  setValue(
    name,
    options.filter((option) => !option.disabled || option.isChecked).map((option) => option.value)
  );
}
