import React, { useCallback } from 'react';

import { Button, CardContent, Divider, Grid, Stack, styled } from '@mui/material';

import { TriggeredModal } from 'components/molecules/modal';
import { VehicleFooter } from 'components/molecules/vehicle-footer';
import { VehicleHeader } from 'components/molecules/vehicle-header';

import { useBuildNewDeal } from 'hooks/useBuildNewDeal';

import { recommendationsPersistStateFieldsBlackList } from 'services/BuildNewDealService';
import { ProgramType } from 'services/PdpApiService';
import { Vehicle } from 'services/SearchApiService';

import { KeyRecommendationReasons } from './KeyRecommendationReasons';

interface Props {
  vehicle: Vehicle;
  programType: ProgramType;
}

const dataCyPrefix = 'KeyRecommendationModal';

const Image = styled('img')(() => ({
  width: '100%',
}));

const StyledCardContent = styled(CardContent)(() => ({
  height: '100%',
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
}));

export const KeyRecommendationModal: React.FC<Props> = ({ vehicle, programType }) => {
  const { handleNavigate: buildNewDeal } = useBuildNewDeal({
    persistState: true,
    persistStateFieldsBlacklist: recommendationsPersistStateFieldsBlackList,
  });

  const handleBuildNewDeal = useCallback(() => {
    buildNewDeal(vehicle.id);
  }, [vehicle.id, buildNewDeal]);

  return (
    <TriggeredModal
      title="We found a better deal for you and your customer"
      maxWidth="md"
      showConfirmButton={false}
      trigger={
        <Button variant="contained" data-cy={`${dataCyPrefix}_triggerButton`}>
          See details
        </Button>
      }
      content={
        <Stack direction="column" paddingLeft={3} paddingRight={3}>
          <StyledCardContent>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
              divider={<Divider />}
              spacing={1}
            >
              <Stack width="100%" divider={<Divider />} spacing={1}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={4}>
                    <Stack>
                      <Image data-cy={`${dataCyPrefix}_carImage`} src={vehicle.pictureUrl} />
                      <VehicleFooter
                        dataCyPrefix={`${dataCyPrefix}_vehicleFooter`}
                        daysInStock={vehicle.daysInStock}
                        location={vehicle.location}
                        stockNumber={vehicle.stockNumber}
                        status={vehicle.status}
                        vin={vehicle.vin}
                      />
                    </Stack>
                  </Grid>
                  <StyledGrid item xs={8}>
                    <Stack>
                      <VehicleHeader
                        make={vehicle.make}
                        model={vehicle.model}
                        trim={vehicle.trim}
                        state={vehicle.state}
                        status={vehicle.status}
                        year={vehicle.year}
                        dataCyPrefix={`${dataCyPrefix}_header`}
                      />
                    </Stack>
                    <KeyRecommendationReasons vehicle={vehicle} programType={programType} />
                  </StyledGrid>
                </Grid>
              </Stack>
            </Stack>
          </StyledCardContent>
          <Stack direction="row" justifyContent="center">
            <Button
              onClick={handleBuildNewDeal}
              variant="contained"
              data-cy={`${dataCyPrefix}_BuildDealButton`}
            >
              Build new deal
            </Button>
          </Stack>
        </Stack>
      }
    />
  );
};
