import React, { useCallback, useContext, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { UserPermission } from 'utils/UserPermission';

interface HookResult {
  anchorEl: null | HTMLElement;
  handleMenuClick(event: React.MouseEvent<HTMLElement>): void;
  handleCloseMenu(): void;
  handleCancelDialog(): void;
  handleOkDialog(): Promise<void>;
  dialogOpen: boolean;
  handleLogoutClick(): void;
  permissions: UserPermission[];
}

export const useAppBar = (): HookResult => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { logout } = useAuth0();
  const { permissions } = useContext(PermissionsContext);

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleCancelDialog = useCallback((): void => {
    setDialogOpen(false);
  }, []);

  const handleOkDialog = useCallback(async (): Promise<void> => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const handleLogoutClick = useCallback((): void => {
    setDialogOpen(true);
    setAnchorEl(null);
  }, []);

  return {
    handleMenuClick,
    handleCloseMenu,
    anchorEl,
    handleCancelDialog,
    handleOkDialog,
    dialogOpen,
    handleLogoutClick,
    permissions,
  };
};
