import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { AxiosContext } from 'providers/AxiosProvider';

import { VehicleFeatures, getVehicleFeaturesUrl } from 'services/PdpApiService';

interface HookOptions {
  carId: string;
  enabled: boolean;
}

export const useVehicleDetailsQuery = ({
  carId,
  enabled,
}: HookOptions): UseQueryResult<VehicleFeatures, unknown> => {
  const axiosClient = useContext(AxiosContext);

  const vehicleDetailsQuery = useQuery(
    ['vehicleDetailsQuery', carId],
    async () => {
      const { data } = await axiosClient.get<VehicleFeatures>(getVehicleFeaturesUrl(carId));

      return data;
    },
    {
      enabled,
      useErrorBoundary: false,
    }
  );

  return vehicleDetailsQuery;
};
