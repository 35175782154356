import { useRecoilValue } from 'recoil';

import { useConfiguration } from 'hooks/useConfiguration';

import { pdpCollapsibleSectionExpandedLeaseAtom } from 'models/PdpCollapsibleSectionModel';
import { priceRangeAtom } from 'models/PriceRangeModel';

import { CalculationLeaseOutput } from 'services/CalculationService';
import { SalesTaxDetailItem } from 'services/payment-grid/PaymentGridApiModels.generated';
import { TtlNote } from 'services/TtlApiService';

import {
  getLeaseDetailItems,
  getLeaseSigningCalculationItems,
  getLeaseSigningTtlCalculationItems,
  LeaseDetailItem,
  SigningCalculationItems,
} from './LeaseDetailCardService';
import { LeaseDetails } from './LeaseDetailsCard';

interface HookOptions {
  leaseDetails: LeaseDetails;
  calculationOutput: CalculationLeaseOutput;
}

interface HookResult {
  expanded: boolean;
  leaseSigningCalculationItems: SigningCalculationItems[];
  leaseSigningTtlCalculationItems: SigningCalculationItems[];
  leaseDetailsItems: LeaseDetailItem[];
  ttlNotes?: TtlNote[];
  salesTaxDetails: SalesTaxDetailItem[];
}

export const useLeaseDetailsCard = ({
  leaseDetails,
  calculationOutput,
}: HookOptions): HookResult => {
  const expanded = useRecoilValue(pdpCollapsibleSectionExpandedLeaseAtom);
  const priceRangeEnabled = useRecoilValue(priceRangeAtom);

  const {
    paymentsIntervalOptions: { defaultInterval },
  } = useConfiguration();

  return {
    leaseSigningCalculationItems: getLeaseSigningCalculationItems(
      calculationOutput,
      priceRangeEnabled,
      defaultInterval
    ),
    leaseSigningTtlCalculationItems: getLeaseSigningTtlCalculationItems(calculationOutput),
    leaseDetailsItems: getLeaseDetailItems(leaseDetails, calculationOutput),
    expanded,
    ttlNotes: calculationOutput.ttlFee?.notes,
    salesTaxDetails: calculationOutput.ttlFee?.details ?? [],
  };
};
