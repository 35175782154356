import React from 'react';

import { Stack, styled, Typography } from '@mui/material';

import { theme } from 'utils/muiThemeUtils';

const StyledStack = styled(Stack)(({ theme }) => ({
  borderTop: `${theme.spacing(0.125)} solid ${theme.palette.grey[300]}`,
  padding: `${theme.spacing(2)} 0 ${theme.spacing(4)} 0`,
  margin: `0 ${theme.spacing(2)}`,
}));

export const Footer: React.FC = () => (
  <StyledStack direction="row" justifyContent="start" alignItems="center" spacing={1.5}>
    <img src="/icon/carmaticLogoGrey.svg" alt="Carmatic" data-cy="universalFooter_carmaticIcon" />
    <Typography variant="body4" color={`${theme.palette.grey[500]}`}>
      NEED HELP? CALL US AT <a href="tel:+1-959-227-6284">(959) CARMATIC</a> OR E-MAIL US AT{' '}
      <a href="mailto:support@carmatic.com">SUPPORT@CARMATIC.COM</a>
    </Typography>
  </StyledStack>
);
