import React from 'react';
import { FieldArrayWithId, FieldValues, UseFormReturn, useFormContext } from 'react-hook-form';

import { Divider, Grid, InputAdornment, Stack, Typography } from '@mui/material';

import { Card } from 'components/atoms/card';
import { NumberFormField, SelectFormField } from 'components/molecules/form-fields';
import { NumberHandlingMode } from 'components/molecules/form-fields/text-form-field/useTextFormField';

import {
  DealerAdminFormValues,
  DealerReserveMode,
  getLenderDisplayName,
  getReserveModeOptions,
} from './DealerAdminFormService';

interface Props {
  fields: FieldArrayWithId<DealerAdminFormValues, 'lenderSettings', 'lenderName'>[];
}

export const DealerLenderSettingsForm: React.FC<Props> = ({ fields }: Props) => {
  const dealerReserveModeOptions = getReserveModeOptions();

  const formContext = useFormContext();

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Reserve Overrides</Typography>

      {fields.map((field, i) => (
        <Card key={field.id} sx={{ padding: 2 }}>
          <Stack spacing={2}>
            <Typography>{getLenderDisplayName(field.lenderName, field.lenderCode)}:</Typography>
            {createReserveOverrideControls(
              i,
              'Lease money factor (new car)',
              'newMoneyFactor',
              dealerReserveModeOptions,
              'money factor',
              formContext
            )}
            {createReserveOverrideControls(
              i,
              'Loan apr (new car)',
              'newApr',
              dealerReserveModeOptions,
              'rate',
              formContext
            )}
            {createReserveOverrideControls(
              i,
              'Loan apr (used car)',
              'usedApr',
              dealerReserveModeOptions,
              'rate',
              formContext
            )}
            {createReserveOverrideControls(
              i,
              'Loan apr (CPOV car)',
              'cpovApr',
              dealerReserveModeOptions,
              'rate',
              formContext
            )}
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

function createReserveOverrideControls(
  index: number,
  header: string,
  path: string,
  dealerReserveModeOptions: { label: string; value: number }[],
  markupLabel: string,
  formContext: UseFormReturn<FieldValues, any>
): React.ReactNode {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={2}>
        <Typography variant="body2">{header}:</Typography>
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <SelectFormField
              name={`lenderSettings[${index}].${path}.dealerReserveMode`}
              label="Reserve Mode"
              focused
              fullWidth
              options={dealerReserveModeOptions}
            ></SelectFormField>
          </Grid>
          <Grid item xs={9}>
            {createFormFields(index, path, markupLabel, formContext)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function getReserveField(
  index: number,
  path: string,
  dealerReserveMode: DealerReserveMode
): React.ReactNode {
  if (dealerReserveMode === DealerReserveMode.Flat) {
    return (
      <NumberFormField
        label="Fee"
        name={`lenderSettings[${index}].${path}.fee`}
        numberHandlingMode={NumberHandlingMode.AsUndefined}
        focused
        step={0.01}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        debounceEnabled={false}
      ></NumberFormField>
    );
  }

  return (
    <NumberFormField
      label="Percent"
      name={`lenderSettings[${index}].${path}.percent`}
      numberHandlingMode={NumberHandlingMode.AsUndefined}
      focused
      step={0.01}
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
      debounceEnabled={false}
    ></NumberFormField>
  );
}

function getRateMarkupField(
  index: number,
  path: string,
  isPercent: boolean,
  label: string
): React.ReactNode {
  if (isPercent) {
    return (
      <NumberFormField
        label={`${label} markup`}
        name={`lenderSettings[${index}].${path}`}
        numberHandlingMode={NumberHandlingMode.AsUndefined}
        focused
        step={0.01}
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
      ></NumberFormField>
    );
  }

  return (
    <NumberFormField
      label={`${label} markup`}
      name={`lenderSettings[${index}].${path}`}
      numberHandlingMode={NumberHandlingMode.AsUndefined}
      focused
      step={0.000001}
    ></NumberFormField>
  );
}

function createFormFields(
  index: number,
  path: string,
  markupLabel: string,
  formContext: UseFormReturn<FieldValues, any>
): React.ReactNode {
  const dealerReserveMode = formContext.watch(`lenderSettings[${index}].${path}.dealerReserveMode`);
  const isRateMarkupPercent = formContext.watch(
    `lenderSettings[${index}].${path}.isRateMarkupPercent`
  );

  return (
    <Stack direction="row" spacing={2}>
      {getReserveField(index, path, dealerReserveMode)}
      <Divider orientation="vertical" />
      {getRateMarkupField(index, `${path}.maxMarkup`, isRateMarkupPercent, `Max ${markupLabel}`)}
      {getRateMarkupField(
        index,
        `${path}.defaultRateMarkup`,
        isRateMarkupPercent,
        `Default ${markupLabel}`
      )}
      <NumberFormField
        label="Default split"
        name={`lenderSettings[${index}].${path}.dealerSplit`}
        numberHandlingMode={NumberHandlingMode.AsUndefined}
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        focused
        step={0.01}
      ></NumberFormField>
    </Stack>
  );
}
