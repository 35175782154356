import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';

import { Dealer, getDealersApiUrl } from 'services/DealerApiService';
import { DealerGroup, getDealerGroupApiUrl } from 'services/DealerGroupApiService';

import { useConfiguration } from './useConfiguration';

export interface DealerOption {
  label: string;
  value: string | number;
}

interface HookProps {
  dealerGroupPublicId?: string;
}

export interface HookResult {
  dealersQuery: UseQueryResult<Dealer[], unknown>;
}

export const useDealersQuery = ({ dealerGroupPublicId }: HookProps): HookResult => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);
  const staleTime = 3600000;
  const { dealerApiUrl } = useConfiguration();

  const dealersQuery = useQuery(
    ['dealersQuery'],
    async () => {
      const { data } = await axiosClient.get<Dealer[]>(getDealersApiUrl(dealerGroupPublicId), {
        baseURL: dealerApiUrl,
      });

      return data;
    },
    {
      enabled: !dealerGroupPublicId,
      staleTime,
      onError: () => {
        enqueueSnackbar('Failed to load dealers', {
          variant: 'error',
        });
      },
    }
  );

  const dealerGroupQuery = useQuery(
    ['dealerGroupQuery', dealerGroupPublicId],
    async () => {
      const { data } = await axiosClient.get<DealerGroup>(
        getDealerGroupApiUrl(dealerGroupPublicId ?? ''),
        {
          baseURL: dealerApiUrl,
        }
      );

      return data.dealers;
    },
    {
      enabled: !!dealerGroupPublicId,
      staleTime,
      onError: () => {
        enqueueSnackbar('Failed to load dealer group', {
          variant: 'error',
        });
      },
    }
  );

  return {
    dealersQuery: dealerGroupPublicId ? dealerGroupQuery : dealersQuery,
  };
};
