// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import React, { createContext, useEffect, useMemo, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { RoleBasedPermissions, UserPermission } from 'utils/UserPermission';
import { UserRole, UserRoles, userRoles } from 'utils/userRoles';

interface Props {
  children: React.ReactNode;
}

interface AuthDealer {
  PublicId: string;
}

interface AppMetadata {
  Dealers: AuthDealer[];
  DealerGroupId: string;
}

interface AccessTokenData {
  user_roles: string[];
  app_metadata: AppMetadata;
}

interface Roles {
  roles: UserRole[];
  isDeskingManager: boolean;
  isGeneralManager: boolean;
  isSalesRepresentative: boolean;
  isBdcRepresentative: boolean;
  permissions: UserPermission[];
  dealerIds: string[];
  dealerGroupId: string;
}

export const PermissionsContext = createContext({ roles: [], isAdmin: false } as unknown as Roles);
export const PermissionsProvider: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [dealerIds, setDealerIds] = useState<string[]>([]);
  const [dealerGroupId, setDealerGroupId] = useState<string>('');

  const extractRoles = async (): Promise<void> => {
    const token = await getAccessTokenSilently();
    const decodedToken = jwt_decode<AccessTokenData>(token);
    const userDealers = decodedToken.app_metadata?.Dealers ?? [];
    const userDealerGroup = decodedToken.app_metadata?.DealerGroupId ?? '';
    setRoles(mapRoles(decodedToken.user_roles));
    setDealerIds(userDealers.map((x) => x.PublicId));
    setDealerGroupId(userDealerGroup);
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    extractRoles();
  }, [isAuthenticated]);

  const isDeskingManager = roles.some((role) => role === UserRoles.deskingManager);
  const isGeneralManager = roles.some((role) => role === UserRoles.generalManager);
  const isSalesRepresentative = roles.some((role) => role === UserRoles.salesRepresentative);
  const isBdcRepresentative = roles.some((role) => role === UserRoles.bdcRepresentative);
  const permissions = RoleBasedPermissions.filter((p) =>
    p.grantedRoles.some((r) => roles.includes(r))
  ).map((p) => p.permission);

  const permissionsContext = useMemo(() => {
    return {
      roles,
      isDeskingManager,
      isGeneralManager,
      isSalesRepresentative,
      isBdcRepresentative,
      permissions,
      dealerIds,
      dealerGroupId,
    };
  }, [
    roles,
    isDeskingManager,
    isGeneralManager,
    isSalesRepresentative,
    isBdcRepresentative,
    permissions,
    dealerIds,
    dealerGroupId,
  ]);

  return (
    <PermissionsContext.Provider value={permissionsContext}>{children}</PermissionsContext.Provider>
  );
};

function mapRoles(roles: string[]): UserRole[] {
  return userRoles.all.filter((role) => !!roles.find((x) => role.name === x));
}
