import * as React from 'react';

import { Backdrop, CircularProgress, styled } from '@mui/material';

interface OverlaySpinnerProps {
  showSpinner: boolean;
  children: React.ReactNode;
}

const StyledDiv = styled('div')({
  position: 'relative',
  height: '100%',
});

export const OverlaySpinner: React.FC<OverlaySpinnerProps> = ({ showSpinner, children }) => {
  return (
    <StyledDiv>
      <Backdrop
        sx={{
          color: '#FFFFFF',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
          margin: '-6px',
        }}
        open={showSpinner}
      >
        <CircularProgress data-cy="overlay-spinner" color="inherit" />
      </Backdrop>
      {children}
    </StyledDiv>
  );
};
