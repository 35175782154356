import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Checkbox,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectProps,
  Typography,
  styled,
} from '@mui/material';

import { TypographyVariants } from 'utils/muiThemeUtils';

import { defaultFormatResultString } from './MultiSelectFormFieldService';
import { useMultiSelectFormField } from './useMultiSelectFormField';

export interface MultiSelectOptions {
  label: string;
  value: string | number;
  isChecked: boolean;
  disabled?: boolean;
}

export type MultiSelectFormFieldProps = SelectProps & {
  name: string;
  label: string;
  dataCyPrefix?: string;
  options: MultiSelectOptions[];
  optionLabelTypographyVariant?: TypographyVariants;
  defaultValue?: (string | number)[];
  debounceEnabled?: boolean;
  debounceTimeMs?: number;
  labelVariant?: TypographyVariants;
  resultStringFormatter?: (x: string[]) => string;
};

const StyledCheckbox = styled(Checkbox)({
  paddingTop: 0,
  paddingBottom: 0,
});

export const MultiSelectFormField: React.FC<MultiSelectFormFieldProps> = (props) => {
  const { control } = useFormContext();
  const {
    dataCyPrefix,
    resultStringFormatter,
    debounceTimeMs,
    debounceEnabled,
    labelVariant,
    ...selectProps
  } = props;
  const { handleChange, handleSelectAll, ref } = useMultiSelectFormField({
    name: props.name,
    options: props.options,
    debounceTimeMs: debounceTimeMs ?? 50,
    debounceEnabled: debounceEnabled ?? true,
  });

  return (
    <FormControl focused fullWidth>
      <Controller
        defaultValue={props.defaultValue ?? []}
        name={props.name}
        control={control}
        render={({ field: { value } }): React.ReactElement => (
          <>
            <InputLabel id={`${props.dataCyPrefix}_${props.name}_select_label`}>
              {props.label}
            </InputLabel>
            <Select
              {...selectProps}
              labelId={`${props.dataCyPrefix}_${props.name}_select_label`}
              multiple
              fullWidth
              variant="outlined"
              size="small"
              displayEmpty
              ref={ref}
              onChange={handleChange}
              autoFocus
              renderValue={(selected: unknown): ReactNode => (
                <Typography variant={labelVariant ?? 'caption'} textOverflow="ellipsis">
                  {resultStringFormatter
                    ? resultStringFormatter(selected as string[])
                    : defaultFormatResultString(selected as string[])}
                </Typography>
              )}
              inputProps={{
                focused: 'focused',
                'data-cy': `${dataCyPrefix}_${props.name}_select`,
              }}
            >
              <MenuItem
                key="selectAll"
                onClick={handleSelectAll}
                data-cy={`${dataCyPrefix}_${props.name}_select_selectAll`}
              >
                <Link color="inherit" underline="none" target="_blank" rel="noopener">
                  Select all
                </Link>
              </MenuItem>
              {props.options.map((o) => (
                <MenuItem
                  data-cy={`${dataCyPrefix}_${o.value}`}
                  key={o.value}
                  value={o.value}
                  disabled={o.disabled}
                >
                  <StyledCheckbox checked={value?.includes(o.value)} />
                  <Typography variant={props.optionLabelTypographyVariant ?? 'caption'}>
                    {o.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />
    </FormControl>
  );
};
