import React, { useContext } from 'react';

import { InputAdornment, Stack, styled, Typography, TypographyProps } from '@mui/material';

import { NumberFormField } from 'components/molecules/form-fields';
import { TriggeredModal } from 'components/molecules/modal';

import { PdpContext } from 'providers/PdpContextProvider';

import { formatToCurrency } from 'utils/formatterUtils';

import { useVehiclePriceModal } from './useVehiclePriceModal';

const InputTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
  display: 'block',
}));

const PriceTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.grey[600],
  textTransform: 'uppercase',
}));

const PriceDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

interface Props {
  isVehiclePriceError: boolean;
  trigger: React.ReactElement;
  editPriceEnabled: boolean;
  isExactExactCalculationEnabled: boolean;
  originalVehiclePrice: number;
  originalMsrp: number;
  originalInvoice: number;
}

export const VehiclePriceModal: React.FC<Props> = ({
  isVehiclePriceError,
  trigger,
  editPriceEnabled,
  isExactExactCalculationEnabled,
  originalVehiclePrice,
  originalMsrp,
  originalInvoice,
}) => {
  const { handleMsrpChange } = useVehiclePriceModal();
  const { shouldBeReadOnly } = useContext(PdpContext);

  return (
    <TriggeredModal
      title="Vehicle Price"
      trigger={trigger}
      displayCloseButton
      confirmButtonDisabled={isVehiclePriceError}
      content={
        <Stack direction="column" spacing={3}>
          <div>
            <Stack direction="row" spacing={5}>
              <Stack direction="column">
                <PriceTitle color="primary" variant="overline">
                  Original Vehicle Price
                </PriceTitle>
                <Typography variant="body3">{formatToCurrency(originalVehiclePrice, 0)}</Typography>
              </Stack>
              <Stack direction="column">
                <PriceTitle color="primary" variant="overline">
                  Original MSRP
                </PriceTitle>
                <Typography variant="body3">{formatToCurrency(originalMsrp, 0)}</Typography>
              </Stack>
              <Stack direction="column">
                <PriceTitle color="primary" variant="overline">
                  Original Invoice
                </PriceTitle>
                <Typography variant="body3">{formatToCurrency(originalInvoice, 0)}</Typography>
              </Stack>
            </Stack>
          </div>
          <div>
            <InputTitle variant="subtitle3" data-cy="vehiclePriceModal_vehiclePrice_title">
              Change Vehicle Price
            </InputTitle>
            <NumberFormField
              name="vehiclePrice"
              label="Vehicle Price"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              dataCy="vehiclePriceModal_vehiclePrice_input"
              focused
              fullWidth
              disabled={!editPriceEnabled || isExactExactCalculationEnabled || shouldBeReadOnly}
            />
            {editPriceEnabled && isExactExactCalculationEnabled && (
              <PriceDescription
                data-cy="vehiclePriceModal_vehiclePrice_description"
                variant="caption"
              >
                Turn off exact payment to edit price
              </PriceDescription>
            )}
          </div>
          <div>
            <InputTitle variant="subtitle3" data-cy="vehiclePriceModal_msrp_title">
              Change MSRP
            </InputTitle>
            <NumberFormField
              name="msrp"
              label="Vehicle MSRP"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              dataCy="vehiclePriceModal_msrp_input"
              focused
              fullWidth
              disabled={!editPriceEnabled || shouldBeReadOnly}
              onChange={handleMsrpChange}
            />
          </div>
          <div>
            <InputTitle variant="subtitle3" data-cy="vehiclePriceModal_vehicleInvoice_title">
              Change Vehicle Invoice
            </InputTitle>
            <NumberFormField
              name="vehicleInvoice"
              label="Vehicle Invoice"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              dataCy="vehiclePriceModal_vehicleInvoice_input"
              focused
              fullWidth
              disabled={!editPriceEnabled || shouldBeReadOnly}
            />
          </div>
        </Stack>
      }
    />
  );
};
