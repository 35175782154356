import {
  ChangeEvent,
  useCallback,
  MouseEvent,
  useState,
  useRef,
  MutableRefObject,
  useContext,
  useEffect,
} from 'react';
import { FieldArrayWithId, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';

import {
  UserDealerAssignmentFormValues,
  mapUserDealerFields,
  mapUserDealers,
} from 'components/administrations/users/form/user-dealer-assignment-modal/UserDealerAssignmentFormService';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useDealersQuery } from 'hooks/useDealersQuery';

import { UserPermission } from 'utils/UserPermission';

import { UserBulkImportResult, useUserBulkImportMutation } from './useUserBulkImportMutation';
import { useUserBulkImportSampleFileDownloadQuery } from './useUserBulkImportSampleFileDownloadQuery';

export interface UserBulkImportFormValues extends UserDealerAssignmentFormValues {
  files: FileList | null | undefined;
}

interface HookResult {
  formMethods: UseFormReturn<UserBulkImportFormValues>;
  handleOnSubmit: (e: MouseEvent) => Promise<void>;
  handleOnShowDealerAssignment: () => void;
  handleOnHideDealerAssignment: () => void;
  handleOnFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnSampleDownload: () => Promise<void>;
  importSuccessfulResults: UserBulkImportResult[];
  importFailedResults: UserBulkImportResult[];
  fileName: string;
  submitButtonDisabled: boolean;
  isSubmitting: boolean;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  isDealerAssignmentDisplayed: boolean;
  isLoadingDealers: boolean;
  userDealerFields: FieldArrayWithId<UserBulkImportFormValues, 'userDealers', 'id'>[];
}

export const useUserBulkImportForm = (): HookResult => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [importSuccessfulResults, setSuccessResults] = useState<UserBulkImportResult[]>([]);
  const [importFailedResults, setFailedResults] = useState<UserBulkImportResult[]>([]);
  const [isDealerAssignmentDisplayed, setIsDealerAssignmentDisplayed] = useState<boolean>(false);
  const formMethods = useForm<UserBulkImportFormValues>({
    defaultValues: { files: undefined, userDealers: [] },
  });

  const { fields } = useFieldArray({
    name: 'userDealers',
    control: formMethods.control,
  });

  const files = formMethods.watch('files') ?? [];
  const fileName = files[0]?.name ?? 'No file selected';
  const { mutateAsync: userBulkImportMutation } = useUserBulkImportMutation();
  const userBulkImportSampleFileDownloadQuery = useUserBulkImportSampleFileDownloadQuery();

  const {
    permissions: currentUserPermissions,
    dealerIds: currentUserDealerIds,
    dealerGroupId,
  } = useContext(PermissionsContext);

  const canEditAllUserDealers =
    currentUserPermissions.includes(UserPermission.editAllUserDealers) ||
    currentUserPermissions.includes(UserPermission.editUserDealerGroup);

  const {
    dealersQuery: { data: dealers, isFetching },
  } = useDealersQuery({ dealerGroupPublicId: dealerGroupId });

  useEffect(() => {
    formMethods.setValue(
      'userDealers',
      (dealers ?? [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((dealer) =>
          mapUserDealerFields(dealer, [], canEditAllUserDealers, currentUserDealerIds)
        )
    );
  }, [dealers]);

  const handleOnSubmit = useCallback(async (event: MouseEvent) => {
    formMethods.handleSubmit(async (values) => {
      if (values.files?.length) {
        const mutationResult = await userBulkImportMutation({
          file: values.files[0],
          dealers: mapUserDealers(values.userDealers),
        });
        const { results } = mutationResult.data;
        setSuccessResults(results.filter((x) => x.errors.length === 0));
        setFailedResults(results.filter((x) => x.errors.length));
        (inputRef.current as HTMLInputElement).value = '';
      }
    })(event);
    event.preventDefault();
  }, []);

  const handleOnShowDealerAssignment = useCallback(() => {
    setIsDealerAssignmentDisplayed(true);
  }, []);

  const handleOnHideDealerAssignment = useCallback(() => {
    setIsDealerAssignmentDisplayed(false);
  }, []);

  const handleOnFileChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    formMethods.setValue('files', event?.target?.files);
  }, []);

  const handleOnSampleDownload = useCallback(async () => {
    await userBulkImportSampleFileDownloadQuery.refetch();
  }, []);

  const submitButtonDisabled = files.length < 1 || formMethods.formState.isSubmitting || isFetching;

  return {
    formMethods,
    handleOnSubmit,
    handleOnShowDealerAssignment,
    handleOnHideDealerAssignment,
    handleOnFileChange,
    handleOnSampleDownload,
    importSuccessfulResults,
    importFailedResults,
    fileName,
    submitButtonDisabled,
    isSubmitting: formMethods.formState.isSubmitting,
    inputRef,
    isDealerAssignmentDisplayed,
    isLoadingDealers: isFetching,
    userDealerFields: fields,
  };
};
