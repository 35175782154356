import { CalculationLoanOutput } from 'services/CalculationService';
import { Precision } from 'services/TtlApiService';

import { formatToCurrency, formatToPercentage } from 'utils/formatterUtils';

import { LoanDetails } from './LoanDetailsCard';

export const DATA_CY_LOAN_CARD_PREFIX = 'pdpPage_paymentCustomization_loan_card_';

export interface LoanDetailItem {
  label: string;
  value: string | number;
  dataCy: string;
  isHidden?: boolean;
}

export function getLoanDetailItems(
  { buyerZipCode, msrp, payAllPackagesUpfront, payAllFeesUpfront }: LoanDetails,
  calculationOutput: CalculationLoanOutput
): LoanDetailItem[] {
  const {
    vehiclePrice,
    apr,
    incentives,
    totalAmountFinanced,
    fees: {
      loanFees: { originationFee },
      addons,
    },
    ttlFee,
  } = calculationOutput;

  return [
    {
      label: 'MSRP',
      value: formatToCurrency(msrp),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}msrp`,
    },
    {
      label: 'Vehicle Price',
      value: formatToCurrency(vehiclePrice),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}vehiclePrice`,
    },
    {
      label: 'Incentives',
      value: formatToCurrency(incentives),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}incentives`,
    },
    {
      label: 'APR',
      value: formatToPercentage(apr, 2),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}apr`,
    },
    {
      label: 'Buyers ZIP Code',
      value: buyerZipCode,
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}buyerZipCode`,
    },
    {
      label: 'Tax Rate',
      value: formatToPercentage(ttlFee?.taxRate ?? 0, 4),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}taxRate`,
    },
    {
      label: 'Origination Fee',
      value: formatToCurrency(originationFee ?? 0),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}originationFee`,
      isHidden: !originationFee,
    },
    {
      label: 'Add-ons',
      value: formatToCurrency(addons || 0),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}addons`,
      isHidden: !!payAllPackagesUpfront && !!payAllFeesUpfront,
    },
    {
      label: 'Total Amount Financed',
      value: formatToCurrency(totalAmountFinanced),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}totalAmountFinanced`,
    },
  ];
}

export interface SigningCalculationItems {
  label: string;
  value: string | number;
  dataCy?: string;
  isHidden?: boolean;
  isSpace?: boolean;
}

export function getLoanSigningCalculationItems(
  calculationOutput: CalculationLoanOutput
): SigningCalculationItems[] {
  const {
    downPayment,
    maxOutOfPocket,
    equityCoveredFees,
    outstandingRolledInFees,
    fees: {
      upfrontFees: { addons },
    },
    ttlFee,
    nonTtlFees,
  } = calculationOutput;

  const nonTtlFeesMapped = nonTtlFees
    ? nonTtlFees.map((nonTtlFee) => {
        return {
          label: nonTtlFee.name,
          value: formatToCurrency(nonTtlFee.value),
          dataCy: `${DATA_CY_LOAN_CARD_PREFIX}${nonTtlFee.value}`,
          isHidden: !nonTtlFee.value,
        };
      })
    : [];

  const items = [
    {
      label: 'Down Payment',
      value: formatToCurrency(downPayment ?? 0),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}downPayment`,
      isHidden: !downPayment,
    },
    {
      label: 'Max Out Of Pocket',
      value: formatToCurrency(maxOutOfPocket ?? 0),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}maxOutOfPocket`,
      isHidden: !maxOutOfPocket,
    },
    ...(ttlFee?.rollupFees?.map(({ feeAmount, feeName }) => ({
      label: feeName,
      value: formatToCurrency(feeAmount),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}{feeName}`,
      isHidden: ttlFee.precision === Precision.High,
    })) ?? []),
    {
      label: '',
      value: '',
      isSpace: true,
    },
    {
      label: 'Amount of fees covered by Equity',
      value: formatToCurrency(equityCoveredFees ?? 0),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}equityCoveredFees`,
      isHidden: !equityCoveredFees,
    },
    {
      label: 'Outstanding fees that are rolled in',
      value: formatToCurrency(outstandingRolledInFees ?? 0),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}outstandingRolledInFees`,
      isHidden: outstandingRolledInFees === 0 || !outstandingRolledInFees,
    },
    {
      label: '',
      value: '',
      isSpace: true,
      isHidden: !equityCoveredFees && (outstandingRolledInFees === 0 || !outstandingRolledInFees),
    },
    ...nonTtlFeesMapped,
    {
      label: 'Add-ons',
      value: formatToCurrency(addons ?? 0),
      dataCy: `${DATA_CY_LOAN_CARD_PREFIX}addons`,
      isHidden: addons === 0 || !addons,
    },
  ];

  return items;
}

export const getLoanSigningTtlCalculationItems = ({
  ttlFee,
}: CalculationLoanOutput): SigningCalculationItems[] => [
  {
    label: 'Tax Fee',
    value: formatToCurrency(ttlFee?.taxFee ?? 0),
    dataCy: `${DATA_CY_LOAN_CARD_PREFIX}taxFee`,
    isHidden: !ttlFee?.taxFee,
  },
  ...(ttlFee?.rollupFees?.map(({ feeAmount, feeName }) => ({
    label: feeName,
    value: formatToCurrency(feeAmount),
    dataCy: `${DATA_CY_LOAN_CARD_PREFIX}${feeName}`,
    isHidden: ttlFee.precision === undefined || ttlFee.precision === Precision.Low,
  })) ?? []),
];
