import React, { useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button, CircularProgress, Stack, styled, Tab, Tabs, Box, Grid } from '@mui/material';

import {
  MultiSelectFormField,
  SelectFormField,
  SwitchFormField,
  TextFormField,
} from 'components/molecules/form-fields';

import { Dealer, DealerMode, TaxCreditTo } from 'services/DealerApiService';

import { convertEnumToList } from 'utils/enumUtils';

import { DealerLenderSettingsForm } from './DealerLenderSettingsForm';
import { useDealerAdminForm } from './useDealerAdminForm';

interface Props {
  dealer: Dealer;
}

const TaxCreditToList = convertEnumToList(TaxCreditTo);

const DealerModeList = convertEnumToList(DealerMode);

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

export const DealerAdminForm: React.FC<Props> = ({ dealer }: Props) => {
  const {
    dealerAdminForm,
    handleOnSubmit,
    isSubmitting,
    getDefaultLeaseMileageOptions,
    getDefaultLeaseTermOptions,
    getDefaultLoanTermOptions,
  } = useDealerAdminForm({ dealer });

  const { fields: lenderSettingsFields } = useFieldArray({
    name: 'lenderSettings',
    control: dealerAdminForm.control,
  });

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (_e: any, tabIndex: React.SetStateAction<number>): void => {
    setCurrentTabIndex(tabIndex);
  };

  return (
    <FormProvider {...dealerAdminForm}>
      <form onSubmit={handleOnSubmit}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              type="submit"
              size="large"
              data-cy="dealerAdministrationPage_dealerAdministrationForm_saveButton"
              disabled={isSubmitting}
            >
              Save
            </Button>
            <StyledLink to="/administration/dealers">
              <Button
                variant="contained"
                size="large"
                data-cy="dealerAdministrationPage_dealerAdministrationForm_cancelButton"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </StyledLink>
            {isSubmitting && <CircularProgress></CircularProgress>}
          </Stack>{' '}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              aria-label="basic tabs example"
              value={currentTabIndex}
              onChange={handleTabChange}
            >
              <Tab label="Dealer Details" />
              <Tab label="Addons" />
              <Tab label="Fees" />
              <Tab label="Lender Selection" />
              <Tab label="Lender Markups" />
            </Tabs>
          </Box>
          {/* TAB 1 Contents */}
          {currentTabIndex === 0 && (
            <StyledGrid container spacing={2}>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                  <TextFormField fullWidth name="dealer.name" label="Dealer Name"></TextFormField>
                  <TextFormField fullWidth name="dealer.code" label="ICC Export Id"></TextFormField>
                  <TextFormField
                    fullWidth
                    type="number"
                    name="dealer.titleLicenseEstimate"
                    label="Title and License Estimate"
                    step={0.01}
                  ></TextFormField>
                  <TextFormField
                    fullWidth
                    type="number"
                    name="dealer.defaults.recommendationsPaymentRange"
                    label="Recommendations Payment Range"
                  ></TextFormField>
                  <TextFormField
                    fullWidth
                    type="number"
                    name="dealer.defaults.paymentInterval"
                    label="Recommendations Payment Interval"
                  ></TextFormField>
                  <SelectFormField
                    name="dealer.defaults.leaseTaxCreditTo"
                    label="Default Lease Tax Credit To"
                    options={TaxCreditToList}
                    defaultValue="dealer.defaults.leaseTaxCreditTo"
                    fullWidth
                  />
                  <SelectFormField
                    name="dealer.defaults.loanTaxCreditTo"
                    label="Default Loan Tax Credit To"
                    options={TaxCreditToList}
                    defaultValue="dealer.defaults.loanTaxCreditTo"
                    fullWidth
                  />
                  <SelectFormField
                    name="dealer.defaults.dealerMode"
                    label="Dealer Mode"
                    options={DealerModeList}
                    defaultValue="dealer.defaults.dealerMode"
                    fullWidth
                  />
                  <SwitchFormField
                    name="dealer.defaults.payAllFeesUpFront"
                    label="Default Pay All Fees Upfront"
                    value="dealer.defaults.payAllFeesUpFront"
                  />
                  <MultiSelectFormField
                    label="Default Lease Mileage"
                    name="dealer.defaults.leaseMileages"
                    options={getDefaultLeaseMileageOptions()}
                    defaultValue={dealer.defaults.leaseMileages}
                    labelVariant="caption2"
                  />
                  <MultiSelectFormField
                    label="Default Lease Terms"
                    name="dealer.defaults.leaseTerms"
                    options={getDefaultLeaseTermOptions()}
                    defaultValue={dealer.defaults.leaseTerms}
                    labelVariant="caption2"
                  />
                  <MultiSelectFormField
                    label="Default Loan Terms"
                    name="dealer.defaults.loanTerms"
                    options={getDefaultLoanTermOptions()}
                    defaultValue={dealer.defaults.loanTerms}
                    labelVariant="caption2"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                  <TextFormField fullWidth name="dealer.logoUrl" label="Logo Url"></TextFormField>
                  <TextFormField
                    fullWidth
                    name="dealer.newCarCrmEmail"
                    label="New Car CRM Email"
                  ></TextFormField>
                  <TextFormField
                    fullWidth
                    name="dealer.usedCarCrmEmail"
                    label="Used Car CRM Email"
                  ></TextFormField>
                  <TextFormField
                    fullWidth
                    name="dealer.dealOfferDisclaimer"
                    label="Deal Offer Disclaimer"
                  ></TextFormField>
                  <TextFormField
                    fullWidth
                    name="dealer.creditAppUrl"
                    label="Credit App Url"
                  ></TextFormField>
                  <TextFormField
                    fullWidth
                    name="dealer.tradeInUrl"
                    label="Trade Url"
                  ></TextFormField>
                  <TextFormField
                    fullWidth
                    name="dealer.address.street"
                    label="Address Street"
                  ></TextFormField>

                  <TextFormField
                    fullWidth
                    name="dealer.address.city"
                    label="Address City"
                  ></TextFormField>

                  <TextFormField
                    fullWidth
                    name="dealer.address.state"
                    label="Address State"
                  ></TextFormField>

                  <TextFormField
                    fullWidth
                    name="dealer.address.zip"
                    type="number"
                    label="Address Zip"
                  ></TextFormField>
                </Stack>
              </Grid>
            </StyledGrid>
          )}
          {/* TAB 2 Contents */}
          {currentTabIndex === 1 && <StyledGrid container spacing={2}></StyledGrid>}
          {/* TAB 3 Contents */}
          {currentTabIndex === 2 && <StyledGrid container spacing={2}></StyledGrid>}
          {/* TAB 4 Contents */}
          {currentTabIndex === 3 && <StyledGrid container spacing={2}></StyledGrid>}
          {/* TAB 5 Contents */}
          {currentTabIndex === 4 && (
            <DealerLenderSettingsForm fields={lenderSettingsFields}></DealerLenderSettingsForm>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
