import React from 'react';

import {
  FormControlLabel,
  styled,
  Switch as MuiSwitch,
  SwitchProps,
  Typography,
} from '@mui/material';

const PriceRangeTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
}));

interface Props extends SwitchProps {
  label: string;
  labelDataCy?: string;
  switchDataCy?: string;
}

export const Switch: React.FC<Props> = (props: Props) => {
  const { labelDataCy, label, name, checked, onChange, switchDataCy, ...switchProps } = props;

  return (
    <FormControlLabel
      control={
        <MuiSwitch
          {...switchProps}
          inputProps={{
            // @ts-ignore Workaround to pass data-cy to Switch input
            'data-cy': switchDataCy,
          }}
          checked={checked}
          onChange={onChange}
        />
      }
      label={
        <PriceRangeTypography data-cy={labelDataCy} variant="body3">
          {label}
        </PriceRangeTypography>
      }
    />
  );
};
