import React from 'react';
import { FieldError } from 'react-hook-form';

import { AutocompleteRenderInputParams, CircularProgress, TextField } from '@mui/material';

interface Props {
  params: AutocompleteRenderInputParams;
  error?: FieldError;
  isLoading: boolean;
  dataCy: string;
}

export const ZipTextInput: React.FC<Props> = ({ params, error, isLoading, dataCy }) => (
  <TextField
    {...params}
    size="small"
    label="ZIP-CODE"
    placeholder="Enter ZIP code"
    focused
    fullWidth
    error={!!error}
    helperText={error?.message}
    inputProps={{
      ...params.inputProps,
      'data-cy': dataCy,
    }}
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <React.Fragment>
          {isLoading ? (
            <CircularProgress data-cy={`${dataCy}_spinner`} color="inherit" size={20} />
          ) : null}
          {params.InputProps.endAdornment}
        </React.Fragment>
      ),
    }}
  />
);
