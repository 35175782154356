import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { useBuildVersionQuery } from 'hooks/useBuildVersionQuery';

import { buildVersionAtom } from 'models/BuildVersionModel';

export const useBuildVersionProvider = (): { isBuildVersionFetched: boolean } => {
  const { mutateAsync: fetchBuildVersionAsync, isSuccess: isBuildVersionFetched } =
    useBuildVersionQuery();
  const setBuildVersion = useSetRecoilState(buildVersionAtom);

  const fetchBuildVersion = useCallback(async () => {
    const { version } = await fetchBuildVersionAsync();
    setBuildVersion(version);
  }, []);

  useEffect(() => {
    fetchBuildVersion();
  }, []);

  return {
    isBuildVersionFetched,
  };
};
