import React, { useCallback, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Editor as TinyMCEEditor } from 'tinymce';

import { EmailFormValues } from 'components/responder/email-editor-wrapper/useEmailEditorWrapper';
import { useUpdateRanges } from 'components/responder/useUpdateRanges';

import { useConfiguration } from 'hooks/useConfiguration';
import { useSendDealOfferEmailMutation } from 'hooks/useSendDealOfferEmailMutation';

import { responderAtom } from 'models/ResponderModel';

interface HookResult {
  apiKey: string;
  toDisplay: string;
  fromDisplay: string;
  handleSendEmail(): Promise<void>;
  handleOnInit(evt: unknown, editor: TinyMCEEditor): void;
}

interface HookOptions {
  isPlainText?: boolean;
  leaseMonthlyPayment: number;
  loanMonthlyPayment: number;
}

export const useEmailEditorCard = ({
  isPlainText,
  leaseMonthlyPayment,
  loanMonthlyPayment,
}: HookOptions): HookResult => {
  const [initialized, setInitialized] = useState(false);
  const {
    tinyMceEditor: { apiKey },
  } = useConfiguration();
  const { control } = useFormContext<EmailFormValues>();
  const values = useWatch({ control }) as EmailFormValues;
  const valuesRef = useRef<EmailFormValues>();
  valuesRef.current = values;
  const { dealOfferId } = useRecoilValue(responderAtom);
  const editorRef = useRef<TinyMCEEditor>();

  const { mutateAsync: sendEmail } = useSendDealOfferEmailMutation(dealOfferId);
  const navigate = useNavigate();

  const handleSendEmail = useCallback(async () => {
    const editorReference = editorRef.current;
    if (editorReference?.getContent && valuesRef.current?.subject) {
      const { to, toName } = values;
      const { current: { subject } = {} } = valuesRef;
      const copiedText = editorReference.getContent({ format: isPlainText ? 'text' : 'html' });
      if (!copiedText) return;

      await sendEmail({
        dealOfferId,
        to,
        toName,
        subject,
        messageBody: copiedText,
      });
      navigate(`/pdp/dealOffer/${dealOfferId}`);
    }
  }, [editorRef.current, isPlainText]);

  const handleOnInit = useCallback((_: React.ReactEventHandler, editor: TinyMCEEditor) => {
    editorRef.current = editor;
    setInitialized(true);
  }, []);

  useUpdateRanges({
    editor: editorRef.current,
    initialized,
    leaseMonthlyPayment,
    loanMonthlyPayment,
  });

  return {
    apiKey,
    toDisplay: getEmailDisplay(values.to, values.toName),
    fromDisplay: getEmailDisplay(values.from, values.fromName),
    handleSendEmail,
    handleOnInit,
  };
};

function getEmailDisplay(email: string, name: string): string {
  return name && name !== email ? `${name} <${email}>` : email;
}
