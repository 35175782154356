import { useContext } from 'react';
import { useMutation, UseMutationResult } from 'react-query';

import { AxiosContext } from 'providers/AxiosProvider';

import {
  Customer as CustomerApi,
  getPullCreditRequest,
  mapCustomer,
  PullCreditRequest,
} from 'services/CustomerApiService';
import { Customer } from 'services/CustomerService';

export const usePullCreditMutation = (): UseMutationResult<
  Customer,
  unknown,
  PullCreditRequest,
  unknown
> => {
  const axiosClient = useContext(AxiosContext);

  const pullCreditMutation = useMutation(
    async (params: PullCreditRequest) => {
      const { data: customerApi } = await axiosClient.request<CustomerApi>(
        getPullCreditRequest(params)
      );

      const customer = mapCustomer(customerApi);

      return customer;
    },
    { retry: false }
  );

  return pullCreditMutation;
};
