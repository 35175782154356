import React from 'react';

import { CircularProgress, Grid } from '@mui/material';

import { Layout } from 'components/molecules/layout';
import { SearchFormWrapper } from 'components/search';

import { useCurrentDealershipQuery } from 'hooks/useCurrentDealershipQuery';

export const SearchPage: React.FC = () => {
  const { data: currentDealershipData, isLoading } = useCurrentDealershipQuery();
  let element = null;

  if (isLoading) {
    element = (
      <Grid item xs={12} textAlign="center">
        <CircularProgress data-cy="searchPage_spinner" size={50} />
      </Grid>
    );
  } else if (currentDealershipData) {
    element = <SearchFormWrapper currentDealership={currentDealershipData} />;
  }

  return <Layout>{element}</Layout>;
};
