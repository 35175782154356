import React from 'react';

import { useTracking } from 'hooks/useTracking';

interface Props {
  children: React.ReactNode;
}

export const TrackingProvider: React.FC<Props> = ({ children }) => {
  useTracking();

  return <>{children}</>;
};
