import { UseFormReturn, UseFormSetValue } from 'react-hook-form';

import { PdpFormValues, TradeVehicle } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { TaxCreditTo } from 'services/PdpApiService';

export interface TradeOptionsModalFormValues {
  tradeInOutstandingBal: number;
  actualCashValue: number;
  tradeInValue: number;
  loanTaxCreditTo: TaxCreditTo;
  leaseTaxCreditTo: TaxCreditTo;
  vin: string | undefined;
  mileage: number | undefined;
  year: number | undefined;
  make: string | undefined;
  model: string | undefined;
}

export function getDefaultFormValues(): TradeOptionsModalFormValues {
  return {
    tradeInOutstandingBal: 0,
    actualCashValue: 0,
    tradeInValue: 0,
    loanTaxCreditTo: TaxCreditTo.TradeEquity,
    leaseTaxCreditTo: TaxCreditTo.TradeEquity,
    vin: undefined,
    mileage: undefined,
    year: undefined,
    make: undefined,
    model: undefined,
  };
}

interface SetFormValuesOptions {
  sourceForm: UseFormReturn<TradeOptionsModalFormValues, any>;
  destinationFormSetter: UseFormSetValue<PdpFormValues>;
  values: TradeOptionsModalFormValues;
}

export function setFormValues(options: SetFormValuesOptions): void {
  options.destinationFormSetter('tradeInOutstandingBal', options.values.tradeInOutstandingBal);
  options.destinationFormSetter('actualCashValue', options.values.actualCashValue, {
    shouldDirty: !!options.sourceForm.formState.dirtyFields?.actualCashValue,
  });
  options.destinationFormSetter('tradeInValue', options.values.tradeInValue);
  options.destinationFormSetter('loanTaxCreditTo', options.values.loanTaxCreditTo);
  options.destinationFormSetter('leaseTaxCreditTo', options.values.leaseTaxCreditTo);

  const tradeVehicle = getTradeVehicle(options.values);
  options.destinationFormSetter('tradeVehicle', tradeVehicle);
}

function getTradeVehicle(formValues: TradeOptionsModalFormValues): TradeVehicle | undefined {
  const { vin, mileage, year, make, model } = formValues;

  if (!vin && !mileage && !year && !make && !model) {
    return undefined;
  }

  return { vin, mileage, year, make, model };
}
