import { format } from 'date-fns';

export function getSign(input: number): string {
  return input >= 0 ? '+' : '-';
}

export function getFormattedPrice(
  priceRangeEnabled: boolean,
  intervalValue: number,
  value?: number | undefined
): string | undefined {
  if (value === undefined) {
    return undefined;
  }

  return priceRangeEnabled
    ? formatToCurrencyInterval(value, intervalValue)
    : formatToCurrency(value);
}

export function formatToCurrency(value: number, fractions = 2): string {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: fractions,
  });

  return currencyFormatter.format(value);
}

export function formatToCurrencyInterval(
  value: number,
  intervalValue: number,
  fractions = 0
): string {
  const intervalTo = value + intervalValue;
  const intervalFrom = value;

  return `${formatToCurrency(intervalFrom, fractions)}-${formatToNumber(intervalTo, fractions, 0)}`;
}

export function formatToPercentage(value: number, minimumFractionDigits: number): string {
  const percentageFormmatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits,
  });

  return percentageFormmatter.format(value);
}

export function formatToNumber(
  value: number,
  minimumFractionDigits: number,
  maximumFractionDigits?: number
): string {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return numberFormatter.format(value);
}

export function formatDate(value: string): string {
  return format(new Date(value), 'MMMM dd, yyyy');
}

export function formatDateTime(value: string): string {
  const currentLocale = navigator.language;

  return new Date(value).toLocaleString(currentLocale);
}
