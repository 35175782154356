import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { Button, Chip, Container, Divider, Grid, Stack, styled } from '@mui/material';

import { Layout } from 'components/molecules/layout';

import { usePdpPageLayout } from './usePdpPageLayout';

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'unset',
  width: '100%',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

interface Props {
  children: React.ReactNode;
}

export const PdpPageLayout: React.FC<Props> = ({ children }) => {
  const { dealCode, handleCopyToClipBoard } = usePdpPageLayout();

  return (
    <Layout>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
              <StyledLink to="/">
                <Button
                  data-cy="pdpPageLayout_returnToSearchResultButton"
                  size="small"
                  color="primary"
                >
                  <ArrowBack fontSize="small" />
                  Return to search results
                </Button>
              </StyledLink>
              {dealCode && (
                <Chip
                  onClick={handleCopyToClipBoard}
                  data-cy="pdpPageLayout_dealCodeIcon"
                  label={`Deal code ${dealCode}`}
                />
              )}
            </Stack>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
