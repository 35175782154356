import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { throttle } from 'lodash';

import { useLogger } from 'hooks/useLogger';
import { usePullCreditMutation } from 'hooks/usePullCreditMutation';

import { currentCustomerAtom } from 'models/CurrentCustomerModel';

import { CreditStatus, pullCredit } from 'services/CustomerService';

interface HookOptions {
  dealerId: number;
}

export const useCustomerCreditPolling = ({ dealerId }: HookOptions): void => {
  const intervalInSec = 30;
  const millisecondsPerSec = 1000;
  const intervalInMs = intervalInSec * millisecondsPerSec;
  const { mutateAsync: pullCreditMutation } = usePullCreditMutation();
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const logger = useLogger();

  useEffect(() => {
    if (currentCustomer.creditStatus !== CreditStatus.Pending || !currentCustomer.id) {
      return;
    }

    const pullCustomerCredit = (): void => {
      pullCredit(
        { customerId: currentCustomer.id, dealerId },
        pullCreditMutation,
        setCurrentCustomer
      ).catch(logger.consoleLog);
    };

    const pullCustomerCreditThrottled = throttle(pullCustomerCredit, intervalInMs);

    // Start first poll request immediately
    pullCustomerCreditThrottled();

    const interval = setInterval(pullCustomerCreditThrottled, intervalInMs);

    return () => {
      clearInterval(interval);
    };
  }, [currentCustomer.creditStatus, currentCustomer.id, dealerId]);
};
