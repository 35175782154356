import { useEffect, useState } from 'react';

interface HookOptions<T> {
  value: T;
  debounceTimeMs: number;
}

export const useDebouncedValue = <T>({ value, debounceTimeMs }: HookOptions<T>): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, debounceTimeMs);

    return () => {
      clearTimeout(handler);
    };
  }, [JSON.stringify(value), debounceTimeMs]);

  return debouncedValue;
};
