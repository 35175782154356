import React from 'react';

import { CircularProgress, Grid, styled, Typography } from '@mui/material';

import { useUserDetailPage } from 'pages/administration/users/detail-page/useUserDetailPage';

import { UserAdministrationForm } from 'components/administrations/users/form';
import { Layout } from 'components/molecules/layout';

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(5.5),
}));

export const UserDetailPage: React.FC = () => {
  const { userID, isLoading, userData, dealerGroups } = useUserDetailPage();
  let element = null;

  if (isLoading) {
    element = (
      <Grid item xs={12} textAlign="center">
        <CircularProgress data-cy="userDetailPage_spinner" size={50} />
      </Grid>
    );
  }
  if (!isLoading) {
    element = (
      <UserAdministrationForm
        initialUserData={userData}
        userID={userID}
        dealerGroups={dealerGroups}
      />
    );
  }

  return (
    <Layout>
      <StyledGrid container spacing={2}>
        <Grid item xs={12}>
          <StyledTypography data-cy="userAdministrationPage_userOperationButton" variant="h6">
            {userID && userData ? 'Edit user' : 'Add new user'}
          </StyledTypography>
        </Grid>
        <Grid item xs={12} lg={6}>
          {element}
        </Grid>
      </StyledGrid>
    </Layout>
  );
};
