import { sortBy } from 'lodash';

import {
  OrderBy,
  OrderByDefaultDirection,
  OrderByIsProfit,
  OrderByLabels,
  OrderByValue,
  OrderDirection,
  OrderDirectionLabels,
} from 'services/SearchApiService';

import { formatToNumber } from 'utils/formatterUtils';

export function getOptions(allowProfitSorting: boolean): { label: string; value: OrderBy }[] {
  return (
    sortBy(
      Object.keys(OrderBy)
        .map((orderByItem) => Number(orderByItem))
        .filter(
          (orderByItemValue: OrderByValue) =>
            !isNaN(orderByItemValue) && (allowProfitSorting || !OrderByIsProfit[orderByItemValue])
        )
        .map((orderByItemValue: OrderByValue) => ({
          label: OrderByLabels[orderByItemValue],
          value: orderByItemValue,
        })),
      (item) => item.label
    ) ?? []
  );
}

export function getTitleText(searchResultsCount: number, orderBy?: OrderBy): string {
  return orderBy !== undefined
    ? `${formatToNumber(searchResultsCount, 0)} cars sorted by ${OrderByLabels[
        orderBy
      ].toLowerCase()}`
    : `${formatToNumber(searchResultsCount, 0)} cars`;
}

export function getSortTitleText(orderBy?: OrderBy, prefix: string = 'Sorted By '): string {
  return orderBy !== undefined ? `${prefix}${OrderByLabels[orderBy].toLowerCase()}` : 'Sort By';
}

export function getTransform(orderDirection?: OrderDirection): string {
  return orderDirection === OrderDirection.Asc ? 'rotate(180deg)' : 'none';
}

export function getDirectionText(
  orderDirection?: OrderDirection,
  orderBy?: OrderBy
): string | undefined {
  return orderDirection !== undefined && orderBy !== undefined
    ? OrderDirectionLabels[orderDirection][orderBy]
    : undefined;
}

export function shouldUpdateOrderDirection(
  initialOrderBy: OrderBy | undefined,
  orderBy: OrderBy,
  orderDirection?: OrderDirection
): boolean {
  return initialOrderBy !== orderBy && OrderByDefaultDirection[orderBy] !== orderDirection;
}

export function getExcludedText(excludedCount: number): string | undefined {
  return excludedCount > 0
    ? `Excluding ${excludedCount} vehicles due to exceeding max LTV. To resolve, increase down payment, improve trade inequity, or change credit score.`
    : undefined;
}
