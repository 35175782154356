import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  SwitchProps,
  Typography,
  styled,
} from '@mui/material';

export type SingleCheckboxFormFieldProps = SwitchProps & {
  name: string;
  label: string;
  dataCyPrefix?: string;
};

const StyledFormControlLabel = styled(FormControlLabel)({
  paddingBottom: '5px',
});

const StyledCheckbox = styled(Checkbox)({
  paddingTop: 0,
  paddingBottom: 0,
});

export const SingleCheckboxFormField: React.FC<SingleCheckboxFormFieldProps> = (
  props: SingleCheckboxFormFieldProps
) => {
  const { control } = useFormContext();
  const { dataCyPrefix, label, name, ...switchProps } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }): React.ReactElement => (
        <FormGroup>
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                {...switchProps}
                name={name}
                checked={value}
                onChange={(e): void => onChange(e.target.checked)}
                inputProps={{
                  // @ts-ignore Workaround to pass data-cy to Switch input
                  'data-cy': `${dataCyPrefix}_checkbox`,
                }}
              />
            }
            label={
              <Typography data-cy={`${dataCyPrefix}_${name}_SingleCheckboxLabel`} variant="caption">
                {label}
              </Typography>
            }
          />
        </FormGroup>
      )}
    />
  );
};
