import React, { useContext } from 'react';

import { MultiSelectFormField } from 'components/molecules/form-fields';

import { PdpContext } from 'providers/PdpContextProvider';

import { formatLeaseMileages } from './leaseMileageFormFieldService';
import { useLeaseMileageFormField } from './useLeaseMileageFormField';

interface Props {
  isDisabled: boolean;
}

// NOTE React won't rerender the Fields, rerendering is based on React key attribute: https://react-hook-form.com/faqs#Whyisdefaultvaluenotchangingcorrectlywithternaryoperator
export const LeaseMileage: React.FC<Props> = ({ isDisabled }) => {
  const { options, current } = useLeaseMileageFormField();
  const { shouldBeReadOnly } = useContext(PdpContext);

  return (
    <MultiSelectFormField
      key="programFilters.mileages"
      name="programFilters.mileages"
      label="MILEAGES"
      dataCyPrefix="pdpPage_paymentCustomization_lease_mileage"
      options={options}
      value={current}
      fullWidth
      disabled={isDisabled || shouldBeReadOnly}
      resultStringFormatter={formatLeaseMileages}
    />
  );
};
