import { TableCellProps } from '@mui/material';

import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { DealOfferAdmin } from 'services/DealOfferApiService';

function getMuiTableBodyCellProps(index: number, columnName: string): TableCellProps {
  return {
    // @ts-ignore
    'data-cy': `dealOfferListPage_table_row-${index}_${columnName}`,
  };
}

function getMuiTableHeadCellProps(columnName: string): TableCellProps {
  return {
    // @ts-ignore
    'data-cy': `dealOfferListPage_table_columnHeader_${columnName}`,
  };
}

const columns = [
  { key: 'id', header: 'Offer ID' },
  { key: 'vin', header: 'VIN' },
  { key: 'stockNumber', header: 'Stock #' },
  { key: 'email', header: 'Customer Email' },
  { key: 'phone', header: 'Customer Phone' },
  { key: 'name', header: 'Customer Name' },
  { key: 'ymmt', header: 'YMMT' },
  { key: 'lease', header: 'Lease Price' },
  { key: 'loan', header: 'Loan Price' },
  { key: 'date', header: 'Create Date' },
  { key: 'createdBy', header: 'Created By' },
];

export const dealOffersTableColumns: ColumnDef<Partial<DealOfferAdmin>>[] = columns.map((column) =>
  createColumn(column)
);

interface Column {
  key: string;
  header: string;
}

function createColumn({ key, header }: Column): ColumnDef<Partial<DealOfferAdmin>> {
  return {
    accessorKey: key,
    header,
    muiTableBodyCellProps: ({ row: { index }, column: { id: columnName } }) =>
      getMuiTableBodyCellProps(index, columnName),
    muiTableHeadCellProps: ({ column: { id: columnName } }) => getMuiTableHeadCellProps(columnName),
  };
}
