import React from 'react';

import { Stack } from '@mui/material';

import { DashboardTile } from 'components/pdp/dashboard-tile';
import { InfoTile } from 'components/pdp/info-tile';

import { CalculationLoanOutput } from 'services/CalculationService';

import { formatDate } from 'utils/formatterUtils';
import { isGreaterThanToday } from 'utils/validationUtils';

import { useLoanMainBox } from './useLoanMainBox';

interface Props {
  calculationOutput?: CalculationLoanOutput;
  lenderName?: string;
  isProgramAvailable: boolean;
  loanProgramName?: string;
  expirationDate?: string;
  term: number;
}

export const LoanMainBox: React.FC<Props> = (input) => {
  const {
    calculationOutput,
    term,
    lenderName,
    isProgramAvailable,
    loanProgramName,
    expirationDate,
  } = input;

  const { payment, dueAtSigning, dueAtSigningNote, programNote2 } = useLoanMainBox({
    calculationOutput,
    term,
  });

  return (
    <Stack direction="column" spacing={3}>
      {isProgramAvailable ? (
        <DashboardTile
          type="loan"
          price={payment}
          priceNote={`${lenderName}/${loanProgramName}`}
          expirationNote={`Ends ${expirationDate ? formatDate(expirationDate) : ''}`}
          inPaymentTaxNote=""
          dueAtSigningPrice={dueAtSigning}
          dueAtSigningPriceNote={dueAtSigningNote}
          selectorPrefix="pdpPage_paymentCustomization_"
          isProgramExpired={isGreaterThanToday(expirationDate)}
          programNote2={programNote2}
        />
      ) : (
        <InfoTile
          title="No Lender Program is available for this Score/Term combination."
          text="Change Credit Score or Length of Loan."
          selectorPrefix="pdpPage_loanCombinationUnavailableBox"
          color="error"
        />
      )}
    </Stack>
  );
};
