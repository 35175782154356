import React from 'react';

import { Container, Grid, Stack, styled } from '@mui/material';

import { InfoTile } from 'components/pdp/info-tile';

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  height: '100%',
}));

export const ErrorCardContainer: React.FC = () => (
  <Container>
    <StyledStack direction="column">
      <Grid item xs={12} textAlign="center">
        <InfoTile
          title="Something went wrong."
          text="We couldn`t load the Carmatic page due to an error in our system. Try to refresh the page or login again. If it won`t work, send us an email or chat via messaging application at the bottom of the page."
          selectorPrefix="errorPage_errorGenericMessage"
          color="error"
        />
      </Grid>
    </StyledStack>
  </Container>
);
