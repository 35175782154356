import { useFormContext } from 'react-hook-form';

import _ from 'lodash';

import { MultiSelectOptions } from 'components/molecules/form-fields/multi-select-form-field/MultiSelectFormField';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

interface HookResult {
  options: MultiSelectOptions[];
  current: number[];
}

export const useLeaseMileageFormField = (): HookResult => {
  const {
    miles,
    programFilters: { mileages: selectedMileages },
  } = useFormContext<PdpFormValues>().getValues();

  const options = miles.map((mileage) => {
    return {
      label: `${mileage.label}`,
      value: mileage.value,
      isChecked: selectedMileages.some((x) => mileage.value === x),
    };
  });

  const current = _.intersection(
    miles.map((x) => x.value),
    selectedMileages
  );

  return { options, current };
};
