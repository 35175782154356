/* eslint-disable no-console */
import { useConfiguration } from './useConfiguration';

interface HookResult {
  consoleLog(message?: unknown, ...optionalParams: any[]): void;
}

export const useLogger = (): HookResult => {
  const { browserConsoleLoggingEnabled } = useConfiguration();

  const consoleLog = (message?: unknown, ...optionalParams: any[]): void => {
    if (browserConsoleLoggingEnabled) {
      if (optionalParams?.length > 0) {
        console.log(message, optionalParams);
      } else {
        console.log(message);
      }
    }
  };

  return {
    consoleLog,
  };
};
