import React from 'react';

import { Box, CircularProgress, Grid } from '@mui/material';

import MaterialReactTable, {
  MRT_ColumnDef as ColumnDef,
  MRT_Cell as Cell,
} from 'material-react-table';

interface Props {
  tableData: any[];
  groupedColumns: string[];
  sumColumns: string[];
  isLoading: boolean;
}

export const ReportTable: React.FC<Props> = ({
  tableData,
  groupedColumns,
  sumColumns,
  isLoading,
}) => {
  let columns: ColumnDef<any>[];
  if (tableData?.length > 0) {
    columns = buildColumns(tableData, sumColumns, groupedColumns);
  } else {
    columns = [] as ColumnDef<any>[];
  }

  if (isLoading) {
    return (
      <Grid item xs={12} textAlign="center">
        <CircularProgress data-cy="responderPage_spinner" size={50} />
      </Grid>
    );
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={tableData}
      enableColumnActions
      enableTopToolbar
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableHiding={false}
      enableFilters
      enableGlobalFilter={false}
      enableGrouping
      enableColumnDragging={false}
      enablePagination={false}
      enableTableFooter
      state={{
        isLoading,
      }}
      initialState={{
        pagination: { pageSize: 25, pageIndex: 0 },
        grouping: groupedColumns,
        expanded: true,
        density: 'compact',
      }}
      getRowId={({ id }): string => id}
    />
  );
};

function buildColumns(
  tableData: any[],
  sumColumns: string[],
  groupedColumns: string[]
): ColumnDef<any>[] {
  return Object.keys(tableData[0]).map((key, _id) => {
    const shouldSum = sumColumns.includes(key);

    return {
      header: key
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        }),
      accessorKey: key,
      id: key,
      enableGrouping: groupedColumns.includes(key),
      aggregationFn: shouldSum ? 'sum' : undefined,
      AggregatedCell: shouldSum
        ? ({ cell }: { cell: Cell<Record<string, number>> }): React.ReactNode => (
            <Box>{cell.getValue() as number}</Box>
          )
        : undefined,
    } as ColumnDef<any>;
  });
}
