import React from 'react';
import { Link } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Grid,
  styled,
  Button,
  ButtonProps,
} from '@mui/material';

import MaterialReactTable, { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { UserTableRow } from 'pages/administration/users/list-page/UsersListPageService';

import { UserBulkImportModal } from 'components/administrations/users/user-bulk-import/UserBulkImportModal';
import { ConfirmDialog } from 'components/atoms/confirm-dialog';
import { Popover } from 'components/atoms/popover';

const StyledButton = styled(Button)<ButtonProps>(() => ({
  fontWeight: 'bold',
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'unset',
  width: '100%',
});

interface Props {
  tableData: UserTableRow[];
  columns: ColumnDef<Partial<UserTableRow>>[];
  isLoading: boolean;
  onDeleteUser(id: string): Promise<void>;
}

export const UsersTable: React.FC<Props> = ({ columns, onDeleteUser, isLoading, tableData }) => {
  return (
    <MaterialReactTable
      columns={columns}
      enableRowActions
      renderRowActions={({
        row: {
          index,
          id,
          original: { canDelete },
        },
      }): React.ReactNode => (
        <Popover
          data-cy={`userListPage_table_row-${index}_popover`}
          icon={<MoreVertIcon />}
          content={
            <List>
              <ListItem disablePadding>
                <StyledLink to={`/administration/users/${id}`}>
                  <ListItemButton>
                    <ListItemText data-cy={`userListPage_table_row-${index}_popover_editUser`}>
                      Edit User
                    </ListItemText>
                  </ListItemButton>
                </StyledLink>
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton disabled={!canDelete}>
                  <ConfirmDialog
                    disabled={!canDelete}
                    triggerElement={
                      <ListItemText data-cy={`userListPage_table_row-${index}_popover_deleteUser`}>
                        Delete User
                      </ListItemText>
                    }
                    title="Delete user"
                    contentText="Are you sure you want to delete this user? This action is irreversible."
                    cancelText="Cancel"
                    confirmText="Yes, I am sure"
                    onConfirm={(): Promise<void> => onDeleteUser(id)}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          }
        />
      )}
      renderTopToolbarCustomActions={(): React.ReactNode => (
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <UserBulkImportModal />
          </Grid>
          <Grid item>
            <StyledLink to="/administration/users/create-user">
              <StyledButton data-cy="userListPage_addUser" variant="contained" size="large">
                Add new user
              </StyledButton>
            </StyledLink>
          </Grid>
        </Grid>
      )}
      data={tableData}
      enableColumnActions={false}
      enableTopToolbar
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableHiding={false}
      enableFilters={false}
      positionActionsColumn="last"
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
        },
      }}
      initialState={{
        pagination: { pageSize: 25, pageIndex: 0 },
        sorting: [{ id: 'dealerNames', desc: false }],
      }}
      state={{
        isLoading,
      }}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getRowId={({ id }): string => id!}
    />
  );
};
