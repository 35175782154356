import { useForm, UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { getDefaultFormValues } from 'components/molecules/custom-incentive-form/form-wrapper/CustomIncentiveFormWrapperService';

import { CustomIncentiveProgramType, CustomIncentivesAtom } from 'models/CustomIncentivesModel';

import { getCustomIncentivesValidationResolver } from 'utils/validationResolvers';

export interface CustomIncentiveFormValues {
  name: string;
  amount: number;
  incentiveProgramType: CustomIncentiveProgramType;
  incentiveLenderName: string;
}

interface HookResult {
  methods: UseFormReturn<CustomIncentiveFormValues>;
}

export const useCustomIncentiveFormWrapper = (): HookResult => {
  const defaultValues = getDefaultFormValues();
  const customIncentivesValues = useRecoilValue(CustomIncentivesAtom);
  const methods = useForm<CustomIncentiveFormValues>({
    defaultValues,
    resolver: getCustomIncentivesValidationResolver(customIncentivesValues),
    mode: 'all',
  });

  return {
    methods,
  };
};
