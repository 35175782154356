/* eslint-disable no-console */
import { ChangeEvent, ChangeEventHandler, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useSnackbar } from 'notistack';

import { AxiosContext } from 'providers/AxiosProvider';
import { PdpContext } from 'providers/PdpContextProvider';

import { searchValuesAtom } from 'models/SearchModel';

import { updateIncentives } from 'services/IncentivesService';

interface HookResult {
  handleMsrpChange: ChangeEventHandler<HTMLInputElement>;
}

export const useVehiclePriceModal = (): HookResult => {
  const { carId = '' } = useParams<{ carId: string }>();
  const searchFormValues = useRecoilValue(searchValuesAtom);
  const zipCode = searchFormValues?.calculationOptions?.location?.zipCode;
  const axiosClient = useContext(AxiosContext);
  const pdpContext = useContext(PdpContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleMsrpChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const msrp = parseFloat(e.target.value);

      if (isNaN(msrp)) return;

      updateIncentives({ axiosClient, carId, enqueueSnackbar, msrp, pdpContext, zipCode });
    },
    [carId, pdpContext, zipCode]
  );

  return { handleMsrpChange };
};
