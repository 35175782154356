import React from 'react';

import { Stack } from '@mui/material';

import { ColorAvatar } from 'components/atoms/color-avatar';

interface Props {
  rgb: string;
  name: string;
  count: number;
}

export const ColorItemLabel: React.FC<Props> = ({ name, rgb, count }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <ColorAvatar color={rgb} />
    <span>{`${name} (${count})`}</span>
  </Stack>
);
