import { useForm, UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { searchValuesAtom } from 'models/SearchModel';

import { Customer } from 'services/CustomerService';
import {
  CarState,
  CurrentDealership,
  OrderByValue,
  OrderDirectionValue,
  ProgramAvailability,
  Vehicle,
} from 'services/SearchApiService';
import { LocationWithZip } from 'services/ZipCodeApiService';

import { getSearchValidationResolver } from 'utils/validationResolvers';

import { getDefaultFormValues } from './SearchFormWrapperService';

export interface KeyRecommendation {
  leaseCarId: string;
  loanCarId: string;
}

export interface SearchResult {
  count: number;
  excludedCount: number;
  items: Vehicle[];
  keyRecommendation?: KeyRecommendation;
}

export interface Interval {
  from?: number | null;
  to?: number | null;
}

export interface CalculationOptions {
  downPayment?: number;
  creditScore?: number;
  tradeIn?: number;
  tradeInOutstandingBalance?: number;
  actualCashValue?: number;
  payAllFeesUpfront: boolean;
  maxOutOfPocket?: number;
  vehiclePrice?: number;
  incentives?: number;
  addons?: number;
  residual?: number;
  moneyFactor?: number;
  leaseTerms?: number[];
  leaseMileages?: number[];
  loanTerms?: number[];
  location?: LocationWithZip;
  onlyCaptive: boolean;
  payFirstLeasePaymentUpfront: boolean;
}

export interface SearchFormValues {
  comparisonCarId: number | undefined;
  vinOrStockNumber?: string;
  dealer: number[];
  programAvailability: ProgramAvailability[];
  carState: CarState[];
  make: string[];
  model: string[];
  trim: string[];
  bodyStyle: string[];
  interiorColor: number[];
  exteriorColor: number[];
  year: Interval;
  price: Interval;
  mileage: Interval;
  monthlyPayment: Interval;
  leaseMonthlyPayment: Interval | undefined;
  loanMonthlyPayment: Interval | undefined;
  leaseProfit: Interval | undefined;
  loanProfit: Interval | undefined;
  featureTypes: number[];
  fuelType: string[];
  calculationOptions: CalculationOptions;
  customer: Customer;
  orderBy?: OrderByValue;
  orderDirection?: OrderDirectionValue;
}

interface HookResult {
  methods: UseFormReturn<SearchFormValues>;
}

interface HookOptions {
  currentDealership: CurrentDealership;
}

export const useSearchFormWrapper = ({ currentDealership }: HookOptions): HookResult => {
  const searchFormValues = useRecoilValue(searchValuesAtom);

  const defaultValues = getDefaultFormValues({
    searchFormValues,
    currentDealership,
  });

  const methods = useForm<SearchFormValues>({
    defaultValues,
    resolver: getSearchValidationResolver(),
    mode: 'all',
  });

  return {
    methods,
  };
};
