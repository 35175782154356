import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useModal } from 'components/molecules/modal/useModal';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { currentCustomerAtom } from 'models/CurrentCustomerModel';
import { dealOfferHasUnsavedCreditScoreAtom } from 'models/DealOfferModel';

import { updateDealCreditScore } from './InvalidCreditScoreService';

interface HookResult {
  isOpen: boolean;
  open: () => void;
  confirm: () => void;
  onImplicitClose: () => void;
}

export const useInvalidCreditScoreEditableDealModal = (): HookResult => {
  const { setValue } = useFormContext<PdpFormValues>();
  const currentCustomer = useRecoilValue(currentCustomerAtom);
  const setDealOfferHasUnsavedCreditScore = useSetRecoilState(dealOfferHasUnsavedCreditScoreAtom);

  const onConfirm = useCallback(() => {
    updateDealCreditScore({
      customer: currentCustomer,
      setValue,
      setDealOfferHasUnsavedCreditScore,
    });
  }, [currentCustomer.creditTierId]);

  const { open, confirm, isOpen, onImplicitClose } = useModal({
    implicitCloseDisabled: true,
    onConfirm,
  });

  return { open, confirm, isOpen, onImplicitClose };
};
