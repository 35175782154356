import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation, UseMutationResult } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useSnackbar } from 'notistack';

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { AxiosContext } from 'providers/AxiosProvider';

import { currentCustomerAtom } from 'models/CurrentCustomerModel';
import { dealOfferCodeAtom, isSavingDealOfferAtom } from 'models/DealOfferModel';

import { Customer } from 'services/CustomerService';
import { DealOffer, DealOfferBase, getPostDealOfferUrl } from 'services/DealOfferApiService';

import { queryClient } from 'utils/queryClientUtils';

export const useSaveDealOfferMutation = (): UseMutationResult<
  AxiosResponse<DealOffer, unknown>,
  unknown,
  DealOfferBase,
  unknown
> => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);
  const [dealOfferCode, setDealOfferCode] = useRecoilState(dealOfferCodeAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const { setValue } = useFormContext<PdpFormValues>();
  const setIsSavingDealOffer = useSetRecoilState(isSavingDealOfferAtom);

  const saveDealOfferMutation = useMutationFactory('3.0');

  return saveDealOfferMutation;

  function useMutationFactory(
    version: string
  ): UseMutationResult<AxiosResponse<DealOffer, unknown>, unknown, DealOfferBase, unknown> {
    return useMutation(
      (params: DealOfferBase) =>
        axiosClient.request<DealOffer>(createSaveDealOfferRequest(dealOfferCode, params, version)),
      {
        onError: () => {
          enqueueSnackbar('Failed to save deal offer', {
            variant: 'error',
            autoHideDuration: null,
          });
        },
        onSuccess: async ({ data }) => {
          await queryClient.invalidateQueries('dealOffersQuery');

          const { customerId, id: dealOfferCode } = data.metadata;

          setDealOfferCode(dealOfferCode);

          setCurrentCustomer((existing) => {
            const updated: Customer = {
              ...existing,
              id: customerId,
            };

            setValue('buyer.id', customerId);

            return updated;
          });

          enqueueSnackbar('Deal offer saved', {
            buttonCopyText: dealOfferCode,
            buttonMessage: 'Copy deal code',
            variant: 'info',
          });
        },
        onSettled: () => {
          setIsSavingDealOffer(false);
        },
        retry: false,
      }
    );
  }
};

export function createSaveDealOfferRequest(
  dealOfferCode: string | undefined,
  dealOffer: DealOfferBase,
  apiVersion: string
): AxiosRequestConfig<any> {
  return {
    method: getSaveDealOfferMethod(dealOfferCode),
    url: getSaveDealOfferUrl(dealOfferCode),
    data: dealOffer,
    params: { version: apiVersion },
  };
}

function getSaveDealOfferUrl(dealOfferCode: string | undefined): string {
  const baseUrl = getPostDealOfferUrl();

  return dealOfferCode ? `${baseUrl}/${dealOfferCode}` : baseUrl;
}

function getSaveDealOfferMethod(dealOfferCode: string | undefined): string {
  return dealOfferCode ? 'put' : 'post';
}
