import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  styled,
} from '@mui/material';

import { ExpandedType } from './AccordionService';
import { useAccordion } from './useAccordion';

export const StyledAccordionDetails = styled(AccordionDetails)({
  paddingLeft: 0,
  paddingRight: 0,
});

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  borderBottom: `${theme.spacing(0.125)} solid ${theme.palette.grey[200]}`,
}));

interface Props {
  children: React.ReactNode;
  expanded: boolean;
  type: ExpandedType;
  titleText: string;
}

export const Accordion: React.FC<Props> = ({ children, expanded, type, titleText }) => {
  const { handleAccordionClick } = useAccordion({ type });

  return (
    <MuiAccordion
      onClick={handleAccordionClick}
      expanded={expanded}
      defaultExpanded
      disableGutters
      elevation={0}
      square
    >
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="grey" variant="overline" fontWeight={500}>
          {titleText}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children} </StyledAccordionDetails>
    </MuiAccordion>
  );
};
