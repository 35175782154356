import { useCallback } from 'react';
import { Control, useFormContext, useWatch } from 'react-hook-form';

import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';

interface HookResult {
  getNewValues(newValue: number): void;
  control: Control<SearchFormValues, any>;
}

export const useCheckboxGroupItems = (): HookResult => {
  const { control } = useFormContext<SearchFormValues>();
  const { featureTypes = [] } = useWatch({ control });

  const getNewValues = useCallback(
    (newValue: number) => {
      const shouldRemoveValue = featureTypes?.includes(newValue);

      if (shouldRemoveValue) {
        return featureTypes?.filter((value) => value !== newValue);
      }

      return [...featureTypes, newValue];
    },
    [featureTypes]
  );

  return {
    getNewValues,
    control,
  };
};
