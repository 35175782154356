import React from 'react';
import { FormProvider } from 'react-hook-form';

import { SearchForm } from 'components/search/form/SearchForm';

import { CurrentDealership } from 'services/SearchApiService';

import { useSearchFormWrapper } from './useSearchFormWrapper';

interface SearchFormWrapperProps {
  currentDealership: CurrentDealership;
}

export const SearchFormWrapper: React.FC<SearchFormWrapperProps> = ({ currentDealership }) => {
  const { methods } = useSearchFormWrapper({ currentDealership });

  return (
    <FormProvider {...methods}>
      <SearchForm currentDealership={currentDealership} />
    </FormProvider>
  );
};
