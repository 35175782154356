import React, { useContext, useMemo } from 'react';

import { MRT_ColumnDef as ColumnDef } from 'material-react-table';

import { debounce } from 'lodash';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useDealersQuery } from 'hooks/useDealersQuery';

import { Dealer } from 'services/DealerApiService';

import { UserPermission } from 'utils/UserPermission';

import {
  DealerTableRow,
  filterTableData,
  mapTableData,
  dealerTableColumns,
} from './DealersListPageService';

interface HookResult {
  tableData: DealerTableRow[];
  columns: ColumnDef<Partial<Dealer>>[];
  canEditDealers: boolean;
  isFetching: boolean;
  filterChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const debounceTimeMs = 100;

export const useDealersListPage = (): HookResult => {
  const [filterValue, setFilterValue] = React.useState('');
  const {
    dealersQuery: { data, isFetching },
  } = useDealersQuery({});

  const { permissions } = useContext(PermissionsContext);

  const filterChangeHandler = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, debounceTimeMs);

  const columns = useMemo<ColumnDef<Partial<DealerTableRow>>[]>(() => dealerTableColumns, []);
  const tableData = mapTableData(data ?? []);
  const filteredTableData = filterTableData(tableData ?? [], filterValue);
  const canEditDealers = permissions.includes(UserPermission.editDealers);

  return {
    tableData: filteredTableData,
    columns,
    isFetching,
    canEditDealers,
    filterChangeHandler,
  };
};
