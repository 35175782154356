import React from 'react';

import { GridTable } from './GridTable';
import { useGridTableWrapper } from './useGridTableWrapper';

export const GridTableWrapper: React.FC = () => {
  const { shouldRender } = useGridTableWrapper();

  return shouldRender ? <GridTable /> : null;
};
