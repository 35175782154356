import React from 'react';

import { CardContent, Grid, Stack, styled, Typography } from '@mui/material';

import { Card } from 'components/atoms/card';
import { InfoButton } from 'components/molecules/info-button';

import { PdpItem } from 'services/PdpApiService';

import { formatToCurrency } from 'utils/formatterUtils';

import { AddOnsModal } from './add-ons-modal';
import { DealDefaults } from './deal-defaults';
import { IncentivesModal } from './incentives-modal';
import { ReserveModal } from './reserve-modal/ReserveModal';
import { usePaymentInformationBox } from './usePaymentInformationBox';
import { VehiclePriceModal } from './vehicle-price-modal';

const StyledItem = styled('div')({
  flex: '1 1 0px',
});

interface PaymentInformationBoxProps {
  isDisabled: boolean;
  isDealOffer: boolean;
  pdpData: PdpItem;
}

export const PaymentInformationBox: React.FC<PaymentInformationBoxProps> = ({
  isDisabled,
  isDealOffer,
  pdpData,
}) => {
  const {
    dealer: { addonsV2: addons },
    vehicle: {
      price: originalVehiclePrice,
      msrp: originalMsrp,
      invoice: originalInvoice,
      id: carId,
    },
  } = pdpData;
  const {
    isIncentiveError,
    isVehiclePriceError,
    loanAndLeaseRebates,
    loanRebates,
    leaseRebates,
    addonsCount,
    incentivesCount,
    values: { vehiclePrice },
    editIncentivesEnabled,
    editAddonsEnabled,
    editReserveEnabled,
    reserveInfoButtonMessage,
    editPriceEnabled,
    isExactExactCalculationEnabled,
  } = usePaymentInformationBox();

  return (
    <Card elevation={13} data-cy="paymentInformationBox">
      <CardContent>
        <Grid container spacing={1}>
          <DealDefaults carId={carId} isDisabled={isDisabled} isDealOffer={isDealOffer} />
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-evenly">
              {editIncentivesEnabled && (
                <StyledItem>
                  <IncentivesModal
                    allRebates={[...leaseRebates, ...loanAndLeaseRebates, ...loanRebates]}
                    isIncentiveError={isIncentiveError}
                    trigger={
                      <InfoButton
                        inputContent={
                          <Typography variant="body4">
                            Incentives{' '}
                            <strong>
                              {incentivesCount ? `${incentivesCount} applied` : 'NONE'}{' '}
                            </strong>
                          </Typography>
                        }
                        dataCy="incentivesModal_triggerButton"
                      />
                    }
                  />
                </StyledItem>
              )}
              {editAddonsEnabled && !!addons && (
                <StyledItem>
                  <AddOnsModal
                    addons={addons}
                    trigger={
                      <InfoButton
                        inputContent={
                          <Typography variant="body4">
                            Add-ons{' '}
                            <strong>{addonsCount ? `${addonsCount} applied` : 'NONE'}</strong>
                          </Typography>
                        }
                        dataCy="addOnsModal_triggerButton"
                      />
                    }
                  />
                </StyledItem>
              )}
              {editPriceEnabled && (
                <StyledItem>
                  <VehiclePriceModal
                    isVehiclePriceError={isVehiclePriceError}
                    trigger={
                      <InfoButton
                        inputContent={
                          <Typography variant="body4">
                            Vehicle price{' '}
                            <strong>
                              {formatToCurrency(originalVehiclePrice)}
                              {isExactExactCalculationEnabled ? (
                                <>{' → '}SEE DETAILS BELOW</>
                              ) : (
                                vehiclePrice &&
                                vehiclePrice !== originalVehiclePrice && (
                                  <>
                                    {' → '}
                                    <strong>{formatToCurrency(vehiclePrice)}</strong>
                                  </>
                                )
                              )}
                            </strong>
                          </Typography>
                        }
                        dataCy="vehiclePriceModal_triggerButton"
                      />
                    }
                    editPriceEnabled={editPriceEnabled}
                    isExactExactCalculationEnabled={isExactExactCalculationEnabled}
                    originalVehiclePrice={originalVehiclePrice}
                    originalMsrp={originalMsrp}
                    originalInvoice={originalInvoice}
                  />
                </StyledItem>
              )}
              {editReserveEnabled && (
                <StyledItem>
                  <ReserveModal
                    trigger={
                      <InfoButton
                        inputContent={
                          <Typography variant="body4">
                            Reserve/fees <strong>{reserveInfoButtonMessage}</strong>
                          </Typography>
                        }
                        dataCy="reserveModal_triggerButton"
                      />
                    }
                  />
                </StyledItem>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
