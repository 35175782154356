import { GridRow } from 'components/pdp/payment-customization-block/quick-quote-modal/QuickQuoteService';

import { LeaseSalesTax, LoanSalesTax, ProgramTtl } from './QuickQuoteApiRequestService';

export interface Cell {
  payment: number;
}

export interface Row {
  cell1: Cell;
  cell2?: Cell;
  cell3?: Cell;
  cell4?: Cell;
}

export interface GridResult {
  row1: Row;
  row2?: Row;
  row3?: Row;
  row4?: Row;
}

export interface TtlResult {
  lease: ProgramTtl<LeaseSalesTax>;
  loan: ProgramTtl<LoanSalesTax>;
}

export interface QuickQuoteResponse {
  lease?: GridResult;
  loan?: GridResult;
  ttl: TtlResult;
}

export function mapToGridRow(gridRow: GridRow, row: Row | undefined): GridRow {
  return {
    cashDown: gridRow.cashDown,
    payments: [row?.cell1.payment, row?.cell2?.payment, row?.cell3?.payment, row?.cell4?.payment]
      .filter((x) => !!x)
      .map((x) => x ?? 0),
  };
}
