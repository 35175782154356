import React from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import { Profit } from 'services/SearchApiService';

import { useVehiclePricing } from './useVehiclePricing';

interface Props {
  price: number;
  lowestPaymentLoan?: number;
  lowestPaymentLease?: number;
  profitLease?: Profit;
  profitLoan?: Profit;
  dataCyPrefix: string;
}

export const VehiclePricing: React.FC<Props> = ({
  price,
  lowestPaymentLoan,
  lowestPaymentLease,
  profitLease,
  profitLoan,
  dataCyPrefix,
}) => {
  const {
    lowestPaymentLeaseValue,
    lowestPaymentLoanValue,
    profitLeaseDisplay,
    profitLoanDisplay,
    viewProfitEnabled,
  } = useVehiclePricing({ price, lowestPaymentLease, lowestPaymentLoan, profitLease, profitLoan });

  return (
    <Grid container justifyContent="space-around">
      <Grid item xs={6}>
        {!!lowestPaymentLease && (
          <Stack direction="column" spacing={0.5} alignItems="center" height="100%">
            <Typography variant="body4" data-cy={`${dataCyPrefix}_leasePrice}`}>
              LEASE
            </Typography>
            <Typography lineHeight="0.5rem">
              <Typography data-cy={`${dataCyPrefix}_leasePriceValue}`} variant="subtitle3">
                {lowestPaymentLeaseValue}
              </Typography>
              <Typography variant="body4" data-cy={`${dataCyPrefix}_leaseProfit}`}>
                /mo.
              </Typography>
            </Typography>
            <Stack>
              {viewProfitEnabled && !!profitLeaseDisplay && (
                <Typography lineHeight="0.5rem">
                  <Typography data-cy={`${dataCyPrefix}_leaseProfitValue}`} variant="subtitle3">
                    {profitLeaseDisplay}
                  </Typography>
                  <Typography variant="body4" data-cy={`${dataCyPrefix}_leaseProfit}`}>
                    &nbsp;Profit
                  </Typography>
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </Grid>
      <Grid item xs={6}>
        {!!lowestPaymentLoan && (
          <Stack direction="column" spacing={0.5} alignItems="center" height="100%">
            <Typography variant="body4" data-cy={`${dataCyPrefix}_loanPrice}`}>
              LOAN
            </Typography>
            <Typography lineHeight="0.5rem">
              <Typography data-cy={`${dataCyPrefix}_loanPriceValue}`} variant="subtitle3">
                {lowestPaymentLoanValue}
              </Typography>
              <Typography variant="body4" data-cy={`${dataCyPrefix}_loanProfit}`}>
                /mo.
              </Typography>
            </Typography>
            <Stack>
              {viewProfitEnabled && !!profitLoanDisplay && (
                <Typography lineHeight="0.5rem">
                  <Typography data-cy={`${dataCyPrefix}_loanProfitValue}`} variant="subtitle3">
                    {profitLoanDisplay}
                  </Typography>
                  <Typography variant="body4" data-cy={`${dataCyPrefix}_loanProfit}`}>
                    &nbsp;Profit
                  </Typography>
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};
