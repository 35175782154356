import { useEffect, useState } from 'react';
import { UseFormReturn, useForm, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { useSnackbar } from 'notistack';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { useDownloadPdf } from 'components/pdp/form/useDownloadPdf';

import { usePdpData } from 'hooks/usePdpData';

import { leasesViewAtom, loansViewAtom } from 'models/GridViewModel';

import { PrintOptionsFormValues, getDefaultFormValues } from './PrintModalService';

interface HookResults {
  printOptionsForm: UseFormReturn<PrintOptionsFormValues>;
  handleOnPrint(): Promise<void>;
  isBusy: boolean;
}

export const usePrintModal = (): HookResults => {
  const { enqueueSnackbar } = useSnackbar();
  const [isBusy, setIsBusy] = useState(false);
  const showLease = useRecoilValue(leasesViewAtom);
  const showLoan = useRecoilValue(loansViewAtom);

  const printOptionsForm = useForm<PrintOptionsFormValues>({
    defaultValues: getDefaultFormValues(showLease, showLoan),
  });
  const getPrintOptionsValues = printOptionsForm.getValues;

  useEffect(() => {
    printOptionsForm.setValue('showLeaseGrid', showLease);
    printOptionsForm.setValue('showLeaseDetails', showLease);
  }, [showLease]);

  useEffect(() => {
    printOptionsForm.setValue('showLoanGrid', showLoan);
    printOptionsForm.setValue('showLoanDetails', showLoan);
  }, [showLoan]);

  const pdpData = usePdpData();
  const { handleDownloadConfigurablePdf } = useDownloadPdf({ pdpData });
  const { getValues: getPdpValues } = useFormContext<PdpFormValues>();

  const handleOnPrint = async (): Promise<void> => {
    setIsBusy(true);
    const printOptionsFormValues = getPrintOptionsValues();
    const pdpFormValues = getPdpValues();
    await handleDownloadConfigurablePdf(pdpFormValues, printOptionsFormValues)
      .then(() => setIsBusy(false))
      .catch(() => {
        enqueueSnackbar('An error occurred generating the PDF document', {
          variant: 'error',
        });
        setIsBusy(false);
      });
  };

  return {
    printOptionsForm,
    handleOnPrint,
    isBusy,
  };
};
