import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useSnackbar } from 'notistack';

import { useDealOffersListPageNavigation } from 'pages/administration/deal-offers/list-page/useDealOffersListPageNavigation';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { useFindCustomerQuery } from 'hooks/useFindCustomerQuery';

import { currentCustomerAtom } from 'models/CurrentCustomerModel';
import { dealOfferHasUnsavedCreditScoreAtom } from 'models/DealOfferModel';

import {
  CreditStatus,
  Customer,
  CustomerMode,
  createDefaultCustomer,
  getCustomerMode,
  getCustomerInitials,
} from 'services/CustomerService';
import { LocationWithZip } from 'services/ZipCodeApiService';

import {
  DealOfferStatus,
  getDealOfferStatus,
  loadCustomer,
  resetCurrentCustomer,
} from './CustomerService';

interface HookOptions {
  isDealOffer?: boolean;
}

interface HookResult {
  customer: Customer;
  customerInitials: string | undefined;
  customerMode: CustomerMode;
  dealOfferStatus: CreditStatus | DealOfferStatus;
  handleNavigateToCustomerDealOffers: () => Promise<void>;
  handleFindCustomerByEmail: () => Promise<void>;
  handleFindCustomerByPhone: () => Promise<void>;
  handleEditCustomer: () => void;
  handleNewCustomer: () => void;
  loadCustomer: () => Promise<Customer | undefined>;
}

export const useCustomerBlock = ({ isDealOffer = false }: HookOptions): HookResult => {
  const [currentCustomer, setCurrentCustomer] = useRecoilState(currentCustomerAtom);
  const dealOfferHasUnsavedCreditScore = useRecoilValue(dealOfferHasUnsavedCreditScoreAtom);
  const { enqueueSnackbar } = useSnackbar();
  const { setValue } = useFormContext<PdpFormValues>();

  const [creditScore, customerEmail, customerPhone] = useWatch<
    PdpFormValues,
    ['creditScore', 'buyer.email', 'buyer.phone']
  >({
    name: ['creditScore', 'buyer.email', 'buyer.phone'],
  });

  const { navigateToCustomerDealOffers } = useDealOffersListPageNavigation();
  const { refetch: findCustomerByEmail } = useFindCustomerQuery({ email: customerEmail });
  const { refetch: findCustomerByPhone } = useFindCustomerQuery({ phone: customerPhone });

  const handleFindCustomerByEmail = useCallback(async (): Promise<void> => {
    await loadCustomer(findCustomerByEmail, setCurrentCustomer, setValue).then((customer) => {
      if (!customer) {
        enqueueSnackbar('Customer email was not found.', {
          variant: 'warning',
          preventDuplicate: true,
        });
      }
    });
  }, [findCustomerByEmail]);

  const handleFindCustomerByPhone = useCallback(async (): Promise<void> => {
    await loadCustomer(findCustomerByPhone, setCurrentCustomer, setValue).then((customer) => {
      if (!customer) {
        enqueueSnackbar('Customer phone was not found.', {
          variant: 'warning',
          preventDuplicate: true,
        });
      }
    });
  }, [findCustomerByPhone]);

  const loadCustomerInternal = useCallback(async (): Promise<Customer | undefined> => {
    const customer = await loadCustomer(findCustomerByEmail, setCurrentCustomer, setValue);

    if (customer) {
      return customer;
    }

    return await loadCustomer(findCustomerByPhone, setCurrentCustomer, setValue);
  }, [findCustomerByEmail, findCustomerByPhone]);

  const handleNavigateToCustomerDealOffers = useCallback(async (): Promise<void> => {
    const customer = await loadCustomerInternal();

    if (customer) {
      navigateToCustomerDealOffers(customer.id);
    } else {
      enqueueSnackbar('Customer was not found.', {
        variant: 'warning',
        preventDuplicate: true,
      });
    }
  }, [customerEmail, customerPhone, loadCustomerInternal, navigateToCustomerDealOffers]);

  const resetCurrentCustomerInternal = useCallback((): void => {
    resetCurrentCustomer(setCurrentCustomer, setValue);
  }, []);

  const handleNewCustomer = useCallback((): void => {
    setValue('buyer', createDefaultCustomer());
    setValue('buyer.location', { zipCode: '' } as LocationWithZip);
  }, []);

  const dealOfferStatus = getDealOfferStatus({
    customer: currentCustomer,
    creditScore,
    isCreditScoreUnsaved: dealOfferHasUnsavedCreditScore,
    isDealOffer,
  });

  return {
    customer: currentCustomer,
    customerInitials: getCustomerInitials(currentCustomer.name),
    customerMode: getCustomerMode(currentCustomer.id),
    dealOfferStatus,
    handleNavigateToCustomerDealOffers,
    handleFindCustomerByEmail,
    handleFindCustomerByPhone,
    handleEditCustomer: resetCurrentCustomerInternal,
    handleNewCustomer,
    loadCustomer: loadCustomerInternal,
  };
};
