import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { Addon, DealType } from 'services/PdpApiService';

const { persistAtom } = recoilPersist({
  key: 'customAddons',
  storage: localStorage,
});

const LegacyCustomAddonsAtom = atom<Addon[]>({
  key: 'customAddons',
  default: [],
  effects: [persistAtom],
});

export const CustomAddonsAtom = selector<Addon[]>({
  key: 'customAddonsSelector',
  get: ({ get }) => get(LegacyCustomAddonsAtom)?.map((addon) => upgradeAddon(addon)) ?? [],
  set: ({ set }, newValue) => set(LegacyCustomAddonsAtom, newValue),
});

function upgradeAddon(addon: Addon): Addon {
  return { ...addon, dealType: addon.dealType ?? DealType.Any };
}
