import React from 'react';

import { Info } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { IncentivesTooltipText } from 'components/pdp/grid-view';

import { ExtendedRebate } from 'services/RebatesService';

import { isGreaterThanToday } from 'utils/validationUtils';

interface Props {
  rebate: ExtendedRebate;
}
export const ProgramNameTableCell: React.FC<Props> = ({ rebate }) => (
  <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0.5}>
    <Typography variant="body3">{rebate.description}</Typography>
    <Tooltip
      title={
        <IncentivesTooltipText
          rebate={rebate}
          firstNotCompatibleRebate={rebate.firstNotCompatibleRebate}
        />
      }
    >
      <IconButton size="small">
        <Info
          fontSize="small"
          color={isGreaterThanToday(rebate.expiryDate) ? 'brandOrange' : 'inherit'}
        />
      </IconButton>
    </Tooltip>
  </Stack>
);
