import { useCallback, useContext, useEffect } from 'react';
import {
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { Dealer } from 'services/DealerApiService';
import { AdministrationUser } from 'services/UserAdministrationApiService';

import { UserPermission } from 'utils/UserPermission';

import {
  UserDealerAssignmentFormValues,
  mapUserDealerFields,
  mapUserDealers,
} from './UserDealerAssignmentFormService';

interface Props {
  dealers: Dealer[];
}

interface HookResult {
  formMethods: UseFormReturn<UserDealerAssignmentFormValues>;
  handleOnSubmit: () => Promise<void>;
  userDealerFields: FieldArrayWithId<UserDealerAssignmentFormValues, 'userDealers', 'id'>[];
}

export const useUserDealerAssignmentModal = ({ dealers }: Props): HookResult => {
  const userAdminForm = useFormContext<AdministrationUser>();
  const userDealers = userAdminForm.getValues('dealers') ?? [];

  const { permissions: currentUserPermissions, dealerIds: currentUserDealerIds } =
    useContext(PermissionsContext);

  const canEditAllUserDealers =
    currentUserPermissions.includes(UserPermission.editAllUserDealers) ||
    currentUserPermissions.includes(UserPermission.editUserDealerGroup);

  const userDealerForm = useForm<UserDealerAssignmentFormValues>({
    defaultValues: {
      userDealers: [],
    },
  });

  const { fields } = useFieldArray({
    name: 'userDealers',
    control: userDealerForm.control,
  });

  useEffect(() => {
    userDealerForm.setValue(
      'userDealers',
      dealers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((dealer) =>
          mapUserDealerFields(dealer, userDealers, canEditAllUserDealers, currentUserDealerIds)
        )
    );
  }, [dealers]);

  const handleOnSubmit = useCallback(async () => {
    userDealerForm.handleSubmit((values) => {
      userAdminForm.setValue('dealers', mapUserDealers(values.userDealers));
    })();
  }, []);

  return {
    handleOnSubmit,
    formMethods: userDealerForm,
    userDealerFields: fields,
  };
};
