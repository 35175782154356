import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { SetterOrUpdater } from 'recoil';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { styled } from '@mui/material';

import { Addon, DealType } from 'services/PdpApiService';

export interface AddOnsFormValues {
  leaseAddonIds: string[];
  loanAddonIds: string[];
  anyDealTypeAddonIds: string[];
  customAddonIds: string[];
}

export const StyledDeleteForeverOutlinedIcon = styled(DeleteForeverOutlinedIcon)(({ theme }) => ({
  fontSize: `${theme.spacing(2)}`,
  cursor: 'pointer',
  color: theme.palette.error.main,
  marginBottom: '5px',
}));

export interface CheckboxItem {
  value: string;
  label: string;
}

interface AddonCheckbox {
  customAddonsValue: Addon[];
  handleClick: (value: string) => void;
}

export function getTransformedNonZeroPriceCheckboxes(
  addons: Addon[],
  dealType: DealType
): CheckboxItem[] {
  return addons
    .filter((item) => item.price !== 0 && item.dealType === dealType)
    .map((item) => {
      return {
        value: item.name,
        label: `${item.name} ($${item.price})`,
      };
    });
}

export function getTransformedCustomAddonCheckboxes({
  customAddonsValue,
  handleClick,
}: AddonCheckbox): CheckboxItem[] {
  return customAddonsValue
    ? customAddonsValue.map((addon) => ({
        value: addon.name,
        label: `${addon.name} ($${addon.price})`,
        postFix: <StyledDeleteForeverOutlinedIcon onClick={(): void => handleClick(addon.name)} />,
      }))
    : [];
}

export function removeCustomAddons(
  addonsForm: UseFormReturn<AddOnsFormValues>,
  formCustomAddons: string[],
  recoilAddons: Addon[],
  name: string,
  setCustomAddonsValue: SetterOrUpdater<Addon[]>
): void {
  addonsForm.setValue('customAddonIds', formCustomAddons?.filter((item) => item !== name) ?? []);
  setCustomAddonsValue(recoilAddons.filter((item) => item.name !== name));
}
