import React from 'react';

import { Button, CardContent, Stack } from '@mui/material';

import { Editor } from '@tinymce/tinymce-react';

import { Card } from 'components/atoms/card';

import { usePhoneEditorCard } from './usePhoneEditorCard';

const DEFAULT_EDITOR_HEIGHT = 700;

interface Props {
  buttonText: string;
  buttonDataCy: string;
  editorBodyId: string;
  defaultValue: string;
  toolbarEnabled: boolean;
  elementPathEnabled: boolean;
  isPlainText: boolean;
  branding?: boolean;
  leaseMonthlyPayment: number;
  loanMonthlyPayment: number;
}

export const PhoneEditorCard: React.FC<Props> = ({
  buttonText,
  defaultValue,
  buttonDataCy,
  editorBodyId,
  toolbarEnabled,
  isPlainText,
  leaseMonthlyPayment,
  loanMonthlyPayment,
  elementPathEnabled,
  branding = false,
}) => {
  const { apiKey, handleCopyToClipBoard, handleOnInit } = usePhoneEditorCard({
    isPlainText,
    leaseMonthlyPayment,
    loanMonthlyPayment,
  });

  return (
    <Card>
      <CardContent>
        <Stack direction="column" justifyContent="start" spacing={3}>
          <Editor
            initialValue={defaultValue}
            apiKey={apiKey}
            onInit={handleOnInit}
            init={{
              skin: 'material-outline',
              content_css: 'material-outline',
              icons: 'material',
              plugins: 'code image link lists',
              height: DEFAULT_EDITOR_HEIGHT,
              body_id: editorBodyId,
              menubar: toolbarEnabled,
              toolbar: toolbarEnabled,
              elementpath: elementPathEnabled,
              branding,
            }}
          />
          <Stack direction="row" justifyContent="center">
            <Button onClick={handleCopyToClipBoard} data-cy={buttonDataCy} variant="contained">
              {buttonText}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
