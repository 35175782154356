import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useCustomerBlock } from 'components/pdp/customer-block/useCustomerBlock';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { useSaveDealOfferMutation } from 'hooks/useSaveDealOfferMutation';

import { CustomAddonsAtom } from 'models/CustomAddonsModel';
import { dealOfferHasChangesAtom, dealOfferHasUnsavedCreditScoreAtom } from 'models/DealOfferModel';
import {
  monthlyPaymentCalculationTypeAtom,
  downPaymentCalculationTypeAtom,
} from 'models/ExactPaymentModel';
import { leasesViewAtom, loansViewAtom } from 'models/GridViewModel';
import { ProgramSelectionMethod } from 'models/PaymentSelectionModel';

import { mapDealOffer } from 'services/DealOfferApiService';
import { sendTagData } from 'services/GoogleAnalyticsService';
import { TtlPrecision } from 'services/payment-grid/PaymentGridApiModels.generated';
import { PdpItem } from 'services/PdpApiService';

import { usePaymentCalculation } from './usePaymentCalculation';

interface HookOptions {
  pdpData: PdpItem;
}

interface HookResult {
  handleSaveDealOffer(): Promise<void>;
  isSaving: boolean;
}

export const useSaveDealOffer = ({ pdpData }: HookOptions): HookResult => {
  const setDealOfferHasChanges = useSetRecoilState(dealOfferHasChangesAtom);
  const setDealOfferHasUnsavedCreditScore = useSetRecoilState(dealOfferHasUnsavedCreditScoreAtom);
  const { mutateAsync: saveDealOfferAsync, isLoading } = useSaveDealOfferMutation();
  const { loadCustomer } = useCustomerBlock({});
  const [customerLoadedAfterSave, setCustomerLoadedAfterSave] = useState(false);
  const { control } = useFormContext<PdpFormValues>();
  const pdpFormValues = useWatch({ control }) as PdpFormValues;
  const monthlyPaymentCalculationType = useRecoilValue(monthlyPaymentCalculationTypeAtom);
  const downPaymentCalculationType = useRecoilValue(downPaymentCalculationTypeAtom);
  const showLease = useRecoilValue(leasesViewAtom);
  const showLoan = useRecoilValue(loansViewAtom);
  const allCustomAddons = useRecoilValue(CustomAddonsAtom);
  const [searchParams] = useSearchParams();
  const fromDealOfferId = searchParams.get('fromDealOffer');

  const { updatePaymentGrid } = usePaymentCalculation({
    autoUpdateGrid: false,
    ttlPrecision: TtlPrecision.High,
    leaseProgramSelectionMethod: ProgramSelectionMethod.Manual,
    loanProgramSelectionMethod: ProgramSelectionMethod.Manual,
  });

  const dependencies = [updatePaymentGrid, JSON.stringify(pdpFormValues.buyer)];

  const handleSaveDealOffer = useCallback(async () => {
    const result = await updatePaymentGrid();

    if (!result) {
      return;
    }

    const dealOffer = mapDealOffer({
      paymentGridUpdate: result,
      pdpData,
      pdpFormValues,
      monthlyPaymentCalculationType,
      downPaymentCalculationType,
      showLease,
      showLoan,
      allCustomAddons,
      fromDealOfferId: fromDealOfferId === null ? undefined : fromDealOfferId,
    });

    sendTagData('save_deal', {
      tradein: dealOffer.paymentCustomization.tradeInValue !== 0,
      vehicle_state: pdpData.vehicle.state,
      lender: dealOffer.paymentCustomization.loanProgram?.lenderName,
      lessor: dealOffer.paymentCustomization.leaseProgram?.lenderName,
      pdp_dealer_id: pdpData.dealer.dealerId,
    });

    const shouldLoadCustomer = dealOffer.paymentCustomization.buyer.id === 0;
    await saveDealOfferAsync(dealOffer);

    if (shouldLoadCustomer) {
      await loadCustomer();
      setCustomerLoadedAfterSave(true);
    }

    setDealOfferHasChanges(false);
    setDealOfferHasUnsavedCreditScore(false);
  }, [...dependencies]);

  useEffect(() => {
    setCustomerLoadedAfterSave(false);
  }, [customerLoadedAfterSave]);

  useEffect(() => {
    // Hack: avoid loading after save being treated as a pending change.
    if (!customerLoadedAfterSave) {
      setDealOfferHasChanges(true);
    }
  }, [...dependencies]);

  return { handleSaveDealOffer, isSaving: isLoading };
};
