import { AxiosRequestConfig } from 'axios';

export interface Link {
  href: string;
}

export interface CreditApplicationResponse {
  applicationId: string;
  link: Link;
}

export function getCreditApplicationUrl(dealOfferId: string): AxiosRequestConfig<any> {
  return {
    url: `/credit-applications/${dealOfferId}`,
    method: 'post',
  };
}
