import { useParams } from 'react-router-dom';

import { useDealerQuery } from 'hooks/useDealerQuery';

import { Dealer } from 'services/DealerApiService';

interface HookResult {
  dealer: Dealer | undefined;
}

export const useDealerDetailPage = (): HookResult => {
  const { dealerId = '' } = useParams();

  const {
    dealerQuery: { data: dealer },
  } = useDealerQuery({ dealerId });

  return {
    dealer,
  };
};
