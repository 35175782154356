import { useForm, UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { useResponderTemplate } from 'components/responder/useResponderTemplate';

import { useAuthUser } from 'hooks/useAuthUser';

import { responderAtom } from 'models/ResponderModel';

import { TemplateType } from 'services/TemaplateService';

import { getResponderValidationResolver } from 'utils/validationResolvers';

export interface EmailFormValues {
  to: string;
  toName: string;
  from: string;
  fromName: string;
  subject: string;
}

interface HookResult {
  values: UseFormReturn<EmailFormValues>;
  template?: string;
  leaseMonthlyPayment: number;
  loanMonthlyPayment: number;
  isLoading: boolean;
}

export const useEmailEditorWrapper = (): HookResult => {
  const {
    customerEmail = '',
    customerName = '',
    year,
    make,
    model,
    trim,
    location,
    leaseMonthlyPayment,
    loanMonthlyPayment,
  } = useRecoilValue(responderAtom);
  const { email = '', userName = '' } = useAuthUser();
  const {
    templateQuery: { data, isLoading },
  } = useResponderTemplate({ templateType: TemplateType.Email });

  const defaultValues: EmailFormValues = {
    to: customerEmail,
    toName: customerName,
    from: email,
    fromName: userName,
    subject: `${year} ${make} ${model} ${trim} at ${location}`,
  };
  const values = useForm<EmailFormValues>({
    defaultValues,
    resolver: getResponderValidationResolver(),
    mode: 'all',
  });

  return {
    values,
    template: data,
    leaseMonthlyPayment,
    loanMonthlyPayment,
    isLoading,
  };
};
