import React from 'react';

import { Stack, Typography } from '@mui/material';

import { ColorChip } from 'components/atoms/color-chip';

import { CarState, CarStatus } from 'services/SearchApiService';

import { useVehicleHeader } from './useVehicleHeader';

interface Props {
  year: number;
  model: string;
  trim: string;
  make: string;
  state: CarState;
  status: CarStatus;
  dataCyPrefix: string;
  justifyContent?: 'space-between' | 'center' | 'flex-start' | 'flex-end' | 'space-evenly';
}

export const VehicleHeader: React.FC<Props> = ({
  make,
  model,
  trim,
  state,
  status,
  year,
  dataCyPrefix,
  justifyContent = 'space-between',
}) => {
  const { chipColor, chipLabel } = useVehicleHeader({ state, status });

  return (
    <Stack
      width="100%"
      direction="row"
      alignItems="flex-start"
      justifyContent={justifyContent}
      spacing={1}
    >
      <Typography
        variant="subtitle3"
        data-cy={`${dataCyPrefix}_title`}
      >{`${year} ${make} ${model} ${trim}`}</Typography>
      <Stack direction="row" spacing={0.5}>
        <ColorChip dataCy={`${dataCyPrefix}_carState`} color={chipColor} label={chipLabel} />
        {status === CarStatus.Virtual && (
          <ColorChip dataCy={`${dataCyPrefix}_isVirtual`} color="warning" label="Virtual" />
        )}
      </Stack>
    </Stack>
  );
};
