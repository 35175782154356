import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, styled } from '@mui/material';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': { width: '80%' },
});

export interface SignOutDialogProps {
  open: boolean;
  onCancel(): void;
  onOk(): void;
}

export const SignOutDialog: React.FC<SignOutDialogProps> = ({
  onCancel,
  onOk,
  open,
}: SignOutDialogProps) => (
  <StyledDialog maxWidth="xs" open={open} onClose={onCancel}>
    <DialogTitle data-cy="signOutDialog_title">Sign out of Carmatic</DialogTitle>
    <DialogContent data-cy="signOutDialog_content">
      Are you sure you want to sign out?
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onCancel} data-cy="signOutDialog_cancelButton">
        CANCEL
      </Button>
      <Button onClick={onOk} data-cy="signOutDialog_confirmButton">
        YES, SIGN ME OUT
      </Button>
    </DialogActions>
  </StyledDialog>
);
