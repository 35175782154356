import React from 'react';

import { Switch } from 'components/molecules/switch';
import { usePersistedSwitch } from 'components/molecules/switch/usePersistedSwitch';

import { loansViewAtom } from 'models/GridViewModel';

export const LoansSwitch: React.FC = () => {
  const { handleChange, value } = usePersistedSwitch({
    recoilValue: loansViewAtom,
  });

  return (
    <Switch
      label="Show loans"
      checked={value}
      onChange={handleChange}
      labelDataCy="pdpPage_paymentCustomization_loansSwitch_text"
      switchDataCy="pdpPage_paymentCustomization_loansSwitch"
    />
  );
};
