import React from 'react';

import { Grid } from '@mui/material';

import { Layout } from 'components/molecules/layout';

import { UserReport } from './UserReport';

export const ReportsPage: React.FC = () => {
  return (
    <Layout>
      <Grid container spacing={2} paddingTop="48px" paddingX="16px">
        <Grid item xs={12}>
          <UserReport />
        </Grid>
      </Grid>
    </Layout>
  );
};
