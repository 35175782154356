import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AxiosContext } from 'providers/AxiosProvider';

import { searchValuesAtom } from 'models/SearchModel';

import { getPdpApiUrl, PdpItem } from 'services/PdpApiService';

import { isEmptyOrValidZip } from 'utils/validationUtils';

export const usePdpQuery = (): UseQueryResult<PdpItem, unknown> => {
  const { carId: id } = useParams<{ carId: string }>();
  const axiosClient = useContext(AxiosContext);
  const searchFormValues = useRecoilValue(searchValuesAtom);
  const zipCode = searchFormValues?.calculationOptions?.location?.zipCode;

  const pdpQuery = useQuery(
    ['pdpQuery', id, zipCode],
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { data } = await axiosClient.get<PdpItem>(getPdpApiUrl(id!), {
        params: { zipCode },
      });

      return data;
    },
    {
      enabled: !!id && isEmptyOrValidZip(zipCode),
      keepPreviousData: true,
      staleTime: 600000,
    }
  );

  return pdpQuery;
};
