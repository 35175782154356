import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { get } from 'lodash';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { isExactExactCalculationSelector } from 'models/ExactPaymentModel';

import { ExtendedRebate } from 'services/RebatesService';

import { getReserveButtonText } from './reserve-modal/ReserveModalService';

interface HookResult {
  loanAndLeaseRebates: ExtendedRebate[];
  loanRebates: ExtendedRebate[];
  leaseRebates: ExtendedRebate[];
  values: PdpFormValues;
  isIncentiveError: boolean;
  isVehiclePriceError: boolean;
  areMarkupsValid: boolean;
  editIncentivesEnabled: boolean;
  editAddonsEnabled: boolean;
  editReserveEnabled: boolean;
  incentivesCount: number;
  addonsCount: number;
  reserveInfoButtonMessage: string;
  editPriceEnabled: boolean;
  isExactExactCalculationEnabled: boolean;
}

export const usePaymentInformationBox = (): HookResult => {
  const {
    control,
    formState: { errors },
  } = useFormContext<PdpFormValues>();
  const values = useWatch({
    control,
  }) as PdpFormValues;
  const {
    rebates: formRebates = [],
    leaseRebates,
    loanAndLeaseRebates,
    loanRebates,
    customIncentiveNames,
    customAddons,
    addons: formAddons,
    leaseReserveType,
    loanReserveType,
    leaseProgram,
    loanProgram,
  } = values;
  const { isDeskingManager, isGeneralManager, isSalesRepresentative } =
    useContext(PermissionsContext);
  const isExactExactCalculationEnabled = useRecoilValue(isExactExactCalculationSelector);
  const editIncentivesEnabled = isDeskingManager || isGeneralManager || isSalesRepresentative;
  const editAddonsEnabled = isDeskingManager || isGeneralManager || isSalesRepresentative;
  const editReserveEnabled = isDeskingManager || isGeneralManager;
  const editPriceEnabled = isDeskingManager || isGeneralManager;
  const customIncentivesCount = customIncentiveNames?.length;

  return {
    isIncentiveError: !!get(errors, 'bonusIncentive'),
    isVehiclePriceError: !!get(errors, 'vehiclePrice'),
    areMarkupsValid: !(
      !!get(errors, 'loanAprMarkupPercent') ||
      !!get(errors, 'leaseMoneyFactorMarkup') ||
      !!get(errors, 'leaseMarkedUpAcquisitionFee')
    ),
    leaseRebates,
    loanAndLeaseRebates,
    loanRebates,
    values,
    incentivesCount: formRebates ? formRebates.length + customIncentivesCount : 0,
    addonsCount: formAddons ? formAddons.length + customAddons.length : 0,
    editIncentivesEnabled,
    editAddonsEnabled,
    editReserveEnabled,
    reserveInfoButtonMessage: getReserveButtonText(
      leaseReserveType,
      loanReserveType,
      leaseProgram?.profit.reserve,
      loanProgram?.profit.reserve
    ),
    editPriceEnabled,
    isExactExactCalculationEnabled,
  };
};
