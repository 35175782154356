import React from 'react';

import { AccessTime, Edit, PersonSearch, VerifiedUser } from '@mui/icons-material';
import {
  Stack,
  Card,
  CardContent,
  Divider,
  Typography,
  InputAdornment,
  Button,
  Grid,
  Avatar,
} from '@mui/material';

import {
  NumberFormField,
  SelectFormField,
  SwitchFormField,
  TextFormField,
  ZipFormField,
} from 'components/molecules/form-fields';

import { creditTierOptions } from 'services/CreditTiersService';
import { CreditStatus, CustomerMode } from 'services/CustomerService';
import { CurrentDealership } from 'services/SearchApiService';

import { theme } from 'utils/muiThemeUtils';

import { LeaseMileage, LeaseTerm, LoanTerm } from '.';
import { useCustomerBlock } from './useCustomerBlock';

interface CustomerBlockProps {
  currentDealership: CurrentDealership;
  payAllFeesUpfront: boolean;
}

export const CustomerBlock: React.FC<CustomerBlockProps> = ({
  currentDealership,
  payAllFeesUpfront,
}) => {
  const {
    searchFormValues,
    customerInitials,
    customerMode,
    customerCreditStatus,
    creditReadOnly,
    programVariationOptions,
    editActualCashValue,
    handleFindCustomerByEmail,
    handleFindCustomerByPhone,
    handleEditCustomer,
    handleNewCustomer,
    handleNavigateToCustomerDealOffers,
  } = useCustomerBlock({ currentDealership });

  return (
    <Stack direction="column">
      <Card elevation={13} data-cy="customerBlock">
        <CardContent>
          <Stack direction="column" spacing={2} divider={<Divider />}>
            <Stack spacing={2}>
              <Typography variant="subtitle3">Current Customer</Typography>
              {customerMode === CustomerMode.Edit && (
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <TextFormField
                      size="small"
                      label="E-MAIL"
                      data-cy="searchPage_customerSection_customerEmailInput"
                      placeholder="Enter e-mail"
                      focused
                      name="customer.email"
                      fullWidth
                    />
                    <Button
                      type="button"
                      variant="contained"
                      data-cy="searchPage_customerSection_findCustomerButton"
                      onClick={handleFindCustomerByEmail}
                    >
                      <PersonSearch></PersonSearch>
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextFormField
                      size="small"
                      label="PHONE"
                      data-cy="searchPage_customerForm_customerPhoneInput"
                      placeholder="Enter phone number"
                      focused
                      name="customer.phone"
                      fullWidth
                    />
                    <Button
                      type="button"
                      variant="contained"
                      data-cy="searchPage_customerForm_findCustomerByPhoneButton"
                      onClick={handleFindCustomerByPhone}
                    >
                      <PersonSearch></PersonSearch>
                    </Button>
                  </Stack>
                </Stack>
              )}
              {customerMode === CustomerMode.ReadOnly && (
                <>
                  <Grid container>
                    <Grid item xs={10}>
                      <Stack spacing={2} direction="row">
                        <Avatar>{customerInitials}</Avatar>
                        <Stack>
                          {searchFormValues.customer.email && (
                            <Typography
                              data-cy="searchPage_customerSection_customerEmail"
                              variant="body1"
                            >
                              {searchFormValues.customer.email}
                            </Typography>
                          )}
                          {searchFormValues.customer.phone && (
                            <Typography
                              data-cy="searchPage_customerForm_customerPhone"
                              variant="body1"
                            >
                              {searchFormValues.customer.phone}
                            </Typography>
                          )}
                          <Typography
                            data-cy="searchPage_customerSection_customerId"
                            variant="caption"
                            color={`${theme.palette.grey[500]}`}
                          >{`registration ID ${searchFormValues.customer.id}`}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        type="button"
                        variant="contained"
                        data-cy="searchPage_customerSection_editCustomerButton"
                        onClick={handleEditCustomer}
                      >
                        <Edit></Edit>
                      </Button>
                    </Grid>
                  </Grid>
                  {customerCreditStatus === CreditStatus.Pending && (
                    <Stack direction="row" spacing={1}>
                      <AccessTime color="warning"></AccessTime>
                      <Typography data-cy="pdpPage_customerForm_creditStatus">
                        credit pending
                      </Typography>
                    </Stack>
                  )}
                  {customerCreditStatus === CreditStatus.Verified && (
                    <Stack direction="row" spacing={1}>
                      <VerifiedUser color="success"></VerifiedUser>
                      <Typography data-cy="pdpPage_customerForm_creditStatus">
                        credit verified
                      </Typography>
                    </Stack>
                  )}
                  <Divider />
                </>
              )}
              <TextFormField
                size="small"
                label="NAME"
                data-cy="searchPage_customerSection_customerNameInput"
                placeholder="Enter name"
                focused
                name="customer.name"
              />
              <ZipFormField
                dataCy="searchPage_customerSection_customerZipCodeInput"
                name="calculationOptions.location.zipCode"
                parentName="calculationOptions.location"
              />
              <Button
                type="button"
                variant="contained"
                data-cy="searchPage_customerSection_handleNewCustomerButton"
                onClick={handleNewCustomer}
              >
                New Customer
              </Button>
              <Button
                type="button"
                variant="contained"
                data-cy="searchPage_customerSection_goToDealOffersButton"
                onClick={handleNavigateToCustomerDealOffers}
              >
                OPEN DEAL OFFERS
              </Button>
            </Stack>
            <Stack spacing={2}>
              <SwitchFormField
                name="calculationOptions.payAllFeesUpfront"
                label="Pay All Fees Upfront"
              />
              {payAllFeesUpfront && (
                <NumberFormField
                  name="calculationOptions.downPayment"
                  label="DOWN PAYMENT"
                  placeholder="Enter down payment"
                  dataCy="searchPage_customerSection_customerDownPaymentInput"
                  focused
                />
              )}
              {!payAllFeesUpfront && (
                <NumberFormField
                  name="calculationOptions.maxOutOfPocket"
                  label="Max Out Of Pocket"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  placeholder="Enter Max Out Of Pocket"
                  dataCy="searchPage_paymentCustomization_maxOutOfPocket_input"
                  focused
                  fullWidth
                />
              )}
            </Stack>
            <SelectFormField
              name="calculationOptions.creditScore"
              label={
                <Stack direction="row" spacing={1}>
                  <div>CREDIT SCORE</div>
                </Stack>
              }
              options={creditTierOptions}
              disabled={creditReadOnly}
              dataCyPrefix="searchPage_customerSection_customerCreditScoreInput"
              focused
              fullWidth
            />

            <Stack spacing={2}>
              <LeaseMileage leaseMileages={programVariationOptions.leaseMileages} />
              <LeaseTerm leaseTerms={programVariationOptions.leaseTerms} />
            </Stack>
            <Stack spacing={2}>
              <LoanTerm loanTerms={programVariationOptions.loanTerms} />
            </Stack>
            <Stack spacing={2}>
              <NumberFormField
                name="calculationOptions.tradeInOutstandingBalance"
                label="TRADE PAYOFF"
                placeholder="Enter trade payoff"
                dataCy="searchPage_customerSection_customerTradePayoffInput"
                focused
              />
              {editActualCashValue && (
                <NumberFormField
                  name="calculationOptions.actualCashValue"
                  label={
                    <Stack direction="row" spacing={1}>
                      <div>TRADE ACTUAL CASH VALUE</div>
                    </Stack>
                  }
                  focused
                  dataCy="searchPage_customerSection_customerTradeActualValueInput"
                  placeholder="Enter trade actual cash value"
                />
              )}
              <NumberFormField
                name="calculationOptions.tradeIn"
                label={
                  <Stack direction="row" spacing={1}>
                    <div>TRADE ALLOWANCE</div>
                  </Stack>
                }
                focused
                dataCy="searchPage_customerSection_customerTradeValueInput"
                placeholder="Enter trade allowance"
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
