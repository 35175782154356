import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  styled,
  Typography,
} from '@mui/material';

const ModalWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: `0 0 ${theme.spacing(2)} 0`,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

export interface ModalProps {
  confirmButtonDisabled?: boolean;
  title: string;
  content: React.ReactElement;
  confirmText?: string;
  displayCloseButton?: boolean;
  showConfirmButton?: boolean;
  isOpen: boolean;
  maxWidth?: Breakpoint;
  confirm: () => void;
  onImplicitClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  content,
  confirmText = 'Done',
  displayCloseButton = true,
  confirmButtonDisabled = false,
  showConfirmButton = true,
  maxWidth = 'md',
  isOpen,
  confirm,
  onImplicitClose,
}) => {
  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullWidth
        onClose={onImplicitClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <ModalWrapper>
          <StyledDialogTitle>
            <Typography variant="subtitle3" data-cy="modal_title">
              {title}
            </Typography>
            {displayCloseButton && (
              <StyledIconButton aria-label="close" data-cy="modal_icon" onClick={onImplicitClose}>
                <CloseIcon />
              </StyledIconButton>
            )}
          </StyledDialogTitle>
          <Divider />
          <StyledDialogContent>{content}</StyledDialogContent>
          {showConfirmButton && (
            <DialogActions>
              <Button
                disabled={confirmButtonDisabled}
                variant="contained"
                autoFocus
                onClick={confirm}
                data-cy="modal_submitButton"
              >
                {confirmText}
              </Button>
            </DialogActions>
          )}
        </ModalWrapper>
      </Dialog>
    </>
  );
};
