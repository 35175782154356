import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Card,
  CardContent,
  ClickAwayListener,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';

import { useSortBlock } from './useSortBlock';

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
}));

const StyledSortBlockCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(1),
}));

const StyledPopperCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const CollapseButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

export interface SortBlockProps {
  title: string | React.ReactElement;
  content: React.ReactElement;
  elevation?: number;
  dataCyPrefix: string;
}
export const SortBlock: React.FC<SortBlockProps> = ({
  title,
  elevation = 13,
  content,
  dataCyPrefix,
}) => {
  const { open, anchorEl, handleToggle, id, handleClickAway } = useSortBlock();

  return (
    <StyledSortBlockCard elevation={elevation}>
      <StyledCardContent onClick={handleToggle}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="overline" fontWeight={500} data-cy={`${dataCyPrefix}_title`}>
            {title}
          </Typography>
          <CollapseButton data-cy={`${dataCyPrefix}_collapseButton`}>
            {open ? (
              <ExpandLess data-cy={`${dataCyPrefix}_collapseButton_expandLessIcon`} />
            ) : (
              <ExpandMore data-cy={`${dataCyPrefix}_collapseButton_expandMoreIcon`} />
            )}
          </CollapseButton>
        </Stack>
      </StyledCardContent>
      <Popper
        disablePortal={false}
        sx={{ zIndex: 1 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-end"
      >
        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        {({ TransitionProps }): React.ReactNode => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <StyledPopperCard elevation={13}>
                <CardContent data-cy={`${dataCyPrefix}_content`}>{content}</CardContent>
              </StyledPopperCard>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </StyledSortBlockCard>
  );
};
