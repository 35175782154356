import React from 'react';

import {
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { Card } from 'components/atoms/card';
import { VehicleDetailsDialog } from 'components/molecules/vehicle-details-dialog';
import { useVehicleDetailsDialog } from 'components/molecules/vehicle-details-dialog/useVehicleDetailsDialog';
import { VehicleFeatures } from 'components/molecules/vehicle-features';
import { VehicleFooter } from 'components/molecules/vehicle-footer';
import { VehicleHeader } from 'components/molecules/vehicle-header';
import { VehicleImage } from 'components/molecules/vehicle-image';
import { VehiclePricing } from 'components/molecules/vehicle-pricing';
import { PdpState } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { CarStatus, Vehicle } from 'services/SearchApiService';

import { useVehicleCard } from './useVehicleCard';

// Note: know issue of emotion, styled will pass all props down to the html dom element
const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== 'clickable' })<{
  clickable: boolean;
}>(({ clickable }) => ({
  '&:hover': clickable && {
    boxShadow:
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
  },
}));

const StyledCardContent = styled(CardContent)(() => ({
  height: '100%',
}));

const VehicleDetailsButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'showButton',
})<{
  showButton: boolean;
}>(({ showButton }) => ({
  visibility: showButton ? 'inherit' : 'hidden',
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(1.25),
}));

export interface VehicleCardProps {
  vehicle: Vehicle;
  onClick?(carId: string): void;
  clickable?: boolean;
  renderVehicleDetailsButton?: boolean;
  dataCyPrefix: string;
  persistState: boolean;
  persistStateFieldsBlacklist?: (keyof PdpState)[];
}

export const VehicleCard: React.FC<VehicleCardProps> = ({
  vehicle: {
    vin,
    price,
    make,
    model,
    trim,
    engine,
    pictureUrl,
    lowestPaymentLoan,
    lowestPaymentLease,
    profitLease,
    profitLoan,
    topFeatures,
    mileage,
    drivetrain,
    mpg,
    stockNumber,
    daysInStock,
    location,
    rgbExterior,
    rgbInterior,
    year,
    id,
    state,
    status,
    interiorColor,
    exteriorColor,
  },
  onClick,
  clickable = false,
  renderVehicleDetailsButton = false,
  dataCyPrefix,
  persistState,
  persistStateFieldsBlacklist,
}) => {
  const { dialogOpen, priceValue, handleCancelDialog, handleOpenDialog } = useVehicleCard({
    price,
  });
  const { handleNavigate } = useVehicleDetailsDialog({
    dialogOpen,
    carId: id,
    persistState,
    persistStateFieldsBlacklist,
  });

  return (
    <>
      <VehicleDetailsDialog
        carId={id}
        onCancel={handleCancelDialog}
        open={dialogOpen}
        persistState={persistState}
        persistStateFieldsBlacklist={persistStateFieldsBlacklist}
      />
      <StyledCard
        clickable={clickable}
        onClick={(): void | undefined => onClick?.(id)}
        data-cy={`${dataCyPrefix}_vehicleCard`}
      >
        <StyledCardContent>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
            divider={<Divider />}
            spacing={1}
          >
            <Stack width="100%" divider={<Divider />} spacing={1}>
              <VehicleHeader
                make={make}
                model={model}
                trim={trim}
                state={state}
                status={status}
                year={year}
                dataCyPrefix={`${dataCyPrefix}_vehicleCard_header`}
              />
              <Grid container justifyContent="space-between">
                <Grid item xs={4}>
                  <VehicleImage
                    pictureUrl={pictureUrl}
                    rgbExterior={rgbExterior}
                    rgbInterior={rgbInterior}
                    dataCyPrefix={`${dataCyPrefix}_vehicleCard`}
                    exteriorColor={exteriorColor}
                    interiorColor={interiorColor}
                  />
                </Grid>
                <StyledGrid item xs={8}>
                  <VehicleFeatures
                    dataCyPrefix={`${dataCyPrefix}_vehicleCard`}
                    features={topFeatures}
                    withIcon
                  />
                </StyledGrid>
              </Grid>
            </Stack>
            <Stack width="100%" divider={<Divider />} spacing={1}>
              <Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography lineHeight="0.5rem">
                    <Typography
                      fontWeight={700}
                      variant="body4"
                      data-cy={`${dataCyPrefix}_vehicleCard_vehicleEngine_label`}
                    >
                      Powertrain&nbsp;
                    </Typography>
                    <Typography
                      variant="body4"
                      data-cy={`${dataCyPrefix}_vehicleCard_vehicleEngine`}
                    >
                      {engine}
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography lineHeight="0.5rem">
                    <Typography
                      data-cy={`${dataCyPrefix}_vehicleCard_vehicleDriveTrain_label`}
                      fontWeight={700}
                      variant="body4"
                    >
                      Drivetrain&nbsp;
                    </Typography>
                    <Typography
                      data-cy={`${dataCyPrefix}_vehicleCard_vehicleDriveTrain`}
                      variant="body4"
                    >
                      {drivetrain}
                    </Typography>
                  </Typography>
                  <Typography lineHeight="0.5rem">
                    <Typography
                      data-cy={`${dataCyPrefix}_vehicleCard_vehicleMpg_label`}
                      fontWeight={700}
                      variant="body4"
                    >
                      MPG&nbsp;
                    </Typography>
                    <Typography data-cy={`${dataCyPrefix}_vehicleCard_vehicleMpg`} variant="body4">
                      {mpg}
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography lineHeight="0.5rem">
                    <Typography
                      data-cy={`${dataCyPrefix}_vehicleCard_vehicleMillage_label`}
                      fontWeight={700}
                      variant="body4"
                    >
                      Odometer&nbsp;
                    </Typography>
                    <Typography
                      data-cy={`${dataCyPrefix}_vehicleCard_vehicleMillage`}
                      variant="body4"
                    >
                      {(status !== CarStatus.Virtual && mileage) || '-'}{' '}
                      {mileage === 1 ? 'mile' : 'miles'}
                    </Typography>
                  </Typography>
                  <Typography lineHeight="0.5rem">
                    <Typography
                      data-cy={`${dataCyPrefix}_vehicleCard_leasePrice`}
                      fontWeight={700}
                      variant="body4"
                    >
                      Price&nbsp;
                    </Typography>
                    <Typography data-cy={`${dataCyPrefix}_carPrice`} variant="body4">
                      {priceValue}
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
              <VehiclePricing
                dataCyPrefix={`${dataCyPrefix}_vehicleCard_vehiclePricing`}
                price={price}
                lowestPaymentLease={lowestPaymentLease}
                lowestPaymentLoan={lowestPaymentLoan}
                profitLease={profitLease}
                profitLoan={profitLoan}
              />
              <VehicleFooter
                dataCyPrefix={`${dataCyPrefix}_vehicleCard_vehicleFooter`}
                daysInStock={daysInStock}
                location={location}
                stockNumber={stockNumber}
                status={status}
                vin={vin}
              />
            </Stack>
          </Stack>
        </StyledCardContent>
        {renderVehicleDetailsButton && (
          <CardActions>
            <StyledStack
              direction="row"
              width="100%"
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <VehicleDetailsButton
                data-cy={`${dataCyPrefix}_vehicleCard_vehicleDetailsButton`}
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
                showButton
              >
                Vehicle Details
              </VehicleDetailsButton>
              <Button
                variant="contained"
                data-cy={`${dataCyPrefix}_vehicleCard_BuildDealButton`}
                onClick={handleNavigate}
              >
                Build New Deal
              </Button>
            </StyledStack>
          </CardActions>
        )}
      </StyledCard>
    </>
  );
};
