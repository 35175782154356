import { CalculationLeaseOutput } from 'services/CalculationService';
import { Precision } from 'services/TtlApiService';

import {
  formatToCurrency,
  formatToNumber,
  formatToPercentage,
  getFormattedPrice,
} from 'utils/formatterUtils';

import { LeaseDetails } from './LeaseDetailsCard';

export const DATA_CY_LEASE_CARD_PREFIX = 'pdpPage_paymentCustomization_lease_card_';

export interface LeaseDetailItem {
  label: string;
  value: string | number;
  dataCy: string;
  isHidden?: boolean;
}

export function getLeaseDetailItems(
  { buyerZipCode, msrp, payAllPackagesUpfront, payAllFeesUpfront }: LeaseDetails,
  calculationOutput: CalculationLeaseOutput
): LeaseDetailItem[] {
  const {
    vehiclePrice,
    residualAmount,
    residualRate,
    incentives,
    capitalizedCost,
    moneyFactor,
    fees: {
      leaseFees: { acquisitionFee, dispositionFee, overMilageFee },
      addons,
    },
    ttlFee,
  } = calculationOutput;

  return [
    {
      label: 'MSRP',
      value: formatToCurrency(msrp),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}msrp`,
    },
    {
      label: 'Vehicle Price',
      value: formatToCurrency(vehiclePrice),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}vehiclePrice`,
    },
    {
      label: 'Incentives',
      value: formatToCurrency(incentives),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}incentives`,
    },
    {
      label: getResidualLabel(residualRate),
      value: formatToCurrency(residualAmount),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}residual`,
    },
    {
      label: 'Money factor',
      value: formatToNumber(moneyFactor, 8),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}moneyFactor`,
    },
    {
      label: 'Buyers ZIP Code',
      value: buyerZipCode,
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}buyerZipCode`,
    },
    {
      label: 'Tax Rate',
      value: formatToPercentage(ttlFee?.taxRate ?? 0, 4),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}taxRate`,
    },
    {
      label: 'Acquisition Fee',
      value: formatToCurrency(acquisitionFee ?? 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}acquisitionFee`,
      isHidden: !acquisitionFee,
    },

    {
      label: 'Add-ons',
      value: formatToCurrency(addons || 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}addons`,
      isHidden: !!payAllPackagesUpfront && !!payAllFeesUpfront,
    },
    {
      label: 'Net Capitalized Costs',
      value: formatToCurrency(capitalizedCost),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}netCapitalizedCost`,
    },
    {
      label: 'Excess charge per mile',
      value: formatToCurrency(overMilageFee ?? 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}excessChargePerMile`,
      isHidden: !overMilageFee,
    },
    {
      label: 'Disposition Fee',
      value: formatToCurrency(dispositionFee ?? 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}dispositionFee`,
      isHidden: !dispositionFee,
    },
  ];
}

export interface SigningCalculationItems {
  label: string;
  value: string | number;
  dataCy?: string;
  isHidden?: boolean;
  isSpace?: boolean;
}

export function getLeaseSigningCalculationItems(
  calculationOutput: CalculationLeaseOutput,
  priceRangeEnabled: boolean,
  intervalValue: number
): SigningCalculationItems[] {
  const {
    payment,
    downPayment,
    maxOutOfPocket,
    equityCoveredFees,
    outstandingRolledInFees,
    fees: {
      upfrontFees: { addons },
    },
    ttlFee,
    nonTtlFees,
  } = calculationOutput;

  const nonTtlFeesMapped = nonTtlFees
    ? nonTtlFees.map((nonTtlFee) => {
        return {
          label: nonTtlFee.name,
          value: formatToCurrency(nonTtlFee.value),
          dataCy: `${DATA_CY_LEASE_CARD_PREFIX}${nonTtlFee.value}`,
          isHidden: !nonTtlFee.value,
        };
      })
    : [];

  const items = [
    {
      label: 'Cap Cost Reduction',
      value: formatToCurrency(downPayment ?? 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}capCostReduction`,
      isHidden: !downPayment,
    },
    {
      label: '1st payment Lease',
      value: getFormattedPrice(priceRangeEnabled, intervalValue, payment) ?? '0',
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}firstPaymentLease`,
    },
    {
      label: 'Max Out Of Pocket',
      value: formatToCurrency(maxOutOfPocket ?? 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}maxOutOfPocket`,
      isHidden: !maxOutOfPocket,
    },
    ...(ttlFee?.rollupFees?.map(({ feeAmount, feeName }) => ({
      label: feeName,
      value: formatToCurrency(feeAmount),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}{feeName}`,
      isHidden: ttlFee.precision === Precision.High,
    })) ?? []),
    {
      label: ' ',
      value: '',
      isSpace: true,
    },
    {
      label: 'Amount of fees covered by Equity',
      value: formatToCurrency(equityCoveredFees ?? 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}equityCoveredFees`,
      isHidden: !equityCoveredFees,
    },
    {
      label: 'Outstanding fees that are rolled in',
      value: formatToCurrency(outstandingRolledInFees ?? 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}outstandingRolledInFees`,
      isHidden: outstandingRolledInFees === 0 || !outstandingRolledInFees,
    },
    {
      label: '',
      value: '',
      isSpace: true,
      isHidden: !equityCoveredFees && (outstandingRolledInFees === 0 || !outstandingRolledInFees),
    },
    ...nonTtlFeesMapped,
    {
      label: 'Add-ons',
      value: formatToCurrency(addons ?? 0),
      dataCy: `${DATA_CY_LEASE_CARD_PREFIX}addons`,
      isHidden: addons === 0 || !addons,
    },
  ];

  return items;
}

export const getLeaseSigningTtlCalculationItems = ({
  ttlFee,
}: CalculationLeaseOutput): SigningCalculationItems[] => [
  {
    label: 'Tax Fee',
    value: formatToCurrency(ttlFee?.taxFee ?? 0),
    dataCy: `${DATA_CY_LEASE_CARD_PREFIX}taxFee`,
    isHidden: !ttlFee?.taxFee,
  },
  ...(ttlFee?.rollupFees?.map(({ feeAmount, feeName }) => ({
    label: feeName,
    value: formatToCurrency(feeAmount),
    dataCy: `${DATA_CY_LEASE_CARD_PREFIX}${feeName}`,
    isHidden: ttlFee.precision === undefined || ttlFee.precision === Precision.Low,
  })) ?? []),
];

function getResidualLabel(residualRate?: number): string {
  if (residualRate === undefined) {
    return `Residual`;
  }

  return `Residual (${formatToPercentage(residualRate, 2)} of MSRP)`;
}
