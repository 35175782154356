import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { getLowestPaymentLeaseOrLoanValue } from 'components/molecules/vehicle-pricing/VehiclePricingService';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useConfiguration } from 'hooks/useConfiguration';

import { priceRangeAtom } from 'models/PriceRangeModel';
import { viewProfitAtom } from 'models/ViewProfitModel';

import { getProfitDisplay } from 'services/ProfitDisplayService';
import { Profit } from 'services/SearchApiService';

import { formatToCurrency } from 'utils/formatterUtils';
import { UserPermission } from 'utils/UserPermission';

interface HookOptions {
  price: number;
  lowestPaymentLease?: number;
  lowestPaymentLoan?: number;
  profitLease?: Profit;
  profitLoan?: Profit;
}

interface HookResult {
  priceValue: string;
  lowestPaymentLeaseValue?: string;
  lowestPaymentLoanValue?: string;
  priceRangeEnabled: boolean;
  profitLeaseDisplay?: string;
  profitLoanDisplay?: string;
  viewProfitEnabled: boolean;
}

export const useVehiclePricing = ({
  price,
  lowestPaymentLease,
  lowestPaymentLoan,
  profitLease,
  profitLoan,
}: HookOptions): HookResult => {
  const priceRangeEnabled = useRecoilValue(priceRangeAtom);
  const viewProfitEnabled = useRecoilValue(viewProfitAtom);
  const { permissions } = useContext(PermissionsContext);
  const allowViewProfitAmount = permissions.includes(UserPermission.viewProfitAmount);
  const {
    paymentsIntervalOptions: { defaultInterval },
  } = useConfiguration();

  return {
    priceValue: formatToCurrency(price),
    lowestPaymentLeaseValue: getLowestPaymentLeaseOrLoanValue(
      priceRangeEnabled,
      defaultInterval,
      lowestPaymentLease
    ),
    lowestPaymentLoanValue: getLowestPaymentLeaseOrLoanValue(
      priceRangeEnabled,
      defaultInterval,
      lowestPaymentLoan
    ),
    priceRangeEnabled,
    profitLeaseDisplay: getProfitDisplay(profitLease, allowViewProfitAmount),
    profitLoanDisplay: getProfitDisplay(profitLoan, allowViewProfitAmount),
    viewProfitEnabled,
  };
};
