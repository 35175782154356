import _ from 'lodash';

import { Addon } from './PdpApiService';

interface GetAllAddonsOptions {
  customAddons: Addon[];
  addonsValues: string[];
  addons: Addon[];
  customAddonsValues: string[];
}

export function getAllCheckedAddons({
  customAddonsValues,
  customAddons,
  addonsValues,
  addons,
}: GetAllAddonsOptions): Addon[] {
  const currentFormAddons: Addon[] = addonsValues
    .map((name) => addons.find((x) => x.name === name))
    .filter((x) => !!x)
    .map((x) => x as Addon);

  const currentCustomAddons = customAddons.filter((x) => customAddonsValues.includes(x.name));

  return [...currentFormAddons, ...currentCustomAddons];
}

export function getServiceAndProductionProducts(allAddons: Addon[]): number {
  return (
    _.sumBy(allAddons, (addon) => addon.price) - _.sumBy(allAddons, (addon) => addon.wholesale)
  );
}

interface TradeInProfitOptions {
  actualCashValue: number;
  tradeInValue: number;
}

export function getTradeInProfit({ actualCashValue, tradeInValue }: TradeInProfitOptions): number {
  return actualCashValue - tradeInValue;
}

interface VehicleProfitOptions {
  originalVehiclePrice: number;
  originalVehicleInvoice: number;
  editedVehiclePrice?: number;
  editedVehicleInvoice?: number;
}

export function getVehicleProfit({
  originalVehiclePrice,
  originalVehicleInvoice,
  editedVehiclePrice,
  editedVehicleInvoice,
}: VehicleProfitOptions): number {
  const actualInvoice = editedVehicleInvoice || originalVehicleInvoice;
  const actualPrice = editedVehiclePrice || originalVehiclePrice;

  return actualInvoice > 0 ? actualPrice - actualInvoice : 0;
}
