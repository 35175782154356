import React from 'react';

import { Stack, Typography } from '@mui/material';

import { Accordion, ExpandedType } from 'components/molecules/accordion';
import { ReserveProfitInfo } from 'components/pdp/reserve-profit-info/ReserveProfitInfo';

import { formatToCurrency } from 'utils/formatterUtils';

import { useLeaseProfitDetails } from './useLeaseProfitDetails';

export const LeaseProfitDetails: React.FC = () => {
  const profitDetails = useLeaseProfitDetails();

  return profitDetails.displayProfitDetails ? (
    <Accordion
      titleText="PROFIT DETAILS"
      type={ExpandedType.ProfitLease}
      expanded={profitDetails.expanded}
    >
      <>
        <Stack spacing={3}>
          <Stack>
            <Typography
              data-cy="pdpPage_leaseProfit_frontendProfitTitle"
              variant="body4"
              fontWeight={700}
            >
              Front-end profit
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography data-cy="pdpPage_leaseProfit_vehicleProfitTitle" variant="body4">
                Vehicle profit
              </Typography>
              <Typography data-cy="pdpPage_leaseProfit_frontendProfitValue" variant="body4">
                {formatToCurrency(profitDetails.vehicleProfit)}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography data-cy="pdpPage_leaseProfit_tradeInProfitTitle" variant="body4">
                Trade-in profit
              </Typography>
              <Typography data-cy="pdpPage_leaseProfit_tradeInProfitValue" variant="body4">
                {formatToCurrency(profitDetails.tradeInProfit)}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography
              data-cy="pdpPage_leaseProfit_backendProfitTitle"
              variant="body4"
              fontWeight={700}
            >
              Back-end profit
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                data-cy="pdpPage_leaseProfit_serviceAndProtectionProductsTitle"
                variant="body4"
              >
                Service and protection products
              </Typography>
              <Typography
                data-cy="pdpPage_leaseProfit_serviceAndProtectionProductsValue"
                variant="body4"
              >
                {formatToCurrency(profitDetails.serviceAndProductionProducts)}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography data-cy="pdpPage_leaseProfit_acquisitionFeeProfitTitle" variant="body4">
                Acquisition fee profit
              </Typography>
              <Typography data-cy="pdpPage_leaseProfit_acquisitionFeeProfitValue" variant="body4">
                {formatToCurrency(profitDetails.acquisitionProfit ?? 0)}
              </Typography>
            </Stack>
            <ReserveProfitInfo
              profitData={profitDetails}
              dataCyPrefix="pdpPage_leaseProfit"
            ></ReserveProfitInfo>
          </Stack>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                data-cy="pdpPage_leaseProfit_totalProfitTitle"
                variant="body4"
                fontWeight={700}
              >
                Total profit
              </Typography>
              <Typography data-cy="pdpPage_leaseProfit_totalProfitValue" variant="body4">
                {formatToCurrency(profitDetails.totalProfit)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </>
    </Accordion>
  ) : null;
};
