import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { usePersistedSwitch } from 'components/molecules/switch/usePersistedSwitch';
import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import {
  CalculationOptions,
  SearchFormValues,
} from 'components/search/form-wrapper/useSearchFormWrapper';
import {
  getOptions,
  shouldUpdateOrderDirection,
} from 'components/search/form/form-header/SearchFormHeaderService';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useSearchInfiniteQuery } from 'hooks/useSearchInfiniteQuery';

import { leasesViewAtom, loansViewAtom } from 'models/GridViewModel';
import { searchValuesAtom } from 'models/SearchModel';

import { PdpItem, ProgramType } from 'services/PdpApiService';
import {
  OrderBy as OrderBySearch,
  OrderDirection as OrderDirectionSearch,
  Vehicle,
} from 'services/SearchApiService';

import {
  OrderBy,
  OrderDirection,
  createSearchFilters,
  getKeyRecommendation,
  getRecommendedVehicles,
} from './RecommendationService';

interface HookProps {
  pdpData: PdpItem;
}

interface HookResult {
  isSameMakeModelChecked: boolean;
  isSameYearChecked: boolean;
  orderBy: OrderBySearch;
  orderByOptions: { label: string; value: OrderBySearch }[];
  orderDirection: OrderDirectionSearch;
  handleOnIsSameMakeModelChecked: () => void;
  handleOnIsSameYearChecked: () => void;
  handleOrderByChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  handleOrderDirectionChanged: () => void;
  vehicles: Vehicle[];
  keyRecommendation: Vehicle | undefined;
  keyRecommendationProgramType: ProgramType;
  isFetchingData: boolean;
  hasFetchedData: boolean;
}

const searchDebounceTimeMs = 2500;

export const useRecommendationCards = ({ pdpData }: HookProps): HookResult => {
  const [isSameMakeModelChecked, setIsSameMakeModelChecked] = useState(
    pdpData.dealerDefaults?.recommendationsFilter.sameMakeModel ?? false
  );
  const [isSameYearChecked, setIsSameYearChecked] = useState(
    pdpData.dealerDefaults?.recommendationsFilter.sameYear ?? false
  );

  const searchValues = useRecoilValue(searchValuesAtom) ?? ({} as SearchFormValues);

  const [orderDirection, setOrderDirection] = useState(
    OrderDirectionSearch[
      pdpData.dealerDefaults?.recommendationsOrderDirection ?? OrderDirection.Desc
    ]
  );
  const [orderBy, setOrderBy] = useState(
    OrderBySearch[pdpData.dealerDefaults?.recommendationsOrderBy ?? OrderBy.Year]
  );
  const { current: initialOrderBy } = useRef(orderBy);

  const { isDeskingManager, isGeneralManager } = useContext(PermissionsContext);
  const allowProfitSorting = isDeskingManager || isGeneralManager;
  const orderByOptions = useMemo(() => {
    return getOptions(allowProfitSorting);
  }, [allowProfitSorting]);

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [keyRecommendation, setKeyRecommendation] = useState<Vehicle | undefined>(undefined);
  const [keyRecommendationProgramType, setKeyRecommendationProgramType] = useState<ProgramType>(
    ProgramType.Lease
  );
  const [hasFetchedData, setHasFetchedData] = useState(false);

  const { value: showLeases } = usePersistedSwitch({
    recoilValue: leasesViewAtom,
  });
  const { value: showLoans } = usePersistedSwitch({
    recoilValue: loansViewAtom,
  });

  const {
    calculationLoanOutputData,
    calculationLeaseOutputData,
    programFilters,
    captive,
    payAllFeesUpfront,
    payFirstLeasePaymentUpfront,
  } = useFormContext<PdpFormValues>().getValues();

  const calculationOptions: CalculationOptions = {
    ...searchValues.calculationOptions,
    leaseTerms: programFilters.leaseTerms,
    leaseMileages: programFilters.mileages,
    loanTerms: programFilters.loanTerms,
    onlyCaptive: captive,
    payAllFeesUpfront,
    payFirstLeasePaymentUpfront,
  };

  const leasePayment = calculationLeaseOutputData?.payment ?? 0;
  const loanPayment = calculationLoanOutputData?.payment ?? 0;

  const searchFilters = useDebouncedValue({
    value: createSearchFilters({
      comparisonCarId: parseInt(pdpData.vehicle.id),
      leasePayment,
      loanPayment,
      leaseProfit: calculationLeaseOutputData?.profit.total,
      loanProfit: calculationLoanOutputData?.profit.total,
      bodyStyle: pdpData.vehicle.bodyStyle,
      make: pdpData.vehicle.make,
      model: pdpData.vehicle.model,
      year: pdpData.vehicle.year,
      featureTypes: searchValues.featureTypes,
      calculationOptions,
      customer: searchValues.customer,
      dealerDefaults: pdpData.dealerDefaults,
      orderBy,
      orderDirection,
      isSameMakeModelChecked,
      isSameYearChecked,
    }),
    debounceTimeMs: searchDebounceTimeMs,
  });

  const handleOnIsSameMakeModelChecked = useCallback(() => {
    setIsSameMakeModelChecked(!isSameMakeModelChecked);
  }, [isSameMakeModelChecked]);

  const handleOnIsSameYearChecked = useCallback(() => {
    setIsSameYearChecked(!isSameYearChecked);
  }, [isSameYearChecked]);

  const handleOrderByChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const newOrderBy = parseInt(event.target.value);
      setOrderBy(newOrderBy);
      if (shouldUpdateOrderDirection(initialOrderBy, newOrderBy, orderDirection)) {
        const newOrderDirection = getOrderDirection(orderDirection);
        setOrderDirection(newOrderDirection);
      }
    },
    [initialOrderBy, orderDirection, orderBy]
  );

  const handleOrderDirectionChanged = useCallback((): void => {
    const direction = getOrderDirection(orderDirection);
    setOrderDirection(direction);
  }, [orderDirection]);

  const query = useSearchInfiniteQuery({ values: searchFilters, enabled: !!searchFilters });

  useEffect(() => {
    if (!query.isFetching) {
      if (query.data) {
        setHasFetchedData(true);
      }
      const vehicles = getRecommendedVehicles(pdpData.vehicle.id, query.data);
      setVehicles(vehicles);
      const { keyRecommendation, keyRecommendationProgramType } = getKeyRecommendation(
        query.data,
        showLeases,
        showLoans
      );
      setKeyRecommendation(keyRecommendation);
      setKeyRecommendationProgramType(keyRecommendationProgramType);
    }
  }, [query.data, showLeases, showLoans]);

  return {
    isSameMakeModelChecked,
    isSameYearChecked,
    orderBy,
    orderByOptions,
    orderDirection,
    handleOnIsSameMakeModelChecked,
    handleOnIsSameYearChecked,
    handleOrderByChanged,
    handleOrderDirectionChanged,
    vehicles,
    keyRecommendation,
    keyRecommendationProgramType,
    isFetchingData: query.isFetching,
    hasFetchedData,
  };
};
function getOrderDirection(orderDirection: OrderDirectionSearch): OrderDirectionSearch {
  return orderDirection === OrderDirectionSearch.Asc
    ? OrderDirectionSearch.Desc
    : OrderDirectionSearch.Asc;
}
