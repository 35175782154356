import React from 'react';

import { MRT_Cell as MrtCell } from 'material-react-table';

import { formatDate } from 'utils/formatterUtils';

interface Props<T> {
  cell: MrtCell<Partial<T>>;
}

export const TableDateCell: <TValue extends {}>({ cell }: Props<TValue>) => React.ReactElement = <
  TValue extends {}
>({
  cell,
}: Props<TValue>) => {
  const value = cell.getValue() as string;

  return <>{value ? formatDate(value) : ''}</>;
};
