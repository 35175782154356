import React from 'react';

import { CircularProgress, Grid, Typography } from '@mui/material';

import { UserPermission } from 'utils/UserPermission';

import { useProtectedRoute } from './useProtectedRoute';

interface Props {
  requiredPermission?: UserPermission;
  outlet: React.ReactElement;
}

export const ProtectedRoute: React.FC<Props> = ({ requiredPermission, outlet }) => {
  const { isAuthenticated, isLoading, error, userPermissions } = useProtectedRoute();

  if (error) {
    return (
      <Grid item xs={12} textAlign="center">
        <Typography data-cy="auth0_failedToLogin_text">
          Oops, failed to login. Something went wrong.
        </Typography>
      </Grid>
    );
  }

  if (isLoading) {
    return (
      <Grid item xs={12} textAlign="center">
        <CircularProgress data-cy="auth0_login_spinner" size={50} />
      </Grid>
    );
  }

  if (!isAuthenticated || (requiredPermission && !userPermissions.includes(requiredPermission))) {
    return (
      <Grid item xs={12} textAlign="center">
        <Typography data-cy="insufficient_permission_text">
          You do not have permission to view this page.
        </Typography>
      </Grid>
    );
  }

  return <>{outlet}</>;
};
