import React from 'react';
import { FormProvider } from 'react-hook-form';

import {
  Grid,
  Button,
  DialogActions,
  Stack,
  styled,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';

import { SingleCheckboxFormField } from 'components/molecules/form-fields/single-checkbox-form-field/SingleCheckboxFormField';
import { SwitchFormField } from 'components/molecules/form-fields/switch-form-field';
import { TriggeredModal } from 'components/molecules/modal';

import { usePrintModal } from './usePrintModal';

interface PrintProps {
  printButtonIsDisabled: boolean;
}

const StyledButton = styled(Button)({
  justifyContent: 'flex-start',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
}));

export const PrintModal: React.FC<PrintProps> = ({ printButtonIsDisabled }) => {
  const { printOptionsForm, handleOnPrint, isBusy } = usePrintModal();

  return (
    <TriggeredModal
      title="Print settings"
      openIsDisabled={printButtonIsDisabled}
      trigger={
        <StyledButton
          disabled={printButtonIsDisabled}
          variant="contained"
          data-cy="PrintModal_triggerButton"
          fullWidth
        >
          Print
        </StyledButton>
      }
      confirmText="Print"
      showConfirmButton={false}
      displayCloseButton
      content={
        <FormProvider {...printOptionsForm}>
          <Grid container sx={{ pl: 2, pr: 2, pb: 2 }} spacing={6}>
            <Grid item xs={6}>
              <Stack spacing={1}>
                <StyledTypography variant="subtitle3">Payment</StyledTypography>
                <Divider />
                <SwitchFormField
                  name="showLoanGrid"
                  dataCyPrefix="printModal_showLoanGrid"
                  label="Finance"
                  disabled={isBusy}
                />
                <SingleCheckboxFormField
                  name="loanMultipleGridPayments"
                  dataCyPrefix="printModal_loanMultipleGridPayments"
                  label="Payment grid"
                  disabled={isBusy}
                  sx={{ height: 36 }}
                />
                <SingleCheckboxFormField
                  name="showLoanGridApr"
                  dataCyPrefix="printModal_showLoanGridApr"
                  label="APR"
                  disabled={isBusy}
                  sx={{ height: 36 }}
                />
                <Divider />
                <SwitchFormField
                  name="showLeaseGrid"
                  dataCyPrefix="printModal_showLeaseGrid"
                  label="Lease"
                  disabled={isBusy}
                />
                <SingleCheckboxFormField
                  name="leaseMultipleGridPayments"
                  dataCyPrefix="printModal_leaseMultipleGridPayments"
                  label="Payment grid"
                  disabled={isBusy}
                  sx={{ height: 36 }}
                />
                <SingleCheckboxFormField
                  name="showLeaseGridMoneyFactor"
                  dataCyPrefix="printModal_showLeaseGridMoneyFactor"
                  label="Money factor"
                  disabled={isBusy}
                />
                <SingleCheckboxFormField
                  name="showResidual"
                  dataCyPrefix="printModal_showLeaseGridResidualInDollars"
                  label="Residual value"
                  disabled={isBusy}
                />
                <Divider />
                <StyledTypography variant="caption">
                  <strong>Note: </strong>Grid includes up to 4 available terms and down payments.
                  Down payment from deal is used in the 2nd row.
                </StyledTypography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1}>
                <StyledTypography variant="subtitle3">Details</StyledTypography>
                <Divider />
                <SwitchFormField
                  name="showLoanDetails"
                  dataCyPrefix="printModal_showLoanDetails"
                  label="Finance"
                  disabled={isBusy}
                />
                <SwitchFormField
                  name="showLeaseDetails"
                  dataCyPrefix="printModal_showLeaseDetails"
                  label="Lease"
                  disabled={isBusy}
                  sx={{ height: 40 }}
                />
                <Divider />
                <StyledTypography variant="subtitle3">Deal info</StyledTypography>
                <Grid container>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showCreditScore"
                      dataCyPrefix="printModal_showCreditScore"
                      label="Credit score"
                      disabled={isBusy}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showLenderName"
                      dataCyPrefix="printModal_showLenderName"
                      label="Lender name"
                      disabled={isBusy}
                    />
                  </Grid>
                </Grid>
                <StyledTypography variant="subtitle3">Cap cost adjustments</StyledTypography>
                <Grid container>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showAddons"
                      dataCyPrefix="printModal_showAddons"
                      label="F&I products"
                      disabled={isBusy}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showIncentives"
                      dataCyPrefix="printModal_showIncentives"
                      label="Rebates"
                      disabled={isBusy}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showTradeIn"
                      dataCyPrefix="printModal_showTradeIn"
                      label="Trade"
                      disabled={isBusy}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showVehiclePriceOrMsrp"
                      dataCyPrefix="printModal_showVehiclePriceOrMsrp"
                      label="Vehicle Price / MSRP"
                      disabled={isBusy}
                    />
                  </Grid>
                </Grid>
                <StyledTypography variant="subtitle3">Fees</StyledTypography>
                <Grid container>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showAcquisitionFee"
                      dataCyPrefix="printModal_showAcquisitionFee"
                      label="Acquisition fee"
                      disabled={isBusy}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showDealerFees"
                      dataCyPrefix="printModal_showDealerFees"
                      label="Dealer fees"
                      disabled={isBusy}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showNonTaxTtlFees"
                      dataCyPrefix="printModal_showNonTaxTtlFees"
                      label="Registration fees"
                      disabled={isBusy}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SingleCheckboxFormField
                      name="showSalesTax"
                      dataCyPrefix="printModal_showSalesTax"
                      label="Taxes"
                      disabled={isBusy}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <DialogActions>
            <Stack direction="row" spacing={1}>
              {isBusy && <CircularProgress data-cy="overlay-spinner" color="primary" />}
              <StyledButton
                type="submit"
                variant="contained"
                onClick={handleOnPrint}
                data-cy="PrintModal_printButton"
                disabled={isBusy}
              >
                Print
              </StyledButton>
            </Stack>
          </DialogActions>
        </FormProvider>
      }
    />
  );
};
