import { useCallback, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import { useDealOffersListPageNavigation } from 'pages/administration/deal-offers/list-page/useDealOffersListPageNavigation';

import { SearchFormValues } from 'components/search/form-wrapper/useSearchFormWrapper';
import { useSearchFormHeader } from 'components/search/form/form-header/useSearchFormHeader';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useFindCustomerQuery } from 'hooks/useFindCustomerQuery';
import { useSearchFormValues } from 'hooks/useSearchFormValues';

import {
  CreditStatus,
  CustomerMode,
  createDefaultCustomer,
  getCustomerMode,
  getCustomerInitials,
} from 'services/CustomerService';
import { ProgramVariations } from 'services/PdpApiService';
import { CurrentDealership } from 'services/SearchApiService';
import { LocationWithZip } from 'services/ZipCodeApiService';

import { loadCustomer, resetCustomer } from './CustomerService';

interface HookProps {
  currentDealership: CurrentDealership;
}

interface HookResult {
  programVariationOptions: ProgramVariations;
  searchFormValues: SearchFormValues;
  customerInitials: string | undefined;
  customerMode: CustomerMode;
  customerCreditStatus: CreditStatus;
  creditReadOnly: boolean;
  editActualCashValue: boolean;
  handleFindCustomerByEmail: () => Promise<void>;
  handleFindCustomerByPhone: () => Promise<void>;
  handleEditCustomer: () => void;
  handleNavigateToCustomerDealOffers: () => Promise<void>;
  handleNewCustomer: () => void;
}

export const useCustomerBlock = ({ currentDealership }: HookProps): HookResult => {
  const { searchFormValues } = useSearchFormValues();
  const { handleClearFilters } = useSearchFormHeader({
    currentDealership,
    searchResultsCount: 0,
    excludedCount: 0,
  });
  const { getValues, setValue } = useFormContext<SearchFormValues>();
  const { isDeskingManager, isGeneralManager } = useContext(PermissionsContext);
  const editActualCashValue = isDeskingManager || isGeneralManager;
  const { enqueueSnackbar } = useSnackbar();

  const { navigateToCustomerDealOffers } = useDealOffersListPageNavigation();
  const { refetch: findCustomerByEmail } = useFindCustomerQuery({
    email: searchFormValues.customer.email,
  });

  const { refetch: findCustomerByPhone } = useFindCustomerQuery({
    phone: searchFormValues.customer.phone,
  });

  const handleFindCustomerByEmail = useCallback(async (): Promise<void> => {
    return await loadCustomer(findCustomerByEmail, setValue).then((customer) => {
      if (!customer) {
        enqueueSnackbar('Customer email was not found.', {
          variant: 'warning',
          preventDuplicate: true,
        });
      }
    });
  }, [findCustomerByEmail]);

  const handleFindCustomerByPhone = useCallback(async (): Promise<void> => {
    return await loadCustomer(findCustomerByPhone, setValue).then((customer) => {
      if (!customer) {
        enqueueSnackbar('Customer phone was not found.', {
          variant: 'warning',
          preventDuplicate: true,
        });
      }
    });
  }, [findCustomerByPhone]);

  const handleNavigateToCustomerDealOffers = useCallback(async (): Promise<void> => {
    const customer =
      (await loadCustomer(findCustomerByEmail, setValue)) ??
      (await loadCustomer(findCustomerByPhone, setValue));

    if (customer) {
      navigateToCustomerDealOffers(customer.id);
    } else {
      enqueueSnackbar('Customer was not found.', {
        variant: 'warning',
        preventDuplicate: true,
      });
    }
  }, [findCustomerByEmail, findCustomerByPhone, navigateToCustomerDealOffers]);

  const handleEditCustomer = useCallback((): void => {
    resetCustomer(getValues().customer, setValue);
  }, []);

  const handleNewCustomer = useCallback((): void => {
    const defaultCustomer = createDefaultCustomer();
    resetCustomer(defaultCustomer, setValue);
    handleClearFilters(defaultCustomer);
    setValue('calculationOptions.location', {
      zipCode: '',
    } as LocationWithZip);
  }, []);

  const customerInitials = useMemo(() => {
    return getCustomerInitials(searchFormValues.customer.name);
  }, [searchFormValues.customer.name]);

  const customerMode = getCustomerMode(searchFormValues.customer.id);

  const emptyProgramVariations: ProgramVariations = {
    leaseMileages: [],
    leaseTerms: [],
    loanTerms: [],
  };

  const programVariationOptions =
    currentDealership?.programVariations?.all ?? emptyProgramVariations;

  return {
    programVariationOptions,
    searchFormValues,
    customerInitials,
    customerMode,
    customerCreditStatus: searchFormValues.customer.creditStatus,
    creditReadOnly: !!searchFormValues.customer.creditTierId,
    editActualCashValue,
    handleEditCustomer,
    handleFindCustomerByEmail,
    handleFindCustomerByPhone,
    handleNavigateToCustomerDealOffers,
    handleNewCustomer,
  };
};
