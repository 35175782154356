import React from 'react';
import { Controller } from 'react-hook-form';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { CollapsibleItem } from 'components/search/collapsible-item';

import { FeatureFilter } from 'services/SearchFiltersApiService';

import { useCheckboxGroupItems } from './useCheckboxGroupItems';

interface CheckBoxGroupItemsProps {
  featureItems: FeatureFilter[];
  dataCyPrefix: string;
  disabled: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)({
  paddingBottom: '5px',
});

export const CheckBoxGroupItems: React.FC<CheckBoxGroupItemsProps> = ({
  featureItems = [],
  dataCyPrefix,
  disabled,
}) => {
  const { control, getNewValues } = useCheckboxGroupItems();

  return (
    <Controller
      name="featureTypes"
      control={control}
      render={({
        field: { value, onBlur, onChange },
        fieldState: { error },
      }): React.ReactElement => (
        <FormControl error={!!error} fullWidth>
          {featureItems.map((featureItem, key) => (
            <CollapsibleItem
              scrollable={featureItem.features.length > 6}
              content={
                <FormGroup>
                  {featureItem.features.map(({ code, count, name }) => (
                    <StyledFormControlLabel
                      key={code}
                      disabled={disabled}
                      control={
                        <Checkbox
                          name={name}
                          onChange={(): void => onChange(getNewValues(code))}
                          onBlur={onBlur}
                          checked={value?.includes(code)}
                          disabled={count === 0}
                          inputProps={{
                            // @ts-ignore Workaround to pass data-cy to Checkbox input
                            'data-cy': `${dataCyPrefix}_${code}_checkbox`,
                          }}
                          size="small"
                        />
                      }
                      label={
                        <Stack direction="row" alignItems="center">
                          <Typography
                            data-cy={`${dataCyPrefix}_${code}_checkboxLabel`}
                            variant="body4"
                          >
                            {`${name} (${count})`}
                          </Typography>
                        </Stack>
                      }
                    />
                  ))}
                </FormGroup>
              }
              title={featureItem.type}
              key={key}
              dataCyPrefix={dataCyPrefix}
            />
          ))}
        </FormControl>
      )}
    />
  );
};
