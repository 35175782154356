import { Dealer } from 'services/DealerApiService';
import { Permission, UserDealer } from 'services/UserAdministrationApiService';

export interface UserDealerField {
  name: string;
  publicId: string;
  carsNew: boolean;
  carsUsed: boolean;
  disabled: boolean;
}
export interface UserDealerAssignmentFormValues {
  userDealers: UserDealerField[];
}

export function mapUserDealerFields(
  dealer: Dealer,
  userDealers: UserDealer[],
  canEditAllUserDealers: boolean,
  currentUserDealerIds: string[]
): UserDealerField {
  const matchingUserDealer = userDealers?.find(
    (userDealer) => userDealer.publicId === dealer.publicId
  );

  return {
    name: dealer.name,
    publicId: dealer.publicId,
    carsNew: !!matchingUserDealer?.permissions.find(
      (permission) => permission === Permission.CarsNew
    ),
    carsUsed: !!matchingUserDealer?.permissions.find(
      (permission) => permission === Permission.CarsUsed
    ),
    disabled:
      !canEditAllUserDealers &&
      !currentUserDealerIds.some((dealerPublicId) => dealerPublicId === dealer.publicId),
  };
}

export function mapUserDealers(userDealerFields: UserDealerField[]): UserDealer[] {
  return userDealerFields
    .filter((x) => x.carsNew || x.carsUsed)
    .map((field) => {
      return {
        publicId: field.publicId,
        permissions: mapPermissions(field),
      };
    });
}

function mapPermissions(field: UserDealerField): Permission[] {
  const result = [];
  if (field.carsNew) {
    result.push(Permission.CarsNew);
  }
  if (field.carsUsed) {
    result.push(Permission.CarsUsed);
  }

  return result;
}
