import { atom } from 'recoil';

export enum ProgramSelectionMethod {
  BestOverall,
  BestByFilterValues,
  Manual,
}

// TODO: 1655 - Do these get removed now?
const leaseProgramSelectionMethodKey = 'leaseProgramSelectionMethod';
const loanProgramSelectionMethodKey = 'loanProgramSelectionMethod';

export const leaseProgramSelectionMethodAtom = atom<ProgramSelectionMethod>({
  key: leaseProgramSelectionMethodKey,
  default: ProgramSelectionMethod.BestOverall,
});

export const loanProgramSelectionMethodAtom = atom<ProgramSelectionMethod>({
  key: loanProgramSelectionMethodKey,
  default: ProgramSelectionMethod.BestOverall,
});

export const shouldSelectLowestPaymentLoanAtom = atom<boolean>({
  key: 'shouldSelectLowestPaymentLoan',
  default: true,
});

export const shouldSelectLowestPaymentLeaseAtom = atom<boolean>({
  key: 'shouldSelectLowestPaymentLease',
  default: true,
});
