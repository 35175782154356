import { useCallback, useContext, useEffect } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';

import { useDeleteUser } from 'pages/administration/users/useDeleteUser';

import { MultiSelectOptions } from 'components/molecules/form-fields/multi-select-form-field/MultiSelectFormField';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { useCreateUserMutation } from 'hooks/useCreateUserMutation';
import { useDealersQuery } from 'hooks/useDealersQuery';
import { useEditUserMutation } from 'hooks/useUserEditMutation';

import { Dealer } from 'services/DealerApiService';
import { DealerGroupSummary } from 'services/DealerGroupApiService';
import {
  AdministrationUser,
  AdministrationUserEdit,
  UserDealer,
} from 'services/UserAdministrationApiService';

import { UserPermission } from 'utils/UserPermission';
import { getUserValidationResolver } from 'utils/validationResolvers';

import {
  DealerGroupOption,
  getUserRolesOptions,
  isSubmitDisabled,
  mapDealerGroupOptions,
  mapDealerOptions,
  mapUserFormValues,
  selectedDealersAreInaccessibleToUser,
} from './UserAdministrationFormService';

interface HookProps {
  initialUserData?: AdministrationUser;
  dealerGroups: DealerGroupSummary[];
  userID?: string;
}

interface HookResult {
  methods: UseFormReturn<AdministrationUser>;
  handleCreateUser(values: AdministrationUser): Promise<void>;
  handleEditUser(values: AdministrationUser): Promise<void>;
  handleDeleteUser(id: string): Promise<void>;
  isEditForm: boolean;
  userRoleOptions: { label: string; value: string | number; isChecked: boolean }[];
  defaultUserRolesValue: (string | number)[];
  dealerGroupOptions: DealerGroupOption[];
  isLoadingDealers: boolean;
  submitDisabled: boolean;
  dealerMultiSelectOptions: MultiSelectOptions[];
  defaultMultiSelectValues: (string | number)[];
  canEditDealerGroup: boolean;
  deleteDisabled: boolean;
  dealers: Dealer[];
}

export const useUserAdministrationForm = ({
  initialUserData,
  dealerGroups,
  userID,
}: HookProps): HookResult => {
  const { handleDeleteUser } = useDeleteUser();
  const isEditForm = !!(initialUserData && userID);

  const mappedUserData = initialUserData ? mapUserFormValues(initialUserData) : undefined;

  const {
    firstName,
    lastName,
    email,
    userDealershipPhone,
    userPreferedText,
    dealerGroupId,
    dealerIds,
    dealers: userDealers,
    jobTitle,
    roles: userRoles,
  } = mappedUserData || {};

  const {
    permissions: currentUserPermissions,
    roles: currentUserRoles,
    dealerIds: currentUserDealerIds,
    dealerGroupId: currentUserDealerGroupId,
  } = useContext(PermissionsContext);

  const methods = useForm<AdministrationUser>({
    defaultValues: {
      firstName,
      lastName,
      email,
      userDealershipPhone,
      userPreferedText,
      dealerGroupId: dealerGroupId ?? currentUserDealerGroupId,
      dealerIds: dealerIds ?? [],
      dealers: userDealers,
      jobTitle,
      roles: userRoles,
    },
    resolver: getUserValidationResolver(),
    mode: 'all',
  });

  const {
    dealerGroupId: selectedDealerGroupId,
    dealerIds: selectedDealerIds,
    dealers,
  } = useWatch({
    control: methods.control,
  });

  const { mutateAsync: createUserAsync } = useCreateUserMutation();
  const { mutateAsync: editUserAsync } = useEditUserMutation(userID);

  const { dealersQuery } = useDealersQuery({
    dealerGroupPublicId: selectedDealerGroupId ?? dealerGroupId,
  });

  useEffect(() => {
    if (selectedDealerGroupId !== dealerGroupId) {
      methods.setValue('dealerIds', []);
    }
  }, [selectedDealerGroupId, dealerGroupId]);

  const handleCreateUser = useCallback(
    async (values: AdministrationUser) => {
      await createUserAsync(values);
    },
    [dealersQuery.data, dealerGroups]
  );

  const handleEditUser = useCallback(
    async (values: AdministrationUserEdit) => {
      await editUserAsync(values);
    },
    [dealersQuery.data, dealerGroups]
  );

  const userRoleOptions = getUserRolesOptions(currentUserRoles, initialUserData);
  const defaultUserRolesValue = userRoleOptions.filter((r) => r.isChecked).map((r) => r.value);
  const canEditDealerGroup = !!currentUserPermissions.find(
    (x) => x === UserPermission.editUserDealerGroup
  );

  const dealerGroupOptions = mapDealerGroupOptions(dealerGroups ?? []);

  const submitDisabled = isSubmitDisabled(
    methods.formState,
    dealersQuery,
    selectedDealerIds,
    dealers as UserDealer[]
  );

  const canEditAllUserDealers =
    currentUserPermissions.includes(UserPermission.editAllUserDealers) ||
    currentUserPermissions.includes(UserPermission.editUserDealerGroup);

  const currentDealerIds = methods.getValues().dealerIds ?? [];
  const dealerMultiSelectOptions: MultiSelectOptions[] = mapDealerOptions(
    dealersQuery.data ?? [],
    currentDealerIds,
    canEditAllUserDealers,
    currentUserDealerIds
  );

  const defaultMultiSelectValues = dealerMultiSelectOptions
    .filter((x) => x.isChecked)
    .map((x) => x.value);

  const deleteDisabled =
    dealersQuery.isFetching ||
    methods.formState.isSubmitting ||
    selectedDealersAreInaccessibleToUser(dealerMultiSelectOptions);

  return {
    methods,
    handleCreateUser,
    handleEditUser,
    handleDeleteUser,
    isEditForm,
    userRoleOptions,
    defaultUserRolesValue,
    dealerGroupOptions,
    isLoadingDealers: dealersQuery.isFetching,
    submitDisabled,
    dealerMultiSelectOptions,
    defaultMultiSelectValues,
    canEditDealerGroup,
    deleteDisabled,
    dealers: dealersQuery.data ?? [],
  };
};
