import _ from 'lodash';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';
import { filterAddons } from 'components/pdp/form/PaymentCalculationService';
import { mapToDealerIncentives } from 'components/pdp/payment-customization-block/BonusIncentiveCalculationService';

import { CustomIncentiveProgramType } from 'models/CustomIncentivesModel';
import { PaymentCalculationType } from 'models/ExactPaymentModel';

import {
  Addon,
  DealType,
  LeaseProgram,
  LoanProgram,
  PdpItem,
  TaxCreditTo as TaxCreditToPdp,
} from 'services/PdpApiService';
import { getAllCheckedAddons } from 'services/ProfitService';
import { ReserveType } from 'services/ReserveService';

import {
  Buyer,
  CalculationOptions,
  IncentivesMethod,
  LeaseOptions,
  LoanOptions,
  Location,
  MarkupMethod,
  PaymentGridRequest,
  PaymentMethod,
  TaxCreditTo,
  TradeIn,
  TtlPrecision,
  VehicleOptions,
} from './PaymentGridApiModels.generated';

export function getPaymentGridApiUrl(carId: string): string {
  return `/pdp/${carId}/payment-grid-calculations`;
}

export interface PaymentGridQueryRequest {
  carId: string;
  pdpFormValues: PdpFormValues;
  pdpData: PdpItem;
  customAddons: Addon[];
  downPaymentCalculationType: PaymentCalculationType;
  paymentCalculationType: PaymentCalculationType;
  ttlPrecision: TtlPrecision;
  selectedLease: LeaseProgram | undefined;
  selectedLoan: LoanProgram | undefined;
}

export function createPaymentGridQuery(request: PaymentGridQueryRequest): PaymentGridRequest {
  return {
    carId: request.carId ? parseInt(request.carId) : 0,
    buyer: mapBuyer(request.pdpFormValues),
    options: mapOptions(request),
    selectedLease: request.selectedLease
      ? {
          programId: request.selectedLease?.programId,
          termMonths: request.selectedLease?.term,
          mileage: request.selectedLease?.millage,
        }
      : undefined,
    selectedLoan: request.selectedLoan
      ? {
          programId: request.selectedLoan?.programId,
          termMonths: request.selectedLoan?.term,
        }
      : undefined,
  };
}

function mapBuyer(pdpFormValues: PdpFormValues): Buyer {
  return {
    creditScore: pdpFormValues.creditScore,
    location: mapLocation(pdpFormValues),
  };
}

function mapLocation(pdpFormValues: PdpFormValues): Location | undefined {
  const { location } = pdpFormValues.buyer;

  return location?.zipCode
    ? {
        city: location.city,
        county: location.county,
        state: location.state,
        zipCode: location.zipCode,
      }
    : undefined;
}

function mapOptions({
  pdpFormValues,
  pdpData,
  customAddons,
  downPaymentCalculationType,
  paymentCalculationType,
  ttlPrecision,
}: PaymentGridQueryRequest): CalculationOptions {
  const leaseMarkupMethod = mapMarkupMethod(pdpFormValues.leaseReserveType);
  const loanMarkupMethod = mapMarkupMethod(pdpFormValues.loanReserveType);

  const addons = getAllCheckedAddons({
    customAddonsValues: pdpFormValues.customAddons,
    customAddons,
    addonsValues: pdpFormValues.addons,
    addons: pdpData.dealer.addonsV2,
  });

  return {
    addonsPaymentMethod: mapAddOnsPaymentMethod(pdpFormValues),
    downPayment:
      downPaymentCalculationType === PaymentCalculationType.EXACT
        ? pdpFormValues.downPayment
        : undefined,
    maxOutOfPocket:
      downPaymentCalculationType === PaymentCalculationType.EXACT
        ? pdpFormValues.maxOutOfPocket
        : undefined,
    payment:
      paymentCalculationType === PaymentCalculationType.EXACT
        ? pdpFormValues.monthlyExactPayment
        : undefined,
    tradeIn: mapTradeIn(pdpFormValues),
    lease: mapLeaseOptions(pdpFormValues, addons, leaseMarkupMethod),
    loan: mapLoanOptions(pdpFormValues, addons, loanMarkupMethod),
    feePaymentMethod: mapFeePaymentMethod(pdpFormValues),
    onlyCaptiveLenders: pdpFormValues.captive,
    ttlPrecision,
    vehicle: mapVehicleOptions(pdpFormValues),
  };
}

export function mapAddOnsPaymentMethod(pdpFormValues: PdpFormValues): PaymentMethod {
  return pdpFormValues.payAllPackagesUpfront ? PaymentMethod.UpFront : PaymentMethod.RolledIn;
}

export function mapFeePaymentMethod(pdpFormValues: PdpFormValues): PaymentMethod {
  return pdpFormValues.payAllFeesUpfront ? PaymentMethod.UpFront : PaymentMethod.RolledIn;
}

function mapMarkupMethod(reserveType: ReserveType): MarkupMethod {
  switch (reserveType) {
    case ReserveType.MaxAllowed:
      return MarkupMethod.MaxAllowed;
    case ReserveType.Flat:
      return MarkupMethod.NoMarkup;
    case ReserveType.Auto:
      return MarkupMethod.Auto;
    default:
      return MarkupMethod.UseSpecified;
  }
}

function mapTradeIn(pdpFormValues: PdpFormValues): TradeIn {
  return {
    allowance: pdpFormValues.tradeInValue,
    balance: pdpFormValues.tradeInOutstandingBal,
    value: pdpFormValues.actualCashValue,
    leaseTaxCreditTo: mapTaxCreditTo(pdpFormValues.leaseTaxCreditTo),
    loanTaxCreditTo: mapTaxCreditTo(pdpFormValues.loanTaxCreditTo),
  };
}

function mapLeaseOptions(
  pdpFormValues: PdpFormValues,
  addons: Addon[],
  markupMethod: MarkupMethod
): LeaseOptions {
  const leaseAddons = filterAddons(addons, DealType.Lease);

  return {
    terms: pdpFormValues.programFilters.leaseTerms,
    mileages: pdpFormValues.programFilters.mileages.map((x) => x),
    acquisitionFeeOverride: pdpFormValues.leaseMarkedUpAcquisitionFee,
    incentives: {
      dealer: mapToDealerIncentives(
        pdpFormValues.appliedCustomIncentives,
        CustomIncentiveProgramType.Lease
      ),
      program: {
        method: IncentivesMethod.UseSpecified,
        specifiedIncentiveIds: mapIncentiveIds(pdpFormValues),
      },
    },
    addons: leaseAddons,
    // ToDo: remove fees when addons array feature flag is removed
    fees: {
      addons: _.sumBy(leaseAddons, (x) => x.price),
      addonsCost: _.sumBy(leaseAddons, (x) => x.wholesale),
    },
    moneyFactorMarkup: {
      method: markupMethod,
      specifiedValue: pdpFormValues.leaseMoneyFactorMarkup,
    },
    reserveSplitRateOverride: pdpFormValues.leaseDealerSplitPercent
      ? pdpFormValues.leaseDealerSplitPercent / 100
      : undefined,
    firstPaymentMethod: mapFirstLeasePaymentMethod(pdpFormValues),
  };
}

function mapIncentiveIds(pdpFormValues: PdpFormValues): string[] {
  return pdpFormValues.rebates.map((x) => x.toString());
}

export function mapFirstLeasePaymentMethod(pdpFormValues: PdpFormValues): PaymentMethod {
  return pdpFormValues.payFirstLeasePaymentUpfront ? PaymentMethod.UpFront : PaymentMethod.RolledIn;
}

function mapLoanOptions(
  pdpFormValues: PdpFormValues,
  addons: Addon[],
  markupMethod: MarkupMethod
): LoanOptions {
  const loanAddons = filterAddons(addons, DealType.Loan);

  return {
    terms: pdpFormValues.programFilters.loanTerms,
    incentives: {
      dealer: mapToDealerIncentives(
        pdpFormValues.appliedCustomIncentives,
        CustomIncentiveProgramType.Loan
      ),
      program: {
        method: IncentivesMethod.UseSpecified,
        specifiedIncentiveIds: mapIncentiveIds(pdpFormValues),
      },
    },
    addons: loanAddons,
    // ToDo: remove fees when addons array feature flag is removed
    fees: {
      addons: _.sumBy(loanAddons, (x) => x.price),
      addonsCost: _.sumBy(loanAddons, (x) => x.wholesale),
    },
    aprMarkup: {
      method: markupMethod,
      specifiedValue:
        pdpFormValues.loanAprMarkupPercent !== undefined
          ? pdpFormValues.loanAprMarkupPercent / 100
          : undefined,
    },
    reserveSplitRateOverride: pdpFormValues.loanDealerSplitPercent
      ? pdpFormValues.loanDealerSplitPercent / 100
      : undefined,
  };
}

function mapVehicleOptions(pdpFormValues: PdpFormValues): VehicleOptions {
  return {
    invoiceOverride: pdpFormValues.vehicleInvoice,
    msrpOverride: pdpFormValues.msrp,
    priceOverride: pdpFormValues.vehiclePrice,
  };
}

export function mapTaxCreditTo(taxCreditTo: TaxCreditToPdp): TaxCreditTo {
  switch (taxCreditTo) {
    case TaxCreditToPdp.CustomerAllowance:
      return TaxCreditTo.CustomerAllowance;
    case TaxCreditToPdp.NoCredit:
      return TaxCreditTo.NoCredit;
    case TaxCreditToPdp.Payoff:
      return TaxCreditTo.Payoff;
    case TaxCreditToPdp.TradeEquity:
      return TaxCreditTo.TradeEquity;
  }
}
