import React, { useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  CardContent,
  Stack,
  Typography,
  IconButton,
  Collapse,
  Divider,
  styled,
} from '@mui/material';

import { Scrollbar } from 'components/atoms/scrollbar';

const CollapseButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
}));

interface CollapsibleItemProps {
  title: string;
  content: React.ReactElement;
  dataCyPrefix: string;
  scrollable?: boolean;
}

export const CollapsibleItem: React.FC<CollapsibleItemProps> = ({
  content,
  title,
  scrollable,
  dataCyPrefix,
}) => {
  const [expand, setExpand] = useState(false);

  return (
    <>
      <CardContent onClick={(): void => setExpand(!expand)}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="overline" fontWeight={500} data-cy={`${dataCyPrefix}_title`}>
            {title}
          </Typography>
          <CollapseButton data-cy={`${dataCyPrefix}_collapseButton`}>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </CollapseButton>
        </Stack>
      </CardContent>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        {scrollable ? (
          <Scrollbar maxheight="200px">
            <CardContent>{content}</CardContent>
          </Scrollbar>
        ) : (
          <CardContent>{content}</CardContent>
        )}
      </Collapse>
    </>
  );
};
