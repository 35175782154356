import React from 'react';

import { Stack, Typography } from '@mui/material';

interface Props {
  label: string;
  value: number | string;
  selectorPrefix?: string;
  isHidden?: boolean;
  isSpace?: boolean;
}

export const PriceListItem: React.FC<Props> = ({
  label,
  value,
  selectorPrefix,
  isHidden,
  isSpace,
}) => {
  if (isHidden) return null;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="body4" data-cy={`${selectorPrefix}_label`}>
        {isSpace ? <br /> : label}
      </Typography>
      <Typography variant="body4" data-cy={`${selectorPrefix}_value`}>
        {value}
      </Typography>
    </Stack>
  );
};
