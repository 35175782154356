import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { PdpContext } from 'providers/PdpContextProvider';

import { useConfiguration } from 'hooks/useConfiguration';

import { ProgramSelectionMethod } from 'models/PaymentSelectionModel';
import { priceRangeAtom } from 'models/PriceRangeModel';

import { CalculationLeaseOutput } from 'services/CalculationService';

import { formatToCurrency, formatToNumber, getFormattedPrice, getSign } from 'utils/formatterUtils';

interface HookProps {
  calculationOutput?: CalculationLeaseOutput;
  programSelectionMethod: ProgramSelectionMethod;
}

interface HookResult {
  payment: string;
  inPaymentTaxNote: string;
  dueAtSigning: string;
  dueAtSigningNote: string;
  programNote1?: string | null;
  programNote2?: string | null;
}

export const useLeaseMainBox = ({
  calculationOutput,
  programSelectionMethod,
}: HookProps): HookResult => {
  const priceRangeEnabled = useRecoilValue(priceRangeAtom);
  const {
    paymentsIntervalOptions: { defaultInterval, dueAtSigningInterval },
  } = useConfiguration();

  const { isProgramSelectionDisabled } = useContext(PdpContext);

  const payment =
    getFormattedPrice(priceRangeEnabled, defaultInterval, calculationOutput?.payment) || '0';
  const inPaymentTaxNote = getInPaymentTaxNote(calculationOutput);
  const dueAtSigning =
    getFormattedPrice(
      priceRangeEnabled,
      dueAtSigningInterval,
      calculationOutput?.paymentDueAtSigning
    ) || '0';
  const dueAtSigningNote = calculationOutput
    ? getDueAtSigningNote({
        calculationOutput,
        priceRangeEnabled,
        intervalValue: defaultInterval,
      })
    : '';

  return {
    payment,
    inPaymentTaxNote,
    dueAtSigning,
    dueAtSigningNote,
    programNote1: createProgramNote1(programSelectionMethod, isProgramSelectionDisabled),
    programNote2: createProgramNote2(calculationOutput?.term, calculationOutput?.mileage),
  };
};

function getInPaymentTaxNote(calculationOutput: CalculationLeaseOutput | undefined): string {
  const monthlySaleTaxFee = calculationOutput?.ttlFee?.monthlySaleTaxFee ?? 0;

  if (monthlySaleTaxFee > 0) {
    return `Includes ${formatToCurrency(monthlySaleTaxFee)} monthly taxes`;
  }

  return '';
}

interface PriceNoteOptions {
  calculationOutput: CalculationLeaseOutput;
  priceRangeEnabled: boolean;
  intervalValue: number;
}

function getDueAtSigningNote({
  calculationOutput: {
    firstPayment,
    downPayment,
    maxOutOfPocket,
    fees: { upfrontFees },
    equityCoveredFees,
  },
  priceRangeEnabled,
  intervalValue,
}: PriceNoteOptions): string {
  const ttlNote = upfrontFees.ttl
    ? ` + ${getFormattedPrice(priceRangeEnabled, intervalValue, upfrontFees.ttl)} TTL`
    : '';
  const dealerNote = upfrontFees.dealer
    ? ` + ${formatToCurrency(upfrontFees.dealer)} dealer fees`
    : '';
  const paymentNote = firstPayment
    ? ` + ${getFormattedPrice(priceRangeEnabled, intervalValue, firstPayment)} first lease payment`
    : '';
  const downPaymentNote = downPayment ? getDownPayment(downPayment) : '';
  const maxOutOfPocketNote = maxOutOfPocket ? getMaxOop(maxOutOfPocket) : '';
  const addonsNote = upfrontFees.addons ? ` + ${formatToCurrency(upfrontFees.addons)} Add-ons` : '';
  const equityNote = downPayment ? getEquitySum(equityCoveredFees) : '';

  const result = ttlNote.concat(
    dealerNote,
    paymentNote,
    downPaymentNote,
    maxOutOfPocketNote,
    addonsNote,
    equityNote
  );

  return result.startsWith(' + ') ? result.slice(3) : result.slice(1);
}

function getMaxOop(maxOutOfPocket: number): string {
  return ` ${getSign(maxOutOfPocket)} ${formatToCurrency(
    Math.abs(maxOutOfPocket),
    2
  )} Max out of Pocket`;
}

function getDownPayment(downPayment: number): string {
  return ` ${getSign(downPayment)} ${formatToCurrency(Math.abs(downPayment), 2)} Down Payment`;
}

function getEquitySum(equityCoveredFees: number): string {
  return equityCoveredFees
    ? ` ${getSign(equityCoveredFees * -1)} ${formatToCurrency(equityCoveredFees, 2)} equity`
    : '';
}

function createProgramNote1(
  programSelectionMethod: ProgramSelectionMethod,
  isPaymentSelectionDisabled: boolean
): string | null {
  if (isPaymentSelectionDisabled) {
    return null;
  }

  switch (programSelectionMethod) {
    case ProgramSelectionMethod.BestOverall:
      return 'Best payment identified';
    case ProgramSelectionMethod.BestByFilterValues:
      return 'Best mileage/term payment combination identified';
    case ProgramSelectionMethod.Manual:
      return '';
  }
}

function createProgramNote2(term?: number, mileage?: number): string | null {
  return term && mileage ? `${formatToNumber(mileage, 0)} miles/${term} months` : null;
}
