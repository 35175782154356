import { useContext } from 'react';
import { UseMutationResult, useMutation } from 'react-query';

import { useSnackbar } from 'notistack';

import { AxiosError, AxiosResponse } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import { getCrmUrl } from 'services/CrmService';

import { handleOnError } from 'utils/queryUtils';

export interface PushToCrmRequest {
  dealOfferId: string | undefined;
}

export const usePushToCrmMutation = (
  dealOfferId: string | undefined
): UseMutationResult<AxiosResponse, unknown, string | undefined, unknown> => {
  const { enqueueSnackbar } = useSnackbar();
  const axiosClient = useContext(AxiosContext);
  const pushToCrmMutation = useMutation(
    [dealOfferId],
    (dealOfferId: string | undefined) => axiosClient.post(getCrmUrl(), { dealOfferId }),
    {
      onError: (error: AxiosError) => {
        handleOnError(error, 'Failed to push deal offer to CRM');
      },
      onSuccess: () => {
        enqueueSnackbar('Push to CRM succeeded', {
          variant: 'success',
          autoHideDuration: null,
        });
      },
      retry: false,
    }
  );

  return pushToCrmMutation;
};
