import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const CustomIncentiveAnyLender = 'Any' as string;

export enum CustomIncentiveProgramType {
  Both = 0,
  Lease = 1,
  Loan = 2,
}

export interface CustomIncentive {
  name: string;
  amount: number;
  incentiveProgramType: CustomIncentiveProgramType;
  incentiveLenderName: string;
}

const { persistAtom } = recoilPersist({
  key: 'customIncentives-v2',
  storage: localStorage,
});

export const CustomIncentivesAtom = atom<CustomIncentive[]>({
  key: 'customIncentives-v2',
  default: [],
  effects: [persistAtom],
});
