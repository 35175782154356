import React, { useContext } from 'react';
import { FormProvider } from 'react-hook-form';

import { PdpForm } from 'components/pdp/form';

import { PdpContext } from 'providers/PdpContextProvider';

import { PaymentCustomization } from 'services/DealOfferApiService';

import { usePdpFormWrapper } from './usePdpFormWrapper';

interface Props {
  dealOfferValues?: PaymentCustomization;
  isLeaseProgramExpired?: boolean;
  isLoanProgramExpired?: boolean;
  isCarSold?: boolean;
  isIncentivesExpired?: boolean;
}

export const PdpFormWrapper: React.FC<Props> = ({
  dealOfferValues,
  isLeaseProgramExpired,
  isLoanProgramExpired,
  isCarSold,
  isIncentivesExpired,
}) => {
  const { pdpData } = useContext(PdpContext);
  const { methods } = usePdpFormWrapper({ pdpData, dealOfferValues });

  return (
    <FormProvider {...methods}>
      <PdpForm
        pdpData={pdpData}
        isCarSold={isCarSold}
        isIncentivesExpired={isIncentivesExpired}
        isLeaseProgramExpired={isLeaseProgramExpired}
        isLoanProgramExpired={isLoanProgramExpired}
        isDealOffer={!!dealOfferValues}
      />
    </FormProvider>
  );
};
