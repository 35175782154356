import React, { useCallback, useState } from 'react';

interface HookResult {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleToggle(event: React.MouseEvent<HTMLElement>): void;
  handleClickAway(): void;
  id: string | undefined;
}

export const useSortBlock = (): HookResult => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return {
    open,
    anchorEl,
    handleToggle,
    handleClickAway,
    id,
  };
};
