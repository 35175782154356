import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { exactMonthlyPaymentAtom } from 'models/ExactPaymentModel';

export const useMonthlyPaymentInput = (): void => {
  const setExactMonthlyPayment = useSetRecoilState(exactMonthlyPaymentAtom);
  const { monthlyExactPayment } = useWatch({}) as PdpFormValues;

  useEffect(() => {
    setExactMonthlyPayment(monthlyExactPayment);
  }, [monthlyExactPayment]);
};
