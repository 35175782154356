import React from 'react';

import { Grid, Stack, TextField, Typography } from '@mui/material';

import { UsersTable } from 'components/administrations/users/table/UsersTable';
import { Layout } from 'components/molecules/layout';

import { useUsersListPage } from './useUsersListPage';

export const UsersListPage: React.FC = () => {
  const { columns, handleDeleteUser, isFetching, tableData, filterChangeHandler } =
    useUsersListPage();

  return (
    <Stack>
      <Layout>
        <Grid container spacing={2} paddingTop="48px" paddingX="16px">
          <Grid item xs={12}>
            <Typography data-cy="userListPage_pageTitle" variant="h6">
              Users
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              name="filterInput"
              onChange={filterChangeHandler}
              label="Filter users"
              data-cy="userListPage_filterInput"
              focused
              placeholder="Filter users by name or email address"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} paddingTop="40px">
            <UsersTable
              columns={columns}
              isLoading={isFetching}
              onDeleteUser={handleDeleteUser}
              tableData={tableData}
            />
          </Grid>
        </Grid>
      </Layout>
    </Stack>
  );
};
