import { useCallback } from 'react';
import { useCopyToClipboard } from 'react-use';

import { SnackbarKey, useSnackbar } from 'notistack';

interface HookResult {
  handleDismiss(): void;
  handleCopyToClipBoard(): void;
}

interface HookProps {
  id: SnackbarKey;
  buttonCopyText?: string;
}

export const useCustomSnackbar = ({ id, buttonCopyText }: HookProps): HookResult => {
  const { closeSnackbar } = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleCopyToClipBoard = useCallback(() => {
    if (buttonCopyText) {
      copyToClipboard(buttonCopyText);
    }
  }, [buttonCopyText, copyToClipboard]);

  return {
    handleDismiss,
    handleCopyToClipBoard,
  };
};
