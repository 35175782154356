import React from 'react';

import { TextFieldProps } from '@mui/material';

import { TextFormField } from './TextFormField';
import { NumberHandlingMode } from './useTextFormField';

export type TextFormFieldProps = TextFieldProps & {
  name: string;
  dataCy?: string;
  autoCompleteOff?: boolean;
  debounceEnabled?: boolean;
  debounceTimeMs?: number;
  numberHandlingMode?: NumberHandlingMode;
  step?: number;
};

export const NumberFormField: React.FC<TextFormFieldProps> = (props) => (
  <TextFormField
    {...props}
    InputProps={{
      type: 'number',
      inputMode: 'numeric',
      ...props.InputProps,
    }}
  />
);
