import React from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  CardContent,
  Collapse,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { Card } from 'components/atoms/card';
import { Scrollbar } from 'components/atoms/scrollbar';

import { useFilterBlock } from './useFilterBlock';

export interface FilterBlockProps {
  title: string;
  content: React.ReactElement;
  dataCyPrefix: string;
  defaultExpanded?: boolean;
  scrollable?: boolean;
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
}));

const CollapseButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
}));

export const FilterBlock: React.FC<FilterBlockProps> = ({
  title,
  content,
  dataCyPrefix,
  defaultExpanded = false,
  scrollable = false,
}) => {
  const { expand, handleCardClicked } = useFilterBlock({ defaultExpanded });

  return (
    <Card>
      <StyledCardContent onClick={(): void => handleCardClicked(!expand)}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="overline" fontWeight={500} data-cy={`${dataCyPrefix}_title`}>
            {title}
          </Typography>
          <CollapseButton data-cy={`${dataCyPrefix}_collapseButton`}>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </CollapseButton>
        </Stack>
      </StyledCardContent>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        {scrollable ? (
          <Scrollbar maxheight="200px">
            <CardContent>{content}</CardContent>
          </Scrollbar>
        ) : (
          <CardContent>{content}</CardContent>
        )}
      </Collapse>
    </Card>
  );
};
