import { useCallback, useContext, useEffect } from 'react';
import { UseFormReturn, useForm, useFormContext } from 'react-hook-form';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { PermissionsContext } from 'providers/PermissionsProvider';

import { usePdpData } from 'hooks/usePdpData';

import { getTradeOptionsModalValidationResolver } from 'utils/validationResolvers';

import {
  getDefaultFormValues,
  setFormValues,
  TradeOptionsModalFormValues,
} from './tradeOptionsModalService';

interface HookResult {
  tradeInUrl: string;
  showProfit: boolean;
  editActualCashValue: boolean;
  formMethods: UseFormReturn<TradeOptionsModalFormValues>;
  handleOnSubmit: () => Promise<void>;
}

export const useTradeOptionsModal = (): HookResult => {
  const {
    getValues: getValuesPdp,
    setValue: setValuePdp,
    formState: { dirtyFields: pdpDirtyFields },
  } = useFormContext<PdpFormValues>();
  const {
    tradeInOutstandingBal: tradeInOutstandingBalPdp,
    actualCashValue: actualCashValuePdp,
    tradeInValue: tradeInValuePdp,
    loanTaxCreditTo: loanTaxCreditToPdp,
    leaseTaxCreditTo: leaseTaxCreditToPdp,
    tradeVehicle: tradeVehiclePdp,
  } = getValuesPdp();

  const { isDeskingManager, isGeneralManager } = useContext(PermissionsContext);
  const editActualCashValue = isDeskingManager || isGeneralManager;
  const showProfit = isDeskingManager || isGeneralManager;

  const shouldDirtyActualCashValue = !!pdpDirtyFields.actualCashValue;

  const {
    dealer: { tradeInUrl },
  } = usePdpData();

  const tradeOptionsForm = useForm<TradeOptionsModalFormValues>({
    defaultValues: getDefaultFormValues(),
    resolver: getTradeOptionsModalValidationResolver(),
    mode: 'all',
  });

  const handleOnSubmit = useCallback(async () => {
    tradeOptionsForm.handleSubmit((values) => {
      setFormValues({ sourceForm: tradeOptionsForm, destinationFormSetter: setValuePdp, values });
    })();
  }, []);

  useEffect(() => {
    tradeOptionsForm.setValue('tradeInOutstandingBal', tradeInOutstandingBalPdp);
    tradeOptionsForm.setValue('actualCashValue', actualCashValuePdp, {
      shouldDirty: shouldDirtyActualCashValue,
    });
    tradeOptionsForm.setValue('tradeInValue', tradeInValuePdp);
    tradeOptionsForm.setValue('loanTaxCreditTo', loanTaxCreditToPdp);
    tradeOptionsForm.setValue('leaseTaxCreditTo', leaseTaxCreditToPdp);
    tradeOptionsForm.setValue('vin', tradeVehiclePdp?.vin);
    tradeOptionsForm.setValue('mileage', tradeVehiclePdp?.mileage);
    tradeOptionsForm.setValue('year', tradeVehiclePdp?.year);
    tradeOptionsForm.setValue('make', tradeVehiclePdp?.make);
    tradeOptionsForm.setValue('model', tradeVehiclePdp?.model);
  }, [
    tradeInOutstandingBalPdp,
    actualCashValuePdp,
    tradeInValuePdp,
    loanTaxCreditToPdp,
    leaseTaxCreditToPdp,
    tradeVehiclePdp?.vin,
    tradeVehiclePdp?.mileage,
    tradeVehiclePdp?.year,
    tradeVehiclePdp?.make,
    tradeVehiclePdp?.model,
  ]);

  return {
    formMethods: tradeOptionsForm,
    handleOnSubmit,
    showProfit,
    editActualCashValue,
    tradeInUrl,
  };
};
