import { useContext, useEffect } from 'react';
import { UseFormReturn, useForm, useFormContext } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PdpFormValues } from 'components/pdp/form-wrapper/usePdpFormWrapper';

import { PdpContext } from 'providers/PdpContextProvider';

import { leasesViewAtom, loansViewAtom } from 'models/GridViewModel';
import {
  ProgramSelectionMethod,
  leaseProgramSelectionMethodAtom,
  loanProgramSelectionMethodAtom,
  shouldSelectLowestPaymentLeaseAtom,
  shouldSelectLowestPaymentLoanAtom,
} from 'models/PaymentSelectionModel';

import { LeaseProgram, LoanProgram } from 'services/PdpApiService';
import { ReserveType } from 'services/ReserveService';

import { ReserveFormValues } from './ReserveModalService';

interface HookResult {
  showLease: boolean;
  showLoan: boolean;
  leaseProgram: LeaseProgram | undefined;
  loanProgram: LoanProgram | undefined;
  reserveForm: UseFormReturn<ReserveFormValues, any>;
  isReadOnly: boolean;
}

export const useReserveModal = (): HookResult => {
  const showLease = useRecoilValue(leasesViewAtom);
  const showLoan = useRecoilValue(loansViewAtom);
  const setShouldSelectLowestPaymentLease = useSetRecoilState(shouldSelectLowestPaymentLeaseAtom);
  const setLeaseProgramSelectionMethod = useSetRecoilState(leaseProgramSelectionMethodAtom);
  const setShouldSelectLowestPaymentLoan = useSetRecoilState(shouldSelectLowestPaymentLoanAtom);
  const setLoanProgramSelectionMethod = useSetRecoilState(loanProgramSelectionMethodAtom);
  const { shouldBeReadOnly: isReadOnly } = useContext(PdpContext);

  const { getValues: getValuesPdp, setValue: setValuePdp } = useFormContext<PdpFormValues>();
  const {
    leaseProgram,
    loanProgram,
    leaseMoneyFactorMarkup: leaseMoneyFactorMarkupPdp,
    loanAprMarkupPercent: loanAprMarkupPercentPdp,
    leaseReserveType: leaseReserveTypePdp,
    loanReserveType: loanReserveTypePdp,
  } = getValuesPdp();

  const reserveForm = useForm<ReserveFormValues>({
    defaultValues: {
      leaseReserveType: leaseReserveTypePdp ?? ReserveType.Auto,
      loanReserveType: loanReserveTypePdp ?? ReserveType.Auto,
      aprMarkupPercent: loanAprMarkupPercentPdp,
      moneyFactorMarkup: leaseMoneyFactorMarkupPdp,
      markedUpAcquisitionFee: getValuesPdp('leaseMarkedUpAcquisitionFee'),
    },
  });

  const {
    leaseReserveType,
    loanReserveType,
    moneyFactorMarkup,
    aprMarkupPercent,
    markedUpAcquisitionFee,
  } = reserveForm.watch();

  // TODO: For phase 1 of the modal rewrite, we are maintaining behavioral parity with the existing modal while introducing a level of decoupling from the PDP form
  // For the near term, we will rely on grid calculation to obtain profit information from the specified modal settings
  // But future work may include further decoupling by, for example, calling a separate endpoint to receive profit and reserve information
  // And then applying the reserve modal settings to the PDP upon close/confirm.
  useEffect(() => {
    setValuePdp('leaseReserveType', leaseReserveType);
    setShouldSelectLowestPaymentLease(leaseReserveType === ReserveType.Auto);
    setLeaseProgramSelectionMethod(
      leaseReserveType === ReserveType.Auto
        ? ProgramSelectionMethod.BestOverall
        : ProgramSelectionMethod.Manual
    );

    if (leaseReserveType === ReserveType.Auto) {
      reserveForm.setValue('moneyFactorMarkup', undefined);
    }
  }, [leaseReserveType]);

  useEffect(() => {
    setValuePdp('leaseMoneyFactorMarkup', moneyFactorMarkup);
  }, [moneyFactorMarkup]);

  useEffect(() => {
    setValuePdp('loanReserveType', loanReserveType);
    setShouldSelectLowestPaymentLoan(loanReserveType === ReserveType.Auto);
    setLoanProgramSelectionMethod(
      loanReserveType === ReserveType.Auto
        ? ProgramSelectionMethod.BestOverall
        : ProgramSelectionMethod.Manual
    );

    if (loanReserveType === ReserveType.Auto) {
      reserveForm.setValue('aprMarkupPercent', undefined);
    }
  }, [loanReserveType]);

  useEffect(() => {
    setValuePdp('loanAprMarkupPercent', aprMarkupPercent);
  }, [aprMarkupPercent]);

  useEffect(() => {
    setValuePdp('leaseMarkedUpAcquisitionFee', markedUpAcquisitionFee);
  }, [markedUpAcquisitionFee]);

  return { showLease, showLoan, leaseProgram, loanProgram, reserveForm, isReadOnly };
};
