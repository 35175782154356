import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { AxiosError } from 'axios';

import { AxiosContext } from 'providers/AxiosProvider';

import {
  getQuickQuotePdfApiUrl,
  QuickQuoteRequest,
} from 'services/quick-quote/QuickQuoteApiRequestService';

import { handleOnError } from 'utils/queryUtils';

interface HookOptions {
  carId: number;
  request: QuickQuoteRequest;
}

export const useQuickQuotePdfQuery = ({
  carId,
  request,
}: HookOptions): UseQueryResult<BlobPart, unknown> => {
  const axiosClient = useContext(AxiosContext);

  return useQuery(
    ['quickQuotePdfQuery', carId, request],
    async () => {
      const { data } = await axiosClient.post<BlobPart>(getQuickQuotePdfApiUrl(carId), request, {
        headers: { Accept: 'application/pdf' },
        responseType: 'blob',
      });

      return data;
    },
    {
      enabled: false,
      onError: (response: AxiosError<unknown, any>) => {
        handleOnError(response, 'Failed to download quick quote', null);
      },
    }
  );
};
