import React from 'react';

import { List, ListItem, ListItemText } from '@mui/material';

import { Rebate } from 'services/PdpApiService';

import { formatDate } from 'utils/formatterUtils';

interface Props {
  rebate: Pick<
    Rebate,
    | 'expiryDate'
    | 'programId'
    | 'groupAffiliation'
    | 'groupAffiliationDetail'
    | 'programRules'
    | 'isGuaranteed'
  >;
  firstNotCompatibleRebate?: Rebate;
}

export const IncentivesTooltipText: React.FC<Props> = ({
  rebate: {
    expiryDate,
    programId,
    groupAffiliation,
    groupAffiliationDetail,
    programRules = [],
    isGuaranteed,
  },
  firstNotCompatibleRebate,
}) => (
  <List disablePadding>
    {firstNotCompatibleRebate && (
      <ListItem
        data-cy={`pdpPage_paymentCustomization_rebates_${programId}_listItem_guaranteed`}
        divider
      >
        <ListItemText
          primary={`This Incentive is not compatible with: ${firstNotCompatibleRebate.description}`}
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    )}
    {isGuaranteed && (
      <ListItem
        data-cy={`pdpPage_paymentCustomization_rebates_${programId}_listItem_guaranteed`}
        divider
      >
        <ListItemText
          primary="This is a Guaranteed Incentive that is applicable without conditions."
          primaryTypographyProps={{ variant: 'body2' }}
        />
      </ListItem>
    )}
    {groupAffiliation && (
      <ListItem
        data-cy={`pdpPage_paymentCustomization_rebates_${programId}_listItem_groupAffiliation`}
        divider
      >
        Group Affiliation: {groupAffiliation}
      </ListItem>
    )}
    {groupAffiliationDetail && (
      <ListItem
        data-cy={`pdpPage_paymentCustomization_rebates_${programId}_listItem_groupAffiliationDetail`}
        divider
      >
        Group Affiliation Detail: {groupAffiliationDetail}
      </ListItem>
    )}
    {expiryDate && (
      <ListItem
        data-cy={`pdpPage_paymentCustomization_rebates_${programId}_listItem_expiryDate`}
        divider
      >
        Expiry Date: {formatDate(expiryDate)}
      </ListItem>
    )}
    {[...programRules]
      .sort((a, b) => a.type.localeCompare(b.type))
      .map((programRule, key) => (
        <ListItem
          data-cy={`pdpPage_paymentCustomization_rebates_${programId}_listItem_programRules${programRule.type}`}
          key={key}
          divider
        >
          {programRule.type} : {programRule.description}
        </ListItem>
      ))}
  </List>
);
