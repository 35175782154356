import React from 'react';

import { Info } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { MRT_Cell as Cell, MRT_Row as Row } from 'material-react-table';

import { ProgramGrid } from 'components/pdp/grid-view/table/GridTableService';
import { ReserveProfitInfo } from 'components/pdp/reserve-profit-info/ReserveProfitInfo';

import { ProgramType } from 'services/PdpApiService';

import { formatToCurrency } from 'utils/formatterUtils';

interface Props {
  cell: Cell<ProgramGrid>;
  row: Row<ProgramGrid>;
}
export const ProfitTableCell: React.FC<Props> = ({
  cell,
  row: {
    original: { profitData, type: programType },
  },
}) => {
  const isLease = programType === ProgramType.Lease;

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={0.5}>
      <Typography variant="body3" fontWeight={700}>
        {formatToCurrency(cell.getValue() as number)}
      </Typography>
      <Tooltip
        data-cy="pdpPage_girdTableTooltip_tooltip"
        title={
          <Stack spacing={3} data-cy="pdpPage_girdTableTooltip_tooltip_body">
            <Stack>
              <Typography
                data-cy="pdpPage_girdTableTooltip_leaseProfit_frontendProfitTitle"
                variant="body4"
                fontWeight={700}
              >
                Front-end profit
              </Typography>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Typography
                  data-cy="pdpPage_girdTableTooltip_leaseProfit_vehicleProfitTitle"
                  variant="body4"
                >
                  Vehicle profit
                </Typography>
                <Typography
                  data-cy="pdpPage_girdTableTooltip_leaseProfit_frontendProfitValue"
                  variant="body4"
                >
                  {formatToCurrency(profitData.vehicleProfit)}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Typography
                  data-cy="pdpPage_girdTableTooltip_leaseProfit_tradeInProfitTitle"
                  variant="body4"
                >
                  Trade-in profit
                </Typography>
                <Typography
                  data-cy="pdpPage_girdTableTooltip_leaseProfit_tradeInProfitValue"
                  variant="body4"
                >
                  {formatToCurrency(profitData.tradeInProfit)}
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography
                data-cy="pdpPage_girdTableTooltip_leaseProfit_backendProfitTitle"
                variant="body4"
                fontWeight={700}
              >
                Back-end profit
              </Typography>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Typography
                  data-cy="pdpPage_girdTableTooltip_leaseProfit_serviceAndProtectionProductsTitle"
                  variant="body4"
                >
                  Service and protection products
                </Typography>
                <Typography
                  data-cy="pdpPage_girdTableTooltip_leaseProfit_serviceAndProtectionProductsValue"
                  variant="body4"
                >
                  {formatToCurrency(profitData.serviceAndProductionProducts)}
                </Typography>
              </Stack>
              <ReserveProfitInfo profitData={profitData} dataCyPrefix="pdpPage_girdTableTooltip" />
              {isLease && (
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                  <Typography
                    data-cy="pdpPage_girdTableTooltip_leaseProfit_acquisitionFeeProfitTitle"
                    variant="body4"
                  >
                    Acquisition fee profit
                  </Typography>
                  <Typography
                    data-cy="pdpPage_girdTableTooltip_leaseProfit_acquisitionFeeProfitValue"
                    variant="body4"
                  >
                    {formatToCurrency(profitData.acquisitionProfit ?? 0)}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        }
      >
        <IconButton size="small">
          <Info fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
